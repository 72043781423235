import React, { useEffect, useState } from 'react'
import { Grid, Box, Tab, Tabs } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import { withTranslation } from 'react-i18next'
import { tablestyle } from '../../plugins/theme'
import { useDispatch, useSelector } from 'react-redux'
import MoneyOffIcon from '@material-ui/icons/MoneyOff'
import * as action from './actions'
import { getObject } from '../../utils'
import CustomSelect from '../../components/common/CustomSelect'
import { monthForInvoice } from '../../constant'
import { getMonthYear } from '../../utils'
import moment from 'moment'
import TabPanel from './TabPanel'
import RupeeIcon from "../../assets/images/RupeeIcon.svg"
import EditVouchers from './EditVouchers'

function MakeYearForInvoice() {
  let minYear = 2020
  let currentYear = moment().year()
  let currentMonth = moment().month()

  let yearForInvoice = []
  let i = 0
  for (minYear; currentYear >= minYear; minYear++) {
    yearForInvoice.push({
      value: minYear,
      label: minYear,
    })
    //   i++;
  }
  return { yearForInvoice }
}

let searchTypeInterval = null

function TotalInvoiceData(props) {
  const { value, label } = props
  return (
    <div className="total-meal-data">
      <span>{label}</span>
      <label>{value}</label>
    </div>
  )
}

function Vouchers(props) {
  const { classes, toast, loginUserType, loginUserPermission } = props

  let showVouchers, updateVouchers
  showVouchers = updateVouchers = true

  showVouchers =
    loginUserType == 'user' && loginUserPermission.vouchers
      ? loginUserPermission.vouchers.vouchers
      : showVouchers

  updateVouchers =
    loginUserType == 'user' && loginUserPermission.vouchers
      ? loginUserPermission.vouchers.updateVoucher
      : updateVouchers

  const dispatch = useDispatch()
  const { isLoading = false, vouchers = {} } =
    useSelector((state) => state.vouchers) || {}
  const {
    data: voucherList = [],
    count,
    ALL,
    ACTIVE,
    INACTIVE,
    EXPIRED,
    // USED,
  } = vouchers
  const [OpenEditPage, setOpenEditPage] = useState(false)
  const [editVoucher, seteditVoucher] = useState({})
  const [limit, setLimit] = useState(8)
  const [skip, setSkip] = useState(0)
  const [page, setPage] = useState(1)
  const [voucherType, setvoucherType] = useState('ALL')
  const [startDate, setStartDate] = useState(0)
  const [endDate, setEndDate] = useState(0)

  const { yearForInvoice } = MakeYearForInvoice()

  const currentYear = moment().year()
  const [yearDate, setyearDate] = useState({
    value: currentYear,
    label: currentYear,
  })
  const [loaderRowId, setLoaderRowId] = useState('')
  const currentMonth = moment().month()
  //  const [filter, setFilter] = useState({
  //    value: (moment().month()+1),
  //    label: "January",
  //  });
  const [filter, setFilter] = useState(monthForInvoice[currentMonth])

  function handleMonthChange(e) {
    setFilter(e)
    const { startDate: newStartDate, endDate: newEndDate } = getMonthYear(
      e.value,
      yearDate.value,
    )

    setEndDate(newEndDate)
    setStartDate(newStartDate)
  }

  function handleYearChange(e) {
    setyearDate(e)
    const { startDate: newStartDate, endDate: newEndDate } = getMonthYear(
      filter.value,
      e.value,
    )

    setEndDate(moment(newEndDate))
    setStartDate(moment(newStartDate))
  }

  const object = getObject('bb-user')
  let { accessToken = '' } = (object && JSON.parse(object)) || ''

  useEffect(() => {
    if (voucherType == 'ALL') {
      setTabValue(0)
    } else if (voucherType == 'ACTIVE') {
      setTabValue(1)
    } else if (voucherType == 'INACTIVE') {
      setTabValue(2)
    } else if (voucherType == 'EXPIRED') {
      setTabValue(3)
    }
    //  else if (voucherType == 'USED') {
    //   setTabValue(4)
    // }
    getVouchers()
  }, [limit, skip, startDate, endDate, voucherType])

  function getVouchers(search = '', nextPage) {
    if (!nextPage) {
      nextPage = page
    }
    setPage(nextPage)
    let skipToSend = (nextPage - 1) * limit
    let data = {}
    if (startDate == 0 && endDate == 0) {
      let newstartDate = moment([yearDate.value, filter.value - 1])
      let newendDate = moment(newstartDate).endOf('month')

      data = {
        limit,
        skip: skipToSend,
        startDate: moment(newstartDate).valueOf(),
        endDate: moment(newendDate).valueOf(),
        status: voucherType,
      }
    } else {
      data = {
        limit,
        skip: skipToSend,
        startDate: moment(startDate).valueOf(),
        endDate: moment(endDate).valueOf(),
        status: voucherType,
      }
    }
    if (showVouchers == true) {
      dispatch(action.getVoucherList(data))
    }
  }

  function handlePageChange(e, nextPage) {
    e.preventDefault()
    if (nextPage === page) {
      return
    }
    setPage(nextPage)
    setSkip((nextPage - 1) * limit)
  }

  const handleEditVoucher = (voucher) => {
    setOpenEditPage(true)
    seteditVoucher(voucher)
  }

  const closeEditPage = () => {
    setOpenEditPage(false)
    getVouchers();
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }
  function getVoucherData(voucher) {
    setvoucherType(voucher)

    setLimit(8)
    setPage(1)
    setSkip(0)
  }
  const [tabValue, setTabValue] = React.useState(0)
  const handleTabChange = (event, newValue) => {
    if (
      newValue == 1 ||
      newValue == 2 ||
      newValue == 3 ||
      newValue == 0 ||
      newValue == 4
    ) {
      setTabValue(newValue)
      let voucher =
        newValue == 0
          ? 'ALL'
          : newValue == 1
            ? 'ACTIVE'
            : newValue == 2
              ? 'EXPIRED'
              : newValue == 3
                ? 'INACTIVE'
                : newValue == 4
                  ? 'USED'
                  : ''
      getVoucherData(voucher)
    }
  }

  if (showVouchers == true) {
    if (OpenEditPage == false) {
      return (
        <Grid container className="location-container">
          <Grid item xs={12}>
            <Grid container className="location-head">
              <Grid item xs={12} sm={12} md={4} lg={4} className="heading">
                <h2>
                  <svg xmlns="http://www.w3.org/2000/svg" width="28.367" height="24.934" viewBox="0 0 169.828 250.03">
                    <g id="Rupee_Icon" data-name="Rupee Icon" transform="translate(0.033 -802.371)">
                      <path id="path4158" d="M99.017,1052.3,8.44,938.97l.523-22.46c42.51,2.93,75.559-1.57,83.248-41.78l-90.578-.52,14.66-24.55,72.253,1.04C77.537,827.82,47.26,825,.062,826.68l16.231-24.03,153.41-.229-15.184,23.731H112.11c7.751,8.182,13.424,17.6,13.613,25.591l43.98-.522-15.184,23.5-29.32.522C120.622,910.3,88.412,931.06,47.71,935.829l91.184,116.44-39.874.022h0Z" fill="#f9c546" stroke="#000" stroke-width="0.099" />
                    </g>
                  </svg>
                  Vouchers
                </h2>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Box>
                    <CustomSelect
                      minWidth={180}
                      options={monthForInvoice}
                      placeholder="Filter"
                      isSearchable
                      onChange={(opt) => handleMonthChange(opt)}
                      value={filter}
                    />
                  </Box>
                  <Box pl={1}>
                    <CustomSelect
                      minWidth={180}
                      options={yearForInvoice}
                      placeholder="Filter"
                      isSearchable
                      onChange={(opt) => handleYearChange(opt)}
                      value={yearDate}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} className="">
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label=""
              className="cus-tabs-design"
            >
              <Tab
                icon={<TotalInvoiceData value={ALL} label="All Vouchers" />}
                {...a11yProps(0)}
                className="tab-allinvoice"
              ></Tab>
              <Tab
                icon={
                  <TotalInvoiceData value={ACTIVE} label="Active Vouchers" />
                }
                {...a11yProps(1)}
                className="tab-newinvoice"
              ></Tab>
              {/* <Tab
                icon={
                  <TotalInvoiceData
                    value={INACTIVE}
                    label="Inactive Vouchers"
                  />
                }
                {...a11yProps(2)}
                className="tab-paidinvoice"
              ></Tab> */}
              <Tab
                icon={
                  <TotalInvoiceData value={EXPIRED} label="Expired Vouchers" />
                }
                {...a11yProps(3)}
                className="tab-holdinoice"
              ></Tab>
              {/* <Tab
                icon={<TotalInvoiceData value={USED} label="Used Vouchers" />}
                {...a11yProps(4)}
                className="tab-processedInvoice"
              ></Tab> */}
            </Tabs>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} className="">
            <TabPanel
              voucherList={voucherList}
              value={tabValue}
              index={tabValue}
              toast={toast}
              isLoading={isLoading}
              classes={classes}
              limit={limit}
              page={page}
              count={count}
              setPage={setPage}
              setSkip={setSkip}
              handleEditVoucher={handleEditVoucher}
              loaderRowId={loaderRowId}
              updateVouchers={updateVouchers}
            ></TabPanel>
          </Grid>
        </Grid>
      )
    } else {
      return (
        <EditVouchers
          closeEditPage={closeEditPage}
          editVoucher={editVoucher}
          classes={classes}
          toast={toast}
        />
      )
    }
  } else {
    return (
      <Grid container className="location-container">
        <Grid item xs={12}>
          <Grid container className="location-head">
            <Grid item xs={12} sm={12} md={4} lg={4} className="heading">
              <h2>
                <MoneyOffIcon />
                Vouchers
              </h2>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Box display="flex" justifyContent="space-between" p={2}>
            <h6>You do not have permission to view this page</h6>
          </Box>
        </Grid>
      </Grid>
    )
  }
}

export default withTranslation('translations')(withStyles(tablestyle)(Vouchers))
