import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  Box,
  Table,
  TableCell,
  TableBody,
  IconButton,
  Typography,
  TableRow,
  TableHead,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

function EnhancedTableHead(props) {
  const {
    headCells
  } = props;

  return (

    <TableHead className="">
      <TableRow>
        {
          headCells.map((cell, key) => {
            return <TableCell colSpan={2} key={key}>
              {cell.label}
            </TableCell>
          })
        }
      </TableRow>
    </TableHead>
  );
}

const OrdersTableHeaders = [
  {
    id: "item_name",
    numeric: false,
    disablePadding: false,
    label: "Item Name",
  },
  { id: "quantity", numeric: false, disablePadding: false, label: "Quantity" },
  { id: 'price', numeric: false, disablePadding: false, label: 'Price'}
];

function HandleOrderItems(props) {
  const { orderItems, numberFormat, orderRefId } = props;

  return (
    (orderItems.length) ?
      <main className="items-details-modal">
        <div className="items-modal-head">
          <h5>Order Items</h5>
        </div>
        <div className="table-design w-100">
          <Table className="table-program" stickyHeader aria-label="sticky table">
            <EnhancedTableHead headCells={OrdersTableHeaders} />
            <TableBody>
              {orderItems && orderItems.length
                ? orderItems.map((Item) => {
                  const { product, quantity}  = Item;
                  const {id, name : itemName, variations, price, discount, discount_type} = product;
                  let variationStr = '';
                  for(let x in variations) {
                    if(variations[x]['values'].length) {
                        for(let y in variations[x]['values']) {
                            variationStr += variations[x]['values'][y]['label'] ? variations[x]['values'][y]['label']+', ' : ''; 
                        }
                    }
                  }
                  variationStr = variationStr ? variationStr.slice(0, -2) : variationStr;
                  let discountedPrice = price
                  if(discount) {
                    if(discount_type == 'percent') {
                      discountedPrice = parseFloat(price - price * discount/100).toFixed(2);
                    } else {
                      discountedPrice = parseFloat(price - discount).toFixed(2);
                    }
                  }
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      className="cursor_default"
                      key={id}
                    >
                      <TableCell colSpan={2} className="table-custom-width">
                      <Box>
                        <span>{itemName}</span><br/>
                        <span>{variationStr}</span>  
                      </Box>  
                      </TableCell>
                      <TableCell colSpan={2} className="table-custom-width">
                        <span>{quantity}</span>
                      </TableCell>
                      <TableCell colSpan={2} className="table-custom-width">
                        {price == discountedPrice ? <span>&#8377;{numberFormat(price)}</span> : <span><s>&#8377;{numberFormat(price)}</s> &#8377;{numberFormat(discountedPrice)}</span>  }
                      </TableCell>
                    </TableRow>
                  );
                })
                : ""}
            </TableBody>
          </Table>
        </div>
      </main>
      : ""
  );
}


function HandleBillingDetails(props) {
  const { billingDetails, numberFormat } = props;

  return (Object.keys(billingDetails).length) ?
      <main className="items-details-modal">
        <div className="items-modal-head">
          <h5>Bill Details</h5>
        </div>
        <div className="billing-table-design w-100">
          <Table className="table-program" stickyHeader aria-label="sticky table">
            <TableBody> 
                    <TableRow
                      hover
                      role="checkbox"
                      className="cursor_default"
                      key='sub_total'
                    >
                      <TableCell colSpan={2} className="table-custom-width">
                      <Box>
                        <span>Sub Total </span>
                      </Box>  
                      </TableCell>
                      <TableCell colSpan={2} className="table-custom-width">
                        <span></span>
                      </TableCell>
                      <TableCell colSpan={2} className="table-custom-width">
                        <span>&nbsp;&nbsp;&#8377;{numberFormat(billingDetails.sub_total)}</span>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      hover
                      role="checkbox"
                      className="cursor_default"
                      key='discount'
                    >
                      <TableCell colSpan={2} className="table-custom-width">
                      <Box>
                        <span>Discount </span>
                      </Box>  
                      </TableCell>
                      <TableCell colSpan={2} className="table-custom-width">
                        <span></span>
                      </TableCell>
                      <TableCell colSpan={2} className="table-custom-width">
                        <span>-&#8377;{numberFormat(billingDetails.discount)}</span>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      hover
                      role="checkbox"
                      className="cursor_default"
                      key='tax'
                    >
                      <TableCell colSpan={2} className="table-custom-width">
                      <Box>
                        <span>Tax </span>
                      </Box>  
                      </TableCell>
                      <TableCell colSpan={2} className="table-custom-width">
                        <span></span>
                      </TableCell>
                      <TableCell colSpan={2} className="table-custom-width">
                        <span>+&#8377;{numberFormat(billingDetails.tax)}</span>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      hover
                      role="checkbox"
                      className="cursor_default"
                      key='grand_total'
                    >
                      <TableCell colSpan={2} className="table-custom-width">
                      <Box>
                        <span><b>Grand Total</b></span>
                      </Box>  
                      </TableCell>
                      <TableCell colSpan={2} className="table-custom-width">
                        <span></span>
                      </TableCell>
                      <TableCell colSpan={2} className="table-custom-width">
                      <span><b>&nbsp;&nbsp;&#8377;{numberFormat(billingDetails.grand_total)}</b></span>
                      </TableCell>
                    </TableRow>
            </TableBody>
          </Table>
        </div>
      </main>
      : "";
}





export default function ViewTuckShopOrderItems(props) {
  const { actionLabel, orderItems, numberFormat, orderRefId, billingDetails } = props;
  const [open, setOpen] = useState(false);
  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <div className="tuck-shop-order-item-field">
      <a
        className="daily-supply-listing view-meal-button"
        onClick={handleClickOpen}
      >
        {actionLabel}
      </a>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Order Summary: {orderRefId}
        </DialogTitle>
        <DialogContent dividers>
          <Box py={1}>
            <HandleOrderItems numberFormat={numberFormat} orderItems={orderItems} />
          </Box>
          <Box py={1}>
            <HandleBillingDetails numberFormat={numberFormat} billingDetails={billingDetails} />
          </Box>
        </DialogContent>
      </Dialog>
    </div>

  );
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
