import axios from "axios";
import { apiUrl } from "../constant";
import qs from "querystring";
import { logOut } from '../utils'

export function generateUrl(path) {
  if (path.includes("http")) {
    return path;
  }
  return apiUrl + path;
}

export function apiReq(
  endPoint,
  data,
  method,
  headers,
  requestOptions = {},
  config = {}
) {
  return new Promise((res, rej) => {
    headers = {
      ...getHeaders(),
      ...headers,
    };
    if (method == "get" || method == "delete") {
      data = {
        ...requestOptions,
        params: { ...data },
        paramsSerializer: function (params) {
          return qs.stringify(params);
        },
        headers,
      };
    }

    let updatedData = data instanceof FormData ? data : { ...data };

    axios.defaults.timeout = 60000;
    axios[method](endPoint, updatedData, { headers, ...config })
      .then((result) => {
        let { data, status } = result;
        if (status === 200) {
          return res(data);
        }
        return rej(data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401 && !endPoint.includes('/login')) {
          logOut('bb-user')
          logOut('bb-user-token')
          window.location.href = '/login'
        }
        // const data = err && err.response && err.response.data && err.response.data.payload || {};
        const data=err.response.data;
        return rej(data);
      });
  });
}

export function apiPost(endPoint, data, headers = {}, config = {}) {
  return apiReq(generateUrl(endPoint), data, "post", headers, {}, config);
}

export function apiDelete(endPoint, data, headers = {}) {
  return apiReq(generateUrl(endPoint), data, "delete", headers);
}

export function apiGet(endPoint, data, headers = {}, requestOptions) {
  return apiReq(generateUrl(endPoint), data, "get", headers, requestOptions);
}

export function apiPut(endPoint, data, headers = {}) {
  return apiReq(generateUrl(endPoint), data, "put", headers);
}

export function getHeaders() {
  let token = window.localStorage.getItem('bb-user-token')
  if(typeof token === "undefined"){
    token="";
  }
  if(token){
    token=JSON.parse(token)
  }
  return {
    Authorization: `Bearer ${token || null}`,
  };
}

export function downloadFile(endPoint, data, fileType, fileName) {
  return new Promise((res, rej) => {
    //Add headers
    let headers = {
      ...getHeaders(),
      Accept: `application/${fileType.toLowerCase()}`,
    };
    //Generate exact url
    endPoint = generateUrl(endPoint);

    //Append data to Url
    if(typeof data == "object" && Object.keys(data).length > 0) {
      for (let key in data) {
          if(endPoint.includes('?')) {
            endPoint += `&${key}=${data[key]}`
          } else {
            endPoint += `?${key}=${data[key]}`
          }
      }
    }
   //API call to download a file
    fetch(endPoint, {
      headers: headers,
      responseType: 'blob',
    })
      .then(response => {
        if (response.ok) {
          // let contentType = response.headers.get('content-type');
          return response.blob();
        }
      })
      .then(blob => {
        //Download file on client end.
        downloadFileOnClientEnd({
              data: blob,
              fileName: fileName,
              fileType: `text/${fileType.toLowerCase()}`,
          });
          return res("Downloaded successfully.");
      })
      .catch((err) => {
        const data=err?.response?.data;
        return rej(data ? data : err);
      });
  });
}


const downloadFileOnClientEnd = ({ data, fileName, fileType }) => {
  const blob = new Blob([data], { type: fileType });

  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};