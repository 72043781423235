import React from "react";
import { Grid } from '@material-ui/core';

export default function AuthWrapper(props) {
  return (
    <Grid className="d-flex login-custom-width">
      <Grid item xs={12} sm={12} md={7} lg={6} className="form-wrapper d-flex align-items-center">
        {props.children}
      </Grid>
      <Grid item md={5} hidden  lg={6} className="auth-bg d-xs-none d-sm-none d-md-block">
        <img alt="login" src='/images/login-image.png' className="logimg-icon" />
      </Grid>
    </Grid>
  )
}


