import React from 'react';
import clsx from "clsx";

export default function MealTypeDot({ mealType }) {
  let isVeg = false
  if (mealType === 'VEG') {
    isVeg = true
  }
  return <span className={clsx('meal-type-dot', { green: isVeg })}>
    <span className="inner"></span>
  </span>
}