import React from 'react'
import moment from 'moment'
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core'
import EnhancedTableHead from '../../components/common/EnhancedTableHead'
import MealTypeDot from '../Common/MealTypeDot'

const WeekDays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

function TabPanel(props) {
  const objs = []
  const { menu, tabValue, workingDays, filter } = props
  Object.entries(menu).forEach(([key, value]) => {
    if (key == 'week1_3') {
      objs.push(
        <MenuListing
          value={value}
          key={key}
          tabValue={tabValue}
          filter={filter}
          week="1st"
          workingWeekDays={workingDays.week1_3}
        />,
      )
    } else if (key == 'week2_4') {
      objs.push(
        <MenuListing
          value={value}
          key={key}
          tabValue={tabValue}
          filter={filter}
          week="2nd"
          workingWeekDays={workingDays.week2_4}
        />,
      )
    }
  })

  return objs
}

function MenuListing(props) {
  const { value, week, tabValue, workingWeekDays, filter } = props
  let TableHeaders = [
    // { id: "type", numeric: false, disablePadding: false, label: "Type" },
    { id: 'Monday', numeric: false, disablePadding: false, label: 'Monday' },
    { id: 'Tuesday', numeric: false, disablePadding: false, label: 'Tuesday' },
    {
      id: 'Wednesday',
      numeric: false,
      disablePadding: false,
      label: 'Wednesday',
    },
    {
      id: 'Thursday',
      numeric: false,
      disablePadding: false,
      label: 'Thursday',
    },
    { id: 'Friday', numeric: false, disablePadding: false, label: 'Friday' },
    {
      id: 'Saturday',
      numeric: false,
      disablePadding: false,
      label: 'Saturday',
    },
    { id: 'Sunday', numeric: false, disablePadding: false, label: 'Sunday' },
  ]
  let countMaxValue = 0

  Object.keys(value).map(function (key, valueKey) {
    value[key].map((item, itemKey) => {
      if (item.mealCategory == 'COMBO') {
        if (itemKey == 0) {
          let i = 0
          for (i = 0; i < TableHeaders.length; i++) {
            if (TableHeaders[i].label == key) {
              TableHeaders[i].label = key + ' (Combo)'
            }
          }
        }
      }
      if (itemKey > countMaxValue) {
        countMaxValue = itemKey
      }
    })
  })

  let rows = []
  if (workingWeekDays) {
    let totalKey = 0
    WeekDays.map(function (key, j) {
      let removeIndex = j - totalKey
      if (workingWeekDays[key] == false) {
        totalKey++
        TableHeaders.splice(removeIndex, 1)
      }
    })
    let Start = 0
    let rowKeys = 0
    if (countMaxValue) {
      for (Start = 0; Start <= countMaxValue; Start++) {
        rows.push(
          <MenuTableRow
            workingWeekDays={workingWeekDays}
            rowKeys={rowKeys}
            item={value}
            start={Start}
            filter={filter}
          />,
        )
        rowKeys++
      }
    }
  }

  return (
    <div className="weekly-menu-listing">
      <div className="menu-heading">
        <h2>
          {week == '1st'
            ? '1st, 3rd & 5th week'
            : week == '2nd'
              ? '2nd & 4th week'
              : ''}
        </h2>
      </div>
      <TableContainer className="menu-table-head">
        <Table className="table-program" stickyHeader aria-label="sticky table">
          <EnhancedTableHead headCells={TableHeaders} />

          <TableBody>{rows}</TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

function MenuTableRow(props) {
  const { workingWeekDays, item, start, rowKeys, filter } = props

  return (
    <TableRow hover role="checkbox" className="cursor_default" key={rowKeys}>
      {workingWeekDays && workingWeekDays.Monday == true ? (
        <TableCell key={1} className="table-custom-width">
          {(item.Monday && item.Monday[start]) && ((filter.value == 'all') ||
            (filter.value == 'veg' && item.Monday[start].DishType == 'VEG') ||
            (filter.value == 'nonveg' &&
              item.Monday[start].DishType == 'NON VEG') ||
            (filter.value == 'snacks' &&
              item.Monday[start].DishType == 'SNACKS')) ? (
            <>
              {item.Monday &&
                item.Monday[start] &&
                item.Monday[start].mealType == 'VEG' ? (
                <MealTypeDot mealType="VEG" />
              ) : item.Monday &&
                item.Monday[start] &&
                item.Monday[start].mealType == 'NON VEG' ? (
                <MealTypeDot mealType="NONVeg" />
              ) : (
                ''
              )}

              {item.Monday && item.Monday[start]
                ? item.Monday[start].itemName
                : '-'}
            </>
          ) : (
            '-'
          )}
        </TableCell>
      ) : (
        ''
      )}
      {workingWeekDays && workingWeekDays.Tuesday == true ? (
        <TableCell key={2} className="table-custom-width">
          {(item.Tuesday && item.Tuesday[start]) && ((filter.value == 'all') ||
            (filter.value == 'veg' && item.Tuesday[start].DishType == 'VEG') ||
            (filter.value == 'nonveg' &&
              item.Tuesday[start].DishType == 'NON VEG') ||
            (filter.value == 'snacks' &&
              item.Tuesday[start].DishType == 'SNACKS')) ? (
            <>
              {item.Tuesday &&
                item.Tuesday[start] &&
                item.Tuesday[start].mealType == 'VEG' ? (
                <MealTypeDot mealType="VEG" />
              ) : item.Tuesday &&
                item.Tuesday[start] &&
                item.Tuesday[start].mealType == 'NON VEG' ? (
                <MealTypeDot mealType="NONVeg" />
              ) : (
                ''
              )}

              {item.Tuesday && item.Tuesday[start]
                ? item.Tuesday[start].itemName
                : '-'}
            </>
          ) : (
            '-'
          )}
        </TableCell>
      ) : (
        ''
      )}
      {workingWeekDays && workingWeekDays.Wednesday == true ? (
        <TableCell key={3} className="table-custom-width">
          {(item.Wednesday && item.Wednesday[start]) && ((filter.value == 'all') ||
            (filter.value == 'veg' && item.Wednesday[start].DishType == 'VEG') ||
            (filter.value == 'nonveg' &&
              item.Wednesday[start].DishType == 'NON VEG') ||
            (filter.value == 'snacks' &&
              item.Wednesday[start].DishType == 'SNACKS')) ? (
            <>
              {item.Wednesday &&
                item.Wednesday[start] &&
                item.Wednesday[start].mealType == 'VEG' ? (
                <MealTypeDot mealType="VEG" />
              ) : item.Wednesday &&
                item.Wednesday[start] &&
                item.Wednesday[start].mealType == 'NON VEG' ? (
                <MealTypeDot mealType="NONVeg" />
              ) : (
                ''
              )}

              {item.Wednesday && item.Wednesday[start]
                ? item.Wednesday[start].itemName
                : '-'}
            </>
          ) : (
            '-'
          )}
        </TableCell>
      ) : null}
      {workingWeekDays && workingWeekDays.Thursday == true ? (
        <TableCell key={4} className="table-custom-width">
          {(item.Thursday && item.Thursday[start]) && ((filter.value == 'all') ||
            (filter.value == 'veg' && item.Thursday[start].DishType == 'VEG') ||
            (filter.value == 'nonveg' &&
              item.Thursday[start].DishType == 'NON VEG') ||
            (filter.value == 'snacks' &&
              item.Thursday[start].DishType == 'SNACKS')) ? (
            <>
              {item.Thursday &&
                item.Thursday[start] &&
                item.Thursday[start].mealType == 'VEG' ? (
                <MealTypeDot mealType="VEG" />
              ) : item.Thursday &&
                item.Thursday[start] &&
                item.Thursday[start].mealType == 'NON VEG' ? (
                <MealTypeDot mealType="NONVeg" />
              ) : (
                ''
              )}

              {item.Thursday && item.Thursday[start]
                ? item.Thursday[start].itemName
                : '-'}
            </>
          ) : (
            '-'
          )}
        </TableCell>
      ) : null}
      {workingWeekDays && workingWeekDays.Friday == true ? (
        <TableCell key={5} className="table-custom-width">
          {(item.Friday && item.Friday[start]) && ((filter.value == 'all') ||
            (filter.value == 'veg' && item.Friday[start].DishType == 'VEG') ||
            (filter.value == 'nonveg' &&
              item.Friday[start].DishType == 'NON VEG') ||
            (filter.value == 'snacks' &&
              item.Friday[start].DishType == 'SNACKS')) ? (
            <>
              {item.Friday &&
                item.Friday[start] &&
                item.Friday[start].mealType == 'VEG' ? (
                <MealTypeDot mealType="VEG" />
              ) : item.Friday &&
                item.Friday[start] &&
                item.Friday[start].mealType == 'NON VEG' ? (
                <MealTypeDot mealType="NONVeg" />
              ) : (
                ''
              )}

              {item.Friday && item.Friday[start]
                ? item.Friday[start].itemName
                : '-'}
            </>
          ) : (
            '-'
          )}
        </TableCell>
      ) : null}
      {workingWeekDays && workingWeekDays.Saturday == true ? (
        <TableCell key={6} className="table-custom-width">
          {(item.Saturday && item.Saturday[start]) && ((filter.value == 'all') ||
            (filter.value == 'veg' && item.Saturday[start].DishType == 'VEG') ||
            (filter.value == 'nonveg' &&
              item.Saturday[start].DishType == 'NON VEG') ||
            (filter.value == 'snacks' &&
              item.Saturday[start].DishType == 'SNACKS')) ? (
            <>
              {item.Saturday &&
                item.Saturday[start] &&
                item.Saturday[start].mealType == 'VEG' ? (
                <MealTypeDot mealType="VEG" />
              ) : item.Saturday &&
                item.Saturday[start] &&
                item.Saturday[start].mealType == 'NON VEG' ? (
                <MealTypeDot mealType="NONVeg" />
              ) : (
                ''
              )}

              {item.Saturday && item.Saturday[start]
                ? item.Saturday[start].itemName
                : '-'}
            </>
          ) : (
            '-'
          )}
        </TableCell>
      ) : null}
      {workingWeekDays && workingWeekDays.Sunday == true ? (
        <TableCell key={7} className="table-custom-width">
          {(item.Sunday && item.Sunday[start]) && ((filter.value == 'all') ||
            (filter.value == 'veg' && item.Sunday[start].DishType == 'VEG') ||
            (filter.value == 'nonveg' &&
              item.Sunday[start].DishType == 'NON VEG') ||
            (filter.value == 'snacks' &&
              item.Sunday[start].DishType == 'SNACKS')) ? (
            <>
              {item.Sunday &&
                item.Sunday[start] &&
                item.Sunday[start].mealType == 'VEG' ? (
                <MealTypeDot mealType="VEG" />
              ) : item.Sunday &&
                item.Sunday[start] &&
                item.Sunday[start].mealType == 'NON VEG' ? (
                <MealTypeDot mealType="NONVeg" />
              ) : (
                ''
              )}

              {item.Sunday && item.Sunday[start]
                ? item.Sunday[start].itemName
                : '-'}
            </>
          ) : (
            '-'
          )}
        </TableCell>
      ) : null}
    </TableRow>
  )
}

export default TabPanel
