export const GET_OPTTIME = 'GET_OPTTIME'
export const GET_OPTTIME_SUCCEEDED = 'GET_OPTTIME_SUCCEEDED'
export const GET_OPTTIME_FAILED = 'GET_OPTTIME_FAILED'

export const GET_TODAY_MEAL = 'GET_TODAY_MEAL'
export const GET_TODAY_MEAL_SUCCEEDED = 'GET_TODAY_MEAL_SUCCEEDED'
export const GET_TODAY_MEAL_FAILED = 'GET_TODAY_MEAL_FAILED'

export const GET_TODAY_SINGLE_MEAL = 'GET_TODAY_SINGLE_MEAL'
export const GET_TODAY_SINGLE_MEAL_SUCCEEDED = 'GET_TODAY_SINGLE_MEAL_SUCCEEDED'
export const GET_TODAY_SINGLE_MEAL_FAILED = 'GET_TODAY_SINGLE_MEAL_FAILED'

export const UPDATE_MEAL_DATA = 'UPDATE_MEAL_DATA'
export const UPDATE_MEAL_DATA_SUCCEEDED = 'UPDATE_MEAL_DATA_SUCCEEDED'
export const UPDATE_MEAL_DATA_FAILED = 'UPDATE_MEAL_DATA_FAILED'


const initState = {
  isLoading: false,
  opttime: {},
  todaymeal: [],
  mealdata: {},
  singleMealData: {}
}

export default function (state = { ...initState }, action) {
  switch (action.type) {
    case GET_OPTTIME:
      return {
        ...state,
        isLoading: true
      }
    case GET_OPTTIME_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        opttime: action.payload
      }
    case GET_OPTTIME_FAILED:
      return {
        ...state,
        isLoading: false,
        opttime: {}
      }
    case GET_TODAY_MEAL:
      return {
        ...state,
        isLoading: true
      }
    case GET_TODAY_MEAL_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        todaymeal: action.payload
      }
    case GET_TODAY_MEAL_FAILED:
      return {
        ...state,
        isLoading: false,
        todaymeal: {}
      }

    case GET_TODAY_SINGLE_MEAL:
      return {
        ...state,
        isLoading: true
      }
    case GET_TODAY_SINGLE_MEAL_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        singleMealData: action.payload
      }
    case GET_TODAY_SINGLE_MEAL_FAILED:
      return {
        ...state,
        isLoading: false,
        singleMealData: {}
      }

    case UPDATE_MEAL_DATA:
      return {
        ...state,
        isLoading: true
      }
    case UPDATE_MEAL_DATA_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        mealdata: action.payload
      }
    case UPDATE_MEAL_DATA_FAILED:
      return {
        ...state,
        isLoading: false,
        mealdata: {}
      }

    default:
      return state
  }
}