import React, { useState } from "react";
// import PropTypes from "prop-types";
// import { makeStyles } from "@material-ui/core/styles";
// import Typography from "@material-ui/core/Typography";
import {
  Grid,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Box,
  // Paper,
  // Tab,
  // Tabs,
  // AppBar,
  // Badge,
  CircularProgress,
} from "@material-ui/core";
// import AddIcon from "@material-ui/icons/Add";
import EnhancedTableHead from "../../components/common/EnhancedTableHead";
import TableLoader from "../../components/common/TableLoader";
import CustomTablePagination from "../../components/common/CustomPagination";
// import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
// import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { getObject, positiveAmount, timeFormat, numberFormat } from "../../utils";
import ToolTip from "../../components/common/ToolTip";
import UpdateIcon from "../../assets/images/refresh.svg";
import SuccessIcon from "../../assets/images/success.gif";
import ErrorIcon from "../../assets/images/error.gif";
import DownLoadIcon from "../../assets/images/downloadIcon.svg";

const manageLocationsHead = [
  { id: "#", numeric: false, disablePadding: false, label: "#" },
  {
    id: "startTime",
    numeric: false,
    disablePadding: false,
    label: "From - To",
  },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
  { id: "amount", numeric: false, disablePadding: false, label: "Total" },
  { id: "actions", numeric: false, disablePadding: false, label: "Action" },
];

function TabPanel(props) {
  const [editable, setEditable] = useState(false);

  const object = getObject("bb-user");

  const userObject = (object && JSON.parse(object)) || "";
  const { name = "", userType = "" } = (userObject && userObject.userDetails) || "";

  const user_id = (userObject.userDetails._id) || "";

  function handlePageChange(e, nextPage) {
    e.preventDefault();
    if (nextPage === page) {
      return;
    }

    setPage(nextPage);
    setSkip((nextPage - 1) * limit);
  }

  function capitalize(s) {
    return s[0].toUpperCase() + s.slice(1);
  }

  const {
    isLoading,
    employees,
    value,
    index,
    classes,
    limit,
    page,
    count,
    setPage,
    setSkip,
    toast,
    showupdateInvoice,
    showdownloadInvoice,
    handleUpdateModal,
    loaderRowId,
    invoiceDownloadLoading,
    handleInvoiceDownload,
    ...other
  } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className="table-design w-100"
    >
      {isLoading ? (
        <>
          <TableContainer className={classes.container}>
            <Table
              className="table-program"
              stickyHeader
              aria-label="sticky table"
            >
              <EnhancedTableHead headCells={manageLocationsHead} />
            </Table>
          </TableContainer>
          <TableLoader />
        </>
      ) : null}

      {!isLoading ? (
        <TableContainer className={classes.container}>
          <Table
            className="table-program"
            stickyHeader
            aria-label="sticky table"
          >
            <EnhancedTableHead
              // order={order}
              // orderBy={orderBy}
              // onRequestSort={handleRequestSort}
              headCells={manageLocationsHead}
            />
            <TableBody>
              {employees && employees.length ? (
                employees.map((employee) => {
                  const {
                    _id,
                    invoiceNumber,
                    totalAmount,
                    fromDate,
                    toDate,
                    status,
                    pdfUrl,
                  } = employee;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      className="cursor_default"
                      key={_id}
                    >
                      <TableCell className="table-custom-width">
                        {invoiceNumber || "-"}
                      </TableCell>
                      <TableCell className="table-custom-width">
                        {fromDate && timeFormat(fromDate || "-")} -{" "}
                        {toDate && timeFormat(toDate || "-")}
                      </TableCell>
                      <TableCell className="table-custom-width">
                        {status ? (
                          status == "NEW" ? (
                            <div className="daily-supply-listing-status invoice-status-new">
                              NEW
                            </div>
                          ) : status == "PAID" ? (
                            <div className="daily-supply-listing-status invoice-status-paid">
                              PAID
                            </div>
                          ) : status == "HOLD" ? (
                            <div className="daily-supply-listing-status invoice-status-hold">
                              HOLD
                            </div>
                          ) : status == "PROCESSED" ? (
                            <div className="daily-supply-listing-status invoice-status-processed">
                              PROCESSED
                            </div>
                          ) : (
                            "-"
                          )
                        ) : (
                          "-"
                        )}
                      </TableCell>
                      <TableCell className="table-custom-width">
                        {" "}
                        {(totalAmount) ?
                          <>&#8377;{numberFormat(totalAmount)}</> :
                          <>&#8377;0</>}
                      </TableCell>
                      <TableCell className="table-custom-width">
                        <div className="td-action-btns">
                          <ul>
                            {showupdateInvoice == true ? (
                              <ToolTip title="Update">
                                <li>
                                  <img
                                    onClick={() =>
                                      handleUpdateModal(employee)
                                    }
                                    className="download-img mr-3"
                                    src={UpdateIcon}
                                  />
                                </li>
                              </ToolTip>
                            ) : (
                              ""
                            )}
                            {loaderRowId === _id &&
                              invoiceDownloadLoading == "LOAD" ? (
                              <span className="progress cus-loader-design">
                                <CircularProgress
                                  size={15}
                                  color="#4c6072"
                                />
                              </span>
                            ) : loaderRowId === _id &&
                              invoiceDownloadLoading == "DONE" ? (
                              <img
                                className="invoice-success-icon"
                                src={SuccessIcon}
                              />
                            ) : loaderRowId === _id &&
                              invoiceDownloadLoading == "ERR" ? (
                              <img
                                className="invoice-success-icon"
                                src={ErrorIcon}
                              />
                            ) : showdownloadInvoice == true ? (
                              <ToolTip title="Download Invoice">
                                <li>
                                  <img
                                    onClick={() =>
                                      handleInvoiceDownload(
                                        _id,
                                        invoiceNumber
                                      )
                                    }
                                    className="download-img"
                                    src={DownLoadIcon}
                                  />
                                </li>
                              </ToolTip>
                            ) : (
                              ""
                            )}
                          </ul>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow
                  hover
                  role="checkbox"
                  className="cursor_default"
                >
                  <TableCell colSpan={5} className="table-custom-width">
                    <Box textAlign="center" p={1} className="no-data-found">
                      <p>No Data Found</p>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}

      <CustomTablePagination
        count={employees.length}
        total={count}
        limit={limit}
        page={page}
        onPageChange={handlePageChange}
      />
    </div>
  );
}
export default TabPanel;
