import Joi from "joi";

export function validateEmployee(data) {
  let isValid = true
  let newErrors = {}

  const schema = Joi.object({
    email: Joi
      .string()
      .trim()
      .optional()
      .email({ tlds: { allow: false } })
      .messages({
        "string.empty": `Email is required!`,
        "string.email": `Please enter valid emil ID`
      }),
    employeeId: Joi
      .string()
      .trim()
      .required()
      .max(20)
      .messages({
        "string.empty": `Employee ID is required!`,
        "string.max": `Employee ID should not be longer than {#limit} characters`
      }),
    phone: Joi
      .string()
      .trim()
      .optional()
      .regex(/^[0-9]{0,10}$/)
      .min(10)
      .max(10)
      .messages({
        "string.empty": "Mobile Number is required!",
        "object.regex": "Invalid Mobile number!",
        "string.pattern.base": "Invalid Mobile number!",
        "string.min": "Please enter 10 digit valid Mobile number!",

      }),
      walletAmount: Joi
      .number()
      .integer()
      .min(0)
      .max(100000)
      .messages({
        "number.integer": "Wallet Amount must be integer",
        "number.base": "Wallet Amount must be numeric!",
        "number.empty": "Wallet Amount is required!",
        "number.max": "Wallet Amount cannot be more than 100000!",
        "number.min": "Wallet Amount cannot be less than 0!",

      }),
    name: Joi
      .string()
      .trim()
      .required()
      .max(40)
      .messages({
        "string.empty": `Name is required!`,
        "string.max": `Name should not be longer than {#limit} characters`
      }),
    mealId: Joi
      .string()
      .required()
      .messages({
        "string.empty": "Meal type is required"
      }),
      shiftType: Joi
      .string()
      .required()
      .messages({
        "string.empty": "Shift type is required"
      })
  }).validate(data, { abortEarly: false })

  const { error } = schema

  if (error) {
    isValid = false
    error.details.forEach(item => newErrors[item.context.key] = item.message)
  }

  return { isValid, errors: newErrors }
}

export function validateWalletData(data) {
  let isValid = true
  let newErrors = {}

  const schema = Joi.object({
      walletAmount: Joi
      .number()
      .integer()
      .required()
      .min(0)
      .max(100000)
      .messages({
        "number.integer": "Wallet Amount must be integer",
        "number.base": "Wallet Amount must be numeric!",
        "number.empty": "Wallet Amount is required!",
        "number.max": "Wallet Amount cannot be more than 100000!",
        "number.min": "Wallet Amount cannot be less than 0!",

      })

  }).validate(data, { abortEarly: false })

  const { error } = schema

  if (error) {
    isValid = false
    error.details.forEach(item => newErrors[item.context.key] = item.message)
  }

  return { isValid, errors: newErrors }
}

Joi.string().trim().required();