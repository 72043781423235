import React, { useEffect, useState } from 'react'
// import { BrowserRouter, Switch, Route } from "react-router-dom";
// import PublicRoute from "./PublicRoute";
import PrivateRoute from './PrivateRoute'

// import Login from "../containers/Auth/Login";
// import NotFound from "../containers/NotFound";
import Dashboard from '../containers/Dashboard'
import Employees from '../containers/Employees'
import Settings from '../containers/Settings'
import Meals from '../containers/Meals'
import TuckShopOrders from '../containers/TuckShopOrders'
import FoodMenu from '../containers/FoodMenu'
import Invoices from '../containers/Invoices'
import DailySupply from '../containers/DailySupply'
import TodayMeal from '../containers/TodayMeal'
import ManageUsers from '../containers/ManageUsers'
import Vouchers from '../containers/Vouchers'
import Wallets from '../containers/Wallets'
import { getObject } from '../utils'
import { useSelector, useDispatch } from 'react-redux'
import { getUserPermission } from '../containers/ManageUsers/actions'

import { useHistory } from 'react-router-dom'
import { saveObject } from '../utils'

const UserPermission = (props) => {
  const { notification } = props

  // const [myEmail, setmyEmail] = useState('')
  // const [myLogo, setmyLogo] = useState('')
  // const [myName, setmyName] = useState('')
  // const [loginUserType, setloginUserType] = useState('')
  const [loginUserPermission, setloginUserPermission] = useState({})
  // const [checkUpdate,setcheckUpdate]=useState(false);
  const dispatch = useDispatch()

  const history = useHistory()

  useEffect(() => {
    return history.listen((location) => {
      updatePermission()
    })
  }, [history])

  useEffect(() => {
    initPermission()
  }, [])

  function displayCompanyLogo() {
    const object = getObject('bb-user')
    const userObject = (object && JSON.parse(object)) || '';
    const {
      _id,
      email = '',
      logo = '',
      name = '',
      userType = '',
      permission = {},
    } = (userObject && userObject.userDetails) || '';
    
    const {tuckshop = false} = userObject;

    return {
      myEmail: email || '',
      myLogo: logo || '',
      myName: name || '',
      loginUserType: userType || '',
      userId: _id || '',
      loginUserPermission: permission || '',
      isTuckShopEnabled : tuckshop || false,
    }
  }

  function getNewPermission(userId) {
    dispatch(getUserPermission(userId))
      .then((res) => {
        if (res.userDetails) {
          let olddata = JSON.parse(window.localStorage.getItem('bb-user'))
          if (res.userDetails.permission) {
            olddata.userDetails.permission = res.userDetails.permission
          }
          saveObject('bb-user', JSON.stringify(olddata))
          initPermission()
        }
      })
      .catch((err) => { })
  }

  function initPermission() {
    let myData = displayCompanyLogo()
    setloginUserPermission({ ...myData.loginUserPermission })
  }

  function updatePermission() {
    let myData = displayCompanyLogo()
    if (myData.loginUserType == 'superAdmin') {
    } else {
      getNewPermission(myData.userId)
    }
  }

  const { myEmail, myLogo, myName, loginUserType, isTuckShopEnabled } = useSelector(
    displayCompanyLogo,
  );


  let showdashboard,
    showemployees,
    showmealRecord,
    showdailySupply,
    showweeklyMenu,
    showinvoices,
    showmanageUsers,
    showsettings,
    showvouchers
  showdashboard = showemployees = showmealRecord = showdailySupply = showweeklyMenu = showinvoices = showmanageUsers = showsettings = showvouchers = true
  if (loginUserType == 'user') {
    showdashboard = showemployees = showmealRecord = showdailySupply = showweeklyMenu = showinvoices = showmanageUsers = showsettings = showvouchers = false

    showdashboard =
      loginUserPermission &&
        loginUserPermission.dashboard &&
        loginUserPermission.dashboard.dashboard == true
        ? true
        : false
    showemployees =
      loginUserPermission &&
        loginUserPermission.employees &&
        loginUserPermission.employees.employees == true
        ? true
        : false
    showmealRecord =
      loginUserPermission &&
        loginUserPermission.mealRecord &&
        loginUserPermission.mealRecord.mealRecord == true
        ? true
        : false
    
    showdailySupply =
      loginUserPermission &&
        loginUserPermission.dailySupply &&
        loginUserPermission.dailySupply.dailySupply == true
        ? true
        : false
    showweeklyMenu =
      loginUserPermission &&
        loginUserPermission.weeklyMenu &&
        loginUserPermission.weeklyMenu.weeklyMenu == true
        ? true
        : false
    showinvoices =
      loginUserPermission &&
        loginUserPermission.invoices &&
        loginUserPermission.invoices.invoices == true
        ? true
        : false
    showmanageUsers = false
    showsettings =
      loginUserPermission &&
        loginUserPermission.settings &&
        loginUserPermission.settings.settings == true
        ? true
        : false

    showvouchers =
      loginUserPermission &&
        loginUserPermission.vouchers &&
        loginUserPermission.vouchers.vouchers == true
        ? true
        : false
  }

  return (
    <>
      <PrivateRoute
        exact
        path="/dashboard"
        component={Dashboard}
        notification={notification}
        myEmail={myEmail}
        myLogo={myLogo}
        myName={myName}
        isTuckShopEnabled = {isTuckShopEnabled}
        loginUserType={loginUserType}
        loginUserPermission={loginUserPermission}
      />

      <PrivateRoute
        exact
        path="/employees"
        component={Employees}
        notification={notification}
        myEmail={myEmail}
        myLogo={myLogo}
        myName={myName}
        isTuckShopEnabled = {isTuckShopEnabled}
        loginUserType={loginUserType}
        loginUserPermission={loginUserPermission}
      />

      <PrivateRoute
        exact
        path="/meal-record"
        component={Meals}
        notification={notification}
        myEmail={myEmail}
        myLogo={myLogo}
        myName={myName}
        isTuckShopEnabled = {isTuckShopEnabled}
        loginUserType={loginUserType}
        loginUserPermission={loginUserPermission}
      />

      <PrivateRoute
        exact
        path="/tuck-shop-order"
        component={TuckShopOrders}
        notification={notification}
        myEmail={myEmail}
        myLogo={myLogo}
        myName={myName}
        isTuckShopEnabled = {isTuckShopEnabled}
        loginUserType={loginUserType}
        loginUserPermission={loginUserPermission}
      />

      <PrivateRoute
        exact
        path="/daily-supply"
        component={DailySupply}
        notification={notification}
        myEmail={myEmail}
        myLogo={myLogo}
        myName={myName}
        isTuckShopEnabled = {isTuckShopEnabled}
        loginUserType={loginUserType}
        loginUserPermission={loginUserPermission}
      />
      <PrivateRoute
        exact
        path="/today-meal"
        component={TodayMeal}
        notification={notification}
        myEmail={myEmail}
        myLogo={myLogo}
        myName={myName}
        isTuckShopEnabled = {isTuckShopEnabled}
        loginUserType={loginUserType}
        loginUserPermission={loginUserPermission}
      />

      <PrivateRoute
        exact
        path="/menu"
        component={FoodMenu}
        notification={notification}
        myEmail={myEmail}
        myLogo={myLogo}
        myName={myName}
        isTuckShopEnabled = {isTuckShopEnabled}
        loginUserType={loginUserType}
        loginUserPermission={loginUserPermission}
      />

      <PrivateRoute
        exact
        path="/invoices"
        component={Invoices}
        notification={notification}
        myEmail={myEmail}
        myLogo={myLogo}
        myName={myName}
        isTuckShopEnabled = {isTuckShopEnabled}
        loginUserType={loginUserType}
        loginUserPermission={loginUserPermission}
      />

      <PrivateRoute
        exact
        path="/manage-users"
        component={ManageUsers}
        notification={notification}
        myEmail={myEmail}
        myLogo={myLogo}
        myName={myName}
        isTuckShopEnabled = {isTuckShopEnabled}
        loginUserType={loginUserType}
        loginUserPermission={loginUserPermission}
      />

      <PrivateRoute
        exact
        path="/settings"
        component={Settings}
        notification={notification}
        myEmail={myEmail}
        myLogo={myLogo}
        myName={myName}
        isTuckShopEnabled = {isTuckShopEnabled}
        loginUserType={loginUserType}
        loginUserPermission={loginUserPermission}
      />

      <PrivateRoute
        exact
        path="/vouchers"
        component={Vouchers}
        notification={notification}
        myEmail={myEmail}
        myLogo={myLogo}
        myName={myName}
        isTuckShopEnabled = {isTuckShopEnabled}
        loginUserType={loginUserType}
        loginUserPermission={loginUserPermission}
      />
      <PrivateRoute
        exact
        path="/wallets"
        component={Wallets}
        notification={notification}
        myEmail={myEmail}
        myLogo={myLogo}
        myName={myName}
        isTuckShopEnabled = {isTuckShopEnabled}
        loginUserType={loginUserType}
        loginUserPermission={loginUserPermission}
      />
    </>
  )
}

export default UserPermission
