import React, { useEffect, useState } from 'react'
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu'
import { getFoodMenu } from './actions'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { Grid, Tab, Tabs, Box } from '@material-ui/core'
import TabPanel from './TabPanel'
import { getCompanyDetailsAction } from '../Settings/actions'
import CustomSelect from '../../components/common/CustomSelect'

function WeeklyMealMenu(props) {
  const { value, label } = props
  return (
    <div className="total-meal-data">
      <span>{label}</span>
    </div>
  )
}
export const MealFilter = [
  { label: 'All', value: 'all' },
  { label: 'Veg', value: 'veg' },
  { label: 'Non Veg', value: 'nonveg' },
  { label: 'Snacks', value: 'snacks' },
]

export default function Settings(props) {
  const { loginUserType, loginUserPermission } = props

  const [filter, setFilter] = useState({
    value: "all",
    label: "All",
  });
  let showweeklyMenu
  showweeklyMenu = true
  showweeklyMenu =
    loginUserType == 'user' && loginUserPermission.weeklyMenu
      ? loginUserPermission.weeklyMenu.weeklyMenu
      : showweeklyMenu
  const [workingDays, setworkingDays] = useState({ week1_3: {}, week2_4: {} })

  const dispatch = useDispatch()
  const menu = useSelector((state) => state?.menu?.menu || [])

  function getCompanyDetails() {
    dispatch(getCompanyDetailsAction(true))
      .then((res) => {
        if (res?.workingDays?.week1_3 && res?.workingDays?.week2_4) {
          setworkingDays({
            ...workingDays,
            week1_3: res.workingDays.week1_3,
            week2_4: res.workingDays.week2_4,
          })
        }
      })
      .catch((err) => { })
  }

  useEffect(() => {
    if (showweeklyMenu == true) {
      dispatch(getFoodMenu())
      getCompanyDetails()
    }
  }, [])

  const todayDay = moment(new Date()).format('dddd')

  const [tabValue, setTabValue] = React.useState(0)
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  if (showweeklyMenu == true) {
    return (
      <Grid container className="location-container">
        <Grid item xs={12}>
          <Grid container className="location-head">
            <Grid item xs={12} sm={12} md={4} lg={4} className="heading">
              <h2>
                <RestaurantMenuIcon />
                Weekly Menu
              </h2>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                {/*  <CustomSelect
                  minWidth={180}
                  options={MealFilter}
                  placeholder="Filter"
                  isSearchable
                  onChange={(opt) => setFilter(opt)}
                  value={filter}
    /> */ }
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className="mt-5 weekly-menu-sec"
        >
          <Grid container spacing={3} className="weekly-menu-container">
            {/* <Grid item xs={12} sm={12} md={12} lg={12} className="">
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label=""
            className="cus-tabs-design"
          >
            <Tab
              icon={<WeeklyMealMenu  label="Normal" />}
              {...a11yProps(0)}
              className="tab-weeklymealmenu"
            ></Tab>
              <Tab
              icon={<WeeklyMealMenu  label="Combo" />}
              {...a11yProps(1)}
              className="tab-weeklymealmenu"
          ></Tab> 
            </Tabs>
          </Grid> */}
            {/*
              {menu?.map(item => {
                const { day, menuItems = [], _id } = item;
                return (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={_id}>
                    <div className={`weekly-menu-listing ${day === todayDay ? 'today' : ''}`}>
                      <h2>{day} </h2>
                      <div className="weekly-inner-con">
                        {
                          menuItems?.map((item = []) => {
                            let name = item.map((itemParamater, i) => {
                              return <div className="menu-item" >
                                <span className={itemParamater.itemType === 'VEG' ? 'veg-meal' : 'non-veg-meal'}>
                                  <span></span>
                                </span>
                                <p>{itemParamater.itemName} {item.length === i + 1 ? '' : "/"}</p>
                              </div>
                            })
                            return (
                              <div>
                                <p>
                                  {name}
                                </p>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </Grid>
                )
              }
            )}
          */}
            {menu ? (
              <TabPanel
                menu={menu}
                tabValue={tabValue}
                workingDays={workingDays}
                filter={filter}
              ></TabPanel>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </Grid>
    )
  } else {
    return (
      <Grid container className="location-container">
        <Grid item xs={12}>
          <Grid container className="location-head">
            <Grid item xs={12} sm={12} md={4} lg={4} className="heading">
              <h2>
                <RestaurantMenuIcon />
                Weekly Menu
              </h2>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Box display="flex" justifyContent="space-between" p={2}>
            <h6>You do not have permission to view this page</h6>
          </Box>
        </Grid>
      </Grid>
    )
  }
}
