import React from "react";
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from '@material-ui/core'


//Table Heading
function EnhancedTableHead(props) {
  const {
    // order,
    // orderBy,
    // onRequestSort,
    headCells
  } = props;
  // const createSortHandler = (event, property) => {
  //   onRequestSort(event, property);
  // };

  return (
    // <TableHead className="cf">
    //   <TableRow>
    //     {headCells.map((headCell) => (
    //       <TableCell
    //         key={headCell.id}
    //         align={headCell.numeric ? "right" : "left"}
    //         padding={headCell.disablePadding ? "none" : "default"}
    //         sortDirection={orderBy === headCell.id ? order : false}
    //       >
    //         <TableSortLabel
    //           active={orderBy === headCell.id}
    //           direction={orderBy === headCell.id ? order : "asc"}
    //           onClick={e => createSortHandler(e, headCell.id)}
    //         >
    //           {headCell.label}
    //           {orderBy === headCell.id ? (
    //             <span style={{ "display": "none" }}>
    //               {order === "desc" ? "sorted descending" : "sorted ascending"}
    //             </span>
    //           ) : null}
    //         </TableSortLabel>
    //       </TableCell>
    //     ))}
    //   </TableRow>
    // </TableHead>
    <TableHead className="">
      <TableRow>
        {
          headCells.map((cell, key) => {
            return <TableCell key={key}>
              {cell.label}
            </TableCell>
          })
        }
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;