import { apiPost, apiGet } from '../../utils/axios'

export function updatePasswordAPI(data) {
  // const data = {}
  // console.log(payload,"payload")
  // data.oldPassword = payload.password
  // data.newPassword = payload.newPassword
  return apiPost(`/api/company/changePassword`, data)
}

export function updateCompanyDetailAPI(payload) {
  let newdata = {};
  Object.entries(payload).forEach(([key, value]) => {
    if (key == "week1_3" || key == "week2_4") {
      newdata[key] = value;
    } else {
      if (value) {
        newdata[key] = value;
      }
    }
  })

  return apiPost(`/api/company/updateCompany`, newdata)
}

export function getCompanyDetailsAPI() {
  return apiGet('/api/company/getCompany')
}

export function getMealSettingsAPI() {
  return apiGet('/api/company/getMealPlan')
}

export function updateMealSettingsAPI(payload) {
  const data = {}
  if (payload.vegEmployShare) {
    data.vegEmployShare = payload.vegEmployShare;
  }
  if (payload.nonVegEmployShare) {
    data.nonVegEmployShare = payload.nonVegEmployShare;
  }
  if (payload.snacksEmployShare) {
    data.snacksEmployShare = payload.snacksEmployShare;
  }
  if (payload.comboEmployShare) {
    data.comboEmployShare = payload.comboEmployShare;
  }
  return apiPost(`/api/company/updateMealPlan`, data)
}
