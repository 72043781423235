import React, { useEffect, useState } from 'react'
import InputField from '../../components/common/InputField'
import { RadioGroup, FormControlLabel, Radio, Button, Grid, DialogActions, CircularProgress } from '@material-ui/core'
import * as action from './actions'
import { useDispatch, useSelector } from "react-redux"
import { formatString } from '../../utils'


const initialState = {
    paymentDetails: '',
    companyComments: '',
    reason: '',
    modalOption: ['PROCESSED', 'HOLD']
}

function UpdateInvoiceForm(props) {
    const dispatch = useDispatch()
    const { isLoading = false } = useSelector(state => state.invoice) || {}
    const [invoiceDetails, setInvoiceDetails] = useState({ ...initialState })
    const [modalOptionSelection, setModalOptionSelection] = useState('PROCESSED')
    const [paymentStatus, setPaymentStatus] = useState("")
    const [dataChange, setDataChange] = useState(false)

    useEffect(() => {
        const { invoiceDetails, update} = props
        let { paymentDetails, companyComments, status } = invoiceDetails
        setPaymentStatus(status)
        const statusOption = status === 'NEW' ? 'PROCESSED' : status
        // const payment = status === 'HOLD' ? '' : ''
        if (update) {
            setInvoiceDetails({
                ...invoiceDetails, paymentDetails, companyComments, modalOption: ['PROCESSED', 'HOLD'], reason: paymentDetails,
            })
            setModalOptionSelection(statusOption)
        }
    }, [])

    const handleChange = (e) => {
        let { name = "", value = "" } = e.target;
        setInvoiceDetails({ ...invoiceDetails, [name]: value })
        setDataChange(true)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const { toast, invoiceId, onClose } = props
        // This form Data for Payment Process
        const processFormData = {
            paymentDetails: invoiceDetails.paymentDetails,
            companyComments: invoiceDetails.companyComments,
            status: modalOptionSelection,
            _id: invoiceId
        }

        // This form Data for Payment on Hold
        const holdFormData = {
            paymentDetails: invoiceDetails.reason,
            status: modalOptionSelection,
            _id: invoiceId
        }

        const formData = modalOptionSelection == 'HOLD' ? holdFormData : processFormData

        if (dataChange) {
            dispatch(action.updateInvoice(formData))
                .then(res => {
                    const { getInvoice } = props
                    onClose()
                    getInvoice()
                    toast.success("Invoice updated successfully");
                    setInvoiceDetails({ ...initialState })
                    setDataChange(false)

                })
                .catch(({ message = '' }) => {
                    toast.error(message || 'Oops! Something went wrong')
                })
            return
        } else {
            onClose()
            setInvoiceDetails({ ...initialState })
            return
        }
    }

    const handleOption = (e) => {
        const { value } = e.target
        setModalOptionSelection(value)
        setDataChange(true)
    }

    const valid = modalOptionSelection == 'HOLD' ? invoiceDetails.reason || false : invoiceDetails.companyComments && invoiceDetails.paymentDetails || false
    return (
        <div className="update-invoice-modal">
            <h6>Update Invoice</h6>
            <div className="update-invoice-inner">
                {paymentStatus === "NEW" ?
                    <RadioGroup className="radio-btn" id="" row aria-label="position" name="position" defaultValue="top">
                        {
                            invoiceDetails?.modalOption.map((type, i) => {
                                return (
                                    <FormControlLabel
                                        value={type}
                                        id={`radio-btn-${i}`}
                                        onChange={(e) => handleOption(e)}
                                        checked={modalOptionSelection == type}
                                        name="options"
                                        control={<Radio color="primary" />}
                                        label={formatString(type)} />
                                )
                            })
                        }
                    </RadioGroup>
                    :
                    ''
                }

                {
                    modalOptionSelection == 'HOLD'
                        ?
                        <InputField
                            fullWidth
                            label="Reason"
                            onChange={handleChange}
                            name="reason"
                            value={invoiceDetails.reason}
                            multiline={true}
                            rows={5}
                        />
                        :
                        <div>

                            <InputField
                                fullWidth
                                label="Payment Details"
                                onChange={handleChange}
                                name="paymentDetails"
                                value={invoiceDetails.paymentDetails}
                                className="mt-3"
                                multiline={true}
                                rows={2}


                            />
                            <InputField
                                fullWidth
                                label="Company Comments"
                                onChange={handleChange}
                                name="companyComments"
                                value={invoiceDetails.companyComments}
                                className="mt-3"
                                multiline={true}
                                rows={2}


                            />
                        </div>
                }
            </div>
            <DialogActions>
                <div className="foot-modal-btns">
                    <Button onClick={() => props.onClose()} color="secondary" variant="contained" >Cancel </Button>
                    <Button onClick={handleSubmit} color="primary" variant="contained" className={`${valid ? '' : 'disabled'} `} disabled={isLoading} >
                        {isLoading ? <CircularProgress size="20px" color="white" /> : 'Submit'}
                    </Button>
                </div>
            </DialogActions>
        </div>
    )
}

export default UpdateInvoiceForm