import {
  getOrdersListAPI,
  getMealTypesAPI,
  getEmployeeNameListAPI
} from './apis'
import {
  GET_ORDERS,
  GET_ORDERS_SUCCEEDED,
  GET_ORDERS_FAILED,
  GET_MEAL_TYPES,
  GET_MEAL_TYPES_SUCCEEDED,
  GET_MEAL_TYPES_FAILED,
  GET_EMPLOYEE,
  GET_EMPLOYEE_SUCCEEDED,
  GET_EMPLOYEE_FAILED,
} from './reducer'

export function getOrdersList(payload) {
  return dispatch => {

    dispatch({
      type: GET_ORDERS
    })

    return new Promise((resolve, reject) => {
      getOrdersListAPI(payload)
        .then(({ data }) => {
          dispatch({
            type: GET_ORDERS_SUCCEEDED,
            payload: data
          })
          return resolve(data)
        })
        .catch(err => {

          dispatch({
            type: GET_ORDERS_FAILED
          })
          return reject(err)
        })
    })
  }
}

export function getEmployeeList(){
    return dispatch => {

    dispatch({
      type: GET_EMPLOYEE
    })

    return new Promise((resolve, reject) => {
      getEmployeeNameListAPI()
        .then(({ data }) => {
          dispatch({
            type: GET_EMPLOYEE_SUCCEEDED,
            payload: data
          })
          return resolve(data)
        })
        .catch(err => {

          dispatch({
            type: GET_EMPLOYEE_FAILED
          })
          return reject(err)
        })
    })
  }  
}

export function getMealTypes() {
  return dispatch => {

    dispatch({
      type: GET_MEAL_TYPES
    })

    return new Promise((resolve, reject) => {
      getMealTypesAPI()
        .then(({ data }) => {
          dispatch({
            type: GET_MEAL_TYPES_SUCCEEDED,
            payload: data
          })
          return resolve(data)
        })
        .catch(err => {

          dispatch({
            type: GET_MEAL_TYPES_FAILED
          })
          return reject(err)
        })
    })
  }
}