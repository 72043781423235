import React, { useState, useEffect } from 'react'

// import PropTypes from "prop-types";
import {
  Grid,
  Box,
  // AppBar,
  CircularProgress,
  Button,
  Checkbox,
  FormControlLabel,
  // FormGroup,
  // FormControl,
  // InputLabel,
  // Select,
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import InputField from '../../../components/common/InputField'
import { validateEditUser } from '../validations'
import { editUser, getSingleUsersData } from '../actions'
// import IOSSwitch from "../../Common/IOSSwitch";
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
// import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const initData = {
  _id: '',
  name: '',
  email: '',
  password: '',
  permission: {
    dashboard: {
      dashboard: false,
      showTotalNumber: false,
      showMealSummary: false,
      showSummary: false,
      showPayoutSummary: false,
    },
    invoices: {
      invoices: false,
      updateInvoice: false,
      downloadInvoice: false,
    },
    employees: {
      employees: false,
      addEmployees: false,
      editEmployees: false,
      changeEmployeesStatus: false,
      deleteEmployees: false,
      viewEmployees: false,
    },
    dailySupply: {
      dailySupply: false,
      updateRecord: false,
      //      dailyInvoices: false,
    },
    settings: {
      settings: false,
      showChangeCompanyDetails: false,
      showChangeMealPrice: false,
    },
    mealRecord: {
      mealRecord: false,
    },
    weeklyMenu: {
      weeklyMenu: false,
    },
    vouchers: {
      vouchers: false,
      updateVoucher: false,
    },
  },
}
//let initPermisionChanged="";
function getUserData(state) {
  const user = state?.manageusers?.user[0] || {}

  return {
    _id: user._id,
    name: user.name,
    email: user.email,
    permission: user.permission,
    password: "",
    isCurrentContactPerson: user.isCurrentContactPerson,
  }
}

export default function EditUserForm(props) {
  const {
    setUpdateLoading,
    setuserListing,
    TopHeadIcon,
    isLoading,
    toast,
    dispatch,
    getUsers,
    seteditEmail,
    editEmail,
    useSelector,
  } = props

  useEffect(() => {
    getUserDetails()
  }, [])

  function getUserDetails() {
    let email = {}
    email.email = editEmail
    dispatch(getSingleUsersData(email))
  }
  const [data, setData] = useState({ ...initData })
  const [errors, setErrors] = useState({})
  const {
    name,
    email,
    permission,
    password,
    _id,
    isCurrentContactPerson,
  } = useSelector(getUserData)
  const [initPermisionChanged, setinitPermisionChanged] = useState({ id: false, name: false, email: false, userPermission: false })
  const [inituserDetailsChanged, setinituserDetailsChanged] = useState('')
  const [contactPersonChanged, setcontactPersonChanged] = useState(false)

  const [dashboardSetTrue, setdashboardSetTrue] = useState('')
  if (_id && !data._id && !initPermisionChanged.id) {
    setData({ ...data, _id: _id })
    setinitPermisionChanged({ ...initPermisionChanged, id: true })
  }
  if (name && !data.name && !initPermisionChanged.name) {
    setData({ ...data, name: name })
    setinitPermisionChanged({ ...initPermisionChanged, name: true })
  }

  if (email && !data.email && !initPermisionChanged.email) {
    setData({ ...data, email: email })
    setinitPermisionChanged({ ...initPermisionChanged, email: true })
  }
  // if (password && !data.password && !inituserDetailsChanged.password) {
  //   setData({ ...data, password: password })
  //   setinituserDetailsChanged({...inituserDetailsChanged,password:true})
  // }
  if (permission && !initPermisionChanged.userPermission) {
    setData({ ...data, permission: permission })
    setinitPermisionChanged({ ...initPermisionChanged, userPermission: true })
    setUpdateLoading(false)
  }

  if (!permission && !initPermisionChanged) {
    setUpdateLoading(true)
  }
  // if (initPermisionChanged && !dashboardSetTrue) {
  //   setdashboardSetTrue('done')
  //   let checked = true
  //   setData({
  //     ...data,
  //     permission: {
  //       ...data.permission,
  //       dashboard: { ...data.permission.dashboard, dashboard: checked },
  //     },
  //   })
  // }

  function backtoManageUser() {
    setuserListing('')
    getUsers()
  }
  function mainCheckBoxChange(e, element) {
    let checked = e.target.checked
    let status
    if (checked == true) {
      status = false
    } else {
      status = true
    }

    if (checked == true) {
      checkAllChildren(e, element)
    }
    if (checked == false) {
      disableAllChildren(e, element)
    }
  }

  function checkAllChildren(e, element) {
    let changeValue = true
    let elementName = element
    elementName = {}
    Object.entries(data.permission[element]).forEach(([key, value]) => {
      elementName[key] = changeValue
    })
    setData({
      ...data,
      permission: { ...data.permission, [element]: elementName },
    })
  }

  function disableAllChildren(e, element) {
    let changeValue = false
    let elementName = element
    elementName = {}
    Object.entries(data.permission[element]).forEach(([key, value]) => {
      elementName[key] = changeValue
    })
    setData({
      ...data,
      permission: { ...data.permission, [element]: elementName },
    })
  }

  function handleChangeCheckbox(e, element, child = '') {
    let checked = e.target.checked
    if (child) {
      if (element != child) {
        let elementName = element
        elementName = {}
        let changeValue = true
        let anyValueTrue = false
        if (checked == true) {
          anyValueTrue = true
        }
        Object.entries(data.permission[element]).forEach(([key, value]) => {
          if (key !== element && checked == false && key != child) {
            if (data.permission[element][key] == true) {
              anyValueTrue = true
            }
          }
          if (key == element) {
            changeValue = true
          } else if (key == child) {
            changeValue = checked
          } else {
            changeValue = value
          }
          elementName[key] = changeValue
        })
        if (anyValueTrue == false && checked == false) {
          elementName[element] = false
        }
        setData({
          ...data,
          permission: { ...data.permission, [element]: elementName },
        })
      } else {
        setData({
          ...data,
          permission: {
            ...data.permission,
            [element]: { ...data.permission[element], [child]: checked },
          },
        })
      }
    } else {
      setData({
        ...data,
        permission: { ...data.permission, [element]: checked },
      })
    }
  }

  function handleChangeInput(key, value) {
    setData({
      ...data,
      [key]: value,
    })
  }

  function saveThisUser() {
    if (validate()) {
      setUpdateLoading(true)
      dispatch(editUser(data))
        .then(() => {
          setUpdateLoading(false)
          getUsers()
          toast.success('User Updated successfully')
          backtoManageUser()
        })
        .catch((err) => {
          setUpdateLoading(false)
          toast.error(err.message || 'Oops! Something went wrong')
        })
    }
  }

  function validate() {
    const { errors: newErrors, isValid } = validateEditUser(data)
    setErrors(newErrors)
    return isValid
  }

  return (
    <Grid item xs={12}>
      <Grid container className="location-head">
        <Grid item xs={12} sm={12} md={4} lg={4} className="heading">
          <h2>
            <TopHeadIcon />
            Edit User
          </h2>
        </Grid>
      </Grid>

      <div className="table-design w-100 manage-users-pg">
        <div className="cus-back-head">
          <h3 onClick={backtoManageUser}>
            <ArrowBackIcon />
            <span>Back to "Manage Users"</span>
          </h3>
        </div>

        <div className="adduser-form edit-user-form">
          <Grid container spacing={3} className="edit-form">
            <Grid item lg={4} xs={12}>
              <Box py={1}>
                <InputField
                  fullWidth
                  label="Name"
                  value={data.name}
                  onChange={({ target: { value } }) =>
                    handleChangeInput('name', value)
                  }
                  error={errors.name}
                />
              </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
              <Box py={1}>
                <InputField
                  fullWidth
                  label="Email"
                  value={data.email}
                  onChange={({ target: { value } }) =>
                    handleChangeInput('email', value)
                  }
                  error={errors.email}
                />
              </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
              <Box py={1}>
                <InputField
                  className="password-input"
                  name="password"
                  variant="outlined"
                  type="password"
                  label="Password"
                  placeholder="Password"
                  disabled={true}
                  value={data.password}
                  fullWidth
                  onChange={({ target: { value } }) =>
                    handleChangeInput('password', value)
                  }
                  error={errors.password}
                />
              </Box>
            </Grid>
          </Grid>

          <div className="cus-permissions-main">
            <h3>Permissions</h3>
            <div className="cus-permissions-inner">
              <Grid container spacing={3}>
                <Grid item lg={4} xs={12}>
                  <div className="per-listing">
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <div className="per-head">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={data.permission.dashboard.dashboard}
                                name="checkedB"
                                color="primary"
                                onChange={(e) =>
                                  mainCheckBoxChange(e, 'dashboard')
                                }
                              />
                            }
                            label="Dashboard"
                          />
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ul>
                          <li>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.permission.dashboard.showTotalNumber
                                  }
                                  color="primary"
                                  onChange={(e) =>
                                    handleChangeCheckbox(
                                      e,
                                      'dashboard',
                                      'showTotalNumber',
                                    )
                                  }
                                />
                              }
                              label="Show Total Number"
                            />
                          </li>
                          <li>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.permission.dashboard.showMealSummary
                                  }
                                  color="primary"
                                  onChange={(e) =>
                                    handleChangeCheckbox(
                                      e,
                                      'dashboard',
                                      'showMealSummary',
                                    )
                                  }
                                />
                              }
                              label="Show Meals Summary"
                            />
                          </li>
                          <li>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.permission.dashboard.showSummary
                                  }
                                  color="primary"
                                  onChange={(e) =>
                                    handleChangeCheckbox(
                                      e,
                                      'dashboard',
                                      'showSummary',
                                    )
                                  }
                                />
                              }
                              label="Show Expense Summary"
                            />
                          </li>
                          <li>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data.permission.dashboard.showPayoutSummary
                                  }
                                  color="primary"
                                  onChange={(e) =>
                                    handleChangeCheckbox(
                                      e,
                                      'dashboard',
                                      'showPayoutSummary',
                                    )
                                  }
                                />
                              }
                              label="Show Payout Summary"
                            />
                          </li>
                        </ul>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div className="per-listing">
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <div className="per-head">
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="checkedB"
                                color="primary"
                                checked={data.permission.employees.employees}
                                onChange={(e) =>
                                  mainCheckBoxChange(e, 'employees')
                                }
                              />
                            }
                            label="Employees"
                          />
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ul>
                          <li>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="checkedB"
                                  color="primary"
                                  checked={
                                    data.permission.employees.addEmployees
                                  }
                                  onChange={(e) =>
                                    handleChangeCheckbox(
                                      e,
                                      'employees',
                                      'addEmployees',
                                    )
                                  }
                                />
                              }
                              label="Add Employee"
                            />
                          </li>
                          <li>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="checkedB"
                                  color="primary"
                                  checked={
                                    data.permission.employees.editEmployees
                                  }
                                  onChange={(e) =>
                                    handleChangeCheckbox(
                                      e,
                                      'employees',
                                      'editEmployees',
                                    )
                                  }
                                />
                              }
                              label="Edit Employee"
                            />
                          </li>
                          <li>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="checkedB"
                                  color="primary"
                                  checked={
                                    data.permission.employees.deleteEmployees
                                  }
                                  onChange={(e) =>
                                    handleChangeCheckbox(
                                      e,
                                      'employees',
                                      'deleteEmployees',
                                    )
                                  }
                                />
                              }
                              label="Delete Employee"
                            />
                          </li>
                          <li>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="checkedB"
                                  color="primary"
                                  checked={
                                    data.permission.employees
                                      .changeEmployeesStatus
                                  }
                                  onChange={(e) =>
                                    handleChangeCheckbox(
                                      e,
                                      'employees',
                                      'changeEmployeesStatus',
                                    )
                                  }
                                />
                              }
                              label="Change Employee Status"
                            />
                          </li>
                          <li>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="checkedB"
                                  color="primary"
                                  checked={
                                    data.permission.employees.viewEmployees
                                  }
                                  onChange={(e) =>
                                    handleChangeCheckbox(
                                      e,
                                      'employees',
                                      'viewEmployees',
                                    )
                                  }
                                />
                              }
                              label="View Employee Meal"
                            />
                          </li>
                        </ul>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </Grid>
                <Grid item lg={4} xs={12}>
                  <div className="per-listing">
                    <Accordion>
                      <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <div className="per-head">
                          <FormControlLabel
                            checked={data.permission.mealRecord.mealRecord}
                            onChange={(e) =>
                              mainCheckBoxChange(e, 'mealRecord')
                            }
                            control={
                              <Checkbox name="checkedB" color="primary" />
                            }
                            label="Meal Records"
                          />
                        </div>
                      </AccordionSummary>
                    </Accordion>
                  </div>

                  <div className="per-listing">
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <div className="per-head">
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="checkedB"
                                color="primary"
                                checked={data.permission.invoices.invoices}
                                onChange={(e) =>
                                  mainCheckBoxChange(e, 'invoices')
                                }
                              />
                            }
                            label="Invoices"
                          />
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ul>
                          <li>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="checkedB"
                                  checked={
                                    data.permission.invoices.updateInvoice
                                  }
                                  color="primary"
                                  onChange={(e) =>
                                    handleChangeCheckbox(
                                      e,
                                      'invoices',
                                      'updateInvoice',
                                    )
                                  }
                                />
                              }
                              label="Update Invoice"
                            />
                          </li>
                          <li>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="checkedB"
                                  checked={
                                    data.permission.invoices.downloadInvoice
                                  }
                                  color="primary"
                                  onChange={(e) =>
                                    handleChangeCheckbox(
                                      e,
                                      'invoices',
                                      'downloadInvoice',
                                    )
                                  }
                                />
                              }
                              label="Download Invoice"
                            />
                          </li>
                        </ul>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div className="per-listing">
                    <Accordion>
                      <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <div className="per-head">
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="checkedB"
                                color="primary"
                                checked={data.permission.weeklyMenu.weeklyMenu}
                                onChange={(e) =>
                                  mainCheckBoxChange(e, 'weeklyMenu')
                                }
                              />
                            }
                            label="Weekly Menu"
                          />
                        </div>
                      </AccordionSummary>
                    </Accordion>
                  </div>
                </Grid>
                <Grid item lg={4} xs={12}>
                  <div className="per-listing">
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <div className="per-head">
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="checkedB"
                                color="primary"
                                checked={data.permission.settings.settings}
                                onChange={(e) =>
                                  mainCheckBoxChange(e, 'settings')
                                }
                              />
                            }
                            label="Settings"
                          />
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ul>
                          <li>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="checkedB"
                                  checked={
                                    data.permission.settings
                                      .showChangeCompanyDetails
                                  }
                                  color="primary"
                                  onChange={(e) =>
                                    handleChangeCheckbox(
                                      e,
                                      'settings',
                                      'showChangeCompanyDetails',
                                    )
                                  }
                                />
                              }
                              label="Show/Change Company Details"
                            />
                          </li>
                          <li>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="checkedB"
                                  checked={
                                    data.permission.settings.showChangeMealPrice
                                  }
                                  color="primary"
                                  onChange={(e) =>
                                    handleChangeCheckbox(
                                      e,
                                      'settings',
                                      'showChangeMealPrice',
                                    )
                                  }
                                />
                              }
                              label="Show/Change Meal Price"
                            />
                          </li>
                        </ul>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div className="per-listing">
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <div className="per-head">
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="checkedB"
                                color="primary"
                                checked={
                                  data.permission.dailySupply.dailySupply
                                }
                                onChange={(e) =>
                                  mainCheckBoxChange(e, 'dailySupply')
                                }
                              />
                            }
                            label="Daily Supply"
                          />
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ul>
                          <li>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="checkedB"
                                  checked={
                                    data.permission.dailySupply.updateRecord
                                  }
                                  color="primary"
                                  onChange={(e) =>
                                    handleChangeCheckbox(
                                      e,
                                      'dailySupply',
                                      'updateRecord',
                                    )
                                  }
                                />
                              }
                              label="Update a Record"
                            />
                          </li>
                          {/*   <li>
                        <FormControlLabel
                          control={<Checkbox name="checkedB" checked={data.permission.dailySupply.dailyInvoices} color="primary" onChange={(e) => handleChangeCheckbox(e, "dailySupply", "dailyInvoices")} />}
                          label="Daily Invoice"
                        />
                      </li> */}
                        </ul>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div className="per-listing">
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <div className="per-head">
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="checkedB"
                                color="primary"
                                checked={data.permission.vouchers.vouchers}
                                onChange={(e) =>
                                  mainCheckBoxChange(e, 'vouchers')
                                }
                              />
                            }
                            label="Vouchers"
                          />
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ul>
                          <li>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="checkedB"
                                  checked={
                                    data.permission.vouchers.updateVoucher
                                  }
                                  color="primary"
                                  onChange={(e) =>
                                    handleChangeCheckbox(
                                      e,
                                      'vouchers',
                                      'updateVoucher',
                                    )
                                  }
                                />
                              }
                              label="Update Vouchers"
                            />
                          </li>
                        </ul>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </Grid>
              </Grid>
            </div>

            <div className="btn-save">
              <Button
                color="primary"
                variant="contained"
                disabled={isLoading}
                onClick={saveThisUser}
                className="btn-custom-primry button-btn"
              >
                {isLoading ? (<>
                  Loading<CircularProgress size="20px" /></>
                ) : (
                  <>Update</>
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Grid>
  )
}
