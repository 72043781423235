import React, { useEffect, useState } from 'react'
import {
  Grid,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Paper,
  Tab,
  Tabs,
  AppBar,
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import EnhancedTableHead from '../../components/common/EnhancedTableHead'
import CustomTablePagination from '../../components/common/CustomPagination'
import { withTranslation } from 'react-i18next'
import { getTimeStamps, numberFormat } from '../../utils'
import { tablestyle } from '../../plugins/theme'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import TableLoader from '../../components/common/TableLoader'
import { getDailySupplyList } from './actions'
import moment from 'moment'
import MealTypeDot from '../Common/MealTypeDot'
import CustomSelect from '../../components/common/CustomSelect'
import { DaydateFilter } from '../../constant'
import { DateRangePicker } from 'react-dates'
import EventIcon from '@material-ui/icons/Event'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import SearchField from '../../components/common/SearchField'
import LightTooltip from '../Common/LightTooltip'
import TabPanel from './TabPanel'

const TableHeaders = [
  { id: 'date', numeric: false, disablePadding: false, label: 'Date' },
  { id: 'orderby', numeric: false, disablePadding: false, label: 'Order By' },
  { id: 'mealdata', numeric: false, disablePadding: false, label: 'Meal Data' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  {
    id: 'updatedby',
    numeric: false,
    disablePadding: false,
    label: 'Updated By',
  },
  { id: 'actions', numeric: false, disablePadding: false, label: 'Actions' },
]

let searchTypeInterval = null

function TotalMealData(props) {
  const { value, label } = props
  return (
    <div className="total-meal-data">
      <span>{label}</span>
      <label>{value}</label>
    </div>
  )
}

function TopHeadIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.367"
      height="24.934"
      viewBox="0 0 28.367 24.934"
    >
      <g
        id="calendar_1_"
        data-name="calendar (1)"
        transform="translate(0 -30.988)"
      >
        <path
          id="Path_5531"
          data-name="Path 5531"
          d="M.83,164.286H22.55a.832.832,0,0,0,.532-.192c.209-.175,4.967-4.268,5.258-13.105H5.013C4.724,159.01.341,162.78.3,162.818a.832.832,0,0,0,.534,1.468Z"
          transform="translate(0 -113.351)"
          fill="#ffc545"
        />
        <path
          id="Path_5532"
          data-name="Path 5532"
          d="M113.472,32.65h-4.156v-.831a.831.831,0,0,0-1.662,0v.831h-4.211v-.831a.831.831,0,0,0-1.662,0v.831H97.625v-.831a.831.831,0,0,0-1.662,0v.831H91.807a.823.823,0,0,0-.831.831v2.493H114.3V33.481A.823.823,0,0,0,113.472,32.65Z"
          transform="translate(-85.935)"
          fill="#ffc545"
        />
        <path
          id="Path_5533"
          data-name="Path 5533"
          d="M110.086,304.086a2.5,2.5,0,0,1-1.6.58H90.977v2.493a.831.831,0,0,0,.831.831h21.664a.831.831,0,0,0,.831-.831v-9.317A16.473,16.473,0,0,1,110.086,304.086Z"
          transform="translate(-85.936 -252.069)"
          fill="#ffc545"
        />
      </g>
    </svg>
  )
}
//let dailySupplyType = "";
//let getDailyMealInterval = "";
function getSupplyData(state) {
  const supply = state?.dailysupply?.supply || {}

  let count = supply.count
  return {
    supply: supply.orders || [],
    totalmeals: supply.totalMeals || 0,
    approvedmeals: supply.approvedMeals || 0,
    pendingapproved: supply.pendingApproved || 0,
    declined: supply.declined || 0,
    count: count,
    totalMeal: supply.totalMeal || 0,
    totalAmount: supply.totalAmount || 0,
    totalVeg: supply.totalVeg || 0,
    totalNonVeg: supply.totalNonVeg || 0,
  }
}

function DailySupply(props) {
  const { classes, toast, loginUserType, loginUserPermission } = props
  const dispatch = useDispatch();
  const isLoading = useSelector(
    (state) => state.dailysupply.isLoading || false
  );

  const {
    totalmeals = 0,
    approvedmeals = 0,
    pendingapproved = 0,
    declined = 0,
    supply = [],
    count = 0,
    totalMeal = 0,
    totalAmount = 0,
    totalVeg = 0,
    totalNonVeg = 0,
  } = useSelector(getSupplyData);

  const [dailySupplyType, setdailySupplyType] = useState("");
  const [limit, setLimit] = useState(8);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({
    value: "all",
    label: "All",
  });
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);
  const [focusedInput, setFocusedInput] = useState(false);
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    if (!startDate || !endDate) return getDailySupply();;
    if (dailySupplyType == "") {
      setTabValue(0);
    } else if (dailySupplyType == "approved") {
      setTabValue(1);
    } else if (dailySupplyType == "pending") {
      setTabValue(2);
    } else if (dailySupplyType == "decline") {
      setTabValue(3);
    }
    getDailySupply();
  }, [skip, limit, endDate, dailySupplyType]);

  useEffect(() => {
    if (filter?.value) {
      const { startDate: newStartDate, endDate: newEndDate } = getTimeStamps(
        filter.value,
        startDate,
        endDate
      );
      if (filter.value !== "customDate") {
        setEndDate(moment(newEndDate));
        setStartDate(moment(newStartDate));
      }
      if (filter.value === "all") {
        setEndDate(0);
        setStartDate(0);
      }
    }
  }, [filter]);

  function getDailySupply(search = "", isSearching = false) {
    const data = {
      limit,
      skip,
      startDate: moment(startDate).valueOf(),
      endDate: moment(endDate).valueOf(),
      keyword: isSearching ? search : keyword,
      status: dailySupplyType ? dailySupplyType : "",
    };
    dispatch(getDailySupplyList(data));
  }

  function getDailyMealData(meal) {
    if (meal == "totalMeal") {
      meal = "";
    }
    setdailySupplyType(meal);

    setLimit(8);
    setPage(1);
    setSkip(0);
  }

  function handleChangeSearch({ target: { value = "" } }) {
    setKeyword(value);

    if (searchTypeInterval) {
      clearTimeout(searchTypeInterval);
      searchTypeInterval = null;
    }
    searchTypeInterval = setTimeout(() => {
      setPage(1);
      setLimit(8);
      if (skip == 0) {
        getDailySupply(value, true);
      } else {
        setSkip(0);
      }
    }, 500);
  }

  function handleDateChange({ startDate, endDate }) {
    if (startDate) {
      setStartDate(startDate);
    }
    if (endDate) {
      setEndDate(endDate);
    }
  }
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    if (newValue == 1 || newValue == 2 || newValue == 3 || newValue == 0) {
      setTabValue(newValue);
      let meal =
        newValue == 0
          ? "totalMeal"
          : newValue == 1
            ? "approved"
            : newValue == 2
              ? "pending"
              : newValue == 3
                ? "decline"
                : "";
      getDailyMealData(meal);
    }
  };

  let showdailySupply, showupdateRecord, showdailyInvoices;
  showdailySupply = showupdateRecord = showdailyInvoices = true;
  showdailySupply =
    loginUserType == "user" && loginUserPermission.dailySupply
      ? loginUserPermission.dailySupply.dailySupply
      : showdailySupply;
  showupdateRecord =
    loginUserType == "user" && loginUserPermission.dailySupply
      ? loginUserPermission.dailySupply.updateRecord
      : showupdateRecord;

  if (showdailySupply == true) {
    return (
      <Grid container className="location-container">
        <Grid item xs={12}>
          <Grid container className="location-head">
            <Grid item xs={12} sm={12} md={4} lg={4} className="heading">
              <h2>
                <TopHeadIcon />
                Daily Supply
              </h2>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <Box pr={1}>
                  <SearchField
                    placeholder="Search"
                    value={keyword}
                    onChange={handleChangeSearch}
                  />
                </Box>
                <CustomSelect
                  minWidth={180}
                  options={DaydateFilter}
                  placeholder="Filter"
                  isSearchable
                  onChange={(opt) => setFilter(opt)}
                  value={filter}
                />
                {filter?.value !== 'customDate' ? (
                  ''
                ) : (
                  <div className="date-range-picker">
                    <Box pl={1}>
                      <DateRangePicker
                        // disabled={filter?.value !== "customDate"}
                        startDate={startDate}
                        startDateId="startDate"
                        startDatePlaceholderText="From..."
                        endDatePlaceholderText="To..."
                        minimumNights={0}
                        customArrowIcon={
                          <p className="start-end-date-divider">|</p>
                        }
                        endDate={endDate}
                        endDateId="endDate"
                        customInputIcon={
                          <EventIcon className="date-picker-icon" />
                        }
                        isOutsideRange={(date) =>
                          date.isBefore(moment().year()) ||
                          date.isAfter(moment())
                        }
                        onDatesChange={handleDateChange}
                        focusedInput={focusedInput}
                        onFocusChange={(focusedInput) =>
                          setFocusedInput(focusedInput)
                        }
                      ></DateRangePicker>
                    </Box>
                  </div>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} className="">
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label=""
            className="cus-tabs-design"
          >
            <Tab
              icon={<TotalMealData value={totalmeals} label="Total" />}
              {...a11yProps(0)}
              className="tab-totalmeals"
            ></Tab>
            <Tab
              icon={<TotalMealData value={approvedmeals} label="Approved" />}
              {...a11yProps(1)}
              className="tab-approvedmeals"
            ></Tab>
            <Tab
              icon={<TotalMealData value={pendingapproved} label="Pending" />}
              {...a11yProps(2)}
              className="tab-pendingapproved"
            ></Tab>
            <Tab
              icon={<TotalMealData value={declined} label="Declined" />}
              {...a11yProps(3)}
              className="tab-declined"
            ></Tab>
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          <Box mt={2}>
            <Paper rounded className="box-shadow">
              <Box display="flex" justifyContent="space-between" p={2}>
                <Box>
                  <div className="dashboard-card-connent">
                    <h5 style={{ paddingBottom: 8 }}>Total Meals</h5>
                    <h6>{numberFormat(totalMeal)}</h6>
                  </div>
                </Box>
                <Box>
                  <div className="dashboard-card-connent">
                    <h5 style={{ paddingBottom: 8 }}>
                      Total Veg
                      <MealTypeDot mealType="VEG" />
                    </h5>
                    <h6>{numberFormat(totalVeg)}</h6>
                  </div>
                </Box>
                <Box>
                  <div className="dashboard-card-connent">
                    <h5 style={{ paddingBottom: 8 }}>
                      Total NonVeg <MealTypeDot mealType="NONVeg" />
                    </h5>
                    <h6>{numberFormat(totalNonVeg)}</h6>
                  </div>
                </Box>
                <Box>
                  <div className="dashboard-card-connent">
                    <h5 style={{ paddingBottom: 8, textAlign: 'right' }}>
                      Total Amount
                    </h5>
                    <h6> &#8377;{numberFormat(totalAmount)}</h6>
                  </div>
                </Box>
              </Box>
            </Paper>
          </Box>
        </Grid>
        <TabPanel
          getDailySupply={getDailySupply}
          value={tabValue}
          index={tabValue}
          supply={supply}
          toast={toast}
          isLoading={isLoading}
          classes={classes}
          limit={limit}
          page={page}
          count={count}
          setPage={setPage}
          setSkip={setSkip}
          showupdateRecord={showupdateRecord}
          numberFormat={numberFormat}
        ></TabPanel>
      </Grid>
    )
  } else {
    return (
      <Grid container className="location-container">
        <Grid item xs={12}>
          <Grid container className="location-head">
            <Grid item xs={12} sm={12} md={4} lg={4} className="heading">
              <h2>
                <TopHeadIcon />
                Daily Supply
              </h2>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Box display="flex" justifyContent="space-between" p={2}>
            <h6>You do not have permission to view this page</h6>
          </Box>
        </Grid>
      </Grid>
    )
  }
}

export default withTranslation('translations')(
  withStyles(tablestyle)(DailySupply),
)
