export const GET_SUPPLY = 'GET_SUPPLY'
export const GET_SUPPLY_SUCCEEDED = 'GET_SUPPLY_SUCCEEDED'
export const GET_SUPPLY_FAILED = 'GET_SUPPLY_FAILED'

export const UPDATE_DAILY_SUPPLY_REQEUST = 'UPDATE_DAILY_SUPPLY_REQEUST'
export const UPDATE_DAILY_SUPPLY_REQUEST_SUCCEEDED = 'UPDATE_DAILY_SUPPLY_REQUEST_SUCCEEDED'
export const UPDATE_DAILY_SUPPLY_REQEUST_FAILED = 'UPDATE_DAILY_SUPPLY_REQEUST_FAILED'


const initState = {
  isLoading: false,
 supply: {
    data: [],
    totalmeals: 0,
    approvedmeals:0,
    pendingapproved:0,
    declined:0,
    count:0,
    totalMeal:0,
    totalAmount:0,
    totalVeg:0,
    totalNonVeg:0,
  },
     orderUpdate:{
         message:"",
         data:[],
     },
}

export default function (state = { ...initState }, action) {
  switch (action.type) {
    case GET_SUPPLY:
      return {
        ...state,
        isLoading: true
      }
    case GET_SUPPLY_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        supply: action.payload
      }
    case GET_SUPPLY_FAILED:
      return {
        ...state,
        isLoading: false,
        supply: {}
      }

    case UPDATE_DAILY_SUPPLY_REQEUST:
      return {
        ...state,
        isLoading: true
      }
    case UPDATE_DAILY_SUPPLY_REQUEST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        orderUpdate: action.payload
      }
    case UPDATE_DAILY_SUPPLY_REQEUST_FAILED:
      return {
        ...state,
        isLoading: false,
        orderUpdate: []
      }

    default:
      return state
  }
}