import React from "react";




function ManageUsersIcon(props) {
    
return (
<svg xmlns="http://www.w3.org/2000/svg" width="28.266" height="18.424" viewBox="0 0 28.266 18.424">
  <g id="Group_1704" data-name="Group 1704" transform="translate(-150.7 -557.459)">
    <path id="Path_5534" data-name="Path 5534" d="M21.244,60.84a7.022,7.022,0,1,0-7.77,0,11.26,11.26,0,0,0-5.307,4.078.936.936,0,0,0,.766,1.475H25.785a.936.936,0,0,0,.765-1.475A11.26,11.26,0,0,0,21.244,60.84Z" transform="translate(142.704 509.49)" fill="#757575"/>
    <path id="Path_5535" data-name="Path 5535" d="M159.192,64.937a11.26,11.26,0,0,0-5.307-4.077,7.022,7.022,0,0,0-5.79-12.606.936.936,0,0,0-.5,1.46,8.875,8.875,0,0,1,.449,9.934.936.936,0,0,0,.239,1.241,13.252,13.252,0,0,1,1.023.844c.017.018.034.037.053.054a13.112,13.112,0,0,1,2.982,4.085.936.936,0,0,0,.848.54h5.234a.936.936,0,0,0,.765-1.475Z" transform="translate(19.604 509.47)" fill="#757575"/>
  </g>
</svg>

);
}
export default ManageUsersIcon;