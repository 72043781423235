export const GET_TUCKSHOP_ORDERS = 'GET_TUCKSHOP_ORDERS'
export const GET_TUCKSHOP_ORDERS_SUCCEEDED = 'GET_TUCKSHOP_ORDERS_SUCCEEDED'
export const GET_TUCKSHOP_ORDERS_FAILED = 'GET_TUCKSHOP_ORDERS_FAILED'

export const GET_EMPLOYEE = 'GET_EMPLOYEE'
export const GET_EMPLOYEE_SUCCEEDED = 'GET_EMPLOYEE_SUCCEEDED'
export const GET_EMPLOYEE_FAILED = 'GET_EMPLOYEE_FAILED'

export const GET_TUCKSHOP_EXPORT_ORDERS = 'GET_TUCKSHOP_EXPORT_ORDERS'
export const GET_TUCKSHOP_EXPORT_SUCCEEDED = 'GET_TUCKSHOP_EXPORT_SUCCEEDED'
export const GET_TUCKSHOP_EXPORT_FAILED = 'GET_TUCKSHOP_EXPORT_FAILED'




const initState = {
  isLoading: false,
  orders: {
    data: [],
    total: 0
  },
  mealTypes: [],
  employees:{},
}

export default function (state = { ...initState }, action) {
  switch (action.type) {
    case GET_TUCKSHOP_ORDERS:
      return {
        ...state,
        isLoading: true
      }
    case GET_TUCKSHOP_ORDERS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        orders: action.payload
      }
    case GET_TUCKSHOP_ORDERS_FAILED:
      return {
        ...state,
        isLoading: false,
        orders: {}
      }
      
      case GET_EMPLOYEE:
      return {
        ...state,
        isLoading: true
      }
    case GET_EMPLOYEE_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        employees: action.payload
      }
    case GET_EMPLOYEE_FAILED:
      return {
        ...state,
        isLoading: false,
        employees: {}
      }

    default:
      return state
  }
}