export const GET_USER = 'GET_USER'
export const GET_USER_SUCCEEDED = 'GET_USER_SUCCEEDED'
export const GET_USER_FAILED = 'GET_USER_FAILED'

export const UPDATE_USER_STATUS_REQEUST = 'UPDATE_USER_STATUS_REQEUST'
export const UPDATE_USER_STATUS_REQUEST_SUCCEEDED = 'UPDATE_USER_STATUS_REQUEST_SUCCEEDED'
export const UPDATE_USER_STATUS_REQUEST_FAILED = 'UPDATE_USER_STATUS_REQUEST_FAILED'

export const CREATE_USER = 'CREATE_USER'
export const CREATE_USER_SUCCEEDED = 'CREATE_USER_SUCCEEDED'
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED'

export const GET_SINGLE_USER = 'GET_SINGLE_USER'
export const GET_SINGLE_USER_SUCCEEDED = 'GET_SINGLE_USER_SUCCEEDED'
export const GET_SINGLE_USER_FAILED = 'GET_SINGLE_USER_FAILED'

export const GET_USER_PERMISSION = 'GET_USER_PERMISSION'
export const GET_USER_PERMISSION_SUCCEEDED = 'GET_USER_PERMISSION_SUCCEEDED'
export const GET_USER_PERMISSION_FAILED = 'GET_USER_PERMISSION_FAILED'

const initState = {
  isLoading: false,
 users: {},
  count:0,
  message:[],
  user:{},
  userPermission:{},
  userPermissionLoading:false,
}


export default function (state = { ...initState }, action) {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        isLoading: true,
        user:{}
      }
    case GET_USER_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        users: action.payload,
        user:{},
      }
    case GET_USER_FAILED:
      return {
        ...state,
        isLoading: false,
        users: {},
        user:{},
      }

    case UPDATE_USER_STATUS_REQEUST:
      return {
        ...state,
        isLoading: true,
      }
    case UPDATE_USER_STATUS_REQUEST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        message: action.payload,

      }
    case UPDATE_USER_STATUS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        message: [],
      }
  case CREATE_USER:
      return {
       ...state,
       isLoading:true,
      }
  case CREATE_USER_SUCCEEDED:
        return{
        ...state,
        isLoading:false,
        data:action.payload
        }
      
    case CREATE_USER_FAILED:
         return {
            ...state,
            isLoading:false,
          }
          case GET_SINGLE_USER:
      return {
        ...state,
        isLoading: true
      }
    case GET_SINGLE_USER_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        user: action.payload
      }
    case GET_SINGLE_USER_FAILED:
      return {
        ...state,
        isLoading: false,
        user: {}
      }
      case GET_USER_PERMISSION:
        return {
          ...state,
          userPermissionLoading:true
        }
        case GET_USER_PERMISSION_SUCCEEDED:
          return {
            ...state,
            userPermissionLoading: false,
            userPermission: action.payload
          }
        case GET_USER_PERMISSION_FAILED:
          return {
            ...state,
            userPermissionLoading: false,
          }
         
        
    default:
      return state
  }
}