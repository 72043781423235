import { updatePasswordAPI, getCompanyDetailsAPI, updateCompanyDetailAPI, getMealSettingsAPI, updateMealSettingsAPI } from './apis';
import {
  SET_LOADING,
  RESET_LOADING,
  GET_COMPANY_DETAILS_REQUEST,
  GET_COMPANY_DETAILS_RESPONSE,
  GET_MEAL_SETTINGS_REQUEST,
  GET_MEAL_SETTINGS_RESPONSE
} from './reducer'
import { saveObject } from '../../utils';

export function updatePassword(payload) {
  return dispatch => {
    dispatch({
      type: SET_LOADING
    })
    return new Promise((resolve, reject) => {
      updatePasswordAPI(payload)
        .then(({ data }) => {
          dispatch({
            type: RESET_LOADING
          })
          return resolve(data)
        })
        .catch(err => {
          dispatch({
            type: RESET_LOADING
          })
          return reject(err)
        })
    })
  }
}

export function getCompanyDetailsAction(save) {
  return dispatch => {

    dispatch({
      type: GET_COMPANY_DETAILS_REQUEST
    })

    return new Promise((resolve, reject) => {
      getCompanyDetailsAPI()
        .then(({ data }) => {
          if (save) {
            let olddata = JSON.parse(window.localStorage.getItem('bb-user'));
            olddata.userDetails.logo = data.logo;
            olddata.userDetails.name = data.name;
            olddata.userDetails.walletAmount = data?.walletAmount || 0;
            saveObject("bb-user", JSON.stringify(olddata))
          }
          dispatch({
            type: GET_COMPANY_DETAILS_RESPONSE,
            payload: data
          })
          return resolve(data)
        })
        .catch(err => {

          dispatch({
            type: GET_COMPANY_DETAILS_REQUEST
          })
          return reject(err)
        })
    })
  }
}

export function updateCompanyDetail(payload) {
  return dispatch => {
    dispatch({
      type: SET_LOADING
    })
    return new Promise((resolve, reject) => {
      updateCompanyDetailAPI(payload)
        .then(({ data }) => {
          dispatch({
            type: RESET_LOADING
          })
          return resolve(data)
        })
        .catch(err => {
          dispatch({
            type: RESET_LOADING
          })
          return reject(err)
        })
    })
  }
}

export function getMealSettingsAction() {
  return dispatch => {

    dispatch({
      type: GET_MEAL_SETTINGS_REQUEST
    })

    return new Promise((resolve, reject) => {
      getMealSettingsAPI()
        .then(({ data }) => {
          dispatch({
            type: GET_MEAL_SETTINGS_RESPONSE,
            payload: data
          })
          return resolve(data)
        })
        .catch(err => {

          dispatch({
            type: GET_MEAL_SETTINGS_REQUEST
          })
          return reject(err)
        })
    })
  }
}

export function updateMealSettings(payload) {
  return dispatch => {
    dispatch({
      type: SET_LOADING
    })
    return new Promise((resolve, reject) => {
      updateMealSettingsAPI(payload)
        .then(({ data }) => {
          dispatch({
            type: RESET_LOADING
          })
          return resolve(data)
        })
        .catch(err => {
          dispatch({
            type: RESET_LOADING
          })
          return reject(err)
        })
    })
  }
}