import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Dialog, RadioGroup, CircularProgress, FormControl, Box, FormControlLabel, InputLabel, Select, MenuItem, Grid } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import InputField from '../../../components/common/InputField';
import { validateVouchers } from '../validations';
import { assignVoucher } from '../actions'
import { useDispatch, useSelector } from 'react-redux';
import Radio from '@material-ui/core/Radio';
import { green, red } from '@material-ui/core/colors';
import { getMealTypes } from '../../Meals/actions';

import WbSunnyIcon from '@material-ui/icons/WbSunny';
import Brightness3Icon from '@material-ui/icons/Brightness3';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const initVoucher = {
  voucherName: '',
  numberOfVoucher: '',
  valueOfEach: '',
  expiredAt: '',
  employeeId: '',
  voucherCode:""
}

export default function AssignVoucher(props) {
  const { actionLabel, thisVoucher,selectUser,toast } = props
  const [open, setOpen] = useState(false);
  const [voucherRequest, setvoucherRequest] = useState({ ...initVoucher })
  const [valueOfAll, setvalueOfAll] = useState(0)
  const [addButtonDisable, setAddButtonDisable] = useState(false)
  const [valueOfEachStatus, setvalueOfEachStatus] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [dataChange, setDataChange] = useState(false)
  const [errors, setErrors] = useState({})
  const dispatch = useDispatch()
  const mealData = useSelector(state => state?.meals || [])
  const { mealTypes } = mealData


  if(!voucherRequest.voucherName && thisVoucher.voucherName){
    setvoucherRequest({ ...voucherRequest,voucherName: thisVoucher.voucherName })
  }
  if(!voucherRequest.voucherCode && thisVoucher.voucherCode){
    setvoucherRequest({ ...voucherRequest,voucherCode: thisVoucher.voucherCode })
  }
  if(!voucherRequest.expiredAt && thisVoucher.expiredAt){
    setvoucherRequest({ ...voucherRequest,expiredAt: thisVoucher.expiredAt })
  }
  if(!voucherRequest.valueOfEach && thisVoucher?.valueOfEach && thisVoucher.valueOfEach > 0){
    setvoucherRequest({ ...voucherRequest,valueOfEach: thisVoucher.valueOfEach })
    setvalueOfEachStatus(true)
  }



  function handleClickOpen() {
    setOpen(true);
    setErrors({});
  };

  function getMealTypesList() {
    dispatch(getMealTypes())
  }

  function handleClose() {
    setOpen(false);
    setvoucherRequest({ ...initVoucher })
    setErrors({})
  };

  function isValid() {
    const VoucherToValidate = {
      numberOfVoucher: voucherRequest.numberOfVoucher,
      valueOfEach: voucherRequest.valueOfEach,
    }
   let availableVoucher=thisVoucher.availableVoucher;
   let availableVoucherValue=thisVoucher.availableVoucherValue;

  //  availableVoucher=3;
  //  availableVoucherValue=30;
    const { isValid, errors: newErrors } = validateVouchers(VoucherToValidate,availableVoucher,availableVoucherValue)
    setErrors(newErrors)
    return isValid
  }

  function onSubmit(e) {
    e.preventDefault()
    if (!isValid()) return;
    setAddButtonDisable(true)
    setIsLoading(true)
    assign()
  }

  function assign() {
    const { toast, onClose } = props;
    dispatch(assignVoucher(voucherRequest))
      .then(async () => {
        onClose();
        handleClose()
        setAddButtonDisable(false)
        setIsLoading(false)
        toast.success("Voucher assigned successfully");
      })
      .catch((error) => {
        toast.error(error.message || 'Oops! Something went wrong')
        setAddButtonDisable(false)
        setIsLoading(false)
      })
  }


  function handleChange(key, value) {
  let totalValue=0;
    setErrors({ ...errors, [key]: '' })
   let totalUsers=selectUser.length;
    if(key == "valueOfEach"){
      if(!isNaN(voucherRequest.numberOfVoucher) && !isNaN(value) && !isNaN(totalUsers) && totalUsers > 0){
      totalValue=(voucherRequest.numberOfVoucher*value *totalUsers)
      setvoucherRequest({ ...voucherRequest,[key]:value,employeeId:selectUser })
      setvalueOfAll(totalValue);
      }
    }else if(key == "numberOfVoucher"){
      if(!isNaN(voucherRequest.valueOfEach) && !isNaN(value) && !isNaN(totalUsers) && totalUsers > 0){
        totalValue=(voucherRequest.valueOfEach*value*totalUsers)
        setvoucherRequest({ ...voucherRequest,[key]:value,employeeId:selectUser })
        setvalueOfAll(totalValue)
        }
    }
    setDataChange(true)
  }



  const valid = voucherRequest.numberOfVoucher && voucherRequest.valueOfEach   || false

  return (
    <div>
      <Button
        className="button-btn btn-custom-primry"
        onClick={handleClickOpen}
      >
        {actionLabel}
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
        fullWidth
        disableBackdropClick={true}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Edit Voucher 
        </DialogTitle>
        <DialogContent dividers>
        <Box py={1}>
            <InputField
              fullWidth
              label="Total Employees Selected"
              value={selectUser.length}
              disabled={valueOfEachStatus}
            />
          </Box>
          <Box py={1}>
            <InputField
              fullWidth
              label="Voucher Name"
              value={voucherRequest.voucherName}
              disabled={valueOfEachStatus}
            />
          </Box>
          <Box py={1}>
            <InputField
              fullWidth
              label="Number of Voucher"
              onChange={({ target: { value } }) => handleChange('numberOfVoucher', value)}
              value={voucherRequest.numberOfVoucher}
              error={errors.numberOfVoucher}
            
            />
          </Box>
          <Box py={1}>
            <InputField
              fullWidth
              label="Value of Each"
              onChange={({ target: { value } }) => handleChange('valueOfEach', value)}
              value={voucherRequest.valueOfEach}
              error={errors.valueOfEach}
              disabled={valueOfEachStatus}
            />
          </Box>
          <Box py={1}>
            <InputField
              // type="number"
              fullWidth
              label="Value of All"
              value={valueOfAll}
              disabled={valueOfEachStatus}
            />
          </Box>

        </DialogContent>
        <DialogActions>
          <div className="foot-modal-btns">
            <Button onClick={handleClose} color="secondary" variant="contained">
              Cancel
            </Button>
            <Button color="primary" variant="contained" onClick={onSubmit} disabled={isLoading}>
              {isLoading ? <CircularProgress size="20px" color="white" /> : "Assign"}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div >
  );
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={`${classes.closeButton}`} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const RedRadio = withStyles({
  root: {
    color: red[400],
    '&$checked': {
      color: red[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);