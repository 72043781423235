import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Dialog, RadioGroup, CircularProgress, FormControl, Box, FormControlLabel, InputLabel, Select, MenuItem, Grid } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import InputField from '../../../components/common/InputField';
import { validateEmployee } from '../validations';
import { createEmployee, updateEmployee } from '../actions'
import { useDispatch, useSelector } from 'react-redux';
import Radio from '@material-ui/core/Radio';
import { green, red } from '@material-ui/core/colors';
import { getMealTypes } from '../../Meals/actions';

import WbSunnyIcon from '@material-ui/icons/WbSunny';
import Brightness3Icon from '@material-ui/icons/Brightness3';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const initEmp = {
  name: '',
  employeeId: '',
  mealId: '',
  shiftType: '',
  walletAmount: 0
}

function getCompanyData(state) {
  const companydata = state?.settings?.companydetails || {}
  //return companydata;

  return {
    companyMealType: companydata.mealType || "",
    _id: companydata._id || '',
    username: companydata.username || '',
    email: companydata.email || '',
    logo: companydata.logo || '',
    address: companydata.address || '',
    name: companydata.name || '',
    contactPersonName: companydata.contactPersonName || '',
    contactPersonId: companydata.contactPersonId || '',
    phoneNumber: companydata.phoneNumber || "",
    gstNumber: companydata.gstNumber || '',
    workingDays: companydata?.workingDays || [],
    shift: companydata.shift || '',

  }
}

export default function AddEmployee(props) {
  const { actionLabel, editable = false, empToEdit = {}, setEditable } = props

  const [open, setOpen] = useState(false);
  const [employee, setEmployee] = useState({ ...initEmp })
  const [addButtonDisable, setAddButtonDisable] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [dataChange, setDataChange] = useState(false)
  const [errors, setErrors] = useState({})
  const dispatch = useDispatch()
  const mealData = useSelector(state => state?.meals || [])
  const { mealTypes } = mealData
  const [NonVegObj, setNonVegObj] = useState({})
  const shiftType = [
    { id: 1, name: 'DAY', value: "DAY" },
    { id: 2, name: 'NIGHT', value: "NIGHT" },
    // { id: 3, name: 'BOTH', value: "BOTH" },
  ]
  const {
    companyMealType,
    shift,
  } = useSelector(getCompanyData)
  useEffect(() => {
    /**
     * The following code will run only in the case of editable.
     * If editable is true then set the empToEdit (employee from props) to current employee object
     */
    if (editable) {
      setEmployee(empToEdit)
      setOpen(true)
      if (!mealTypes.length) {
        getMealTypesList()
      }
    }

  }, [editable, empToEdit])

  function handleClickOpen() {
    setOpen(true);
    setErrors({});
    if (!editable) {
      let requiredMealId = "";
      let requiredShiftId = "";
      mealTypes.map((meal) => {
        if (meal.mealName == companyMealType) {
          requiredMealId = meal._id;
        }
      })
      shiftType.map((item) => {
        if (item.value == shift) {
          requiredShiftId = item.value;
        }
      })
      // console.log(requiredMealId, requiredShiftId, "shidtf")
      if (requiredMealId || requiredShiftId) {
        setEmployee({ ...employee, mealId: requiredMealId, shiftType: requiredShiftId })
      }

    }
  };

  function getMealTypesList() {
    dispatch(getMealTypes())
  }
  // if( Object.keys(NonVegObj).length ===0 && mealTypes.length > 0){
  //   var NonVeg=mealTypes.filter((meal) => { return meal.mealType==="NON VEG"; })
  //   setNonVegObj(NonVeg[0] || {});
  // }
  // console.log(mealTypes," this is are meal type")

  function handleClose() {
    setOpen(false);
    setEmployee({ ...initEmp })
    setErrors({})
    setEditable(false)
  };

  function isValid() {
    const employeeToValidate = {
      name: employee.name,
      mealId: employee.mealId,
      ...(employee.phone && {phone: employee.phone}),
      ...(employee.email && {email: employee.email}),
      employeeId: employee.employeeId,
      shiftType: employee.shiftType,
      walletAmount: employee.walletAmount || 0
    }
    
    const { isValid, errors: newErrors } = validateEmployee(employeeToValidate)
    setErrors(newErrors)
    return isValid
  }

  function onSubmit(e) {
    e.preventDefault()
    if (!isValid()) return;
    setAddButtonDisable(true)
    setIsLoading(true)
    if (editable) {
      update()
    } else {
      create()
    }
  }

  function create() {
    const { toast, onClose } = props;
    dispatch(createEmployee(employee))
      .then(async () => {
        onClose();
        handleClose()
        setAddButtonDisable(false)
        setIsLoading(false)
        toast.success("Employee added successfully");
      })
      .catch(({ message = '' }) => {
        toast.error(message || 'Oops! Something went wrong')
        setAddButtonDisable(false)
        setIsLoading(false)
      })
  }

  function update() {
    const { toast, onClose } = props;
    if (dataChange) {
      dispatch(updateEmployee(employee)).then((res) => {
        onClose()
        handleClose()
        toast.success("Employee updated successfully");
        setAddButtonDisable(false)
        setIsLoading(false)
      }).catch(({ message = '' }) => {
        toast.error(message || 'Oops! Something went wrong')
        setAddButtonDisable(false)
        setIsLoading(false)
      })
      return
    } else {
      handleClose()
      setAddButtonDisable(false)
      setIsLoading(false)
      onClose()
      return
    }

  }

  function handleChange(key, value) {
    let numberReg = /^[0-9]{0,10}$/;
    if (key == "phone" && value && !numberReg.test(value)) {
      setErrors({ ...errors, [key]: 'This key is not allowed in this field'});
      setEmployee({ ...employee, [key]: employee[key] ? employee[key] : '' })
      return;
    } 
    let spaceReg = /\s/g;
    if (key == "employeeId" && value && spaceReg.test(value)) {
      setErrors({ ...errors, [key]: 'This key is not allowed in this field'});
      setEmployee({ ...employee, [key]: employee[key] ? employee[key] : '' })
      return;
    } 
    if(key == "employeeId") {
      value = value.toUpperCase();
    }
    setEmployee({ ...employee, [key]: value })
    setErrors({ ...errors, [key]: '' })
    setDataChange(true)
  }



  const valid = employee.name && employee.employeeId && employee.email && employee.phone && employee?.mealId && employee?.shiftType && employee?.walletAmount || false;

  return (
    <div className='tras-bin ml-2'>
      <Button
        className="btn-custom-primry button-btn-csv tras-bin-btn add-employee"
        onClick={handleClickOpen}
        data-tooltip="Add Employee"
      >
        {actionLabel}
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
        fullWidth
        disableBackdropClick={true}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {editable ? 'Edit Employee' : 'Add Employee'}
        </DialogTitle>
        <DialogContent dividers>
          <Box py={1}>
            <InputField
              fullWidth
              label="Name"
              onChange={({ target: { value } }) => handleChange('name', value)}
              value={employee.name}
              error={errors.name}
            />
          </Box>
          <Box py={1}>
            <InputField
              fullWidth
              label="Employee ID"
              onChange={({ target: { value } }) => handleChange('employeeId', value)}
              value={employee.employeeId}
              error={errors.employeeId}
            />
          </Box>
          <Box py={1}>
            <InputField
              fullWidth
              label="Email"
              onChange={({ target: { value } }) => handleChange('email', value)}
              value={employee.email}
              error={errors.email}
            />
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box py={1}>
                <InputField
                  // type="number"
                  fullWidth
                  label="Mobile No."
                  onChange={({ target: { value } }) => handleChange('phone', value)}
                  value={employee.phone}
                  error={errors.phone}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box py={1}>
                <InputField
                  // type="number"
                  disabled={editable}
                  fullWidth
                  label="Wallet Amount (Optional)"
                  onChange={({ target: { value } }) => handleChange('walletAmount', value)}
                  value={employee.walletAmount}
                  error={errors.walletAmount}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box py={1}>
                <FormControl variant="outlined" style={{ minWidth: "100%" }}>
                  <InputLabel id="meal-type-selection">Meal Type</InputLabel>
                  <Select
                    labelId="meal-type-selection"
                    id="meal-type"
                    value={employee?.mealId || ''}
                    onChange={({ target: { value } }) => handleChange('mealId', value)}
                    label="Meal Type"
                    fullWidth
                  >
                    {companyMealType === "BOTH" ? mealTypes?.map(meal => <MenuItem value={meal._id} key={meal._id}>{meal?.mealName || '-'}</MenuItem>) : mealTypes.filter((item) => item.mealName === companyMealType)?.map(meal => <MenuItem value={meal._id} key={meal._id}>{meal?.mealName || '-'}</MenuItem>)}
                    {/* {editable ? mealTypes?.map(meal => <MenuItem value={meal._id} key={meal._id}>{meal?.mealName || '-'}</MenuItem>) : (companyMealType === "BOTH" ? mealTypes?.map(meal => <MenuItem value={meal._id} key={meal._id}>{meal?.mealName || '-'}</MenuItem>) : mealTypes.filter((item) => item.mealName === companyMealType)?.map(meal => <MenuItem value={meal._id} key={meal._id}>{meal?.mealName || '-'}</MenuItem>))} */}
                    {/* {mealTypes?.map(meal => <MenuItem value={meal._id} key={meal._id}>{meal?.mealName || '-'}</MenuItem>)} */}
                  </Select>
                  {errors.mealId && <span className="help-block error text-left">{errors.mealId}</span>}
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box py={1}>
                <FormControl variant="outlined" style={{ minWidth: "100%" }}>
                  <InputLabel id="meal-type-selection">Shift Type</InputLabel>
                  <Select
                    labelId="meal-type-selection"
                    id="shift-type"
                    value={employee?.shiftType || ''}
                    onChange={({ target: { value } }) => handleChange('shiftType', value)}
                    label="Shift Type"
                    fullWidth
                  >
                    {(shift === "BOTH" ? shiftType?.map(shift => <MenuItem value={shift.value} key={shift.id}>{shift?.name || '-'}</MenuItem>) : shiftType.filter((item) => item.value === shift)?.map(shift => <MenuItem value={shift.value} key={shift.id}>{shift?.name || '-'}</MenuItem>))}
                    {/* {editable ? shiftType?.map(shift => <MenuItem value={shift.value} key={shift.id}>{shift?.name || '-'}</MenuItem>) : (shift === "BOTH" ? shiftType?.map(shift => <MenuItem value={shift.value} key={shift.id}>{shift?.name || '-'}</MenuItem>) : shiftType.filter((item) => item.value === shift)?.map(shift => <MenuItem value={shift.value} key={shift.id}>{shift?.name || '-'}</MenuItem>))} */}

                    {/* {shiftType?.map(shift => <MenuItem value={shift.value} key={shift.id}>{shift?.name || '-'}</MenuItem>)} */}
                  </Select>
                  {errors.shiftType && <span className="help-block error text-left">{errors.shiftType}</span>}
                </FormControl>
              </Box>
            </Grid>
          </Grid>


          {/* <Box py={1}>
            <FormControl component="fieldset">
              <label>Meal Type</label>
              <RadioGroup
                aria-label="Meal Type"
                name="mealType"
                value={employee.mealType}
                onChange={({ target: { value = '' } }) => handleChange('mealType', value)}
              >
                <Box display="flex">
                  <FormControlLabel
                    value="VEG"
                    control={<GreenRadio />}
                    label="Veg"
                    style={{ margin: 0 }}
                  />
                  <FormControlLabel
                    value="NON_VEG"
                    control={<RedRadio />}
                    label="Non Veg"
                    style={{ margin: 0 }}
                  />
                </Box>
              </RadioGroup>
            </FormControl>
          </Box> */}
        </DialogContent>
        <DialogActions>
          <div className="foot-modal-btns">
            <Button onClick={handleClose} color="secondary" variant="contained">
              Cancel
            </Button>
            <Button color="primary" variant="contained" onClick={onSubmit} disabled={isLoading}>
              {isLoading ? <CircularProgress size="20px" color="white" /> : (editable ? "Update" : "Add")}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div >
  );
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const RedRadio = withStyles({
  root: {
    color: red[400],
    '&$checked': {
      color: red[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);