import {
  getCompanyWalletAPI,
  addWalletDataAPI
} from './apis'
import {
  GET_WALLET,
  GET_WALLET_SUCCEEDED,
  GET_WALLET_FAILED,
  UPDATE_WALLET_DATA,
  UPDATE_WALLET_DATA_SUCCEEDED,
  UPDATE_WALLET_DATA_FAILED,
} from './reducer'



export function getCompanyWallet(payload) {
  return dispatch => {

    dispatch({
      type: GET_WALLET
    })

    return new Promise((resolve, reject) => {
      getCompanyWalletAPI(payload)
        .then(({ data }) => {
          dispatch({
            type: GET_WALLET_SUCCEEDED,
            payload: data
          })
          return resolve(data)
        })
        .catch(err => {

          dispatch({
            type: GET_WALLET_FAILED
          })
          return reject(err)
        })
    })
  }
}


export function addWalletData(data) {
  return dispatch => {

    dispatch({
      type: UPDATE_WALLET_DATA
    })

    return new Promise((resolve, reject) => {
      addWalletDataAPI(data)
        .then(({ data }) => {

          dispatch({
            type: UPDATE_WALLET_DATA_SUCCEEDED,
            payload: data
          })
          return resolve(data)
        })
        .catch(err => {

          dispatch({
            type: UPDATE_WALLET_DATA_FAILED
          })
          return reject(err)
        })
    })
  }
}

// export function getDailySupplyList(data) {
//   return dispatch => {

//     dispatch({
//       type: UPDATE_DAILY_SUPPLY_REQEUST
//     })

//     return new Promise((resolve, reject) => {
//       getOptTimeAPI(data)
//         .then(({ data }) => {

//           dispatch({
//             type: UPDATE_DAILY_SUPPLY_REQUEST_SUCCEEDED,
//             payload: data
//           })
//           return resolve(data)
//         })
//         .catch(err => {

//           dispatch({
//             type: UPDATE_DAILY_SUPPLY_REQEUST_FAILED
//           })
//           return reject(err)
//         })
//     })
//   }
// }