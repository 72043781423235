import {
  getOptTimeAPI,
  getTodayMealDataAPI,
  approveMealDataAPI,
  getSingleMealDataAPI
} from './apis'
import {
  GET_OPTTIME,
  GET_OPTTIME_SUCCEEDED,
  GET_OPTTIME_FAILED,
  GET_TODAY_MEAL,
  GET_TODAY_MEAL_SUCCEEDED,
  GET_TODAY_MEAL_FAILED,
  UPDATE_MEAL_DATA,
  UPDATE_MEAL_DATA_SUCCEEDED,
  UPDATE_MEAL_DATA_FAILED,
  GET_TODAY_SINGLE_MEAL,
  GET_TODAY_SINGLE_MEAL_SUCCEEDED,
  GET_TODAY_SINGLE_MEAL_FAILED,
} from './reducer'

export function getOptTime() {
  return dispatch => {

    dispatch({
      type: GET_OPTTIME
    })

    return new Promise((resolve, reject) => {
      getOptTimeAPI()
        .then(({ data }) => {
          dispatch({
            type: GET_OPTTIME_SUCCEEDED,
            payload: data
          })
          return resolve(data)
        })
        .catch(err => {

          dispatch({
            type: GET_OPTTIME_FAILED
          })
          return reject(err)
        })
    })
  }
}

export function getTodayMealData(payload) {
  return dispatch => {

    dispatch({
      type: GET_TODAY_MEAL
    })

    return new Promise((resolve, reject) => {
      getTodayMealDataAPI(payload)
        .then(({ data }) => {
          dispatch({
            type: GET_TODAY_MEAL_SUCCEEDED,
            payload: data
          })
          return resolve(data)
        })
        .catch(err => {

          dispatch({
            type: GET_TODAY_MEAL_FAILED
          })
          return reject(err)
        })
    })
  }
}


export function getSingleMealData(payload) {
  return dispatch => {

    dispatch({
      type: GET_TODAY_SINGLE_MEAL
    })

    return new Promise((resolve, reject) => {
      getSingleMealDataAPI(payload)
        .then(({ data }) => {
          dispatch({
            type: GET_TODAY_SINGLE_MEAL_SUCCEEDED,
            payload: data
          })
          return resolve(data)
        })
        .catch(err => {

          dispatch({
            type: GET_TODAY_SINGLE_MEAL_FAILED
          })
          return reject(err)
        })
    })
  }
}

export function approveMealData(data) {
  return dispatch => {

    dispatch({
      type: UPDATE_MEAL_DATA
    })

    return new Promise((resolve, reject) => {
      approveMealDataAPI(data)
        .then(({ data }) => {

          dispatch({
            type: UPDATE_MEAL_DATA_SUCCEEDED,
            payload: data
          })
          return resolve(data)
        })
        .catch(err => {

          dispatch({
            type: UPDATE_MEAL_DATA_FAILED
          })
          return reject(err)
        })
    })
  }
}

// export function getDailySupplyList(data) {
//   return dispatch => {

//     dispatch({
//       type: UPDATE_DAILY_SUPPLY_REQEUST
//     })

//     return new Promise((resolve, reject) => {
//       getOptTimeAPI(data)
//         .then(({ data }) => {

//           dispatch({
//             type: UPDATE_DAILY_SUPPLY_REQUEST_SUCCEEDED,
//             payload: data
//           })
//           return resolve(data)
//         })
//         .catch(err => {

//           dispatch({
//             type: UPDATE_DAILY_SUPPLY_REQEUST_FAILED
//           })
//           return reject(err)
//         })
//     })
//   }
// }