export const GET_DASHBOARD_GRAPHS_REQUEST = 'GET_DASHBOARD_GRAPHS_REQUEST'
export const GET_DASHBOARD_GRAPHS_REQUEST_SUCCEEDED = 'GET_DASHBOARD_GRAPHS_REQUEST_SUCCEEDED'
export const GET_DASHBOARD_GRAPHS_REQUEST_FAILED = 'GET_DASHBOARD_GRAPHS_REQUEST_FAILED'

export const GET_DASHBOARD_MEAL_GRAPHS_REQUEST = 'GET_DASHBOARD_MEAL_GRAPHS_REQUEST'
export const GET_DASHBOARD_MEAL_GRAPHS_REQUEST_SUCCEEDED = 'GET_DASHBOARD_MEAL_GRAPHS_REQUEST_SUCCEEDED'
export const GET_DASHBOARD_MEAL_GRAPHS_REQUEST_FAILED = 'GET_DASHBOARD_MEAL_GRAPHS_REQUEST_FAILED'

export const GET_DASHBOARD_EXPENSE_GRAPHS_REQUEST = 'GET_DASHBOARD_EXPENSE_GRAPHS_REQUEST'
export const GET_DASHBOARD_EXPENSE_GRAPHS_REQUEST_SUCCEEDED = 'GET_DASHBOARD_EXPENSE_GRAPHS_REQUEST_SUCCEEDED'
export const GET_DASHBOARD_EXPENSE_GRAPHS_REQUEST_FAILED = 'GET_DASHBOARD_EXPENSE_GRAPHS_REQUEST_FAILED'

export const GET_DASHBOARD_PAYOUT_GRAPHS_REQUEST = 'GET_DASHBOARD_PAYOUT_GRAPHS_REQUEST'
export const GET_DASHBOARD_PAYOUT_GRAPHS_REQUEST_SUCCEEDED = 'GET_DASHBOARD_PAYOUT_GRAPHS_REQUEST_SUCCEEDED'
export const GET_DASHBOARD_PAYOUT_GRAPHS_REQUEST_FAILED = 'GET_DASHBOARD_PAYOUT_GRAPHS_REQUEST_FAILED'

export const GET_DASHBOARD_DATA_REQUEST = 'GET_DASHBOARD_DATA_REQUEST'
export const GET_DASHBOARD_DATA_REQUEST_SUCCEEDED = 'GET_DASHBOARD_DATA_REQUEST_SUCCEEDED'
export const GET_DASHBOARD_DATA_REQUEST_FAILED = 'GET_DASHBOARD_DATA_REQUEST_FAILED'


const initState = {
  isLoading: false,
  graphData: [],
  dashboardData: {},
  mealGraph: [],
  expenseGraph:[],
  payoutGraph:[]
}

export default function (state = { ...initState }, action) {
  switch (action.type) {
    case GET_DASHBOARD_GRAPHS_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case GET_DASHBOARD_GRAPHS_REQUEST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        graphData: action.payload
      }
    case GET_DASHBOARD_GRAPHS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false
      }
    case GET_DASHBOARD_DATA_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case GET_DASHBOARD_DATA_REQUEST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        dashboardData: action.payload
      }
    case GET_DASHBOARD_DATA_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false
      }
    case GET_DASHBOARD_MEAL_GRAPHS_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_DASHBOARD_MEAL_GRAPHS_REQUEST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        mealGraph: action.payload
      }
    case GET_DASHBOARD_MEAL_GRAPHS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        mealGraph: {}
      }
    case GET_DASHBOARD_EXPENSE_GRAPHS_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_DASHBOARD_EXPENSE_GRAPHS_REQUEST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        expenseGraph: action.payload
      }
    case GET_DASHBOARD_EXPENSE_GRAPHS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        expenseGraph: {}
      }
      case GET_DASHBOARD_PAYOUT_GRAPHS_REQUEST:
        return {
          ...state,
          isLoading: true,
        }
      case GET_DASHBOARD_PAYOUT_GRAPHS_REQUEST_SUCCEEDED:
        return {
          ...state,
          isLoading: false,
          payoutGraph: action.payload
        }
      case GET_DASHBOARD_PAYOUT_GRAPHS_REQUEST_FAILED:
        return {
          ...state,
          isLoading: false,
          payoutGraph: {}
        }



    default:
      return state
  }
}