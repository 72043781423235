import { apiPost } from '../../utils/axios';

export function loginAPI(data) {
  const user = {}
  user.username = data.email
  user.password = data.password
  return apiPost('/api/company/login', user);
}

export function logoutAPI() {
  return apiPost('/api/company/logout');
}


export function forgotPasswordAPI(data) {
        const payload={};
        payload.email=data.email;
   return apiPost('/api/company/forgetPassword', payload);
}

export function resetPasswordAPI(data) {
  let formData = new FormData();
  formData.append('password', data.password);
  formData.append('confirm_password', data.confirmPassword);
  formData.append('resent_toker', data.tokenId);
}

