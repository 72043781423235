import React, { useState } from "react";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from "@material-ui/core";
import EnhancedTableHead from "../../components/common/EnhancedTableHead";
import TableLoader from "../../components/common/TableLoader";
import CustomTablePagination from "../../components/common/CustomPagination";
import { getObject, numberFormat, timeFormat } from "../../utils";
import ToolTip from "../../components/common/ToolTip";
import UpdateIcon from "../../assets/images/refresh.svg";
import moment from 'moment'



const manageLocationsHead = [
  {
    id: "vouchertitle",
    numeric: false,
    disablePadding: false,
    label: "Title",
  },
  {
    id: "dateofissue",
    numeric: false,
    disablePadding: false,
    label: "Issue Date",
  },
  {
    id: "dateofexpire",
    numeric: false,
    disablePadding: false,
    label: "Expire Date",
  },
  { id: "noofvoucher", numeric: false, disablePadding: false, label: "Number of Vouchers" },
  { id: "totalvoucherval", numeric: false, disablePadding: false, label: "Total value of Vouchers" },
  { id: "vouchervalueeach", numeric: false, disablePadding: false, label: "Value of each" },
  // { id: "usedvoucher", numeric: false, disablePadding: false, label: "Used voucher" },
  { id: "availableVoucher", numeric: false, disablePadding: false, label: "Available voucher" },
  { id: "availablevalue", numeric: false, disablePadding: false, label: "Available voucher value" },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
  { id: "actions", numeric: false, disablePadding: false, label: "Action" },
];

function TabPanel(props) {
  const [editable, setEditable] = useState(false);

  const object = getObject("bb-user");

  const userObject = (object && JSON.parse(object)) || "";
  const { name = "", userType = "" } = (userObject && userObject.userDetails) || "";

  const user_id = (userObject.userDetails._id) || "";


  const today = moment();

  function compareDate(date) {
    if (date && date != 0) {
      var day = moment(date)

      if (today > date) {
        return false
      } else {
        return true
      }
    } else {

      return true;
    }
    //past date
  }

  function handlePageChange(e, nextPage) {
    e.preventDefault();
    if (nextPage === page) {
      return;
    }

    setPage(nextPage);
    setSkip((nextPage - 1) * limit);
  }

  function capitalize(s) {
    return s[0].toUpperCase() + s.slice(1);
  }

  const {
    isLoading,
    voucherList,
    value,
    index,
    classes,
    limit,
    page,
    count,
    setPage,
    setSkip,
    toast,
    showupdateInvoice,
    showdownloadInvoice,
    handleEditVoucher,
    loaderRowId,
    invoiceDownloadLoading,
    handleInvoiceDownload,
    updateVouchers,
    ...other
  } = props;



  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className="table-design w-100"
    >
      {isLoading ? (
        <>
          <TableContainer className={classes.container}>
            <Table
              className="table-program"
              stickyHeader
              aria-label="sticky table"
            >
              <EnhancedTableHead headCells={manageLocationsHead} />
            </Table>
          </TableContainer>
          <TableLoader />
        </>
      ) : null}

      {!isLoading ? (
        <TableContainer className={classes.container}>
          <Table
            className="table-program"
            stickyHeader
            aria-label="sticky table"
          >
            <EnhancedTableHead
              // order={order}
              // orderBy={orderBy}
              // onRequestSort={handleRequestSort}
              headCells={manageLocationsHead}
            />
            <TableBody>
              {voucherList && voucherList.length ? (
                voucherList.map((vouchers) => {
                  const {
                    _id,
                    numberOfVoucher,
                    valueOfEach,
                    valueOfAll,
                    availableVoucher,
                    availableVoucherValue,
                    status,
                    createdAt,
                    updatedAt,
                    voucherName,
                    expiredAt,
                    assignTo,
                    voucherCode,
                    voucherUsed,
                  } = vouchers;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      className="cursor_default"
                      key={_id}
                    >
                      <TableCell className="table-custom-width">
                        {voucherName || ""}
                      </TableCell>
                      <TableCell className="table-custom-width">

                        {createdAt && moment(createdAt).format('MMM DD, YY hh:mm A') || ''}
                      </TableCell>
                      <TableCell className="table-custom-width">
                        {expiredAt && moment(expiredAt).format('MMM DD, YY hh:mm A') || ''}
                      </TableCell>

                      <TableCell className="table-custom-width">
                        {numberOfVoucher || "-"}
                      </TableCell>
                      <TableCell>
                        &#8377;{numberFormat(valueOfAll) || 0}
                      </TableCell>
                      <TableCell>
                        &#8377;{numberFormat(valueOfEach) || 0}
                      </TableCell>
                      {/* <TableCell className="table-custom-width">
                        {voucherUsed || 0}
                      </TableCell> */}
                      <TableCell>
                        {availableVoucher || 0}
                      </TableCell>
                      <TableCell>
                        &#8377;{numberFormat(availableVoucherValue) || 0}
                      </TableCell>
                      <TableCell className="table-custom-width">
                        {status ? (
                          status == "ACTIVE" ? (
                            <div className="daily-supply-listing-status invoice-status-new">
                              ACTIVE
                            </div>
                          ) : status == "INACTIVE" ? (
                            <div className="daily-supply-listing-status invoice-status-paid">
                              INACTIVE
                            </div>
                          ) : status == "EXPIRED" ? (
                            <div className="daily-supply-listing-status invoice-status-hold2">
                              EXPIRED
                            </div>
                          ) : status == "USED" ? (
                            <div className="daily-supply-listing-status invoice-status-processed">
                              USED
                            </div>
                          ) : (
                            "-"
                          )
                        ) : (
                          "-"
                        )}
                      </TableCell>

                      <TableCell className="table-custom-width">
                        {(status == "ACTIVE" && updateVouchers == true) && compareDate(expiredAt) == true ?
                          (<div className="td-action-btns">
                            <ul>

                              <ToolTip title="Update">
                                <li>
                                  <img
                                    onClick={() =>
                                      handleEditVoucher(vouchers)
                                    }
                                    className="download-img mr-3"
                                    src={UpdateIcon}
                                  />
                                </li>
                              </ToolTip>
                            </ul>
                          </div>) : "-"}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow
                  hover
                  role="checkbox"
                  className="cursor_default"
                >
                  <TableCell colSpan={10} className="table-custom-width">
                    <Box textAlign="center" p={1} className="no-data-found">
                      <p>No Data Found</p>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}

      <CustomTablePagination
        count={voucherList.length}
        total={count}
        limit={limit}
        page={page}
        onPageChange={handlePageChange}
      />
    </div>
  );
}
export default TabPanel;
