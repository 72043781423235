import React, { useState, useEffect, useMemo } from 'react'
import MoneyOffIcon from '@material-ui/icons/MoneyOff'
import {
  Grid,
  Box,
  Tab,
  Tabs,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useDispatch, useSelector } from 'react-redux'
import { getEmployeesList, getEmployeeNameList } from './actions'
import SearchField from '../../components/common/SearchField'
// import EnhancedTableHead from "../../components/common/EnhancedTableHead";
import CustomTablePagination from '../../components/common/CustomPagination'
import TableLoader from '../../components/common/TableLoader'
import MealTypeDot from '../Common/MealTypeDot'
import AssignVoucher from "./AssignVoucher/AssignVoucher"
import AddIcon from "@material-ui/icons/Add";


let searchTypeInterval = null
function getEmployeesData(state) {
  const employees =
    (state && state.vouchers && state.vouchers.employees) || {}
  const employeeIds = (state && state.vouchers && state.vouchers?.employeeIds) || {}

  return {
    employees: employees.data || [],
    count: employees.count || 0,
    employeeIds: employeeIds,
  }
}
let manageLocationsHead = [
  { id: 'all', numeric: false, disablePadding: false, label: 'ALL' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  {
    id: 'emaployeeId',
    numeric: false,
    disablePadding: false,
    label: 'Employee ID',
  },
  { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
  {
    id: 'phone',
    numeric: false,
    disablePadding: false,
    label: 'Phone No.',
  },
  {
    id: 'mealType',
    numeric: false,
    disablePadding: false,
    label: 'Meal Type',
  },
]

function EnhancedTableHead(props) {
  const { headCells, selectAllCheckBox, selectAllUser } = props
  return (
    <TableHead className="">
      <TableRow>
        {headCells.map((cell, key) => {
          return (
            <TableCell key={key}>
              {cell.id == 'all' ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectAllUser}
                      color="primary"
                      onChange={(e) => selectAllCheckBox(e)}
                    />
                  }
                  label="All"
                />
              ) : (
                cell.label
              )}
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}


function EditVouchers(props) {
  const { closeEditPage, editVoucher, toast, classes } = props
  const dispatch = useDispatch()

  const isLoading = useSelector((state) => state.vouchers.isLoading || false)

  const [selectUser, setSelectUser] = useState([])
  const [selectAllUser, setSelectAllUser] = useState(false)
  const [userAddedStatus, setuserAddedStatus] = useState(false)
  const [showAssignVoucher, setshowAssignVoucher] = useState(false);

  const [limit, setLimit] = useState(8)
  const [skip, setSkip] = useState(0)
  const [page, setPage] = useState(1)
  const [keyword, setKeyword] = useState('');

  const { employees, count, employeeIds } = useSelector(getEmployeesData)

  useEffect(() => {
    getEmployees();
  }, [limit, skip])


  function getEmployees(search = '', nextPage) {
    if (!nextPage) {
      nextPage = page
    }
    setPage(nextPage)
    let skipToSend = (nextPage - 1) * limit
    dispatch(getEmployeesList({ limit, skip: skipToSend, keyword: search }))
    dispatch(getEmployeeNameList({ keyword: search }))
  }

  function handleChangeSearch({ target: { value = '' } }) {
    setKeyword(value)

    if (searchTypeInterval) {
      clearInterval(searchTypeInterval)
    }

    searchTypeInterval = setTimeout(() => {
      getEmployees(value, 1)
    }, 500)
  }
  function handlePageChange(e, nextPage) {
    e.preventDefault()
    if (nextPage === page) {
      return
    }
    setPage(nextPage)
    setSkip((nextPage - 1) * limit)
  }

  function handleChangeCheckbox(e, _id) {
    let checked = e.target.checked
    setSelectAllUser(false);
    setuserAddedStatus(false)
    let selectUserTmp = selectUser

    if (checked == false) {
      let index = selectUserTmp.indexOf(_id)
      if (index > -1) {
        selectUserTmp.splice(index, 1)
      }
      setSelectUser([...selectUserTmp])
    } else {
      if (selectUserTmp.indexOf(_id) > -1) {
        return true
      } else {
        selectUserTmp.push(_id)
        setSelectUser([...selectUserTmp])
      }
    }
  }

  function selectAllCheckBox(e) {
    let checked = e.target.checked
    setSelectAllUser(checked)
    setuserAddedStatus(checked);
    if (checked == false) {
      let myArr = [];
      setSelectUser([...myArr])
    }
  }

  function checkIfChecked(_id) {
    if (selectAllUser == true) {
      return true
    } else if (selectUser.length) {
      if (selectUser.indexOf(_id) > -1) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  function SelectAllCheckoxDefault() {
    if (employeeIds && employeeIds.length) {
      let myArr = [];
      employeeIds.map((Ids) => {
        if (Ids.employeeId) {
          myArr.push(Ids.employeeId);
        }
      })
      setuserAddedStatus(false);
      setTimeout(function () {
        setSelectUser([...myArr]);
      }, 100);
    }
  }

  function oncloseAssignVoucher() {
    closeEditPage()
  }


  return (
    <Grid container className="location-container">
      <Grid item xs={12}>
        <Grid container className="location-head">
          <Grid item xs={12} sm={12} md={4} lg={4} className="heading">
            <h2>
              <MoneyOffIcon />
              Edit Vouchers
              {
                (selectAllUser == true && userAddedStatus == true) ?
                  SelectAllCheckoxDefault()
                  : null}
            </h2>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <SearchField
                placeholder="Search"
                value={keyword}
                onChange={handleChangeSearch}
              />
            </Box>
          </Grid>
        </Grid>
        <div className="table-design w-100 manage-users-pg">
          <div className="cus-back-head just-spc">
            <h3 onClick={closeEditPage}>
              <ArrowBackIcon />
              <span>Back to "Vouchers"</span>
            </h3>
            {
              (selectUser.length && editVoucher.availableVoucher > 0) ?
                <AssignVoucher
                  toast={toast}
                  thisVoucher={editVoucher}
                  selectUser={selectUser}
                  onClose={oncloseAssignVoucher}
                  actionLabel={
                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                      <AddIcon />
                      Assign Voucher
                    </Box>
                  }
                />
                : null}
          </div>
        </div>

      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} className="">
        <div id="no-more-tables" className="card-design table-design cf">
          {isLoading ? (
            <>
              <TableContainer className={classes.container}>
                <Table
                  className="table-program"
                  stickyHeader
                  aria-label="sticky table"
                >
                  <EnhancedTableHead
                    headCells={manageLocationsHead}
                    selectAllCheckBox={selectAllCheckBox}
                    selectAllUser={selectAllUser}
                  />
                </Table>
              </TableContainer>
              <TableLoader />
            </>
          ) : null}

          {!isLoading ? (
            <TableContainer className={classes.container}>
              <Table
                className="table-program"
                stickyHeader
                aria-label="sticky table"
              >
                <EnhancedTableHead
                  headCells={manageLocationsHead}
                  selectAllCheckBox={selectAllCheckBox}
                  selectAllUser={selectAllUser}
                />
                <TableBody>
                  {employees && employees.length ? (
                    employees.map((employee) => {
                      const {
                        name,
                        phone,
                        email,
                        isActive,
                        employeeId,
                        _id,
                        mealId,
                      } = employee
                      const { mealType } = mealId || {}

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          className="cursor_default"
                          key={_id}
                        >
                          <TableCell className="table-custom-width">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={checkIfChecked(_id)}
                                  color="primary"
                                  onChange={(e) => handleChangeCheckbox(e, _id)}
                                />
                              }
                            />
                          </TableCell>

                          <TableCell className="table-custom-width">
                            {name}
                          </TableCell>
                          <TableCell className="table-custom-width">
                            {employeeId}
                          </TableCell>
                          <TableCell className="table-custom-width">
                            {email}
                          </TableCell>
                          <TableCell className="table-custom-width">
                            {phone}
                          </TableCell>
                          <TableCell className="table-custom-width">
                            <Box display="flex" alignItems="center">
                            {mealType === 'VEG' ? 
                                <><MealTypeDot mealType={mealType} />Veg</> :
                                 mealType == "NON VEG" ? 
                                 <><MealTypeDot mealType={mealType} />Non Veg</> :
                                  mealType == "BOTH" ?  <><MealTypeDot mealType="VEG" />Veg<MealTypeDot mealType="NON VEG" />Non Veg</>: ""}
                            </Box>
                          </TableCell>
                        </TableRow>
                      )
                    })
                  ) : (
                    <TableRow hover role="checkbox" className="cursor_default">
                      <TableCell colSpan={10} className="table-custom-width">
                        <Box textAlign="center" p={1} className="no-data-found">
                          <p>No Data Found</p>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          ) : null}

          <CustomTablePagination
            count={employees.length}
            total={count}
            limit={limit}
            page={page}
            onPageChange={handlePageChange}
          />
        </div>
      </Grid>
    </Grid>
  )
}
export default EditVouchers

