import React from 'react'
import SearchIcon from '@material-ui/icons/Search';

function SearchField(props) {

    const { value = '', onChange, placeholder = 'Search...' } = props;

    return (
        <div className="custom-search-field bb-filter-border ">
            <SearchIcon />
            <input
                type="text"
                placeholder={placeholder}
                value={value}
                onChange={onChange}
            />
        </div>
    )
}

export default SearchField
