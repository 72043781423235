import {
    getInvoiceAPI,
    updateInvoiceAPI,
    invoiceDownloadAPI
} from './apis'

export function getInvoiceList(data) {
    return dispatch => {
        dispatch({
            type: GET_INVOICE_LIST
        })
        return new Promise((resolve, reject) => {
            getInvoiceAPI(data)
                .then(({ data }) => {
                    dispatch({
                        type: GET_INVOICE_LIST_SUCCESS,
                        payload: data
                    })
                    return resolve(data)
                })
                .catch(err => {
                    dispatch({
                        type: GET_INVOICE_LIST_FAILED
                    })
                    return reject(err)
                })
        })
    }
}


export const GET_INVOICE_LIST = "GET_INVOICE_LIST"
export const GET_INVOICE_LIST_SUCCESS = "GET_INVOICE_LIST_SUCCESS"
export const GET_INVOICE_LIST_FAILED = "GET_INVOICE_LIST_FAILED"

export function invoiceDownload(data) {
    return dispatch => {
        dispatch({
            type: GET_INVOICE_DOWNLOAD
        })
        return new Promise((resolve, reject) => {
            invoiceDownloadAPI(data)
                .then(({ data }) => {
                    dispatch({
                        type: GET_INVOICE_DOWNLOAD_SUCCESS,
                        payload: data
                    })
                    return resolve(data)
                })
                .catch(err => {
                    dispatch({
                        type: GET_INVOICE_DOWNLOAD_FAILED
                    })
                    return reject(err)
                })
        })
    }
}

export const GET_INVOICE_DOWNLOAD = "GET_INVOICE_DOWNLOAD"
export const GET_INVOICE_DOWNLOAD_SUCCESS = "GET_INVOICE_DOWNLOAD_SUCCESS"
export const GET_INVOICE_DOWNLOAD_FAILED = "GET_INVOICE_DOWNLOAD_FAILED"


export function updateInvoice(data) {
    return dispatch => {
        dispatch({
            type: UPDATE_INVOICE
        })
        return new Promise((resolve, reject) => {
            updateInvoiceAPI(data)
                .then(({ data }) => {
                    dispatch({
                        type: UPDATE_INVOICE_SUCCESS,
                        payload: data
                    })
                    return resolve(data)
                })
                .catch(err => {
                    dispatch({
                        type: UPDATE_INVOICE_FAILED
                    })
                    return reject(err)
                })
        })
    }
}

export const UPDATE_INVOICE = "UPDATE_INVOICE"
export const UPDATE_INVOICE_SUCCESS = "UPDATE_INVOICE_SUCCESS"
export const UPDATE_INVOICE_FAILED = "UPDATE_INVOICE_FAILED"
