export const GET_ORDERS = 'GET_ORDERS'
export const GET_ORDERS_SUCCEEDED = 'GET_ORDERS_SUCCEEDED'
export const GET_ORDERS_FAILED = 'GET_ORDERS_FAILED'

export const GET_MEAL_TYPES = 'GET_MEAL_TYPES'
export const GET_MEAL_TYPES_SUCCEEDED = 'GET_MEAL_TYPES_SUCCEEDED'
export const GET_MEAL_TYPES_FAILED = 'GET_MEAL_TYPES_FAILED'

export const GET_EMPLOYEE = 'GET_EMPLOYEE'
export const GET_EMPLOYEE_SUCCEEDED = 'GET_EMPLOYEE_SUCCEEDED'
export const GET_EMPLOYEE_FAILED = 'GET_EMPLOYEE_FAILED'




const initState = {
  isLoading: false,
  orders: {
    data: [],
    total: 0
  },
  mealTypes: [],
  employees:{},
}

export default function (state = { ...initState }, action) {
  switch (action.type) {
    case GET_ORDERS:
      return {
        ...state,
        isLoading: true
      }
    case GET_ORDERS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        orders: action.payload
      }
    case GET_ORDERS_FAILED:
      return {
        ...state,
        isLoading: false,
        orders: {}
      }
      
      case GET_EMPLOYEE:
      return {
        ...state,
        isLoading: true
      }
    case GET_EMPLOYEE_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        employees: action.payload
      }
    case GET_EMPLOYEE_FAILED:
      return {
        ...state,
        isLoading: false,
        employees: {}
      }

    case GET_MEAL_TYPES:
      return {
        ...state,
        isLoading: true
      }
    case GET_MEAL_TYPES_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        mealTypes: action.payload
      }
    case GET_MEAL_TYPES_FAILED:
      return {
        ...state,
        isLoading: false,
        mealTypes: []
      }

    default:
      return state
  }
}