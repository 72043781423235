import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
  Grid,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Paper,
  Tab,
  Tabs,
  AppBar,
  Badge,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EnhancedTableHead from "../../components/common/EnhancedTableHead";
import TableLoader from "../../components/common/TableLoader";
import CustomTablePagination from "../../components/common/CustomPagination";
import moment from "moment";
import { updateUserStatus, DeleteUser } from "./actions";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { getObject } from "../../utils";
import IOSSwitch from "../Common/IOSSwitch";
import DeleteUserModal from "./PopupModal/DeleteUserModal";
import EditIcon from '@material-ui/icons/Edit';

const TableHeaders = [
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
  { id: "name", numeric: false, disablePadding: false, label: "Name" },
  { id: "email", numeric: false, disablePadding: false, label: "Email" },
  {
    id: "lastLogin",
    numeric: false,
    disablePadding: false,
    label: "Last Login",
  },
  { id: "contactPerson", numeric: false, disablePadding: false, label: "Contact Person" },
  { id: "actions", numeric: false, disablePadding: false, label: "Actions" },
];



function UserListing(props) {
  const [editable, setEditable] = useState(false);

  function handlePageChange(e, nextPage) {
    e.preventDefault();
    if (nextPage === page) {
      return;
    }

    setPage(nextPage);
    setSkip((nextPage - 1) * limit);
  }

  function capitalize(s) {
    return s[0].toUpperCase() + s.slice(1);
  }

  function onUpdateStatus(e, email, active, _id) {
    e.preventDefault();
    setUpdateLoading(true);
    if (active == true) {
      active = false;
    } else {
      active = true;
    }
    let updatedata = {
      _id: _id, email: email, active: active
    }
    let message = "User status has been updated successfully";
    updateUser(updatedata, message);
  }
  function onUpdateContactPerson(e, email, isCurrentContactPerson, _id) {
    e.preventDefault();
    setUpdateLoading(true);
    if (isCurrentContactPerson == true) {
      isCurrentContactPerson = false;
    } else {
      isCurrentContactPerson = true;
    }
    let updatedata = {
      _id: _id, email: email, isCurrentContactPerson: isCurrentContactPerson
    }
    let message = "Contact person has been updated successfully"
    updateUser(updatedata, message);
  }

  function updateUser(updatedata, message) {
    dispatch(updateUserStatus(updatedata))
      .then(() => {
        setUpdateLoading(false);
        getUsers();
        toast.success(message);
      })
      .catch(({ message = "" }) => {
        toast.error(message || "Oops! Something went wrong");
        setUpdateLoading(false);
      });
  }

  function deleteThisUser(email) {
    setUpdateLoading(true)
    dispatch(DeleteUser(email))
      .then(() => {
        setUpdateLoading(false)
        getUsers()
        toast.success("User has been deleted successfully")
      })
      .catch(({ message = '' }) => {
        toast.error(message || 'Oops! Something went wrong')
        setUpdateLoading(false)
      })
  }


  const {
    isLoading,
    users,
    classes,
    limit,
    page,
    count,
    setPage,
    setSkip,
    setUpdateLoading,
    toast,
    getUsers,
    dispatch,
    callEditUserFormUser,
    ...other
  } = props;

  return (
    <div className="table-design w-100">
      {isLoading ? (
        <>
          <TableContainer className={classes.container}>
            <Table
              className="table-program"
              stickyHeader
              aria-label="sticky table"
            >
              <EnhancedTableHead headCells={TableHeaders} />
            </Table>
          </TableContainer>
          <TableLoader />
        </>
      ) : null}

      {!isLoading ? (
        <TableContainer className={classes.container}>
          <Table
            className="table-program"
            stickyHeader
            aria-label="sticky table"
          >
            <EnhancedTableHead headCells={TableHeaders} />
            <TableBody>
              {users && users.length ? (
                users.map((userList) => {
                  const {
                    active,
                    email,
                    lastLogin,
                    name,
                    _id,
                    companyId,
                    isCurrentContactPerson,
                    isDefaultContactPerson,
                  } = userList;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      className="cursor_default"
                      key={_id}
                    >
                      {/* <TableCell className="table-custom-width">{orderNumber || '-'}</TableCell> */}

                      {/* <TableCell className="table-custom-width">{orderItem || '-'}</TableCell> */}

                      <TableCell className="table-custom-width">
                        {!isDefaultContactPerson ?
                          <IOSSwitch
                            checked={active}
                            onChange={(e) => onUpdateStatus(e, email, active, _id)}
                          />
                          : <IOSSwitch
                            disabled={true}
                            checked={active}
                            onChange={(e) => onUpdateStatus(e, email, active, _id)}
                          />}
                      </TableCell>
                      <TableCell className="table-custom-width">
                        {name ? capitalize(name) : ""}
                      </TableCell>
                      <TableCell className="table-custom-width">
                        <a href="mailto:{email}">{email}</a>
                      </TableCell>
                      <TableCell className="table-custom-width">
                        {(lastLogin) ? moment(lastLogin).format('MMM DD, YY hh:mm A') : '--:--'}
                      </TableCell>
                      <TableCell className="table-custom-width">
                        {!isDefaultContactPerson ?
                          <IOSSwitch
                            checked={isCurrentContactPerson}
                            onChange={(e) => onUpdateContactPerson(e, email, isCurrentContactPerson, _id)}
                          />
                          : <IOSSwitch
                            disabled={true}
                            checked={isCurrentContactPerson}
                            onChange={(e) => onUpdateContactPerson(e, email, isCurrentContactPerson, _id)}
                          />
                        }
                      </TableCell>

                      <TableCell className="table-custom-width">
                        {!isDefaultContactPerson ?
                          <div className="td-action-btns">
                            <ul>
                              <li>
                                <EditIcon onClick={(e) => callEditUserFormUser(email)} />
                              </li>
                              <li>
                                <DeleteUserModal isLoading={isLoading} email={email} deleteThisUser={deleteThisUser} />
                              </li>
                            </ul>
                          </div>
                          : <span>Admin</span>
                        }
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow hover role="checkbox" className="cursor_default">
                  <TableCell colSpan={6} className="table-custom-width">
                    <Box textAlign="center" p={1} className="no-data-found">
                      <p>No Data Found</p>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}

      <CustomTablePagination
        count={users.length}
        total={count}
        limit={limit}
        page={page}
        onPageChange={handlePageChange}
      />
    </div>
  );
}
export default UserListing;
