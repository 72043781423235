import { apiPost, apiGet, apiDelete } from '../../utils/axios'

export function getOrdersListAPI(data) {
  const payload = {}
  payload.skip = data.skip
  payload.limit = data.limit

  if (data.startDate) {
    payload.startDate = data.startDate
  }

  if (data.endDate) {
    payload.endDate = data.endDate
  }

  if (data.keyword) {
    payload.keyword = data.keyword
  }
  
  if(data.employeeId){
      payload.employeeId=data.employeeId
  }

  return apiGet(`/api/company/getOrders`, payload)
}

export function getEmployeeNameListAPI(){
    return apiGet("/api/company/getEmployeeNameList")
}

export function getMealTypesAPI() {
  return apiGet(`/api/company/getMeals`)
}