import {
  GET_VOUCHER_LIST,
  GET_VOUCHER_LIST_SUCCESS,
  GET_VOUCHER_LIST_FAILED,
  GET_EMPLOYEE_LIST_REQUEST,
  GET_EMPLOYEE_LIST_REQUEST_SUCCEEDED,
  GET_EMPLOYEE_LIST_REQUEST_FAILED,
  GET_EMPLOYEE_NAME_LIST_REQUEST,
  GET_EMPLOYEE_NAME_LIST_REQUEST_SUCCEEDED,
  GET_EMPLOYEE_NAME_LIST_REQUEST_FAILED,
  ASSIGN_VOUCHER_REQEUST,
  ASSIGN_VOUCHER_REQEUST_SUCCEEDED,
  ASSIGN_VOUCHER_REQEUST_FAILED
} from './actions'

const initialState = {
  isLoading: false,
  vouchers: {
    data: [],
    count: 0,
    ALL: 0,
    ACTIVE: 0,
    INACTIVE: 0,
    EXPIRED: 0,
    USED: 0,
  },
  employees: {
    data: [],
    count: 0,
  },
  employeeIds:{},
}

export default function (state = { ...initialState }, action) {
  switch (action.type) {
    case GET_VOUCHER_LIST:
      return { ...state, isLoading: true }

    case GET_VOUCHER_LIST_SUCCESS:
      return { ...state, isLoading: false, vouchers: action.payload }

    case GET_VOUCHER_LIST_FAILED:
      return { ...state, isLoading: false, vouchers: {} }
      
    case GET_EMPLOYEE_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_EMPLOYEE_LIST_REQUEST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        employees: action.payload,
      }
    case GET_EMPLOYEE_LIST_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        employees: [],
      }
      case GET_EMPLOYEE_NAME_LIST_REQUEST:
        return {
          ...state,
          isLoading: true
        }
      case GET_EMPLOYEE_NAME_LIST_REQUEST_SUCCEEDED:
        return {
          ...state,
          isLoading: false,
          employeeIds: action.payload
        }
      case GET_EMPLOYEE_NAME_LIST_REQUEST_FAILED:
        return {
          ...state,
          isLoading: false,
          employeeIds: {}
        }

    default:
      return state
  }
}
