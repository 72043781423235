import React, { useEffect, useState } from 'react'
import {
  Grid,
  Box,
  Paper,
  Card,
  CardContent,
  Tab,
  Tabs,
  Button
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { getTimeStamps, numberFormat } from '../../utils'
import { tablestyle } from '../../plugins/theme'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { getOptTime, getTodayMealData } from './actions'
import moment from 'moment'
import MealTypeDot from '../Common/MealTypeDot'
import { Link } from 'react-router-dom'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

import TabPanel from './TabPanel'
import EmojiFoodBeverageIcon from '@material-ui/icons/EmojiFoodBeverage';
import UpdateForm from "./PopupModal/UpdateForm";
import { getObject } from "../../utils";
import { useHistory } from "react-router";
import {
  getCompanyDetailsAction,
} from '../Settings/actions'
import ViewMeal from './ViewMeal'
import VisibilityIcon from '@material-ui/icons/Visibility';
let searchTypeInterval = null

function TotalMealData(props) {
  const { value, label } = props
  return (
    <div className="total-meal-data">
      <span>{label}</span>
      <label>{value}</label>
    </div>
  )
}

function ViewIcon(props) {
  const { value, label } = props
  return (
    <div className="total-meal-data">
      <span>{label}</span>
      <label>{value}</label>
    </div>
  )
}

function getTodayMealDataResults(state) {
  const timeData = state?.todaymeal || {}
  return {
    opttime: timeData.opttime || [],
    todayMealData: timeData.todaymeal?.todayMealData || [],
    mealData: timeData.todaymeal?.mealData || [],
    count: timeData.todaymeal?.count || 0,
    // count:timeData.todaymeal?.todayMealData.length || 0
  };
}

function TodayMeal(props) {
  const { classes, toast, loginUserType, loginUserPermission } = props
  const dispatch = useDispatch();
  const isLoading = useSelector(
    (state) => state.dailysupply.isLoading || false
  );



  const {
    opttime = [],
    todayMealData = [],
    mealData = [],
    count = 0,
    supply = [],
  } = useSelector(getTodayMealDataResults);

  const [limit, setLimit] = useState(8);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(1);
  const [isViewMeal, setIsViewMeal] = useState(false);
  const history = useHistory();
  let params = window.location.search
  let getParams = new URLSearchParams(params)
  let term = getParams.get('meal_id')

  function onView(emp) {
    return () => {
      history.push(`/meal-record/?employee_id=${emp._id}`)
    }
  }
  function getMealData() {
    const data = {
      limit,
      skip,
    };
    dispatch(getTodayMealData(data));
  }

  useEffect(() => {
    dispatch(getCompanyDetailsAction("true"));
    dispatch(getOptTime());
    getMealData();
  }, [])


  useEffect(() => {
    getMealData();
  }, [skip, limit,]);

  function CardField(props) {
    return (
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <Card className="box-shadow">
          <CardContent>
            <Grid container>
              {props.mealType ?
                <Grid item xs={3} sm={3} md={3} lg={3}>
                  <div className="dashboard-card-img">
                    <MealTypeDot mealType={props.mealType} />
                  </div>
                </Grid>
                : null}
              <Grid item xs={9} sm={9} md={9} lg={9}>
                <div className="dashboard-card-connent">{props?.children}</div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    )
  }




  const object = getObject("bb-user");

  const userObject = (object && JSON.parse(object)) || "";
  const { name = "", userType = "" } = (userObject && userObject.userDetails) || "";
  const user_id = (userObject.userDetails._id) || "";

  return (
    <Grid container className="location-container">
      <Grid item xs={12}>
        <Grid container className="location-head">
          <Grid item xs={12} sm={12} md={4} lg={4} className="heading">
            <h2>
              <EmojiFoodBeverageIcon />
              Today’s Meal Count
            </h2>
          </Grid>

        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} className="">
        <Tabs
          aria-label=""
          className="cus-tabs-design"
        >
          {
            todayMealData && todayMealData.length ? todayMealData.map((meal, i) => {
              const {
                mealName = "",
                quantity = 0,
                mealType = "",
              } = meal
              console.log("quantity", quantity)
              return (<Tab
                icon={<TotalMealData value={quantity} key={mealType} label={mealType == "VEG" ? "Veg" : "Non Veg"} mealType={mealType} />}
                className={`${(mealType) ? (mealType == "VEG") ? "tab-approvedmeals" : "tab-declined" : "tab-declined"}`}
              ></Tab>)

            })
              : (
                <>
                  <Tab
                    icon={<TotalMealData value={0} key={1} label="Total Non Veg" mealType="VEG" />}

                    className="tab-approvedmeals"
                  ></Tab>
                  <Tab
                    icon={<TotalMealData value={0} key={2} label="Total Veg" mealType="NON VEG" />}
                    className="tab-declined"
                  ></Tab> </>)
          }

          {!todayMealData?.every((item) => item.quantity == 0) &&
            <Link className="view-list-emp" to={`/today-meal/?meal_id=userDetail`}>
              <Button
                className="tab-approvedmeals"
                onClick={() => {
                  setIsViewMeal(false)
                }
                }>

                Recent Opted List <VisibilityIcon />


              </Button>
            </Link>}

          {((todayMealData && todayMealData.length) && (opttime && opttime.length)) ?

            //  <Tab style={{margin: "0 0 0 auto"}}  icon={ }
            //  className="tab-declined">

            //     </Tab>
            <UpdateForm
              toast={toast}
              actionLabel="Update Additional Meal"
              status="approved"
              userType={userType}
              user_id={user_id}
              userName={name}
              opttime={opttime}
              todayMealData={todayMealData}
              getMealData={getMealData}
            />

            : null}

        </Tabs>
        {todayMealData.every((item) => item.quantity === 0) && <Box py={1} className="modal-textarea" style={{ float: "right" }}>
          <p style={{ fontSize: "12px", fontStyle: "italic" }} href="tel:+91 8571016164">Please call us if there are any issues with the meal{"  "} <a href="tel:+91 8571016164"> {"  "}+91 8571016164</a></p>
        </Box>}
      </Grid>

      {
        term ?
          <ViewMeal
            toast={toast}
            isLoading={isLoading}
            classes={classes}
            limit={limit}
            page={page}
            count={count}
            setPage={setPage}
            setSkip={setSkip}
            numberFormat={numberFormat}
            skip={skip}
          /> :
          <TabPanel
            toast={toast}
            isLoading={isLoading}
            classes={classes}
            limit={limit}
            page={page}
            count={count}
            setPage={setPage}
            setSkip={setSkip}
            numberFormat={numberFormat}
            mealData={mealData}
            MealTypeDot={MealTypeDot}
            isViewMeal={isViewMeal}
            onView={onView}
            setIsViewMeal={setIsViewMeal}
            skip={skip}
          ></TabPanel>
      }
    </Grid >
  )

}

export default withTranslation('translations')(
  withStyles(tablestyle)(TodayMeal),
)
