import Joi from "joi";

export function validateWalletDatal(data) {
  let isValid = true
  let newErrors = {}

  const schema = Joi.object({
    walletCode: Joi
    .string()
   .required()
    .messages({
      "string.empty": `Wallet Code is required!`,
      "string.base": `Wallet Code is required!`,
      "string.required": `Wallet Code is required!`,
    }),

  }).options({allowUnknown: true}).validate(data, { abortEarly: false })

  const { error } = schema

  if (error) {
    isValid = false
    error.details.forEach(item => newErrors[item.context.key] = item.message)
  }
  
  return { isValid, errors: newErrors }
}

Joi.string().trim().required();