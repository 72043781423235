import React from 'react'
import ScrollRestoration from '../components/common/ScrollRestoration'
import { isLoggedIn } from '../utils'
import { Route, Redirect } from 'react-router-dom'
import toast from '../plugins/toast'
import AppWrapper from '../containers/Common/AppWrapper'
import { getObject } from '../utils'
import { useSelector } from 'react-redux'

const PrivateRoute = ({
  component: Component,
  myEmail: myEmail,
  myLogo: myLogo,
  myName: myName,
  loginUserType: loginUserType,
  loginUserPermission: loginUserPermission,
  isTuckShopEnabled : isTuckShopEnabled,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return !isLoggedIn('bb-user-token') ? (
          <Redirect to="/login" />
        ) : (
          <AppWrapper
            toast={toast}
            {...props}
            isTuckShopEnabled={isTuckShopEnabled}
            loginUserType={loginUserType}
            loginUserPermission={loginUserPermission}
            myLogo={myLogo}
            myName={myName}
            myEmail={myEmail}
          >
            <ScrollRestoration {...props}>
              <Component
                {...props}
                {...rest}
                toast={toast}
                isTuckShopEnabled={isTuckShopEnabled}
                loginUserType={loginUserType}
                loginUserPermission={loginUserPermission}
              />
            </ScrollRestoration>
          </AppWrapper>
        )
      }}
    />
  )
}

export default PrivateRoute
