import React, { useState, useEffect } from 'react'
import {
  Grid,
  Box,
  CircularProgress,
  Tab,
  Tabs,
  Checkbox,
  FormControlLabel,
  InputLabel,
  RadioGroup,
  Radio,
  FormControl,
} from '@material-ui/core'
// import SettingsIcon from '@material-ui/icons/Settings'
import InputField from '../../../components/common/InputField'
import { Button } from '@material-ui/core'
import {
  updatePassword,
  getCompanyDetailsAction,
  updateCompanyDetail,
} from '../actions'
import { useDispatch, useSelector } from 'react-redux'
import { validateCompanyDetails } from '../validations'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
// import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { getMealTypes } from '../../Meals/actions'

const initObj = {
  name:"",
  email:"",
  phoneNumber:"",
  address: '',
  gstNumber: '',
  contactPersonName: '',
  contactPersonId: '',
  file: '',
  workingDays: {
    week1_3: {
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
      Sunday: false,
    },
    week2_4: {
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
      Sunday: false,
    },
  },
  shift: '',
  mealType:"",
}

function getCompanyData(state) {
  const companydata = state?.settings?.companydetails || {}
  //return companydata;

  return {
    _id: companydata._id || '',
    username: companydata.username || '',
    email: companydata.email || '',
    logo: companydata.logo || '',
    address: companydata.address || '',
    name: companydata.name || '',
    contactPersonName: companydata.contactPersonName || '',
    contactPersonId: companydata.contactPersonId || '',
    phoneNumber:companydata.phoneNumber || "",
    gstNumber: companydata.gstNumber || '',
    workingDays: companydata?.workingDays || [],
    shift: companydata.shift || '',
    mealType:companydata.mealType || ""
  }
}

export default function CompanyDetails(props) {
  const isLoading = useSelector((state) => state.settings.isLoading || false)

  useEffect(() => {
    getCompanyDetails('true');
    getMealTypesList();
  }, [])

  const {
    _id,
    username,
    email,
    logo,
    address,
    name,
    gstNumber,
    contactPersonName,
    contactPersonId,
    workingDays,
    phoneNumber,
    shift,
    mealType,
  } = useSelector(getCompanyData)
  const mealData = useSelector(state => state?.meals || [])
  const { mealTypes } = mealData

  const [data, setData] = useState({ ...initObj })
  // const [comAddress, setcomAddress] = useState('')
  // const [comGst, setcomGst] = useState('')
  // const [comName, setcomName] = useState('')
  const [image, setImage] = useState('')
  const [initWeekData, setinitWeekData] = useState('')

  const [dataValueChanged, setdataValueChanged] = useState('')

  useEffect(() => {
    setdataValueChanged('')
    setData({ ...initObj })
  }, [address, gstNumber, contactPersonName])


  function getMealTypesList() {
    dispatch(getMealTypes())
  }

  if (!image && logo) {
    setImage(logo)
  }

  if (!data.address && address && !dataValueChanged) {
    setData({ ...data, address: address })
  }
  if (!data.gstNumber && gstNumber && !dataValueChanged) {
    setData({ ...data, gstNumber: gstNumber })
  }
  if (!data.contactPersonName && contactPersonName && !dataValueChanged) {
    setData({ ...data, contactPersonName: contactPersonName })
  }
  if (!data.contactPersonId && contactPersonId && !dataValueChanged) {
    setData({ ...data, contactPersonId: contactPersonId })
  }
  if (!data.name && name && !dataValueChanged) {
    setData({ ...data, name: name })
  }
  if (!data.email && email && !dataValueChanged) {
    setData({ ...data, email: email })
  }
  if (!data.phoneNumber && phoneNumber && !dataValueChanged) {
    setData({ ...data, phoneNumber: phoneNumber })
  }

  if (!data.shift && shift && !dataValueChanged) {
    setData({ ...data, shift: shift })
  }

  if (!data.mealType && mealType && !dataValueChanged) {
    setData({ ...data, mealType: mealType })
  }

  if (workingDays.week1_3 && workingDays.week2_4 && !initWeekData) {
    setTimeout(() => {
      setData({ ...data, workingDays: workingDays })
    }, 100)
    setinitWeekData('changed')
  }
  const { toast, dispatch, value, index, ...other } = props
  function getCompanyDetails(save = '') {
    dispatch(getCompanyDetailsAction(save))
  }

  const [errors, setErrors] = useState({})


  function handleChange(key, value) {
    setdataValueChanged('changed')
    setData({
      ...data,
      [key]: value,
    })
  }

  function submit() {

    if (validate()) {
      dispatch(updateCompanyDetail(data))
        .then(() => {
          getCompanyDetails('true')
          toast.success('Company Details updated successfully')
        })
        .catch((err) => {
          toast.error(err.message || 'Oops! Something went wrong')
        })
    }
  }

  function validate() {
    const { errors: newErrors, isValid } = validateCompanyDetails(data)
    setErrors(newErrors)
    return isValid
  }

  function readUrl(input) {
    var name = input.target.files[0].name
    var Extension = name.substring(name.lastIndexOf('.') + 1).toLowerCase()
    var valid_extension = ['gif', 'png', 'bmp', 'jpeg', 'jpg']
    if (
      input.target.files &&
      input.target.files[0] &&
      valid_extension.includes(Extension)
    ) {
      let img = input.target.files[0]

      const fileSize = img.size / 1024 / 1024 // in MiB
      if (fileSize < 1.5) {
        let Base64
        let fileReader = new FileReader()

        fileReader.readAsDataURL(img)
        fileReader.onload = (e) => {
          Base64 = e.target.result
          setData({ ...data, file: Base64 })
          setImage(Base64)
        }
      } else {
        toast.error('Image size should be less then 1.5 MB')
      }
    } else {
      input.target.value = ''
      toast.error('Please Upload a valid image')
    }
  }

  function handleChangeCheckbox(e, week, key = '') {
    setdataValueChanged('changed')
    let checked = e.target.checked
    if (week == 'week1_3') {
      setData({
        ...data,
        workingDays: {
          ...data.workingDays,
          week1_3: { ...data.workingDays.week1_3, [key]: checked },
        },
      })
    } else if (week == 'week2_4') {
      setData({
        ...data,
        workingDays: {
          ...data.workingDays,
          week2_4: { ...data.workingDays.week2_4, [key]: checked },
        },
      })
    }
  }

  const valid =
    data.address || data.gstNumber || data.contactPersonName || false

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      <div id="no-more-tables" className="card-design table-design cf">
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <Box p={2} className="tab-con-form">
              <h5>Company Details</h5>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6}>
                  <Box>
                    <InputField
                      className="name-input"
                      name="name"
                      fullWidth
                      variant="outlined"
                      value={data.name}
                      label="Name"
                      placeholder="Name"
                      onChange={({ target: { value } }) =>
                        handleChange('name', value)
                      }
                      error={errors.name}
                    />
                  </Box>
                </Grid>
  
                <Grid item xs={12} sm={6} md={6}>
                  <Box>
                    <InputField
                      className="gst-input"
                      name="gst"
                      fullWidth
                      variant="outlined"
                      value={data.gstNumber}
                      label="GST"
                      placeholder="GST"
                      onChange={({ target: { value } }) =>
                        handleChange('gstNumber', value)
                      }
                      error={errors.gstNumber}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Box>
                    <InputField
                      className="phone-input"
                      name="phoneNumber"
                      fullWidth
                      variant="outlined"
                      value={data.phoneNumber}
                      onChange={({ target: { value } }) =>
                      handleChange('phoneNumber', value)
                    }
                    error={errors.phoneNumber}
                      label="Phone Number"
                      placeholder="Phone Number"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Box>
                    <InputField
                      className="email-input"
                      name="email"
                      fullWidth
                      variant="outlined"
                      value={data.email}
                      disabled={true}
                    error={errors.email}
                      label="Email"
                      placeholder="Email"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Box>
                    <InputField
                      className="contact-input"
                      name="contact"
                      fullWidth
                      variant="outlined"
                      value={data.contactPersonName}
                      label="Contact Person Name"
                      placeholder="Contact Person Name"
                      onChange={({ target: { value } }) =>
                        handleChange('contactPersonName', value)
                      }
                      error={errors.contactPersonName}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Box>
                    <InputField
                      className="address-input"
                      name="address"
                      fullWidth
                      variant="outlined"
                      value={data.address}
                      label="Address"
                      placeholder="Address"
                      onChange={({ target: { value } }) =>
                        handleChange('address', value)
                      }
                      error={errors.address}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6 }>
                  <div className="cus-days-inn">
                    <h5 className=''>Working Days</h5>

                    <Box>
                      {/*<h6>Week 1st, 3rd and 5th</h6> */}
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <h6>Week 1st, 3rd and 5th</h6>
                        </AccordionSummary>
                        <AccordionDetails>
                          <ul>
                            <li>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={data.workingDays.week1_3.Monday}
                                    color="primary"
                                    onChange={(e) =>
                                      handleChangeCheckbox(
                                        e,
                                        'week1_3',
                                        'Monday',
                                      )
                                    }
                                  />
                                }
                                label="Mon"
                              />
                            </li>
                            <li>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={data.workingDays.week1_3.Tuesday}
                                    color="primary"
                                    onChange={(e) =>
                                      handleChangeCheckbox(
                                        e,
                                        'week1_3',
                                        'Tuesday',
                                      )
                                    }
                                  />
                                }
                                label="Tue"
                              />
                            </li>
                            <li>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={data.workingDays.week1_3.Wednesday}
                                    color="primary"
                                    onChange={(e) =>
                                      handleChangeCheckbox(
                                        e,
                                        'week1_3',
                                        'Wednesday',
                                      )
                                    }
                                  />
                                }
                                label="Wed"
                              />
                            </li>
                            <li>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={data.workingDays.week1_3.Thursday}
                                    color="primary"
                                    onChange={(e) =>
                                      handleChangeCheckbox(
                                        e,
                                        'week1_3',
                                        'Thursday',
                                      )
                                    }
                                  />
                                }
                                label="Thu"
                              />
                            </li>
                            <li>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={data.workingDays.week1_3.Friday}
                                    color="primary"
                                    onChange={(e) =>
                                      handleChangeCheckbox(
                                        e,
                                        'week1_3',
                                        'Friday',
                                      )
                                    }
                                  />
                                }
                                label="Fri"
                              />
                            </li>
                            <li>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={data.workingDays.week1_3.Saturday}
                                    color="primary"
                                    onChange={(e) =>
                                      handleChangeCheckbox(
                                        e,
                                        'week1_3',
                                        'Saturday',
                                      )
                                    }
                                  />
                                }
                                label="Sat"
                              />
                            </li>
                            <li>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={data.workingDays.week1_3.Sunday}
                                    color="primary"
                                    onChange={(e) =>
                                      handleChangeCheckbox(
                                        e,
                                        'week1_3',
                                        'Sunday',
                                      )
                                    }
                                  />
                                }
                                label="Sun"
                              />
                            </li>

                          </ul>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                   <br></br>
                    <Box>
                      {/*   <h6>Week 2nd and 4th</h6> */}
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <h6>Week 2nd and 4th</h6>
                        </AccordionSummary>
                        <AccordionDetails>
                          <ul>
                            <li>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={data.workingDays.week2_4.Monday}
                                    color="primary"
                                    onChange={(e) =>
                                      handleChangeCheckbox(
                                        e,
                                        'week2_4',
                                        'Monday',
                                      )
                                    }
                                  />
                                }
                                label="Mon"
                              />
                            </li>
                            <li>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={data.workingDays.week2_4.Tuesday}
                                    color="primary"
                                    onChange={(e) =>
                                      handleChangeCheckbox(
                                        e,
                                        'week2_4',
                                        'Tuesday',
                                      )
                                    }
                                  />
                                }
                                label="Tue"
                              />
                            </li>
                            <li>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={data.workingDays.week2_4.Wednesday}
                                    color="primary"
                                    onChange={(e) =>
                                      handleChangeCheckbox(
                                        e,
                                        'week2_4',
                                        'Wednesday',
                                      )
                                    }
                                  />
                                }
                                label="Wed"
                              />
                            </li>
                            <li>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={data.workingDays.week2_4.Thursday}
                                    color="primary"
                                    onChange={(e) =>
                                      handleChangeCheckbox(
                                        e,
                                        'week2_4',
                                        'Thursday',
                                      )
                                    }
                                  />
                                }
                                label="Thu"
                              />
                            </li>
                            <li>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={data.workingDays.week2_4.Friday}
                                    color="primary"
                                    onChange={(e) =>
                                      handleChangeCheckbox(
                                        e,
                                        'week2_4',
                                        'Friday',
                                      )
                                    }
                                  />
                                }
                                label="Fri"
                              />
                            </li>
                            <li>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={data.workingDays.week2_4.Saturday}
                                    color="primary"
                                    onChange={(e) =>
                                      handleChangeCheckbox(
                                        e,
                                        'week2_4',
                                        'Saturday',
                                      )
                                    }
                                  />
                                }
                                label="Sat"
                              />
                            </li>
                            <li>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={data.workingDays.week2_4.Sunday}
                                    color="primary"
                                    onChange={(e) =>
                                      handleChangeCheckbox(
                                        e,
                                        'week2_4',
                                        'Sunday',
                                      )
                                    }
                                  />
                                }
                                label="Sun"
                              />
                            </li>
  
                          </ul>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  </div>
                  
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="cus-days-inn">
                    <Box>
                      <h5 className='p-0'>Shift</h5>
                      <RadioGroup
                        aria-label="gender"
                        name="gender1"
                        value={data.shift}
                        onChange={({ target: { value } }) =>
                          handleChange('shift', value)
                        }
                      >
                        <ul>
                          <li>
                            <FormControlLabel
                              value="DAY"
                              control={<Radio />}
                              label="Day"
                            />
                          </li>
                          <li>
                            <FormControlLabel
                              value="NIGHT"
                              control={<Radio />}
                              label="Night"
                            />
                          </li>
                          <li>
                            <FormControlLabel
                              value="BOTH"
                              control={<Radio />}
                              label="Both"
                            />
                          </li>
                        </ul>
                      </RadioGroup>
                    </Box>
                  </div>
                  <div className="cus-days-inn">
                    <Box>
                    <h5 className='p-0'>Meal Type</h5>
                      <RadioGroup
                        aria-label="gender"
                        name="gender1"
                        value={data.mealType}
                        onChange={({ target: { value } }) =>
                          handleChange('mealType', value)
                        }
                      >
                        <ul>
                        {
                        mealTypes && mealTypes.length ?
                        mealTypes.map((meal)=>{
                       return (
                          <li>
                            <FormControlLabel
                              value={meal.mealName}
                              control={<Radio />}
                              label={meal.mealName}
                            />
                          </li>
                          )
                        })
                        : null
                        
                        }
                          {/* <li>
                            <FormControlLabel
                              value="NON VEG"
                              control={<Radio />}
                              label="Non Veg"
                            />
                          </li>
                          <li>
                            <FormControlLabel
                              value="BOTH"
                              control={<Radio />}
                              label="Both"
                            />
                          </li> */}
                        </ul>
                      </RadioGroup>
                    </Box>
                  </div>
                </Grid>

         
 
                <Grid item xs={12} sm={6} md={6} className='mt-3'>
                  <div className="upload-logo-sec d-flex ">
                 
                    <div className="upload-logo-btn">
               
                      <Button variant="contained" component="label">
                      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
<path d="M29.6877 13.8051C29.1474 11.6455 27.9012 9.72838 26.1469 8.35805C24.3925 6.98771 22.2307 6.24275 20.0046 6.24146C17.7785 6.24016 15.6158 6.98261 13.8598 8.35091C12.1039 9.71921 10.8555 11.6349 10.3127 13.7938C7.75248 14.0341 5.38323 15.2514 3.69706 17.1929C2.01089 19.1343 1.13743 21.6507 1.2581 24.2193C1.37876 26.788 2.48429 29.2114 4.34504 30.9862C6.20579 32.7611 8.67873 33.7509 11.2502 33.7501H15.0002C15.3317 33.7501 15.6497 33.6184 15.8841 33.384C16.1185 33.1495 16.2502 32.8316 16.2502 32.5001C16.2502 32.1686 16.1185 31.8506 15.8841 31.6162C15.6497 31.3818 15.3317 31.2501 15.0002 31.2501H11.2502C10.2653 31.253 9.28945 31.0618 8.3784 30.6875C7.46736 30.3133 6.63896 29.7633 5.94049 29.0688C4.52986 27.6664 3.73412 25.7611 3.72832 23.772C3.72252 21.7828 4.50713 19.8729 5.90955 18.4622C7.31197 17.0516 9.21732 16.2559 11.2064 16.2501C11.5272 16.2742 11.8455 16.1784 12.0995 15.9811C12.3536 15.7839 12.5253 15.4993 12.5814 15.1826C12.836 13.3977 13.7259 11.7645 15.0878 10.583C16.4498 9.40151 18.1922 8.75104 19.9952 8.75104C21.7982 8.75104 23.5406 9.40151 24.9025 10.583C26.2645 11.7645 27.1544 13.3977 27.4089 15.1826C27.4743 15.4883 27.6438 15.7618 27.8884 15.9565C28.133 16.1512 28.4376 16.255 28.7502 16.2501C30.7393 16.2501 32.647 17.0403 34.0535 18.4468C35.46 19.8533 36.2502 21.761 36.2502 23.7501C36.2502 25.7392 35.46 27.6469 34.0535 29.0534C32.647 30.4599 30.7393 31.2501 28.7502 31.2501H25.0002C24.6687 31.2501 24.3507 31.3818 24.1163 31.6162C23.8819 31.8506 23.7502 32.1686 23.7502 32.5001C23.7502 32.8316 23.8819 33.1495 24.1163 33.384C24.3507 33.6184 24.6687 33.7501 25.0002 33.7501H28.7502C31.3028 33.7234 33.7486 32.7214 35.5864 30.9496C37.4241 29.1777 38.5147 26.7701 38.6345 24.2202C38.7544 21.6702 37.8945 19.1709 36.231 17.2345C34.5676 15.2981 32.2266 14.0711 29.6877 13.8051Z" fill="#5A5A5A"/>
<path d="M25.3665 24.6337C25.6023 24.8614 25.918 24.9874 26.2458 24.9846C26.5735 24.9817 26.887 24.8503 27.1188 24.6185C27.3505 24.3867 27.482 24.0732 27.4849 23.7455C27.4877 23.4177 27.3617 23.102 27.134 22.8662L20.884 16.6162C20.6496 16.3819 20.3317 16.2502 20.0003 16.2502C19.6688 16.2502 19.3509 16.3819 19.1165 16.6162L12.8665 22.8662C12.6388 23.102 12.5128 23.4177 12.5157 23.7455C12.5185 24.0732 12.65 24.3867 12.8817 24.6185C13.1135 24.8503 13.427 24.9817 13.7548 24.9846C14.0825 24.9874 14.3983 24.8614 14.634 24.6337L18.7503 20.5175V36.25C18.7503 36.5815 18.882 36.8994 19.1164 37.1339C19.3508 37.3683 19.6687 37.5 20.0003 37.5C20.3318 37.5 20.6497 37.3683 20.8841 37.1339C21.1186 36.8994 21.2503 36.5815 21.2503 36.25V20.5175L25.3665 24.6337Z" fill="#5A5A5A"/>
</svg>     Upload Logo
                        <input
                          type="file"
                          hidden
                          onChange={(e) => readUrl(e)}
                        />
                      </Button>
                    </div>
                    <div className="uploaded-img">
                      <img src={image ? image : ''} />
                    </div>
                  </div>
                </Grid>


                <Grid item xs={12} sm={12} md={12}>
                  <Box className="set-tabs-btn">
                    <Button
                      variant="contained"
                      className={`btn-custom-primry button-btn ${
                        valid ? '' : 'disabled'
                      }`}
                      disabled={isLoading}
                      onClick={submit}
                    >
                      {isLoading ? (<>Loading
                        <CircularProgress size="24px" /></>
                      ) : (
                        'Update'
                      )}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
