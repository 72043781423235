import { apiPost, apiGet, apiDelete } from '../../utils/axios'

export function getOptTimeAPI() {
  return apiGet(`/api/company/getOptTime`)
}

export function getTodayMealDataAPI(payload) {

  return apiGet(`/api/company/todayMealData`, payload)
}

export function getSingleMealDataAPI(payload) {
  return apiGet(`/api/company/todayMealDetails`, payload)
}
export function approveMealDataAPI(data) {
  if (data?.mealData) {
    data.mealData.map((meal, i) => {
      delete data.mealData[i]['status'];
    })
  }
  if (data.vegAddOn != 0) {
    data.isAddOn = true;
    if (data?.mealData) {
      data.mealData.map((meal, i) => {
        if (meal.mealType == "VEG") {
          data.mealData[i].quantity = parseInt(parseInt(data.mealData[i].quantity) + parseInt(data.vegAddOn));
        }
      })
    }
  }
  if (data.nonVegAddOn != 0) {
    data.isAddOn = true;
    if (data?.mealData) {
      data.mealData.map((meal, i) => {
        if (meal.mealType == "NON VEG") {
          data.mealData[i].quantity = parseInt(parseInt(data.mealData[i].quantity) + parseInt(data.vegAddOn));
        }
      })
    }
  }
  return apiPost(`/api/company/todayMealData/approve`, data)
}

export function updateDailySupplyAPI(data) {
  const payload = {}
  if (data.user_id) {
    payload.user_id = data.user_id;
  }
  if (data.orderId) {
    payload.orderId = data.orderId;
  }
  if (data.status) {
    payload.status = data.status;
  }
  payload.comments = data.comments;
  return apiPost('/api/company/updateDailySupply', payload)
}