import Joi from "joi";

export function validateVouchers(data,availableVoucher,availableVoucherValue) {
  let isValid = true
  let newErrors = {}

  const schema = Joi.object({
    numberOfVoucher: Joi
        .number()
      .required()
      .min(0)
      .max(availableVoucher)
      .messages({
        "number.empty": `Number of Voucher is required!`,
        "number.max": `Max no of voucher cannot be more than {#limit} voucher`
      }),
      valueOfEach: Joi
      .number()
      .required()
      .messages({
        "number.empty": `Value Of Voucher is required!`,
        "number.basic":"Value of each must be number"
      }),
    // valueOfAll: Joi
    //   .number()
    //   .required()
    //   .min(0)
    //   .max(availableVoucherValue)
    //   .messages({
    //     "number.max": `Total value should not be longer than {#limit} `,
    //     "number.basic":"Value of all must be number"
    //   }),
  }).validate(data, { abortEarly: false })

  const { error } = schema

  if (error) {
    isValid = false
    error.details.forEach(item => newErrors[item.context.key] = item.message)
  }

  return { isValid, errors: newErrors }
}

Joi.string().trim().required();