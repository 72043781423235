import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  Dialog,
  CircularProgress,
  Box,
  IconButton,
  Typography,

} from '@material-ui/core'
import moment from 'moment'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import CloseIcon from '@material-ui/icons/Close'
import InputField from '../../../components/common/InputField'
import { addWalletData } from '../actions'
import { useDispatch, useSelector } from 'react-redux'
import { validateWalletDatal } from '../validations'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const initAdditionalWallet = {
walletCode:"",
companyId:"",
}


function getCompanyData(state) {
  const companydata = state?.settings?.companydetails || {}
  //return companydata;
  return {
    companyId: companydata._id || '',
  }
}




export default function UpdateForm(props) {
  const dispatch = useDispatch()
  const { actionLabel } = props
  const [open, setOpen] = useState(false)
  const [addButtonDisable, setAddButtonDisable] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [walletData, setWalletData] = useState({
    ...initAdditionalWallet,
  })
  const [errors, setErrors] = useState({})

  const {
    companyId,
  } = useSelector(getCompanyData)


  if (!walletData.companyId && companyId) {
    setWalletData({ ...walletData, companyId: companyId })
  }

  function handleClickOpen() {
    setOpen(true)
    //    setErrors({});
  }

  function handleClose() {
    setOpen(false)
    //    setErrors({})
  }

  function onSubmit(e) {
    e.preventDefault()
    setIsLoading(true)
    updateOrder()
  }
  function updateOrder() {
    const { toast } = props;
    const formIsValid = validate()

    if (validate()) {
      setIsLoading(false);
          toast.success("Wallet Amount Added Successfully");
          handleClose();
    // dispatch(addWalletData(walletData))
    //   .then(async () => {
    //     setIsLoading(false);
    //     toast.success("Wallet Amount Added Successfully");
    //     handleClose();
    //   })
    //   .catch(({ message = "" }) => {
    //     toast.error(message || "Oops! Something went wrong");
    //     setIsLoading(false);
    //     handleClose();
    //   });
    }else{
      setIsLoading(false)
    }
  }
  //

  function handleChange(key, value) {
    setWalletData({ ...walletData, [key]: value })
  }

  function validate() {
    const { errors: newErrors, isValid } = validateWalletDatal(
      walletData,
    )
    setErrors(newErrors)
    let checkValid = true
    let validErrors = {}

    return isValid
  }

  const valid =
    (walletData.walletCode) || false




  return (
    <>
      <div className={`btns-group-sec sec-`}>
        <Button
          className="button-btn btn-custom-primry"
          onClick={handleClickOpen}
        >
          {actionLabel}
        </Button>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          maxWidth="sm"
          fullWidth
          disableBackdropClick={true}
          className="modal-contents-main"
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {actionLabel}
          </DialogTitle>

          <DialogContent dividers>
            <Box py={1} className="modal-textarea">
              <p>Add Wallet Code</p>
            </Box>
            <Box py={1} className="modal-textarea">
              <InputField
                className="password-input"
                name="walletCode"
                fullWidth
                variant="outlined"
                value={walletData.walletCode}
                label="Wallet Code"
                placeholder="Wallet Code"
                onChange={({ target: { value } }) =>
                  handleChange('walletCode', value)
                }
                error={errors.walletCode}
              />
             
            </Box>

          </DialogContent>
          <DialogActions>
            <div className="foot-modal-btns">
              <Button
                onClick={handleClose}
                color="secondary"
                variant="contained"
              >
                Close
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={onSubmit}
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress size="20px" color="white" />
                ) : (
                  <>Add</>
                )}
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    </>
  )
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)
