import React, { useEffect, useState } from 'react'
import { Grid, Box, AppBar, CircularProgress, Button } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import CustomTablePagination from '../../components/common/CustomPagination'
import { withTranslation } from 'react-i18next'
import { getTimeStamps } from '../../utils'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { getUsersList } from './actions'
import moment from 'moment'
import MealTypeDot from '../Common/MealTypeDot'
import { dateFilter } from '../../constant'
import { DateRangePicker } from 'react-dates'
import EventIcon from '@material-ui/icons/Event'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import SearchField from '../../components/common/SearchField'
import LightTooltip from '../Common/LightTooltip'
import UserListing from './UserListing'
import { tablestyle } from '../../plugins/theme'
import AddUserForm from './AddUser/AddUserForm'
import EditUserForm from './AddUser/EditUserForm'
import GroupAddIcon from '@material-ui/icons/GroupAdd';
const TableHeaders = [
  { id: 'date', numeric: false, disablePadding: false, label: 'Date' },
  { id: 'orderby', numeric: false, disablePadding: false, label: 'Order By' },
  { id: 'mealdata', numeric: false, disablePadding: false, label: 'Meal Data' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  {
    id: 'updatedby',
    numeric: false,
    disablePadding: false,
    label: 'Updated By',
  },
  { id: 'actions', numeric: false, disablePadding: false, label: 'Actions' },
]

let searchTypeInterval = null

function TotalMealData(props) {
  const { value, label } = props
  return (
    <div className="total-meal-data">
      <span>{label}</span>
      <label>{value}</label>
    </div>
  )
}

function TopHeadIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31.593"
      height="20.592"
      viewBox="0 0 31.593 20.592"
    >
      <g
        id="Group_1706"
        data-name="Group 1706"
        transform="translate(-150.7 -557.459)"
      >
        <path
          id="Path_5534"
          data-name="Path 5534"
          d="M22.8,62.355a7.848,7.848,0,1,0-8.685,0,12.585,12.585,0,0,0-5.932,4.558,1.046,1.046,0,0,0,.856,1.649H27.879a1.046,1.046,0,0,0,.856-1.649A12.585,12.585,0,0,0,22.8,62.355Z"
          transform="translate(142.704 509.49)"
          fill="#ffc545"
        />
        <path
          id="Path_5535"
          data-name="Path 5535"
          d="M160.578,66.932a12.585,12.585,0,0,0-5.931-4.557,7.848,7.848,0,0,0-6.471-14.089,1.046,1.046,0,0,0-.556,1.632,9.919,9.919,0,0,1,.5,11.1,1.046,1.046,0,0,0,.267,1.387,14.815,14.815,0,0,1,1.144.944c.019.021.038.041.059.06a14.655,14.655,0,0,1,3.333,4.566,1.046,1.046,0,0,0,.948.6h5.85a1.046,1.046,0,0,0,.855-1.649Z"
          transform="translate(21.524 509.47)"
          fill="#ffc545"
        />
      </g>
    </svg>
  )
}

function getUserData(state) {
  const users = state?.manageusers?.users || {}

  let count = users.count
  return {
    users: users.users,
    count: count,
  }
}

function ManageUsers(props) {
  const [editEmail, seteditEmail] = useState('')
  const [updateLoading, setUpdateLoading] = useState(false)
  const [userListing, setuserListing] = useState('')
  const { classes, toast, loginUserType, loginUserPermission } = props
  let showManageUser = true
  if (loginUserType == 'user') {
    showManageUser = false
  }

  const dispatch = useDispatch()
  const isLoading = useSelector((state) => state.manageusers.isLoading || false)
  //  const { orders = [], count, amount } = useSelector(getOrdersData)
  const { users = {}, count } = useSelector(getUserData)

  const [limit, setLimit] = useState(8)
  const [skip, setSkip] = useState(0)
  const [page, setPage] = useState(1)
  const [filter, setFilter] = useState({
    value: 'today',
    label: 'Today',
  })
  const [startDate, setStartDate] = useState(0)
  const [endDate, setEndDate] = useState(0)
  const [focusedInput, setFocusedInput] = useState(false)
  const [keyword, setKeyword] = useState('')

  useEffect(() => {
    if (!startDate || !endDate) return

    getUsers()
  }, [skip, limit, startDate, endDate])

  useEffect(() => {
    if (filter?.value) {
      const { startDate: newStartDate, endDate: newEndDate } = getTimeStamps(
        filter.value,
        startDate,
        endDate,
      )
      if (filter.value !== 'customDate') {
        setEndDate(moment(newEndDate))
        setStartDate(moment(newStartDate))
      }
    }
  }, [filter])

  function getUsers(search = '', isSearching = false) {
    const data = {
      limit,
      skip,
      keyword: isSearching ? search : keyword,
    }
    if (showManageUser == true) {
      dispatch(getUsersList(data))
    }
  }

  function handleChangeSearch({ target: { value = '' } }) {
    setKeyword(value)

    if (searchTypeInterval) {
      clearTimeout(searchTypeInterval)
      searchTypeInterval = null
    }
    searchTypeInterval = setTimeout(() => {
      setPage(1)
      setLimit(8)
      if (skip == 0) {
        getUsers(value, true)
      } else {
        setSkip(0)
      }
    }, 500)
  }

  function handleDateChange({ startDate, endDate }) {
    if (startDate) {
      setStartDate(startDate)
    }
    if (endDate) {
      setEndDate(endDate)
    }
  }

  function callAddUserForm() {
    setuserListing('adduser')
  }
  function callEditUserFormUser(email) {
    setuserListing('edituser')
    seteditEmail(email)
  }
  if (showManageUser == true) {
    return (
      <Grid container className="location-container">
        {updateLoading ? (
          <div className="main-advanced-loader">
            <CircularProgress size={45} />
          </div>
        ) : null}
        {!userListing ? (
          <>
            <Grid item xs={12}>
              <Grid container className="location-head">
                <Grid item xs={12} sm={12} md={4} lg={4} className="heading">
                  <h2>
                    <TopHeadIcon />
                    Manage Users
                  </h2>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Box className='mr-2'>
                      <SearchField
                        placeholder="Search"
                        value={keyword}
                        onChange={handleChangeSearch}
                      />
                    </Box>
                    <Button
                      className="btn-custom-primry button-btn-csv tras-bin-btn add-employee"
                      onClick={callAddUserForm}
                      data-tooltip="Add User"
                    >
                      <GroupAddIcon />
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <UserListing
              getUsers={getUsers}
              users={users}
              toast={toast}
              isLoading={isLoading}
              classes={classes}
              limit={limit}
              page={page}
              count={count}
              setPage={setPage}
              setSkip={setSkip}
              setUpdateLoading={setUpdateLoading}
              dispatch={dispatch}
              callEditUserFormUser={callEditUserFormUser}
            ></UserListing>
          </>
        ) : userListing == 'adduser' ? (
          <>
            {' '}
            {updateLoading ? (
              <div className="main-advanced-loader">
                <CircularProgress size={45} />
              </div>
            ) : null}
            <AddUserForm
              getUsers={getUsers}
              dispatch={dispatch}
              toast={toast}
              setuserListing={setuserListing}
              setUpdateLoading={setUpdateLoading}
              TopHeadIcon={TopHeadIcon}
              isLoading={isLoading}
            />{' '}
          </>
        ) : (
          <>
            {' '}
            {updateLoading ? (
              <div className="main-advanced-loader">
                <CircularProgress size={45} />
              </div>
            ) : null}
            <EditUserForm
              useSelector={useSelector}
              seteditEmail={seteditEmail}
              editEmail={editEmail}
              getUsers={getUsers}
              dispatch={dispatch}
              toast={toast}
              setuserListing={setuserListing}
              setUpdateLoading={setUpdateLoading}
              TopHeadIcon={TopHeadIcon}
              isLoading={isLoading}
            />
          </>
        )}
      </Grid>
    )
  } else {
    return (
      <Grid container className="location-container">
        <Grid item xs={12}>
          <Grid container className="location-head">
            <Grid item xs={12} sm={12} md={4} lg={4} className="heading">
              <h2>
                <TopHeadIcon />
                Manage Users
              </h2>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Box display="flex" justifyContent="space-between" p={2}>
            <h6>You do not have permission to view this page</h6>
          </Box>
        </Grid>
      </Grid>
    )
  }
}

export default withTranslation('translations')(
  withStyles(tablestyle)(ManageUsers),
)
