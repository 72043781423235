import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import InputField from '../../../components/common/InputField';
import * as actions from '../actions';
import { forgotPasswordValidation } from '../validations';
//import { useSelector } from "react-redux";


import {
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from '@material-ui/core';
import AuthWrapper from '../AuthWrapper'
import { rememberMe, getObject } from "../../../utils";

class ForgetPassword extends Component {
  state = {
    email: "",
    errors: {},
  }

  componentDidMount = () => {

    const data={email:""};

    this.setState({
      email: data.email,
    });
  }
  
//   const isLoading = useSelector(
//    (state) => state.auth.isLoading || false
//  );


  handleChange = (e) => {
    const { errors } = this.state
    let { value = "", name = "" } = e.target;
    this.setState({ [name]: value, errors: { ...errors, [name]: "" } });
  };

  isValid = () => {
    const { email } = this.state;
    const { isValid = true, errors = {} } = forgotPasswordValidation({ email })
    this.setState({ errors });
    return isValid;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    
    let { email } = this.state;
    const { toast } = this.props;

    if (this.isValid()) {

      this.props.forgotPasswordAction({ email:email }).then((res) => {
             toast.success((res && res.message) || "New password sent to your email successfully.");
        this.props.history.push("/Login");    
      }).catch(err => {
        toast.error(err.message || "Something went wrong.")
      })
    }
  };

  handleSignIn = () => {
    this.props.history.push("/login")
  }

  render() {
    const { isLoading } = this.props;
    const { errors, email } = this.state;

    return (
      <AuthWrapper>
        <form className="full-w login-custom custom-width" onSubmit={this.handleSubmit} method="POST" >
          <div className="logo-wrapper d-flex"><img src='/images/login-logo.jpeg' className="logo" alt="" /></div>
          <h4 className="d-flex justify-content-center">Forgot Password</h4>
          <Grid item xs={12} sm={12} md={12} lg={12} className="my-4 input-group">
            <InputField
              name="email"
              type="text"
              label="Email"
              fullWidth
              value={email}
              placeholder="Please enter email"
              onChange={this.handleChange}
              error={errors.email}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} className="text-center">
            <Button
              type="submit"
              variant="contained"
              className="btn-custom-primry button-btn"
              disabled={isLoading}
              fullWidth
            >
              {isLoading ? <CircularProgress size="24px" color="white" /> : "Forgot Password"}
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} className="mt-4 input-group form-link-sec form-link-sign-in">
           <a onClick={this.handleSignIn}>Sign In</a>
          </Grid>
        </form>
      </AuthWrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.auth.isLoading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    forgotPasswordAction: (data) => dispatch(actions.forgotPassword(data))
  }
}

export default withTranslation("translations")(connect(mapStateToProps, mapDispatchToProps)(ForgetPassword));


