import { apiPost, apiGet, apiDelete } from '../../utils/axios'

const initData = {
//   keyword: '',
  skip: 0,
  limit: 10
}

export function getInvoiceAPI(data = initData) {
    return apiGet('/api/company/getInvoice', data)
}

export function updateInvoiceAPI(data) {
    const invoice = {}
    invoice.paymentDetails = data.paymentDetails
    invoice.companyComments = data.companyComments
    invoice.status = data.status

    return apiPost(`/api/company/updateInvoiceStatus/${data._id}`, invoice)
}

export function invoiceDownloadAPI(id) {
    return apiGet(`/api/company/getInvoicePdf?invoiceId=${id}`)
}