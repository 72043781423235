import {
  getUsersListAPI,
  updateUserStatusAPI,
  DeleteUserAPI,
  createUserAPI,
  editUserAPI,
  getSingleUser,
  getUserPermissionAPI
} from './apis'
import {
  GET_USER,
  GET_USER_SUCCEEDED,
  GET_USER_FAILED,
  UPDATE_USER_STATUS_REQEUST,
  UPDATE_USER_STATUS_REQUEST_SUCCEEDED,
  UPDATE_USER_STATUS_REQUEST_FAILED,
  CREATE_USER,
  CREATE_USER_SUCCEEDED,
  CREATE_USER_FAILED,
  GET_SINGLE_USER,
  GET_SINGLE_USER_SUCCEEDED,
  GET_SINGLE_USER_FAILED,
  GET_USER_PERMISSION,
  GET_USER_PERMISSION_SUCCEEDED,
  GET_USER_PERMISSION_FAILED
} from './reducer'

export function getUsersList(payload) {
  return dispatch => {

    dispatch({
      type: GET_USER
    })

    return new Promise((resolve, reject) => {
      getUsersListAPI(payload)
        .then(({ data }) => {
          dispatch({
            type: GET_USER_SUCCEEDED,
            payload: data
          })
          return resolve(data)
        })
        .catch(err => {

          dispatch({
            type: GET_USER_FAILED
          })
          return reject(err)
        })
    })
  }
}

export function updateUserStatus(data) {
  return dispatch => {

    dispatch({
      type: UPDATE_USER_STATUS_REQEUST
    })

    return new Promise((resolve, reject) => {
      updateUserStatusAPI(data)
        .then(({ data }) => {

          dispatch({
            type: UPDATE_USER_STATUS_REQUEST_SUCCEEDED,
            payload: data
          })
          return resolve(data)
        })
        .catch(err => {

          dispatch({
            type: UPDATE_USER_STATUS_REQUEST_FAILED
          })
          return reject(err)
        })
    })
  }
}

export function DeleteUser(data) {
  return dispatch => {

    dispatch({
      type: UPDATE_USER_STATUS_REQEUST
    })

    return new Promise((resolve, reject) => {
      DeleteUserAPI(data)
        .then(({ data }) => {

          dispatch({
            type: UPDATE_USER_STATUS_REQUEST_SUCCEEDED,
            payload: data
          })
          return resolve(data)
        })
        .catch(err => {

          dispatch({
            type: UPDATE_USER_STATUS_REQUEST_FAILED
          })
          return reject(err)
        })
    })
  }
}

export function createUser(data) {
  return dispatch => {

    dispatch({
      type: CREATE_USER
    })

    return new Promise((resolve, reject) => {
      createUserAPI(data)
        .then(({ data }) => {

          dispatch({
            type: CREATE_USER_SUCCEEDED,
            payload: data
          })
          return resolve(data)
        })
        .catch(err => {
          dispatch({
            type: CREATE_USER_FAILED,
          })
          return reject(err)
        })
    })
  }
}

export function editUser(data) {
  return dispatch => {

    dispatch({
      type: CREATE_USER
    })

    return new Promise((resolve, reject) => {
      editUserAPI(data)
        .then(({ data }) => {

          dispatch({
            type: CREATE_USER_SUCCEEDED,
            payload: data
          })
          return resolve(data)
        })
        .catch(err => {
          dispatch({
            type: CREATE_USER_FAILED,
          })
          return reject(err)
        })
    })
  }
}

export function getSingleUsersData(payload) {
  return dispatch => {

    dispatch({
      type: GET_SINGLE_USER
    })

    return new Promise((resolve, reject) => {
      getSingleUser(payload)
        .then(({ data }) => {
          dispatch({
            type: GET_SINGLE_USER_SUCCEEDED,
            payload: data
          })
          return resolve(data)
        })
        .catch(err => {

          dispatch({
            type: GET_SINGLE_USER_FAILED
          })
          return reject(err)
        })
    })
  }
}


export function getUserPermission(userId) {
  return dispatch => {

    dispatch({
      type: GET_USER_PERMISSION
    })

    return new Promise((resolve, reject) => {
      getUserPermissionAPI(userId)
        .then(({ data }) => {
          dispatch({
            type: GET_USER_PERMISSION_SUCCEEDED,
            payload: data
          })
          return resolve(data)
        })
        .catch(err => {

          dispatch({
            type: GET_USER_PERMISSION_FAILED
          })
          return reject(err)
        })
    })
  }
}