import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
  Grid,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Paper,
  Tab,
  Tabs,
  AppBar,
  Badge,
  Button,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import HistoryIcon from '@material-ui/icons/History';
import EnhancedTableHead from "../../components/common/EnhancedTableHead";
import TableLoader from "../../components/common/TableLoader";
import CustomTablePagination from "../../components/common/CustomPagination";

import UpdateForm from "./PopupModal/UpdateForm";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { getObject } from "../../utils";
import VisibilityIcon from '@material-ui/icons/Visibility';
import moment from 'moment'
import { Link } from 'react-router-dom'
import { useHistory } from "react-router";
const TableHeaders = [
  { id: "createdat", numeric: false, disablePadding: false, label: "Date" },
  { id: "totalveg", numeric: false, disablePadding: false, label: "Veg" },
  { id: "nonveg", numeric: false, disablePadding: false, label: "Non Veg" },
  { id: "addon", numeric: false, disablePadding: false, label: "Add-ons Meal" },
  { id: "total", numeric: false, disablePadding: false, label: "Total" },
  { id: "shiftType", numeric: false, disablePadding: false, label: "Shift Type" },
  { id: "approvedBy", numeric: false, disablePadding: false, label: "Approved By" },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
  { id: "view", numeric: false, disablePadding: false, label: "Action" },
];

function TabPanel(props) {
  const [editable, setEditable] = useState(false);

  const object = getObject("bb-user");
  const history = useHistory();
  const userObject = (object && JSON.parse(object)) || "";
  const { name = "", userType = "" } = (userObject && userObject.userDetails) || "";

  const user_id = (userObject.userDetails._id) || "";

  function handlePageChange(e, nextPage) {
    e.preventDefault();
    if (nextPage === page) {
      return;
    }

    setPage(nextPage);
    setSkip((nextPage - 1) * limit);
  }

  function capitalize(s) {
    return s[0].toUpperCase() + s.slice(1);
  }

  const {
    classes,
    isLoading,
    mealData,
    value,
    index,
    limit,
    page,
    count,
    setPage,
    setSkip,
    toast,
    numberFormat,
    MealTypeDot,
    setIsViewMeal = () => { },
    onView,
    ...other
  } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className="table-design w-100"
    >
      <Grid item xs={12} sm={12} md={4} lg={4} className="heading">
        <h2 style={{ fontSize: "24px", marginLeft: "10px" }}>
          <HistoryIcon style={{ color: "#f9c546", fontSize: "32px", marginRight: "10px" }} />
          Meal History
        </h2>
      </Grid>
      {isLoading ? (
        <>
          <TableContainer className={classes.container}>
            <Table
              className="table-program"
              stickyHeader
              aria-label="sticky table"
            >
              <EnhancedTableHead headCells={TableHeaders} />
            </Table>
          </TableContainer>
          <TableLoader />
        </>
      ) : null}

      {!isLoading ? (
        <TableContainer className={classes.container}>
          <Table
            className="table-program"
            stickyHeader
            aria-label="sticky table"
          >
            <EnhancedTableHead headCells={TableHeaders} />
            <TableBody>
              {mealData && mealData.length ? (
                mealData.map((order, i) => {
                  const {
                    approvedBy,
                    createdAt,
                    shiftType,
                    vegTotal,
                    nonVegTotal,
                    nonVegAddOn,
                    vegAddOn,
                    status,
                    employeeNonVeg,
                    employeeVeg,
                    _id
                  } = order;
                  const totalItems = parseInt(parseInt(employeeVeg || 0) + parseInt(employeeNonVeg || 0) + parseInt(vegAddOn || 0) + parseInt(nonVegAddOn || 0));
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      className="cursor_default"
                      key={i}
                    >
                      {/* <TableCell className="table-custom-width">{orderNumber || '-'}</TableCell> */}

                      {/* <TableCell className="table-custom-width">{orderItem || '-'}</TableCell> */}

                      <TableCell className="table-custom-width">
                        {/* {_id && _id.day && _id.month && _id.year
                          ? moment((_id.month), 'M').format('MMMM') + "  " + _id?.day + ", " + moment((_id.year), 'y').format('YY')
                          : "-"} */}
                        {createdAt && moment(createdAt).format('MMM DD, YY hh:mm A') || ''}
                      </TableCell>
                      <TableCell className="table-custom-width">
                        <strong>
                          <span className="green_span "> {numberFormat(employeeVeg) || 0}</span>
                        </strong>
                      </TableCell>
                      <TableCell className="table-custom-width">
                        <strong>
                          <span className="red_text ">   {numberFormat(employeeNonVeg) || 0}</span>

                        </strong>
                      </TableCell>
                      <TableCell className="table-custom-width">
                        <span className="green_span ">
                          <span className="meal-type-dot green">
                            <span className="inner"> </span></span>


                          {numberFormat(vegAddOn) || 0}</span>
                        <span className="red_text ml-2">
                          <span className="meal-type-dot ">
                            <span className="inner"> </span>
                          </span>
                          {numberFormat(nonVegAddOn) || 0}
                        </span>

                      </TableCell>
                      <TableCell className="table-custom-width">
                        <strong>

                          {numberFormat(totalItems) || 0}
                        </strong>
                      </TableCell>
                      <TableCell className="table-custom-width">
                        {shiftType === "DAY" ?
                          <img src="images/afternoon.svg" width="24px" />

                          :
                          <img src="images/night.svg" width="24px" />

                        }
                      </TableCell>
                      <TableCell className="table-custom-width">
                        {approvedBy || ""}
                      </TableCell>

                      <TableCell className="table-custom-width">
                        {status === "approved" ? <span className="green_span green_badge">Approved </span> : <span className="red orange_badge">Declined </span>}
                      </TableCell>
                      <TableCell className="table-custom-width">

                        <Link to={`/today-meal/?meal_id=${_id}`}>
                          <Button onClick={() => {
                            setIsViewMeal(true)

                          }
                          }> <VisibilityIcon /></Button>
                        </Link>


                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow hover role="checkbox" className="cursor_default">
                  <TableCell colSpan={12} className="table-custom-width">
                    <Box textAlign="center" p={1} className="no-data-found">
                      <p>No Data Found</p>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null
      }

      <CustomTablePagination
        count={mealData.length}
        total={count}
        limit={limit}
        page={page}
        onPageChange={handlePageChange}
      />
    </div >
  );
}
export default TabPanel;
