import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
  Grid,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Paper,
  Tab,
  Tabs,
  AppBar,
  Badge,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EnhancedTableHead from "../../components/common/EnhancedTableHead";
import TableLoader from "../../components/common/TableLoader";
import CustomTablePagination from "../../components/common/CustomPagination";
import ViewMeal from "./PopupModal/ViewMeal";
import ViewNote from "./PopupModal/ViewNote";
import UpdateForm from "./PopupModal/UpdateForm";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { getObject } from "../../utils";
import moment from 'moment'

const TableHeaders = [
  { id: "createdat", numeric: false, disablePadding: false, label: "Created At" },
  { id: "orderby", numeric: false, disablePadding: false, label: "Order By" },
  { id: "mealdata", numeric: false, disablePadding: false, label: "Meal Data" },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
  { id: "updatedby", numeric: false, disablePadding: false, label: "Updated By" },
  { id: "actions", numeric: false, disablePadding: false, label: "Actions" },
];

function TabPanel(props) {
  const [editable, setEditable] = useState(false);

  const object = getObject("bb-user");

  const userObject = (object && JSON.parse(object)) || "";
  const { name = "", userType = "" } = (userObject && userObject.userDetails) || "";

  const user_id = (userObject.userDetails._id) || "";

  function handlePageChange(e, nextPage) {
    e.preventDefault();
    if (nextPage === page) {
      return;
    }

    setPage(nextPage);
    setSkip((nextPage - 1) * limit);
  }

  function capitalize(s) {
    return s[0].toUpperCase() + s.slice(1);
  }

  const {
    isLoading,
    supply,
    value,
    index,
    classes,
    limit,
    page,
    count,
    setPage,
    setSkip,
    toast,
    getDailySupply,
    showupdateRecord,
    numberFormat,
    ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className="table-design w-100"
    >
      {isLoading ? (
        <>
          <TableContainer className={classes.container}>
            <Table
              className="table-program"
              stickyHeader
              aria-label="sticky table"
            >
              <EnhancedTableHead headCells={TableHeaders} />
            </Table>
          </TableContainer>
          <TableLoader />
        </>
      ) : null}

      {!isLoading ? (
        <TableContainer className={classes.container}>
          <Table
            className="table-program"
            stickyHeader
            aria-label="sticky table"
          >
            <EnhancedTableHead headCells={TableHeaders} />
            <TableBody>
              {supply && supply.length ? (
                supply.map((order) => {
                  const {
                    createdDate,
                    createdTime,
                    updatedDate,
                    updatedTime,
                    acceptedBy,
                    status,
                    comments,
                    _id,
                    companyId,
                    orderBy,
                    created,
                    mealData = {},
                    allaCart = {},
                  } = order;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      className="cursor_default"
                      key={_id}
                    >
                      {/* <TableCell className="table-custom-width">{orderNumber || '-'}</TableCell> */}

                      {/* <TableCell className="table-custom-width">{orderItem || '-'}</TableCell> */}

                      <TableCell className="table-custom-width">
                        {created
                          ? moment(created).format('MMM DD, YY hh:mm A')
                          : "--:--"}
                      </TableCell>
                      <TableCell className="table-custom-width">

                        {orderBy ? capitalize(orderBy) : "-"}
                      </TableCell>
                      <TableCell className="table-custom-width">
                        <ViewMeal numberFormat={numberFormat} mealData={mealData} allaCart={allaCart} actionLabel="View" />
                      </TableCell>
                      <TableCell className="table-custom-width">
                        {status === null ? (
                          <div className="daily-supply-listing-status pending">
                            Pending
                          </div>
                        ) : null}
                        {status == false ? (
                          <div className="daily-supply-listing-status declined">
                            Declined
                          </div>
                        ) : null}
                        {status == true ? (
                          <div className="daily-supply-listing-status approved">
                            Approved
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell className="table-custom-width">

                        {acceptedBy ? acceptedBy : "-"}
                      </TableCell>
                      <TableCell className="table-custom-width">

                        {(status === null && showupdateRecord == true) ?

                          <div className="td-action-btns">
                            <UpdateForm
                              getDailySupply={getDailySupply}
                              toast={toast}
                              acceptedBy={name}
                              actionLabel="Accept"
                              status="approved"
                              orderId={_id}
                              user_id={user_id}
                              userType={userType}
                            />
                            <UpdateForm
                              getDailySupply={getDailySupply}
                              toast={toast}
                              acceptedBy={name}
                              actionLabel="Decline"
                              status="declined"
                              orderId={_id}
                              user_id={user_id}
                              userType={userType}
                            />
                          </div>
                          : (status !== null) ?
                            <ViewNote
                              comments={comments}
                              getDailySupply={getDailySupply}
                              toast={toast}
                              acceptedBy={name}
                              orderId={_id}
                              user_id={user_id}
                              userType={userType}
                              actionLabel="View Note"
                            />
                            : ""
                        }
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow hover role="checkbox" className="cursor_default">
                  <TableCell colSpan={6} className="table-custom-width">
                    <Box textAlign="center" p={1} className="no-data-found">
                      <p>No Data Found</p>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}

      <CustomTablePagination
        count={supply.length}
        total={count}
        limit={limit}
        page={page}
        onPageChange={handlePageChange}
      />
    </div>
  );
}
export default TabPanel;
