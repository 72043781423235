import {
  getSupplyListAPI,
  updateDailySupplyAPI
} from './apis'
import {
  GET_SUPPLY,
  GET_SUPPLY_SUCCEEDED,
  GET_SUPPLY_FAILED,
  UPDATE_DAILY_SUPPLY_REQEUST,
  UPDATE_DAILY_SUPPLY_REQUEST_SUCCEEDED,
  UPDATE_DAILY_SUPPLY_REQEUST_FAILED,
} from './reducer'

export function getDailySupplyList(payload) {
  console.log(payload, "payload")
  return dispatch => {

    dispatch({
      type: GET_SUPPLY
    })

    return new Promise((resolve, reject) => {
      getSupplyListAPI(payload)
        .then(({ data }) => {
          dispatch({
            type: GET_SUPPLY_SUCCEEDED,
            payload: data
          })
          return resolve(data)
        })
        .catch(err => {

          dispatch({
            type: GET_SUPPLY_FAILED
          })
          return reject(err)
        })
    })
  }
}

export function updateDailySupply(data) {
  return dispatch => {

    dispatch({
      type: UPDATE_DAILY_SUPPLY_REQEUST
    })

    return new Promise((resolve, reject) => {
      updateDailySupplyAPI(data)
        .then(({ data }) => {

          dispatch({
            type: UPDATE_DAILY_SUPPLY_REQUEST_SUCCEEDED,
            payload: data
          })
          return resolve(data)
        })
        .catch(err => {

          dispatch({
            type: UPDATE_DAILY_SUPPLY_REQEUST_FAILED
          })
          return reject(err)
        })
    })
  }
}