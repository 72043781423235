import React, { useState } from 'react'
import { Grid, Box, CircularProgress, Tab, Tabs } from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings'
import InputField from '../../../components/common/InputField'
import { Button } from '@material-ui/core'
import { updatePassword } from '../actions'
import { useDispatch, useSelector } from 'react-redux'
import { validatePasswordUpdate } from '../validations'
import { getObject } from '../../../utils'


const initObj = {
  password: '',
  newPassword: '',
  confirmPassword: '',
}


export default function ChangePassword(props) {
  const [data, setData] = useState({ ...initObj })
  const [errors, setErrors] = useState({})
  const { toast, dispatch, isLoading, value, index, ...other } = props;
  function handleChange(key, value) {
    setData({
      ...data,
      [key]: value
    })
  }
  const object = getObject("bb-user");

  const userObject = (object && JSON.parse(object)) || "";
  const { _id = "", userType = "" } = (userObject && userObject.userDetails) || "";


  function submit() {
    if (validate()) {
      let obj = {
        ...data,
        oldPassword: data.password
      }
      if (userType === "user") {
        obj = {
          ...data,
          userId: _id,
          userType: userType,
          oldPassword: data.password
        }

      }
      delete obj.password;
      delete obj.confirmPassword;

      dispatch(updatePassword(obj))
        .then(() => {
          toast.success('Password updated successfully')
          setData({ ...initObj })
        })
        .catch(err => {
          console.log(err, " this is err")
          toast.error(err.message || "Oops! Something went wrong")
        })
    }
  }

  function validate() {
    const { errors: newErrors, isValid } = validatePasswordUpdate(data)
    setErrors(newErrors)
    return isValid
  }

  const valid = data.password && data.confirmPassword && data.newPassword || false

  return (

    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      <div id="no-more-tables" className="card-design table-design cf">
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <Box p={2} className="tab-con-form">
              <h5>Update Password</h5>
              <div className="update-pass-tab">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box>
                      <InputField
                        className="password-input"
                        name="password"
                        fullWidth
                        variant="outlined"
                        type="password"
                        label="Current Password"
                        placeholder="Current Password"
                        value={data.password}
                        onChange={({ target: { value } }) => handleChange('password', value)}
                        error={errors.password}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box>
                      <InputField
                        className="password-input"
                        name="password"
                        fullWidth
                        variant="outlined"
                        type="password"
                        label="New Password"
                        placeholder="New Password"
                        value={data.newPassword}
                        onChange={({ target: { value } }) => handleChange('newPassword', value)}
                        error={errors.newPassword}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box>
                      <InputField
                        className="password-input"
                        name="password"
                        fullWidth
                        variant="outlined"
                        type="password"
                        label="Confirm New Password"
                        placeholder="Confirm New Password"
                        value={data.confirmPassword}
                        onChange={({ target: { value } }) => handleChange('confirmPassword', value)}
                        error={errors.confirmPassword}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="set-tabs-btn">
                      <Button
                        variant="contained"
                        className={`btn-custom-primry button-btn ${valid ? '' : 'disabled'}`}
                        disabled={isLoading}
                        onClick={submit}
                      >
                        {isLoading ? <>Loading<CircularProgress size="24px" color="white" /></> : "Update"}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );


}