import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Dialog, RadioGroup, CircularProgress, FormControl, Box, FormControlLabel, InputLabel, Select, MenuItem, Grid } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import InputField from '../../../components/common/InputField';
import { validateWalletData } from '../validations';
import { addWalletData, deleteAllEmployee, deleteEmployee } from '../actions'
import { useDispatch, useSelector } from 'react-redux';
import Radio from '@material-ui/core/Radio';
import { green, red } from '@material-ui/core/colors';
import { getMealTypes } from '../../Meals/actions';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';

import WbSunnyIcon from '@material-ui/icons/WbSunny';
import Brightness3Icon from '@material-ui/icons/Brightness3';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const initEmp = {
  employeeIds: [],
  isAllSelected: false,
}



export default function DeleteEmployee(props) {
  const { actionLabel, selectAllUser = false, selectUser = [], setEditable } = props
  const [open, setOpen] = useState(false);
  const [employee, setEmployee] = useState({ ...initEmp })
  const [addButtonDisable, setAddButtonDisable] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const dispatch = useDispatch()




  function handleClickOpen() {
    setOpen(true);
    setErrors({});
    setEmployee({ ...employee, isAllSelected: selectAllUser, employeeIds: selectUser })
  };


  function handleClose() {
    setOpen(false);
    setEmployee({ ...initEmp })
    setErrors({})
  };



  function onSubmit(e) {
    e.preventDefault()
    setAddButtonDisable(true)
    setIsLoading(true)
    removeEmployees()
  }

  function removeEmployees() {
    const { toast, onClose } = props;
    onClose();
    handleClose()
    setAddButtonDisable(false)
    setIsLoading(false)
    // toast.success("Employees deleted successfully");
    dispatch(deleteAllEmployee(employee))
      .then(async () => {
        onClose();
        handleClose()
        setAddButtonDisable(false)
        setIsLoading(false)
        toast.success("Employees deleted successfully");
      })
      .catch(({ message = '' }) => {
        toast.error(message || 'Oops! Something went wrong')
        setAddButtonDisable(false)
        setIsLoading(false)
      })
  }



  return (
    <div style={{ marginRight: 30 }}>
      <Button
        className="tras-bin-btn "
        onClick={handleClickOpen}
        style={{ backgroundColor: "#d12a11" }}
        data-tooltip="Delete Employees"
      >
        <DeleteForeverOutlinedIcon />
        {/* {actionLabel} */}
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
        fullWidth
        disableBackdropClick={true}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Delete User(s)
        </DialogTitle>
        <DialogContent dividers>
          <Box py={1}>
            <p>Are you sure you want to remove all selected users ?</p>
          </Box>
        </DialogContent>
        <DialogActions>
          <div className="foot-modal-btns">
            <Button onClick={handleClose} color="secondary" variant="contained">
              Cancel
            </Button>
            <Button color="primary" variant="contained" onClick={onSubmit} disabled={isLoading ? true : false}>
              {isLoading ? <CircularProgress size="20px" color="white" /> : "Delete"}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div >
  );
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const RedRadio = withStyles({
  root: {
    color: red[400],
    '&$checked': {
      color: red[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);