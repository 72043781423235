
export const actionsTypes = {
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_REQUEST_SUCCEEDED: 'LOGIN_REQUEST_SUCCEEDED',
    LOGIN_REQUEST_FAILED: 'LOGIN_REQUEST_FAILED',

    FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
    FORGOT_PASSWORD_SUCCEEDED: 'FORGOT_PASSWORD_SUCCEEDED',
    FORGOT_PASSWORD_FAILED: 'FORGOT_PASSWORD_FAILED',

    RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCEEDED: 'RESET_PASSWORD_SUCCEEDED',
    RESET_PASSWORD_FAILED: 'RESET_PASSWORD_FAILED'

}