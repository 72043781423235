import {
    GET_INVOICE_LIST,
    GET_INVOICE_LIST_SUCCESS,
    GET_INVOICE_LIST_FAILED,

    UPDATE_INVOICE,
    UPDATE_INVOICE_SUCCESS,
    UPDATE_INVOICE_FAILED,

    GET_INVOICE_DOWNLOAD,
    GET_INVOICE_DOWNLOAD_SUCCESS,
    GET_INVOICE_DOWNLOAD_FAILED,
} from './actions'


const initialState = {
    isLoading: false,
    invoiceDownloadLoading: false,
    invoice: {
        employees:[],
        count:0,
        ALL: 0,
        NEW: 0,
        PAID: 0,
        PROCESSED: 1,
        HOLD: 0
    },
    updatedUrl:{},
}

export default function (state = { ...initialState }, action) {
    switch (action.type) {

        case GET_INVOICE_LIST:
            return { ...state, isLoading: true }

        case GET_INVOICE_LIST_SUCCESS:
            return { ...state, isLoading: false, invoice: action.payload }

        case GET_INVOICE_LIST_FAILED:
            return { ...state, isLoading: false, invoice: {} }


        case UPDATE_INVOICE:
            return { ...state, isLoading: true }

        case UPDATE_INVOICE_SUCCESS:
            return { ...state, isLoading: false }

        case UPDATE_INVOICE_FAILED:
            return { ...state, isLoading: false }

        case GET_INVOICE_DOWNLOAD:
            return { ...state, invoiceDownloadLoading: true ,updatedUrl:{}}

        case GET_INVOICE_DOWNLOAD_SUCCESS:
            return { ...state, invoiceDownloadLoading: false ,updatedUrl:action}

        case GET_INVOICE_DOWNLOAD_FAILED:
            return { ...state, invoiceDownloadLoading: false,updatedUrl:{} }

        default:
            return state
    }
}