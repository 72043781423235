
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
    Grid,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Box,
    Paper,
    Tab,
    Tabs,
    AppBar,
    Badge,
    Button,
} from "@material-ui/core";
import HistoryIcon from '@material-ui/icons/History';
import EnhancedTableHead from "../../components/common/EnhancedTableHead";
import TableLoader from "../../components/common/TableLoader";
import CustomTablePagination from "../../components/common/CustomPagination";
import { getObject } from "../../utils";
import moment from 'moment'
import { useDispatch, useSelector } from "react-redux";
import { getSingleMealData } from "./actions";
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useHistory } from "react-router";
const TableHeaders = [
    { id: "name", numeric: false, disablePadding: false, label: "Name" },
    { id: "employeeId", numeric: false, disablePadding: false, label: "Employee Id" },
    { id: "mealType", numeric: false, disablePadding: false, label: "Meal Type" },
    { id: "createdAt", numeric: false, disablePadding: false, label: "Date Time" },
];

function ViewMeal(props) {
    const dispatch = useDispatch()
    const {
        singleMealData
    } = useSelector(state => state.todaymeal);
    const history = useHistory();
    const object = getObject("bb-user");
    const userObject = (object && JSON.parse(object)) || "";
    const { name = "", userType = "" } = (userObject && userObject.userDetails) || "";

    const user_id = (userObject.userDetails._id) || "";

    function handlePageChange(e, nextPage) {
        e.preventDefault();
        if (nextPage === page) {
            return;
        }

        setPage(nextPage);
        setSkip((nextPage - 1) * limit);
    }

    function capitalize(s) {
        return s[0].toUpperCase() + s.slice(1);
    }


    const {
        classes,
        isLoading,
        value,
        index,
        limit,
        page,
        count,
        setPage,
        setSkip,
        skip,
        toast,
        numberFormat,
        ...other
    } = props;
    let params = window.location.search
    let getParams = new URLSearchParams(params)
    let term = getParams.get('meal_id')
    // console.log(mealData.filter((item => item._id === term)), "data")
    // const employeeData = mealData.find((item => item._id === term));

    console.log(term, "term")
    function fetchSingleMealData() {
        const data = {
            limit,
            skip,
            approveMealId: term

        };
        if (term == "userDetail") {
            delete data.approveMealId
        }
        console.log(data, "data")
        dispatch(getSingleMealData(data));
    }

    useEffect(() => {
        fetchSingleMealData()
    }, [])

    useEffect(() => {
        fetchSingleMealData();
    }, [skip, limit,]);

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            className="table-design w-100 manage-users-pg"
        >
            <div className="cus-back-head">
                <h3 onClick={() => {
                    history.push(`/today-meal`)
                }}>
                    <ArrowBackIcon />
                    {term == "userDetail" ? <span>Recent Opted-In Employee(s) </span> : <span>Back to "Meal History"</span>}
                </h3>
            </div>
            {/* <Grid item xs={12} sm={12} md={4} lg={4} className="heading">
                <h2 style={{ fontSize: "24px", marginLeft: "10px" }}>
                    <HistoryIcon style={{ color: "#f9c546", fontSize: "32px", marginRight: "10px" }} />
                    Meal Detail
                </h2>
            </Grid> */}
            {isLoading ? (
                <>
                    <TableContainer className={classes.container}>
                        <Table
                            className="table-program"
                            stickyHeader
                            aria-label="sticky table"
                        >
                            <EnhancedTableHead headCells={TableHeaders} />
                        </Table>
                    </TableContainer>
                    <TableLoader />
                </>
            ) : null}

            {!isLoading ? (
                <TableContainer className={classes.container}>
                    <Table
                        className="table-program"
                        stickyHeader
                        aria-label="sticky table"
                    >
                        <EnhancedTableHead headCells={TableHeaders} />
                        <TableBody>
                            {singleMealData?.optInData && singleMealData?.optInData?.length ? (
                                singleMealData?.optInData?.map((order, i) => {
                                    const {
                                        vegTotal,
                                        nonVegTotal,
                                        status,
                                        _id,
                                    } = order;
                                    const totalItems = parseInt(parseInt(vegTotal || 0) + parseInt(nonVegTotal || 0));
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            className="cursor_default"
                                            key={i}
                                        >
                                            <TableCell className="table-custom-width">
                                                {order?.employeeId?.name || "-"}

                                            </TableCell>
                                            <TableCell className="table-custom-width">
                                                {order?.employeeId?.employeeId || "-"}

                                            </TableCell>
                                            <TableCell className="table-custom-width">
                                                {/* {order?.mealType || "-"} */}
                                                {order?.mealType == "VEG" ? <span className="">
                                                    <span className="meal-type-dot green green_span ml-2 ">
                                                        <span className="inner"> </span>
                                                    </span>
                                                    {order?.mealType || "-"}
                                                </span> :
                                                    <span className="">
                                                        <span className="meal-type-dot red_text ml-2">
                                                            <span className="inner"> </span>
                                                        </span>
                                                        {order?.mealType || "-"}
                                                    </span>}
                                            </TableCell>
                                            <TableCell className="table-custom-width">
                                                {order?.createdAt && moment(order?.createdAt).format('MMM DD, YY hh:mm A') || ''}
                                            </TableCell>




                                        </TableRow>
                                    );
                                })
                            ) : (
                                <TableRow hover role="checkbox" className="cursor_default">
                                    <TableCell colSpan={6} className="table-custom-width">
                                        <Box textAlign="center" p={1} className="no-data-found">
                                            <p>No Data Found</p>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : null}

            <CustomTablePagination
                count={singleMealData?.optInData?.length}
                total={singleMealData?.count}
                limit={limit}
                page={page}
                onPageChange={handlePageChange}
            />
        </div>
    );
}
export default ViewMeal;

