import {
    getVoucherAPI,
    getEmployeesListAPI,
    getEmployeeNameListAPI,
    assignVoucherAPI
} from './apis';

export function getVoucherList(data) {
    return dispatch => {
        dispatch({
            type: GET_VOUCHER_LIST
        })
        return new Promise((resolve, reject) => {
            getVoucherAPI(data)
                .then(({ data }) => {
                    dispatch({
                        type: GET_VOUCHER_LIST_SUCCESS,
                        payload: data
                    })
                    return resolve(data)
                })
                .catch(err => {
                    dispatch({
                        type: GET_VOUCHER_LIST_FAILED
                    })
                    return reject(err)
                })
        })
    }
}
export const GET_VOUCHER_LIST = "GET_VOUCHER_LIST"
export const GET_VOUCHER_LIST_SUCCESS = "GET_VOUCHER_LIST_SUCCESS"
export const GET_VOUCHER_LIST_FAILED = "GET_VOUCHER_LIST_FAILED"


export function getEmployeesList(data) {
    return dispatch => {
  
      dispatch({
        type: GET_EMPLOYEE_LIST_REQUEST
      })
  
      return new Promise((resolve, reject) => {
        getEmployeesListAPI(data)
          .then(({ data }) => {
            dispatch({
              type: GET_EMPLOYEE_LIST_REQUEST_SUCCEEDED,
              payload: data
            })
            return resolve(data)
          })
          .catch(err => {
  
            dispatch({
              type: GET_EMPLOYEE_LIST_REQUEST_FAILED
            })
            return reject(err)
          })
      })
    }
  }

  export const GET_EMPLOYEE_LIST_REQUEST = 'GET_EMPLOYEE_LIST_REQUEST'
  export const GET_EMPLOYEE_LIST_REQUEST_SUCCEEDED = 'GET_EMPLOYEE_LIST_REQUEST_SUCCEEDED'
  export const GET_EMPLOYEE_LIST_REQUEST_FAILED = 'GET_EMPLOYEE_LIST_REQUEST_FAILED'


  export function getEmployeeNameList(data){
    return dispatch => {

    dispatch({
      type: GET_EMPLOYEE_NAME_LIST_REQUEST
    })

    return new Promise((resolve, reject) => {
      getEmployeeNameListAPI(data)
        .then(({ data }) => {
          dispatch({
            type: GET_EMPLOYEE_NAME_LIST_REQUEST_SUCCEEDED,
            payload: data
          })
          return resolve(data)
        })
        .catch(err => {

          dispatch({
            type: GET_EMPLOYEE_NAME_LIST_REQUEST_FAILED
          })
          return reject(err)
        })
    })
  }  
}
export const GET_EMPLOYEE_NAME_LIST_REQUEST = 'GET_EMPLOYEE_NAME_LIST_REQUEST'
export const GET_EMPLOYEE_NAME_LIST_REQUEST_SUCCEEDED = 'GET_EMPLOYEE_NAME_LIST_REQUEST_SUCCEEDED'
export const GET_EMPLOYEE_NAME_LIST_REQUEST_FAILED = 'GET_EMPLOYEE_NAME_LIST_REQUEST_FAILED'
  

export function assignVoucher(data) {
  return dispatch => {

    dispatch({
      type: ASSIGN_VOUCHER_REQEUST
    })

    return new Promise((resolve, reject) => {
      assignVoucherAPI(data)
        .then(({ data }) => {

          dispatch({
            type: ASSIGN_VOUCHER_REQEUST_SUCCEEDED,
            payload: data
          })
          return resolve(data)
        })
        .catch(err => {

          dispatch({
            type: ASSIGN_VOUCHER_REQEUST_FAILED
          })
          return reject(err)
        })
    })
  }
}

export const ASSIGN_VOUCHER_REQEUST = 'ASSIGN_VOUCHER_REQEUST'
export const ASSIGN_VOUCHER_REQEUST_SUCCEEDED = 'ASSIGN_VOUCHER_REQEUST_SUCCEEDED'
export const ASSIGN_VOUCHER_REQEUST_FAILED = 'ASSIGN_VOUCHER_REQEUST_FAILED'

