import React, { useEffect, useMemo, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  Dialog,
  RadioGroup,
  CircularProgress,
  FormControl,
  Box,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Table,
  TableCell,
  TableBody,
  IconButton,
  Typography,
  Radio,
  TableRow,
  TextField,
} from '@material-ui/core'
import moment from 'moment'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import CloseIcon from '@material-ui/icons/Close'
import CachedIcon from '@material-ui/icons/Cached'; 
import InputField from '../../../components/common/InputField'
import { green, red } from '@material-ui/core/colors'
import MealTypeDot from '../../Common/MealTypeDot'
import EnhancedTableHead from '../../../components/common/EnhancedTableHead'
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import { getOptTime, approveMealData } from '../actions'
import { useDispatch, useSelector } from 'react-redux'
import { validateAdditionalMeal } from '../validations'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const initAdditionalMeal = {
  isAddOn: false,
  vegAddOn: 0,
  nonVegAddOn: 0,
  shiftType: "",
  userId: "",
  status: "",
  approvedBy: "",
  vegTotal: 0,
  nonVegTotal: 0,
  // mealTiming: "",
  // orderBy: '',
  // companyId: '',
  // contactPersonId: '',
  // created: moment().format(),
  // createdDate: moment().format('DD-MM-YYYY'),
  // createdTime: moment().format('hh:mm:A'),
  // mealData: [
  //   {
  //     mealName: 'Veg meal',
  //     quantity: 0,
  //     mealType: 'VEG',
  //   },
  //   {
  //     mealName: 'Non veg meal',
  //     quantity: 0,
  //     mealType: 'NON VEG',
  //   },
  // ],
}

function getCompanyData(state) {
  const companydata = state?.settings?.companydetails || {}
  //return companydata;
  return {
    companyId: companydata._id || '',
    username: companydata.username || '',
    email: companydata.email || '',
    logo: companydata.logo || '',
    address: companydata.address || '',
    name: companydata.name || '',
    approvedBy: companydata.contactPersonName || '',
    userId: companydata.contactPersonId || '',
    phoneNumber: companydata.phoneNumber || '',
    gstNumber: companydata.gstNumber || '',
    workingDays: companydata?.workingDays || [],
    companyShift: companydata.shift || '',
  }
}

const initShiftType = [
  {
    _id: 'DAY',
    shiftName: 'DAY',
  },
  {
    _id: 'NIGHT',
    shiftName: 'NIGHT',
  },
]

export default function UpdateForm(props) {
  const dispatch = useDispatch()
  const { actionLabel, user_id, userName, opttime, todayMealData, getMealData } = props
  const [open, setOpen] = useState(false)
  const [addButtonDisable, setAddButtonDisable] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [additionalMeal, setadditionalMeal] = useState({
    ...initAdditionalMeal,
  })
  const [ShiftType, setShiftType] = useState({})
  const [errors, setErrors] = useState({})
  const [isValidByTime, setIsValidByTime] = useState(false)

  const [NonVegObj, setNonVegObj] = useState({})
  const [VegObj, setVegObj] = useState({})
  const [canSubmitForm, setCanSubmitForm] = useState(false)
  // const [approveMeal, setApproveMeal] = useState(
  //   {
  //     vegAddOn: 0,
  //     nonVegAddOn: 0
  //   }
  // )
  function handleRefresh() {
    localStorage.setItem('openAdditionalMealModal', 'true');
    window.location.reload();
  }


  useEffect(() => {
    const shouldOpenModal = localStorage.getItem('openAdditionalMealModal');
    if (shouldOpenModal === 'true') {

      setadditionalMeal({
        ...additionalMeal,
        vegTotal: parseInt(VegObj?.quantity || 0),
        nonVegTotal: parseInt(NonVegObj?.quantity || 0),
      });
      setOpen(true);
    
      localStorage.removeItem('openAdditionalMealModal');
    }
  }, []);

  const {
    companyId,
    username,
    email,
    logo,
    address,
    name,
    gstNumber,
    contactPersonName,
    contactPersonId,
    workingDays,
    phoneNumber,
    companyShift,
    userId,
    approvedBy
  } = useSelector(getCompanyData)
  if (companyShift && Object.keys(ShiftType).length === 0) {
    if (companyShift == 'DAY') {
      setShiftType([
        {
          _id: 'DAY',
          shiftName: 'DAY',
        },

      ])
    }
    if (companyShift == 'NIGHT') {
      setShiftType([
        {
          _id: 'NIGHT',
          shiftName: 'NIGHT',
        },
      ])
    }
    if (companyShift == 'BOTH') {
      setShiftType([...initShiftType])
    }
  }

  if (!additionalMeal.approvedBy && approvedBy) {
    setadditionalMeal({ ...additionalMeal, approvedBy: approvedBy })
    var NonVeg = todayMealData.filter((meal) => { return meal.mealType == "NON VEG"; })
    var Veg = todayMealData.filter((meal) => { return meal.mealType == "VEG"; })
    setNonVegObj(NonVeg[0] || {});
    setVegObj(Veg[0] || {});
  }

  if (!additionalMeal.userId && user_id) {
    setadditionalMeal({ ...additionalMeal, userId: user_id })
  }

  // if (!additionalMeal.orderBy && userName) {
  //   setadditionalMeal({ ...additionalMeal, orderBy: userName })
  // }

  function handleClickOpen() {
    sessionStorage.setItem('openAdditionalMealModal', 'true');
    window.location.reload();
  }
  

  function handleClose() {
    setOpen(false)
    //    setErrors({})
  }

  function onSubmit(e) {
    e.preventDefault()
    setIsLoading(true)


    updateOrder()
  }
  function updateOrder() {
    const { toast } = props;
    const formIsValid = validate()
    let extraVeg = additionalMeal.vegTotal - parseInt(VegObj?.quantity || 0)

    let extraNonVeg = additionalMeal.nonVegTotal - parseInt(NonVegObj?.quantity || 0)

    setadditionalMeal({ ...additionalMeal, vegAddOn: extraVeg, nonVegAddOn: extraNonVeg, })

    if (validate()) {
      dispatch(approveMealData({ ...additionalMeal, vegAddOn: extraVeg, nonVegAddOn: extraNonVeg, employeeVeg: VegObj?.quantity, employeeNonVeg: NonVegObj?.quantity }))
        .then(async () => {
          setIsLoading(false);
          toast.success("Meals Approved Successfully");
          handleClose();
          getMealData();
        })
        .catch(({ message = "" }) => {
          toast.error(message || "Oops! Something went wrong");
          setIsLoading(false);
          handleClose();
          getMealData();
        });
    } else {
      setIsLoading(false)
    }
  }
  //
  // console.log(additionalMeal, "additionalMeal")

  function handleChange(key, value) {
    var totalVeg = 0;
    var totalNonVeg = 0;
    totalVeg = parseInt(parseInt(totalVeg) + parseInt(VegObj?.quantity || 0));

    if (key == "vegAddOn") {
      totalVeg = parseInt(parseInt(totalVeg) + parseInt(value));
    } else {
      totalVeg = parseInt(parseInt(totalVeg) + parseInt(additionalMeal.vegAddOn));
    }
    totalNonVeg = parseInt(parseInt(totalNonVeg) + parseInt(NonVegObj?.quantity || 0));
    if (key == "nonVegAddOn") {
      totalNonVeg = parseInt(parseInt(totalNonVeg) + parseInt(value));
    } else {
      totalNonVeg = parseInt(parseInt(totalNonVeg) + parseInt(additionalMeal.nonVegAddOn));
    }
    if (totalNonVeg > 0 || totalVeg > 0) {
      setCanSubmitForm(true);
    } else {
      setCanSubmitForm(false);
    }


    if (key === "shiftType") {
      // var newVal = "NIGHT";
      // if (value == "DAY") {
      //   newVal = "MORNING";
      // }

      // setadditionalMeal({ ...additionalMeal, mealTiming: newVal, shiftType: value })
      opttime.forEach((meal) => {
        if (value == "DAY" && meal.shiftType == "DAY") {
          if (meal?.dayApproveStatus) {
            return setIsValidByTime(false)
          }

          var nowTime = moment()
          var hrOptTime = moment(new Date()).set({
            hours: meal?.hrOptTime?.split(':')[0],
            minutes: meal?.hrOptTime?.split(':')[1]
          })
          let employeeOptTimeDate = moment(new Date()).set({
            hour: meal?.employeeOptTime?.split(':')[0],
            minutes: meal?.employeeOptTime?.split(':')[1]
          }).subtract(parseInt(meal?.leadTime), 'hours')


          let isAfterExist = (moment(nowTime).isAfter(moment(employeeOptTimeDate)) && moment(nowTime).isBefore(hrOptTime))

          setIsValidByTime(isAfterExist)
        }
        if (value == "NIGHT" && meal.shiftType == "NIGHT") {

          if (meal?.nightApproveStatus) {
            return setIsValidByTime(false)
          }

          var nowTime = moment()
          var hrOptTime = moment(new Date()).set({
            hours: meal?.hrOptTime?.split(':')[0],
            minutes: meal?.hrOptTime?.split(':')[1]
          })
          let employeeOptTimeDate = moment(new Date()).set({
            hour: meal?.employeeOptTime?.split(':')[0],
            minutes: meal?.employeeOptTime?.split(':')[1]
          }).subtract(parseInt(meal?.leadTime), 'hours')


          let isAfterExist = (moment(nowTime).isAfter(moment(employeeOptTimeDate)) && moment(nowTime).isBefore(hrOptTime))

          setIsValidByTime(isAfterExist)
        }
      })
    }

    setadditionalMeal({ ...additionalMeal, [key]: value })


  }


  function validate() {
    const { errors: newErrors, isValid } = validateAdditionalMeal(
      additionalMeal,
    )
    setErrors(newErrors)
    let checkValid = true
    let validErrors = {}

    return isValid
  }

  const valid =
    (additionalMeal.vegTotal !== 0 || additionalMeal.nonVegTotal !== 0) || false

  const handleInrementBtn = (name, value) => {
    setadditionalMeal({ ...additionalMeal, [name]: value + 1 })

  }

  const handleDecrementBtn = (name, value) => {
    if (value > 0) {
      setadditionalMeal({ ...additionalMeal, [name]: value - 1 })
    }

  }
  useEffect(() => {
    const shouldOpenModal = sessionStorage.getItem('openAdditionalMealModal');
    if (shouldOpenModal === 'true') {
      setadditionalMeal({
        ...additionalMeal,
        vegTotal: parseInt(VegObj?.quantity || 0),
        nonVegTotal: parseInt(NonVegObj?.quantity || 0),
      });
      setOpen(true);
      sessionStorage.removeItem('openAdditionalMealModal');
    }
  }, []); 

  return (
    <>
      <div className={`btns-group-sec sec- `} style={{ margin: "auto 12px auto auto" }}>
        <Button
          className="button-btn btn-custom-primry"
          color="primary"
          variant="contained"
          onClick={handleClickOpen}
        // disabled={todayMealData?.every((item) => item.quantity === 0)}
        >
          {actionLabel}
        </Button>

        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          maxWidth="sm"
          fullWidth
          disableBackdropClick={true}
          className="modal-contents-main"
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {actionLabel}
          </DialogTitle>

          <DialogContent dividers>
            {/* <Box py={1} className="modal-textarea">
              <p>Add Additional Meal</p>
            </Box> */}
            <Box py={1} className="modal-textarea-modal">
              <div className='plus-btn btn-modal-textarea'>
                <label> <span className="meal-type-dot green"><span className="inner"></span></span>  <span className='veg-theme'>Veg</span> </label>
                <Button onClick={() => { handleDecrementBtn("vegTotal", additionalMeal.vegTotal) }}> - </Button>
                {/* <InputField
                // className="password-input"
                name="vegAddOn"
                variant="outlined"
                value={additionalMeal.vegAddOn}
                label="Veg Addition"
                placeholder="Veg Addition"
                onChange={({ target: { value } }) =>
                  handleChange('vegAddOn', value)
                }
                error={errors.vegAddOn}
              /> */}
                <span className='count'>{additionalMeal.vegTotal}</span>
                <Button onClick={() => { handleInrementBtn("vegTotal", additionalMeal.vegTotal) }}> + </Button>
              </div>
              <div className='plus-btn btn-modal-textarea'>
                <label> <span className="meal-type-dot"><span className="inner"></span></span>  <span className='non-veg-theme'>Non Veg </span> </label>
                {/* <Button > - </Button> */}
                <Button onClick={() => { handleDecrementBtn("nonVegTotal", additionalMeal.nonVegTotal) }}> - </Button>

                {/* <InputField
                className="password-input"
                name="nonVegAddOn"
                fullWidth
                variant="outlined"
                value={additionalMeal.nonVegAddOn}
                label="Non Veg Addition"
                placeholder="Non Veg Addition"
                onChange={({ target: { value } }) =>
                  handleChange('nonVegAddOn', value)
                }
                error={errors.nonVegAddOn}
              /> */}
                {/* <span> {parseInt(parseInt(additionalMeal.nonVegAddOn || 0) + parseInt(NonVegObj?.quantity || 0))}</span> */}
                <span className='count'>{additionalMeal.nonVegTotal}</span>
                <Button onClick={() => { handleInrementBtn("nonVegTotal", additionalMeal.nonVegTotal) }}> + </Button>
              </div>
            </Box>
            {/* 0000000000 */}
            <Box py={1}>
              <FormControl variant="outlined" style={{ minWidth: '100%' }}>
                <InputLabel id="meal-type-selection">Shift Type</InputLabel>
                <Select
                  labelId="meal-type-selection"
                  id="meal-type"
                  onChange={({ target: { value } }) => {
                    handleChange('shiftType', value)
                  }
                  }
                  label="Meal Type"
                  fullWidth
                >
                  {ShiftType &&
                    Object.keys(ShiftType).length !== 0 &&
                    ShiftType?.map((shift) => (
                      <MenuItem value={shift._id} key={shift._id}>
                        {shift?.shiftName || '-'}
                      </MenuItem>
                    ))}
                </Select>
                {errors.shiftType && (
                  <span className="help-block error text-left">
                    {errors.shiftType}
                  </span>
                )}
              </FormControl>
            </Box>
            <Box py={2}>
              <FormControl variant="outlined" style={{ minWidth: '100%' }}>
                <InputLabel id="meal-type-selection1">Status</InputLabel>
                <Select
                  labelId="meal-type-selection1"
                  id="meal-status"
                  onChange={({ target: { value } }) => {
                    // setadditionalMeal({ ...additionalMeal, status: value })
                    handleChange('status', value)
                  }
                  }
                  label="Status"
                  fullWidth
                >
                  <MenuItem value={"approved"} key={"approved"}>
                    Approved
                  </MenuItem>
                  <MenuItem value={"declined"} key={"declined"}>
                    Decline
                  </MenuItem>
                </Select>
                {errors.status && (
                  <span className="help-block error text-left">
                    {errors.status}
                  </span>
                )}
              </FormControl>
            </Box>
            <div className="note_style">
              <div>
              <p>
                Note: 
                <p className='second_note'>- The final number will be considered as approved and delivered.</p>
                </p>
                <p className='second_note'>
                  - Please refresh the page to view the latest count of opt-ins, and then proceed with your submission.
                </p>
              </div>
       
              <p className='refresh-icon' onClick={handleRefresh}>
              <CachedIcon /> Refresh
            </p>

  
</div>


          </DialogContent>
          <DialogActions>
            <div className="foot-modal-btns">
              <Button
                onClick={handleClose}
                color="secondary"
                variant="contained"
              >
                Close
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={onSubmit}
                disabled={isLoading || !isValidByTime || !valid}
              >

                {isLoading ? (
                  <CircularProgress size="20px" color="white" />
                ) : (
                  <>Submit</>
                )}
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    </>
  )
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)
