import {
    getTuckShopOrdersListAPI,
    getEmployeeNameListAPI,
    exportTuckShopOrdersListAPI
  } from './apis'
  import {
    GET_TUCKSHOP_ORDERS,
    GET_TUCKSHOP_ORDERS_SUCCEEDED,
    GET_TUCKSHOP_ORDERS_FAILED,
    GET_EMPLOYEE,
    GET_EMPLOYEE_SUCCEEDED,
    GET_EMPLOYEE_FAILED,
    GET_TUCKSHOP_EXPORT_ORDERS,
    GET_TUCKSHOP_EXPORT_SUCCEEDED,
    GET_TUCKSHOP_EXPORT_FAILED
  } from './reducer'
  
  export function getTuckShopOrdersList(payload) {
    return dispatch => {
  
      dispatch({
        type: GET_TUCKSHOP_ORDERS
      })
  
      return new Promise((resolve, reject) => {
        getTuckShopOrdersListAPI(payload)
          .then(({ data }) => { 
            dispatch({
              type: GET_TUCKSHOP_ORDERS_SUCCEEDED,
              payload: data
            })
            return resolve(data)
          })
          .catch(err => {
  
            dispatch({
              type: GET_TUCKSHOP_ORDERS_FAILED
            })
            return reject(err)
          })
      })
    }
  }
  

  export function getEmployeeList(){
      return dispatch => {
  
      dispatch({
        type: GET_EMPLOYEE
      })
  
      return new Promise((resolve, reject) => {
        getEmployeeNameListAPI()
          .then(({ data }) => {
            dispatch({
              type: GET_EMPLOYEE_SUCCEEDED,
              payload: data
            })
            return resolve(data)
          })
          .catch(err => {
  
            dispatch({
              type: GET_EMPLOYEE_FAILED
            })
            return reject(err)
          })
      })
    }  
  }


  export function exportTuckShopOrdersList(payload){
    return dispatch => {

    dispatch({
      type: GET_TUCKSHOP_EXPORT_ORDERS
    })

    return new Promise((resolve, reject) => {
      exportTuckShopOrdersListAPI(payload)
        .then(({ data }) => {
          dispatch({
            type: GET_TUCKSHOP_EXPORT_SUCCEEDED,
            payload: data
          })
          return resolve(data)
        })
        .catch(err => {

          dispatch({
            type: GET_TUCKSHOP_EXPORT_FAILED
          })
          return reject(err)
        })
    })
  }  
}
  