
import { saveObject } from '../../utils';
import { actionsTypes } from './actionsTypes'

const initalState = {
  isLoading: false,
  user: {}
}

export default function (state = { ...initalState }, action) {
  switch (action.type) {

    case actionsTypes.LOGIN_REQUEST:
      return { ...state, isLoading: true }

    case actionsTypes.LOGIN_REQUEST_SUCCEEDED:
      saveObject("bb-user-token", JSON.stringify(action.payload.accessToken))
      saveObject("bb-user", JSON.stringify(action.payload))
      return {
        ...state,
        isLoading: false,
        user: action.payload
      }

    case actionsTypes.LOGIN_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false
      }

    case actionsTypes.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true
      }

    case actionsTypes.FORGOT_PASSWORD_SUCCEEDED:
      return {
        ...state,
        isLoading: false
      }

    case actionsTypes.FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        isLoading: false
      }

    case actionsTypes.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true
      }

    case actionsTypes.RESET_PASSWORD_SUCCEEDED:
      return {
        ...state,
        isLoading: false
      }

    case actionsTypes.RESET_PASSWORD_FAILED:
      return {
        ...state,
        isLoading: false
      }



    default:
      return state
  }
}