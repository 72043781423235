import { apiPost, apiGet, apiDelete } from '../../utils/axios'

export function getSupplyListAPI(data) {
  const payload = {}
  payload.skip = data.skip
  payload.limit = data.limit

  if (data.startDate) {
    payload.startDate = data.startDate
  }

  if (data.endDate) {
    payload.endDate = data.endDate
  }

  if (data.keyword) {
    payload.keyword = data.keyword
  }
  if(data.status){
      payload.status=data.status
  }

  return apiGet(`/api/company/dailySupply`, payload)
}

export function updateDailySupplyAPI(data) {
    const payload = {}
    if(data.user_id){
        payload.user_id =data.user_id;
    }
    if(data.orderId){
        payload.orderId=data.orderId;
    }
    if(data.status){
        payload.status=data.status;
    }
    payload.comments=data.comments;
  return apiPost('/api/company/updateDailySupply', payload)
}