import React from "react"
import Pagination from '@material-ui/lab/Pagination'
import Grid from "@material-ui/core/Grid"

export default function CustomTablePagination(props) {
  const {
    count = 0,
    total = 0,
    onPageChange = () => { },
    page = 1,
    limit
  } = props;
  const totalPages = Math.ceil(Number(total) / limit)

  let ofStart = count

  if (ofStart === 0) {
    ofStart = 1
  }

  return (
    <Grid container className="custom-table-pagination">
      <div className="pagination-div">
        {count ? <div className="showing-items">
          <>
            <p>Showing {`${((page - 1) * limit + 1)} - ${((page - 1) * limit + count)} of ${total}`}</p>
          </>
        </div> : <div></div>}
        <div className="table-pagination">
          <Pagination
            count={totalPages}
            showFirstButton
            showLastButton
            page={page}
            onChange={onPageChange}
          />
        </div>
      </div>
    </Grid>
  )
}