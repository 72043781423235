import {
  getEmployeesListAPI,
  createEmployeeAPI,
  deleteEmployeeAPI,
  updateEmployeeAPI,
  getEmployeeDetailAPI,
  addWalletDataAPI,
  deleteAllEmployeeAPI,
  addCsvDataAPI
} from './apis'
import {
  GET_EMPLOYEE_LIST_REQUEST,
  GET_EMPLOYEE_LIST_REQUEST_SUCCEEDED,
  GET_EMPLOYEE_LIST_REQUEST_FAILED,
  CREATE_EMPLOYEE_REQEUST,
  CREATE_EMPLOYEE_REQEUST_SUCCEEDED,
  CREATE_EMPLOYEE_REQEUST_FAILED,
  EMPLOYEE_UPDATED,
  ADD_WALLET,
  ADD_WALLET_SUCCEEDED,
  ADD_WALLET_FAILED,
  ADD_CSV,
  ADD_CSV_SUCCEEDED,
  ADD_CSV_FAILED,
} from './reducer'

export function getEmployeesList(data) {
  return dispatch => {

    dispatch({
      type: GET_EMPLOYEE_LIST_REQUEST
    })

    return new Promise((resolve, reject) => {
      getEmployeesListAPI(data)
        .then(({ data }) => {
          dispatch({
            type: GET_EMPLOYEE_LIST_REQUEST_SUCCEEDED,
            payload: data
          })
          return resolve(data)
        })
        .catch(err => {

          dispatch({
            type: GET_EMPLOYEE_LIST_REQUEST_FAILED
          })
          return reject(err)
        })
    })
  }
}

export function createEmployee(data) {
  return dispatch => {

    dispatch({
      type: CREATE_EMPLOYEE_REQEUST
    })

    return new Promise((resolve, reject) => {
      createEmployeeAPI(data)
        .then(({ data }) => {

          dispatch({
            type: CREATE_EMPLOYEE_REQEUST_SUCCEEDED,
            payload: data
          })
          return resolve(data)
        })
        .catch(err => {

          dispatch({
            type: CREATE_EMPLOYEE_REQEUST_FAILED
          })
          return reject(err)
        })
    })
  }
}

export function deleteEmployee(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      deleteEmployeeAPI(data)
        .then(({ data }) => {
          return resolve(data)
        })
        .catch(err => {
          return reject(err)
        })
    })
  }
}

export function deleteAllEmployee(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      deleteAllEmployeeAPI(data)
        .then(({ data }) => {
          return resolve(data)
        })
        .catch(err => {
          return reject(err)
        })
    })
  }
}

export function updateEmployee(payload) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      updateEmployeeAPI(payload)
        .then(() => {

          getEmployeeDetailAPI(payload._id)
            .then(({ data }) => {
              dispatch({
                type: EMPLOYEE_UPDATED,
                payload: data
              })
              return resolve(data)
            })
        })
        .catch(err => {
          return reject(err)
        })
    })
  }
}

export function getEmployeeDetail(id) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      getEmployeeDetailAPI(id)
        .then(({ data }) => {
          return resolve(data)
        })
        .catch(err => {
          return reject(err)
        })
    })
  }
}
export function addWalletData(data) {
  return dispatch => {
    dispatch({
      type: ADD_WALLET
    })
    return new Promise((resolve, reject) => {
      addWalletDataAPI(data)
        .then(({ data }) => {

          dispatch({
            type: ADD_WALLET_SUCCEEDED,
            payload: data
          })
          return resolve(data)
        })
        .catch(err => {
          dispatch({
            type: ADD_WALLET_FAILED
          })
          return reject(err)
        })
    })
  }
}
export function addCsvData(data) {
  return dispatch => {
    dispatch({
      type: ADD_CSV
    })
    return new Promise((resolve, reject) => {
      addCsvDataAPI(data)
        .then(({ data }) => {
          console.log(data, "data")
          dispatch({
            type: ADD_CSV_SUCCEEDED,
            payload: data
          })
          return resolve(data)
        })
        .catch(err => {
          console.log(err, "err")
          dispatch({
            type: ADD_CSV_FAILED
          })
          return reject(err)
        })
    })
  }
}