import React, { useEffect, useState } from 'react'
import {
  Grid,
  // TableContainer,
  // Table,
  // TableRow,
  // TableCell,
  // TableBody,
  Button,
  Box,
  // IconButton,
  Tab,
  Tabs,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
// import EnhancedTableHead from "../../components/common/EnhancedTableHead";
// import CustomTablePagination from "../../components/common/CustomPagination";
import { withTranslation } from 'react-i18next'
import { tablestyle } from '../../plugins/theme'
import { useDispatch, useSelector } from 'react-redux'
// import TableLoader from "../../components/common/TableLoader";
// import MoreVertIcon from "@material-ui/icons/MoreVert";
// import BeeMenu from "../Common/BeeMenu";
import AlertDialog from '../Common/AlertDialog'
import ReceiptIcon from '@material-ui/icons/Receipt'
import * as action from './actions'
import { getObject } from '../../utils'
import CustomModal from '../../components/common/CustomModal'
import UpdateInvoiceForm from './UpdateInvoiceForm'
import CustomSelect from '../../components/common/CustomSelect'
import { monthForInvoice } from '../../constant'
import { getMonthYear } from '../../utils'
import moment from 'moment'
import TabPanel from './TabPanel'

import { apiUrl } from '../../constant'

function MakeYearForInvoice() {
  let minYear = 2020
  let currentYear = moment().year()
  let currentMonth = moment().month()

  let yearForInvoice = []
  let i = 0
  for (minYear; currentYear >= minYear; minYear++) {
    yearForInvoice.push({
      value: minYear,
      label: minYear,
    })
    //   i++;
  }
  return { yearForInvoice }
}

let searchTypeInterval = null

function TotalInvoiceData(props) {
  const { value, label } = props
  return (
    <div className="total-meal-data">
      <span>{label}</span>
      <label>{value}</label>
    </div>
  )
}

function Invoices(props) {
  const { classes, toast, loginUserType, loginUserPermission } = props
  let showinvoices, showupdateInvoice, showdownloadInvoice
  showinvoices = showupdateInvoice = showdownloadInvoice = true
  showinvoices =
    loginUserType == 'user' && loginUserPermission.invoices
      ? loginUserPermission.invoices.invoices
      : showinvoices
  showupdateInvoice =
    loginUserType == 'user' && loginUserPermission.invoices
      ? loginUserPermission.invoices.updateInvoice
      : showupdateInvoice
  showdownloadInvoice =
    loginUserType == 'user' && loginUserPermission.invoices
      ? loginUserPermission.invoices.downloadInvoice
      : showdownloadInvoice

  const dispatch = useDispatch()
  const { isLoading = false, invoice = {} } =
    useSelector((state) => state.invoice) || {}
  const {
    data: employees = [],
    count,
    ALL,
    NEW,
    PAID,
    PROCESSED,
    HOLD,
  } = invoice
  const [openUpdateModal, setOpenUpdateModal] = useState(false)
  const [invoiceId, setInvoiceId] = useState('')
  const [invoiceDownloadLoading, setInvoiceDownloadLoading] = useState('')
  const [isEdit, setIsEdit] = useState(false)
  const [limit, setLimit] = useState(8)
  const [skip, setSkip] = useState(0)
  const [page, setPage] = useState(1)
  const [invoiceType, setinvoiceType] = useState('ALL')
  const [startDate, setStartDate] = useState(0)
  const [endDate, setEndDate] = useState(0)

  const { yearForInvoice } = MakeYearForInvoice()

  const currentYear = moment().year()
  const [yearDate, setyearDate] = useState({
    value: currentYear,
    label: currentYear,
  })
  const [loaderRowId, setLoaderRowId] = useState('')
  const [invoiceDetails, setInvoiceDetails] = useState({})
  const currentMonth = moment().month()
  //  const [filter, setFilter] = useState({
  //    value: (moment().month()+1),
  //    label: "January",
  //  });
  const [filter, setFilter] = useState(monthForInvoice[currentMonth])

  function handleMonthChange(e) {
    setFilter(e)
    const { startDate: newStartDate, endDate: newEndDate } = getMonthYear(
      e.value,
      yearDate.value,
    )

    setEndDate(newEndDate)
    setStartDate(newStartDate)
  }

  function handleYearChange(e) {
    setyearDate(e)
    const { startDate: newStartDate, endDate: newEndDate } = getMonthYear(
      filter.value,
      e.value,
    )
    setEndDate(moment(newEndDate))
    setStartDate(moment(newStartDate))
  }

  const object = getObject('bb-user')
  let { accessToken = '' } = (object && JSON.parse(object)) || ''

  useEffect(() => {
    if (invoiceType == 'ALL') {
      setTabValue(0)
    } else if (invoiceType == 'NEW') {
      setTabValue(1)
    } else if (invoiceType == 'PAID') {
      setTabValue(2)
    } else if (invoiceType == 'HOLD') {
      setTabValue(3)
    } else if (invoiceType == 'PROCESSED') {
      setTabValue(4)
    }
    getInvoice()
  }, [limit, skip, startDate, endDate, invoiceType])

  function getInvoice(search = '', nextPage) {
    if (!nextPage) {
      nextPage = page
    }
    setPage(nextPage)
    let skipToSend = (nextPage - 1) * limit
    let data = {}
    if (startDate == 0 && endDate == 0) {
      let newstartDate = moment([yearDate.value, filter.value - 1])
      let newendDate = moment(newstartDate).endOf('month')
      data = {
        limit,
        skip: skipToSend,
        startDate: moment(newstartDate).valueOf(),
        endDate: moment(newendDate).valueOf(),
        status: invoiceType,
      }
    } else {
      data = {
        limit,
        skip: skipToSend,
        startDate: moment(startDate).valueOf(),
        endDate: moment(endDate).valueOf(),
        status: invoiceType,
      }
    }
    if (showinvoices == true) {
      dispatch(action.getInvoiceList(data))
    }
  }

  function handlePageChange(e, nextPage) {
    e.preventDefault()
    if (nextPage === page) {
      return
    }
    setPage(nextPage)
    setSkip((nextPage - 1) * limit)
  }

  const handleUpdateModal = (employee) => {
    const { _id } = employee
    setOpenUpdateModal(true)
    setInvoiceId(_id)
    setInvoiceDetails(employee)
    setIsEdit(true)
  }

  const closeUpdateModal = () => {
    setOpenUpdateModal(false)
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }
  function getInvoiceData(invoice) {
    setinvoiceType(invoice)

    setLimit(8)
    setPage(1)
    setSkip(0)
  }
  const [tabValue, setTabValue] = React.useState(0)
  const handleTabChange = (event, newValue) => {
    if (
      newValue == 1 ||
      newValue == 2 ||
      newValue == 3 ||
      newValue == 0 ||
      newValue == 4
    ) {
      setTabValue(newValue)
      let invoice =
        newValue == 0
          ? 'ALL'
          : newValue == 1
          ? 'NEW'
          : newValue == 2
          ? 'PAID'
          : newValue == 3
          ? 'HOLD'
          : newValue == 4
          ? 'PROCESSED'
          : ''
      getInvoiceData(invoice)
    }
  }
  const handleInvoiceDownload = (id, invoiceNumber) => {
    setInvoiceDownloadLoading('LOAD')
    setLoaderRowId(id)
    let makeUrl = `${apiUrl}/api/company/getInvoicePdf?invoiceId=${id}`
    fetch(makeUrl, {
      //    fetch(`http://bitesbee-partner-backend.s2.ths.agency/api/company/getInvoicePdf?invoiceId=${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {

        if(response.status != 200){
          throw "Something went wrong";
        }
        response
          .blob()
          .then((blob) => {

            let url = window.URL.createObjectURL(blob)
            let a = document.createElement('a')
            a.href = url
            a.download = `invoice${invoiceNumber}.pdf`
            a.click()
            setInvoiceDownloadLoading('DONE')
            setTimeout(() => {
              setInvoiceDownloadLoading('')
              setLoaderRowId('')
            }, 4000)
          })
          .catch((message) => {

            toast.error(message || 'Oops! Something went wrong')
            setInvoiceDownloadLoading('ERR')
            setTimeout(() => {
              setInvoiceDownloadLoading('')
              setLoaderRowId('')
            }, 4000)
          })
          
      })
      .catch((message) => {
        toast.error(message || 'Oops! Something went wrong')
        setInvoiceDownloadLoading('ERR')
        setTimeout(() => {
          setInvoiceDownloadLoading('')
          setLoaderRowId('')
        }, 4000)
      })
  }



  if (showinvoices == true) {
    return (
      <Grid container className="location-container">
        <Grid item xs={12}>
          <Grid container className="location-head">
            <Grid item xs={12} sm={12} md={4} lg={4} className="heading">
              <h2>
                <ReceiptIcon />
                Invoices
              </h2>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <Box>
                  <CustomSelect
                    minWidth={180}
                    options={monthForInvoice}
                    placeholder="Filter"
                    isSearchable
                    onChange={(opt) => handleMonthChange(opt)}
                    value={filter}
                  />
                </Box>
                <Box pl={1}>
                  <CustomSelect
                    minWidth={180}
                    options={yearForInvoice}
                    placeholder="Filter"
                    isSearchable
                    onChange={(opt) => handleYearChange(opt)}
                    value={yearDate}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} className="">
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label=""
            className="cus-tabs-design"
          >
            <Tab
              icon={<TotalInvoiceData value={ALL} label="All Invoice" />}
              {...a11yProps(0)}
              className="tab-allinvoice"
            ></Tab>
            <Tab
              icon={<TotalInvoiceData value={NEW} label="New Invoice" />}
              {...a11yProps(1)}
              className="tab-newinvoice"
            ></Tab>
            <Tab
              icon={<TotalInvoiceData value={PAID} label="Paid" />}
              {...a11yProps(2)}
              className="tab-paidinvoice"
            ></Tab>
            <Tab
              icon={<TotalInvoiceData value={HOLD} label="Hold Invoice" />}
              {...a11yProps(3)}
              className="tab-holdinoice"
            ></Tab>
            <Tab
              icon={
                <TotalInvoiceData value={PROCESSED} label="Processed Invoice" />
              }
              {...a11yProps(4)}
              className="tab-processedInvoice"
            ></Tab>
          </Tabs>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} className="">
          <TabPanel
            employees={employees}
            value={tabValue}
            index={tabValue}
            toast={toast}
            isLoading={isLoading}
            classes={classes}
            limit={limit}
            page={page}
            count={count}
            setPage={setPage}
            setSkip={setSkip}
            showupdateInvoice={showupdateInvoice}
            showdownloadInvoice={showdownloadInvoice}
            handleUpdateModal={handleUpdateModal}
            loaderRowId={loaderRowId}
            invoiceDownloadLoading={invoiceDownloadLoading}
            handleInvoiceDownload={handleInvoiceDownload}
          ></TabPanel>

          <CustomModal open={openUpdateModal} maxWidth="lg">
            <UpdateInvoiceForm
              {...props}
              onClose={closeUpdateModal}
              invoiceId={invoiceId}
              toast={toast}
              update={isEdit}
              invoiceDetails={invoiceDetails}
              getInvoice={getInvoice}
            />
          </CustomModal>
          <AlertDialog
            title="Confirm Delete"
            content="Are you sure you want to delete this employee?"
            open={false}
            actions={
              <Box display="flex" justifyContent="flex-end">
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => []}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  autoFocus
                  style={{ marginLeft: 8 }}
                  onClick={() => []}
                >
                  Confirm
                </Button>
              </Box>
            }
          />
        </Grid>
      </Grid>
    )
  } else {
    return (
      <Grid container className="location-container">
        <Grid item xs={12}>
          <Grid container className="location-head">
            <Grid item xs={12} sm={12} md={4} lg={4} className="heading">
              <h2>
                <ReceiptIcon />
                Invoices
              </h2>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Box display="flex" justifyContent="space-between" p={2}>
            <h6>You do not have permission to view this page</h6>
          </Box>
        </Grid>
      </Grid>
    )
  }
}

export default withTranslation('translations')(withStyles(tablestyle)(Invoices))
