import { apiPost, apiGet, apiDelete, downloadFile } from '../../utils/axios'

export function getTuckShopOrdersListAPI(data) {
  const payload = {}
  payload.page = (data.skip/8)+1;
  payload.limit = data.limit

  if (data.startDate) {
    payload.startDate = data.startDate
  }

  if (data.endDate) {
    payload.endDate = data.endDate
  }

  if (data.keyword) {
    payload.keyword = data.keyword
  }
  
  if(data.employeeId){
      payload.employeeId=data.employeeId
  }

  return apiGet(`/api/company/getTuckshopOrders`, payload)
}

export function getEmployeeNameListAPI(){
    return apiGet("/api/company/getEmployeeNameList")
}

export function exportTuckShopOrdersListAPI(data) {
  const payload = {};

  if (data?.startDate) {
    payload.startDate = data.startDate
  }

  if (data?.endDate) {
    payload.endDate = data.endDate
  }
  
  if(data?.employeeId){
      payload.employeeId=data.employeeId
  }

  if(data?.keyword) {
    payload.keyword = data.keyword;
  }

  let fileName = `TuckShopOrders.csv`;
  return downloadFile(`/api/company/exportTuckshopOrders`, payload,'csv', fileName);
}

