import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

export default function BeeMenu({ actionTitle, actions = [] }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function onClickMenu(cb = () => []) {
    setAnchorEl(null);
    cb()
  }

  if (!actions.length) {
    return null
  }

  return (
    <div>
      <span aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        {actionTitle}
      </span>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {actions && actions.length ? actions.map((action, key) => <MenuItem onClick={() => onClickMenu(action.action)} key={key}>{action.label}</MenuItem>) : null}
      </Menu>
    </div>
  );
}