import { apiPost, apiGet, apiDelete } from '../../utils/axios'

export function getUsersListAPI(data) {
  const payload = {}
  payload.skip = data.skip
  payload.limit = data.limit

  if (data.startDate) {
    payload.startDate = data.startDate
  }

  if (data.endDate) {
    payload.endDate = data.endDate
  }

  if (data.keyword) {
    payload.keyword = data.keyword
  }

  return apiGet(`/api/company/getUser`, payload)
}

export function updateUserStatusAPI(data) {
  return apiPost('/api/company/updateUser', data)
}

export function DeleteUserAPI(email) {
  return apiPost(`/api/company/deleteUser/${email}`)
}

export function createUserAPI(data) {

  return apiPost('/api/company/createUser', data)
}
export function editUserAPI(data) {
  if(data.password == ""){
    delete  data['password']; 
  }
  return apiPost('/api/company/updateUser', data)
}
export function getSingleUser(data){
   return apiGet(`/api/company/getUser`, data)
}
export function getUserPermissionAPI(userId){
  return apiGet(`/api/company/getUserPermitions?userId=${userId}`)
}