import React from 'react'
import { Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: '#222222',
        boxShadow: theme.shadows[1],
        fontSize: 13,

    },
}))(Tooltip);

function ToolTip(props) {
    let {
        children,
        title = "",
        placement = "top"
    } = props

    return (
        <LightTooltip className="custom-tooltip" title={title} placement={placement} aria-label="add">
            {children[1] || children}
        </LightTooltip>
    )
}
export default ToolTip