export const GET_MENU = "GET_MENU"
export const GET_MENU_SUCCEEDED = "GET_MENU_SUCCEEDED"
export const GET_MENU_FAILED = "GET_MENU_FAILED"

const initState = {
  isLoading: false,
  menu: []
}

export default function (state = { ...initState }, action) {
  switch (action.type) {
    case GET_MENU:
      return {
        ...state,
        isLoading: true
      }
    case GET_MENU_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        menu: action.payload
      }
    case GET_MENU_FAILED:
      return {
        ...state,
        isLoading: false,
        menu: []
      }
    default:
      return state
  }
}