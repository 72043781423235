import React, { useEffect, useState } from 'react'
import {
  Grid,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Paper,
  Button,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import EnhancedTableHead from '../../components/common/EnhancedTableHead'
import CustomTablePagination from '../../components/common/CustomPagination'
import { withTranslation } from 'react-i18next'
import { getTimeStamps, numberFormat } from '../../utils'
import { tablestyle } from '../../plugins/theme'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import TableLoader from '../../components/common/TableLoader'
import { getTuckShopOrdersList, getEmployeeList, exportTuckShopOrdersList } from './actions'
import FastfoodIcon from '@material-ui/icons/Fastfood'
import moment from 'moment'
import CustomSelect from '../../components/common/CustomSelect'
import { dateFilter } from '../../constant'
import { DateRangePicker } from 'react-dates'
import EventIcon from '@material-ui/icons/Event'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import SearchField from '../../components/common/SearchField'
import ViewTuckShopOrderItems from './ViewProducts'
// import CSVBtn from './CSVBtn'
import GetAppIcon from '@material-ui/icons/GetApp';
import ToolTip from "../../components/common/ToolTip";
import AlertDialog from '../Common/AlertDialog'

const TableHeaders = [
  {
    id: 'orderId',
    numeric: false,
    disablePadding: false,
    label: 'Order Id',
  },
  {
    id: 'employee_id',
    numeric: false,
    disablePadding: false,
    label: 'Employee ID',
  },
  {
    id: 'employee.name',
    numeric: false,
    disablePadding: false,
    label: 'Employee Name',
  },
  {
    id: 'items',
    numeric: false,
    disablePadding: false,
    label: 'Items',
  },
  {
    id: 'orderAmount',
    numeric: false,
    disablePadding: false,
    label: 'Order Amount',
  },
  {
    id: 'paymentMethod',
    numeric: false,
    disablePadding: false,
    label: 'Payment Method',
  },
  { id: 'time', numeric: false, disablePadding: false, label: 'Date' }
]

let searchTypeInterval = null
let employeeSelectedInterval = null

function getOrdersData(state) {
  const orders = state?.tuckshop?.orders || {}
  const employees = state?.tuckshop?.employees?.employees || {}
  return {
    orders: orders.orders || [],
    count: orders.total || 0,
    amount: orders.totalOrderAmount || 0,
    // employeeShare: orders.totalOrderAmount ? orders.totalOrderAmount/2 : 0,
    employeesNameList: employees || [],
  }
}

function TuckShopOrders(props) {
  const { classes, toast, loginUserType, loginUserPermission, isTuckShopEnabled } = props;
  const dispatch = useDispatch()
  const isLoading = useSelector((state) => state.tuckshop.isLoading || false)
  const {
    orders = [],
    count,
    amount,
    employeesNameList,
  } = useSelector(getOrdersData)

  const [limit, setLimit] = useState(8)
  const [skip, setSkip] = useState(0)
  const [page, setPage] = useState(1)
  const [searchEmployee, setsearchEmployee] = useState(0)
  const [filter, setFilter] = useState({ value: 'last30Days', label: 'Last 30 Days' })
  const [startDate, setStartDate] = useState(0)
  const [endDate, setEndDate] = useState(0)
  const [focusedInput, setFocusedInput] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [currentSelectedUser, setcurrentSelectedUser] = useState('')
  const [getEmployeeParamDetect, setgetEmployeeParamDetect] = useState('');
  const [openExportConfirm, setOpenExportConfirm] = useState(false);
  const [isFirstNavigation, setFirstNavigation] = useState(false);

  let urlParams = props.location.search;
  let getUrlParams = new URLSearchParams(urlParams)
  let urlEmployeeId = getUrlParams.get('employee_id');

  let QueryData = {}

  useEffect(() => {
    if (!startDate || !endDate) return
    if(urlEmployeeId && !searchEmployee) return;
    getOrders()
  }, [skip, limit, endDate, searchEmployee])

  useEffect(() => {
    if (filter?.value) {
      const { startDate: newStartDate, endDate: newEndDate } = getTimeStamps(
        filter.value,
        startDate,
        endDate,
      )
      if (filter.value !== 'customDate') {
        setEndDate(moment(newEndDate))
        setStartDate(moment(newStartDate))
      }
    }
  }, [filter])

  useEffect(() => {
    getAllEmployees()
  }, [])

  function handlePageChange(e, nextPage) {
    e.preventDefault()
    if (nextPage === page) {
      return
    }
    setPage(nextPage)
    setSkip((nextPage - 1) * limit)
  }

  function getAllEmployees() {
    if (isTuckShopEnabled == true) {
      dispatch(getEmployeeList())
    }
  }

  function getOrders(search = '', isSearching = false) {
    let skipValue = (skip/8)+1 > page ? (page*8 - 1) : skip;
    QueryData = {
      limit,
      skip: skipValue,
      startDate: moment(startDate).valueOf(),
      endDate: moment(endDate).valueOf(),
      keyword: isSearching ? search : keyword,
      employeeId: searchEmployee ? searchEmployee : '',
    }
    if (isTuckShopEnabled == true) {
      const {payload } = dispatch(getTuckShopOrdersList(QueryData));
    }
  }

  function handleChangeSearch({ target: { value = '' } }) {
    setKeyword(value)

    if (searchTypeInterval) {
      clearInterval(searchTypeInterval)
    }
    searchTypeInterval = setTimeout(() => {
      setPage(1)
      setLimit(8)
      setSkip(0)
      getOrders(value, true)
    }, 500)
  }

  function handleDateChange({ startDate, endDate }) {
    if (startDate) {
      setStartDate(startDate)
    }

    if (endDate) {
      setEndDate(endDate)
    }
  }

  function handleEmployeeChange(e) {
    let employeeId = e.value
    let employee = e.label
    let employeeName = e.name
    setcurrentSelectedUser(employeeId)
    if (employee) {
      setgetEmployeeParamDetect(employeeName)
    } else {
      setgetEmployeeParamDetect('changed')
    }
    if (employeeSelectedInterval) {
      clearTimeout(employeeSelectedInterval)
      employeeSelectedInterval = null
    }
    employeeSelectedInterval = setTimeout(() => {
      setPage(1)
      setLimit(8)
      setSkip(0)
      setsearchEmployee(employeeId)
    }, 500)
  }
  function resetEmployeeFilter() {
    setgetEmployeeParamDetect('changed')
    setcurrentSelectedUser('')
    setsearchEmployee('')
  }
  const employeesSelectOptions = employeesNameList

  function getEmployeeParam() {
    let params = props.location.search
    let getParams = new URLSearchParams(params)
    let term = getParams.get('employee_id')
    let getEmployee = getParams.get('employee')
    if (term && getEmployee) {
      getParams.delete('employee_id')
      getParams.delete('employee')
      setcurrentSelectedUser(term)
      setgetEmployeeParamDetect(getEmployee)
      setsearchEmployee(term)
    } else {
      setgetEmployeeParamDetect('changed')
    }
  }
  if (!getEmployeeParamDetect && employeesSelectOptions.length) {
    getEmployeeParam()
  }

  function onExport() {
    setOpenExportConfirm(true)
  }

  function onCancelExport() {
    setOpenExportConfirm(false)
  }

  function onFilterChange(Opt) {
    setFilter(Opt)
    setPage(1) 
  }


  const exportToCsv = e => {
    e.preventDefault();
    QueryData = {
      ... startDate ? {startDate: moment(startDate).valueOf()} : {},
      ... endDate ? {endDate: moment(endDate).valueOf()} : {},
      ... currentSelectedUser ? {employeeId: currentSelectedUser} : {},
      ... keyword ? {keyword : keyword} : {}
    }
    dispatch(exportTuckShopOrdersList(QueryData))
    .then(() => {
      toast.success('File has been downloaded successfully');
      setOpenExportConfirm(false)
    })
    .catch(({ message = '' }) => {
      toast.error('Oops! Something went wrong')
    })
  };
  if (isTuckShopEnabled == true) {
    return (
      <Grid container className="location-container">
        <Grid item xs={12}>
          <Grid container className="location-head">
            <Grid item xs={12} sm={12} md={4} lg={4} className="heading">
              <h2>
                <FastfoodIcon />
                Tuck Shop Orders
              </h2>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <Box pr={1} className="select-emp-sec">
                  {employeesNameList.length ? (
                    <>
                      <CustomSelect
                        id="employee_list_selectBox"
                        className="emp-change-select"
                        options={employeesSelectOptions}
                        label="Employees"
                        isSearchable
                        minWidth={180}
                        onChange={handleEmployeeChange}
                        value={employeesSelectOptions.filter(
                          (employeesSelectOptions) =>
                            employeesSelectOptions.value ===
                            currentSelectedUser,
                        )}
                      />
                      <Button
                        className="button-btn btn-custom-primry"
                        onClick={resetEmployeeFilter}
                      >
                        Reset Record
                      </Button>
                    </>
                  ) : null}
                </Box>
                <Box pr={1}>
                  <SearchField
                    placeholder="Search"
                    value={keyword}
                    onChange={handleChangeSearch}
                  />
                </Box>
                <CustomSelect
                  minWidth={180}
                  options={dateFilter}
                  placeholder="Filter"
                  isSearchable
                  onChange={(opt) => onFilterChange(opt)}
                  value={filter}
                />
                {filter?.value !== 'customDate' ? (
                  ''
                ) : (
                  <div className="date-range-picker">
                    <Box pl={1}>
                      <DateRangePicker
                        // disabled={filter?.value !== "customDate"}
                        startDate={startDate}
                        startDateId="startDate"
                        startDatePlaceholderText="From..."
                        endDatePlaceholderText="To..."
                        minimumNights={0}
                        customArrowIcon={
                          <p className="start-end-date-divider">|</p>
                        }
                        endDate={endDate}
                        endDateId="endDate"
                        customInputIcon={
                          <EventIcon className="date-picker-icon" />
                        }
                        isOutsideRange={(date) =>
                          date.isBefore(moment().year()) ||
                          date.isAfter(moment())
                        }
                        onDatesChange={handleDateChange}
                        focusedInput={focusedInput}
                        onFocusChange={(focusedInput) =>
                          setFocusedInput(focusedInput)
                        }
                      ></DateRangePicker>
                    </Box>
                  </div>
                )}
                <Box item margin="10px;" >
                <ToolTip title="Export to csv file">
                  <Button color="secondary" variant="contained" className='download-btn' onClick={onExport}>
                              <GetAppIcon />
                          </Button> 
                          </ToolTip>
                  </Box>
                
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {getEmployeeParamDetect != 'changed' ? (
          <Grid item xs={12}>
            <Box mt={4} className="showing-record-name">
              <h5>
                Showing tuck shop order of <span>{getEmployeeParamDetect}</span>
              </h5>
            </Box>
          </Grid>
        ) : (
          ''
        )}
        <Grid item xs={12}>
          <Box mt={4}>
            <Paper rounded className="box-shadow">
              <Box display="flex" justifyContent="space-between" p={2}>
                <Box>
                  <div className="dashboard-card-connent">
                    <h5 style={{ paddingBottom: 8 }}>Total Orders</h5>
                    <h6>{count}</h6>
                  </div>
                </Box>
                <Box>
                  <div className="dashboard-card-connent">
                    <h5 style={{ paddingBottom: 8 }}>Total Employee Share</h5>
                    <h6> &#8377;{numberFormat(amount)}</h6>
                  </div>
                </Box>
                <Box>
                  <div className="dashboard-card-connent">
                    <h5 style={{ paddingBottom: 8, textAlign: 'right' }}>
                      Total Company Share
                    </h5>
                    <h6>&#8377;{numberFormat(amount)}</h6>
                  </div>
                </Box>
              </Box>
            </Paper>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} className="">
          <div id="no-more-tables" className="card-design table-design cf">
            {isLoading ? (
              <>
                <TableContainer className={classes.container}>
                  <Table
                    className="table-program"
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <EnhancedTableHead headCells={TableHeaders} />
                  </Table>
                </TableContainer>
                <TableLoader />
              </>
            ) : null}
            {!isLoading ? (
              <TableContainer className={classes.container}>
                <Table
                  className="table-program"
                  stickyHeader
                  aria-label="sticky table"
                >
                  <EnhancedTableHead headCells={TableHeaders} />
                  <TableBody>
                    {orders && orders.length ? (
                      orders.map((order) => {
                        const {
                          _id : Id,
                          orderRef : orderRefId,
                          order_amount : orderAmount,
                          employee,
                          cart,
                          created_at : createdAt,
                          payment_method : paymentMethod
                        } = order
                      const {employeeId, name : employeeName} = employee;
                      const { items, total : billingDetails } = cart;
                      let ItemNames = (items.map(value => value.product.name)).join(', ');
                      ItemNames = ItemNames.length > 18 ? ItemNames.substring(0,19)+'...' : ItemNames;
                      // const employeShareAmount = orderAmount ? orderAmount/2 : 0;
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            className="cursor_default"
                            key={Id}
                          >
                            {/* <TableCell className="table-custom-width">{orderNumber || '-'}</TableCell> */}
                            <TableCell className="table-custom-width">
                              <Box>
                                <span>{orderRefId}</span>
                              </Box>
                            </TableCell>
                            {/* <TableCell className="table-custom-width">{orderItem || '-'}</TableCell> */}
                            <TableCell className="table-custom-width">
                              <Box>
                                <span>{employeeId}</span>
                              </Box>
                            </TableCell>
                            <TableCell className="table-custom-width">
                              <Box>
                                <span>{employeeName}</span>
                              </Box>
                            </TableCell>
                            <TableCell className="table-custom-width">
                              <Box>
                                <span>
                                  {ItemNames}
                                  <ViewTuckShopOrderItems numberFormat={numberFormat} orderItems={items} orderRefId={orderRefId} billingDetails={billingDetails} actionLabel="View" />
                                </span>
                                
                              </Box>
                            </TableCell>
                            <TableCell className="table-custom-width">
                              &#8377;{numberFormat(orderAmount)}
                            </TableCell>
                            <TableCell className="table-custom-width">
                              {paymentMethod ? (paymentMethod).toUpperCase() : paymentMethod}
                            </TableCell>
                            <TableCell className="table-custom-width">
                              {createdAt
                                ? moment(createdAt).format('MMM DD, YYYY hh:mm A')
                                : '--:--'}
                            </TableCell>
                          </TableRow>
                        )
                      })
                    ) : (
                      <TableRow
                        hover
                        role="checkbox"
                        className="cursor_default"
                      >
                        <TableCell colSpan={6} className="table-custom-width">
                          <Box textAlign="center" p={1} className="no-data-found">
                            <p>No Order Found</p>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : null}

            <CustomTablePagination
              count={orders.length}
              total={count}
              limit={limit}
              page={page}
              onPageChange={handlePageChange}
            />
          </div>
          <AlertDialog
            title="Confirm Download"
            content="Are you sure you want to export this data?"
            open={openExportConfirm}
            actions={
              <div className="foot-modal-btns">
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={onCancelExport}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  autoFocus
                  style={{ marginLeft: 8 }}
                  onClick={exportToCsv}
                >
                  Confirm
                </Button>
              </div>
            }
          />
        </Grid>
      </Grid>
    )
  } else {
    return (
      <Grid container className="location-container">
        <Grid item xs={12}>
          <Grid container className="location-head">
            <Grid item xs={12} sm={12} md={4} lg={4} className="heading">
              <h2>
                <FastfoodIcon />
                Tuck Shop Orders
              </h2>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Box display="flex" justifyContent="space-between" p={2}>
            <h6>You do not have permission to view this page</h6>
          </Box>
        </Grid>
      </Grid>
    )
  }
}

export default withTranslation('translations')(withStyles(tablestyle)(TuckShopOrders))
