export const GET_EMPLOYEE_LIST_REQUEST = 'GET_EMPLOYEE_LIST_REQUEST'
export const GET_EMPLOYEE_LIST_REQUEST_SUCCEEDED = 'GET_EMPLOYEE_LIST_REQUEST_SUCCEEDED'
export const GET_EMPLOYEE_LIST_REQUEST_FAILED = 'GET_EMPLOYEE_LIST_REQUEST_FAILED'

export const CREATE_EMPLOYEE_REQEUST = 'CREATE_EMPLOYEE_REQEUST'
export const CREATE_EMPLOYEE_REQEUST_SUCCEEDED = 'CREATE_EMPLOYEE_REQEUST_SUCCEEDED'
export const CREATE_EMPLOYEE_REQEUST_FAILED = 'CREATE_EMPLOYEE_REQEUST_FAILED'

export const EMPLOYEE_UPDATED = "EMPLOYEE_UPDATED"

export const ADD_WALLET = 'ADD_WALLET'
export const ADD_WALLET_SUCCEEDED = 'ADD_WALLET_SUCCEEDED'
export const ADD_WALLET_FAILED = 'ADD_WALLET_FAILED'

export const ADD_CSV = 'ADD_CSV'
export const ADD_CSV_SUCCEEDED = 'ADD_CSV_SUCCEEDED'
export const ADD_CSV_FAILED = 'ADD_CSV_FAILED'

const initState = {
  isLoading: false,
  employees: {
    data: [],
    count: 0
  },
  employee: {},
  csvfile: {}
}

export default function (state = { ...initState }, action) {
  switch (action.type) {
    case GET_EMPLOYEE_LIST_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case GET_EMPLOYEE_LIST_REQUEST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        employees: action.payload
      }
    case GET_EMPLOYEE_LIST_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        employees: []
      }

    case EMPLOYEE_UPDATED:
      const newList = state?.employees?.data || [];
      const newEmp = action?.payload || {}
      const index = newList?.findIndex(i => i._id === newEmp._id)
      if (index !== -1) {
        newList[index] = newEmp
      }

      return {
        ...state,
        employees: {
          ...state.employees,
          data: newList
        }
      }
    case ADD_WALLET:
      return {
        ...state,
        isLoading: true
      }
    case ADD_WALLET_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
      }
    case ADD_WALLET_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case ADD_CSV:
      return {
        ...state,
        isLoading: true
      }
    case ADD_CSV_SUCCEEDED:
      return {
        ...state,
        isLoading: false, csvfile: action.payload,
      }
    case ADD_CSV_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }


}