
export const GET_WALLET = 'GET_WALLET'
export const GET_WALLET_SUCCEEDED = 'GET_WALLET_SUCCEEDED'
export const GET_WALLET_FAILED = 'GET_WALLET_FAILED'

export const UPDATE_WALLET_DATA = 'UPDATE_WALLET_DATA'
export const UPDATE_WALLET_DATA_SUCCEEDED = 'UPDATE_WALLET_DATA_SUCCEEDED'
export const UPDATE_WALLET_DATA_FAILED = 'UPDATE_WALLET_DATA_FAILED'


const initState = {
  isLoading: false,
    wallets:[],
    walletdata:{},
}

export default function (state = { ...initState }, action) {
  switch (action.type) {

       case GET_WALLET:
      return {
        ...state,
        isLoading: true
      }
    case GET_WALLET_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        wallets: action.payload
      }
    case GET_WALLET_FAILED:
      return {
        ...state,
        isLoading: false,
        wallets: []
      }

    case UPDATE_WALLET_DATA:
      return {
        ...state,
        isLoading: true
      }
    case UPDATE_WALLET_DATA_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        walletdata: action.payload
      }
    case UPDATE_WALLET_DATA_FAILED:
      return {
        ...state,
        isLoading: false,
        walletdata: {}
      }

    default:
      return state
  }
}