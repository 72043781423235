import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { addCsvDataAPI } from '../apis';
import { addCsvData, getEmployeesList } from '../actions';
import { getCompanyDetailsAction } from '../../Settings/actions';
import { isLoggedIn } from '../../../utils';
import PublishIcon from '@material-ui/icons/Publish';
import GetAppIcon from '@material-ui/icons/GetApp';
const usersData =
{
    "users": [
        {
            "name": "joe",
            "employeeId": "3425",
            "employeeEmail": "demo@yopmail.com",
            "mobileNumber": 8563245698,
            "mealType": "VEG",
            "shiftType": "DAY"
        },
        {
            "name": "joe",
            "employeeId": "2355",
            "employeeEmail": "demo@yopmail.com",
            "mobileNumber": 8563245698,
            "mealType": "NON VEG",
            "shiftType": "NIGHT"
        },
        {
            "name": "joe",
            "employeeId": "2367",
            "employeeEmail": "demo@yopmail.com",
            "mobileNumber": 8563245698,
            "mealType": "BOTH",
            "shiftType": "BOTH"
        },
    ]
}

const CSVBtn = (props) => {
    const { actionLabel } = props
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [base64Data, setBase64Data] = useState('');
    const [error, setError] = useState("");

    const dispatch = useDispatch()

    const handleOpenModal = () => {
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    const handleFileInputChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile("")

        // const isCSV = file.includes(".csv")
        if (file && file.type === "text/csv") {
            setError("")
            setSelectedFile(file)
            convertToBase64(file);
        } else {
            setError("Please upload CSV File")
        }
    };
    const convertToBase64 = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setBase64Data(reader.result);
        };
        reader.onerror = (error) => {
            console.error('Error converting to Base64:', error);
        };
    };
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType });

        const a = document.createElement("a");
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        const clickEvt = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: true,
        });
        a.dispatchEvent(clickEvt);
        a.remove();
    };

    const exportToCsv = e => {
        e.preventDefault();
        let headers = ["Name, Employee Id,Employee Email,Mobile Number,Meal Type,Shift Type"];

        let usersCsv = usersData.users.reduce((acc, user) => {
            const { name, employeeId, employeeEmail, mobileNumber, mealType, shiftType } = user;

            acc.push([name, employeeId, employeeEmail, mobileNumber, mealType, shiftType].join(","));
            return acc;
        }, []);

        downloadFile({
            data: [...headers, ...usersCsv].join("\n"),
            fileName: "Sample-Employee-Format.csv",
            fileType: "text/csv",
        });
    };
    function onSubmit(e) {
        e.preventDefault()
        // if (!isValid()) return;
        // setAddButtonDisable(true)

        setIsLoading(true)
        create()
    }

    function create() {
        const { toast } = props;
        dispatch(addCsvData({ file: base64Data }))
            .then(async (res) => {
                dispatch(getEmployeesList({ limit: 8, skip: 0, keyword: "" })).then((res) => {
                    handleCloseModal()
                    setSelectedFile("")
                    console.log("res", res)

                }).catch((err) => {
                    console.log("err", err)
                })
                setIsLoading(false)
                toast.success("Employee Added successfully");
            })
            .catch(({ message = '' }) => {
                console.log(error, "error")
                toast.error(message || 'Oops! Something went wrong')
                setIsLoading(false)
            })
    }
    useEffect(() => {
        dispatch(getCompanyDetailsAction(true));
    }, [open])
    return (
        <div className='ml-2 tras-bin'>
            <Button variant="contained" onClick={handleOpenModal} className='button-btn-csv tras-bin-btn ' data-tooltip="Upload CSV">
                <PublishIcon />
            </Button>

            <Dialog
                onClose={handleCloseModal}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth="sm"
                fullWidth
                disableBackdropClick={true}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleCloseModal}>
                    Import CSV
                </DialogTitle>

                <DialogContent dividers >
                    <div className='demo-export d-flex ' >
                        <spav> Export Demo CSV File</spav>
                        <Button color="secondary" variant="contained" onClick={exportToCsv}>
                            <GetAppIcon />
                        </Button> </div>
                    <div className='csv-file'>
                        <div className='demo-export d-flex ' >
                            <p>Please select the CSV File</p>
                            <input
                                type="file"
                                id="fileInput"
                                accept=".csv"
                                style={{ display: 'none' }}
                                onChange={handleFileInputChange}
                            />

                            <label htmlFor="fileInput" className='select-file-csv'>
                                <Button variant="contained" component="span">
                                    <PublishIcon />
                                </Button>
                            </label>

                        </div>
                        <p className='red_text'>{error !== "" && error}</p>
                        {selectedFile && (
                            <div>
                                Selected File: {selectedFile.name}
                            </div>
                        )}
                    </div>
                    <Box>

                        <span className='note_style'>Note: Please follow the sample format and add your employees to the same column.</span>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <div className="foot-modal-btns" >

                        <Button onClick={handleCloseModal} color="secondary" variant="contained">
                            Cancel
                        </Button>
                        <Button color="primary" variant="contained" onClick={onSubmit} disabled={isLoading || error !== "" ? true : false || selectedFile === null}>
                            {isLoading ? <CircularProgress size="20px" color="white" /> : "Submit"}
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>

        </div>
    );
}

export default CSVBtn