import { apiPost, apiGet, apiDelete } from '../../utils/axios'

const initData = {
  keyword: '',
  skip: 0,
  limit: 10
}

export function getEmployeesListAPI(data = initData) {
  return apiGet(`/api/company/getEmployeeList`, data)
}

export function createEmployeeAPI(data) {
  if (data.walletAmount === "") {
    data.walletAmount = 0;
  }
  return apiPost('/api/company/createEmployee', data)
}

export function addWalletDataAPI(data) {
  return apiPost('/api/company/employee/addWallet', data)
}
export function addCsvDataAPI(data) {
  return apiPost('/api/company/importEmployee', data)
}

export function deleteAllEmployeeAPI(data) {
  return apiPost(`/api/company/employee/deleteAll`, data)
}
export function deleteEmployeeAPI(data) {
  return apiDelete(`/api/company/deleteEmployee/${data}`)
}


export function updateEmployeeAPI(data) {
  const employee = {}
  employee.name = data.name
  employee.email = data.email
  employee.phone = data.phone
  employee.employeeId = data.employeeId
  employee.isActive = data.isActive
  employee.mealId = data.mealId
  employee.shiftType = data.shiftType

  return apiPost(`/api/company/updateEmployee/${data._id}`, employee)
}

export function getEmployeeDetailAPI(id) {
  return apiGet(`/api/company/getEmployeeDetails/${id}`)
}

