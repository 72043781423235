import { combineReducers } from 'redux'

import auth from '../containers/Auth/reducer'
import dashboard from "../containers/Dashboard/reducer"
import employees from "../containers/Employees/reducer"
import meals from '../containers/Meals/reducer'
import tuckshop from '../containers/TuckShopOrders/reducer'
import menu from '../containers/FoodMenu/reducer'
import settings from '../containers/Settings/reducer'
import invoice from '../containers/Invoices/reducer'
import dailysupply from "../containers/DailySupply/reducer"
import manageusers from "../containers/ManageUsers/reducer"
import vouchers from "../containers/Vouchers/reducer"
import todaymeal from "../containers/TodayMeal/reducer"
import wallets from "../containers/Wallets/reducer"

export default combineReducers({
  auth,
  dashboard,
  employees,
  meals,
  tuckshop,
  menu,
  invoice,
  settings,
  dailysupply,
  manageusers,
  vouchers,
  todaymeal,
  wallets
})