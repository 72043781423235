import {
  getDashboardGraphsAPI,
  getDashboardDataAPI,
  getDashboardMealGraphsAPI,
  getDashboardExpenseGraphsAPI,
  getDashboardPayoutGraphsAPI
} from './apis'

import {
  GET_DASHBOARD_GRAPHS_REQUEST,
  GET_DASHBOARD_GRAPHS_REQUEST_SUCCEEDED,
  GET_DASHBOARD_GRAPHS_REQUEST_FAILED,
  GET_DASHBOARD_DATA_REQUEST,
  GET_DASHBOARD_DATA_REQUEST_SUCCEEDED,
  GET_DASHBOARD_DATA_REQUEST_FAILED,
  GET_DASHBOARD_MEAL_GRAPHS_REQUEST,
  GET_DASHBOARD_MEAL_GRAPHS_REQUEST_SUCCEEDED,
  GET_DASHBOARD_MEAL_GRAPHS_REQUEST_FAILED,
  GET_DASHBOARD_EXPENSE_GRAPHS_REQUEST,
  GET_DASHBOARD_EXPENSE_GRAPHS_REQUEST_SUCCEEDED,
  GET_DASHBOARD_EXPENSE_GRAPHS_REQUEST_FAILED,
  GET_DASHBOARD_PAYOUT_GRAPHS_REQUEST,
  GET_DASHBOARD_PAYOUT_GRAPHS_REQUEST_SUCCEEDED,
  GET_DASHBOARD_PAYOUT_GRAPHS_REQUEST_FAILED
} from './reducer'

export function getDashboardData(payload) {
  return dispatch => {
    dispatch({
      type: GET_DASHBOARD_DATA_REQUEST
    })
    return new Promise((resolve, reject) => {
      getDashboardDataAPI(payload)
        .then(({ data }) => {
          dispatch({
            type: GET_DASHBOARD_DATA_REQUEST_SUCCEEDED,
            payload: data
          })
          return resolve(data)
        })
        .catch(err => {
          dispatch({
            type: GET_DASHBOARD_DATA_REQUEST_FAILED
          })
          return reject(err)
        })
    })
  }
}

export function getDashboardGraphs(payload) {
  return dispatch => {
    dispatch({
      type: GET_DASHBOARD_GRAPHS_REQUEST,
    })
    return new Promise((resolve, reject) => {
      getDashboardGraphsAPI(payload)
        .then(({ data }) => {
          dispatch({
            type: GET_DASHBOARD_GRAPHS_REQUEST_SUCCEEDED,
            payload: data
          })
          return resolve(data)
        })
        .catch(err => {
          dispatch({
            type: GET_DASHBOARD_GRAPHS_REQUEST_FAILED,
          })
          return reject(err)
        })
    })
  }
}
export function getDashboardMealGraphs(payload) {
  return dispatch => {
    dispatch({
      type: GET_DASHBOARD_MEAL_GRAPHS_REQUEST,
    })
    return new Promise((resolve, reject) => {
      getDashboardMealGraphsAPI(payload)
        .then(({ data }) => {
          dispatch({
            type: GET_DASHBOARD_MEAL_GRAPHS_REQUEST_SUCCEEDED,
            payload: data
          })
          return resolve(data)
        })
        .catch(err => {
          dispatch({
            type: GET_DASHBOARD_MEAL_GRAPHS_REQUEST_FAILED,
          })
          return reject(err)
        })
    })
  }
}

export function getDashboardExpenseGraphs(payload) {
  return dispatch => {
    dispatch({
      type: GET_DASHBOARD_EXPENSE_GRAPHS_REQUEST,
    })
    return new Promise((resolve, reject) => {
      getDashboardExpenseGraphsAPI(payload)
        .then(({ data }) => {
          dispatch({
            type: GET_DASHBOARD_EXPENSE_GRAPHS_REQUEST_SUCCEEDED,
            payload: data
          })
          return resolve(data)
        })
        .catch(err => {
          dispatch({
            type: GET_DASHBOARD_EXPENSE_GRAPHS_REQUEST_FAILED,
          })
          return reject(err)
        })
    })
  }
}
export function getDashboardPayoutGraphs(payload) {
  return dispatch => {
    dispatch({
      type: GET_DASHBOARD_PAYOUT_GRAPHS_REQUEST,
    })
    return new Promise((resolve, reject) => {
      getDashboardPayoutGraphsAPI(payload)
        .then(({ data }) => {
          dispatch({
            type: GET_DASHBOARD_PAYOUT_GRAPHS_REQUEST_SUCCEEDED,
            payload: data
          })
          return resolve(data)
        })
        .catch(err => {
          dispatch({
            type: GET_DASHBOARD_PAYOUT_GRAPHS_REQUEST_FAILED,
          })
          return reject(err)
        })
    })
  }
}