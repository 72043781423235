import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  RadioGroup,
  CircularProgress,
  FormControl,
  Box,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Table,
  TableCell,
  TableBody,
  IconButton,
  Typography,
  Radio,
  TableRow,
  TextField
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import InputField from "../../../components/common/InputField";
import { green, red } from "@material-ui/core/colors";
import MealTypeDot from "../../Common/MealTypeDot";
import EnhancedTableHead from "../../../components/common/EnhancedTableHead";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { updateDailySupply } from "../actions";
import { useDispatch, useSelector } from "react-redux";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const initSupply = {
  orderId: "",
  comments: "",
  status: "",
  user_id: ""
};

export default function UpdateForm(props) {
  const dispatch = useDispatch();
  const { actionLabel, status, orderId, getDailySupply, toast, user_id, userType } =
    props;
  const [open, setOpen] = useState(false);
  const [addButtonDisable, setAddButtonDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [supply, setSupply] = useState({ ...initSupply });
  if (!supply.status && status) {
    setSupply({ ...supply, status: status });
  }
  if (!supply.orderId && orderId) {
    setSupply({ ...supply, orderId: orderId });
  }

  if (!supply.user_id && user_id && userType != "superAdmin") {
    setSupply({ ...supply, user_id: user_id });
  }
  const [dataChange, setDataChange] = useState(false);
  //    const [errors, setErrors] = useState({})

  function handleClickOpen() {
    setOpen(true);
    //    setErrors({});
  }

  function handleClose() {
    setOpen(false);
    //    setErrors({})
  }

  function onSubmit(e) {
    e.preventDefault();
    setAddButtonDisable(true);
    setIsLoading(true);
    updateOrder();
  }
  function updateOrder() {
    const { toast, getDailySupply } = props;
    dispatch(updateDailySupply(supply))
      .then(async () => {
        getDailySupply();
        handleClose();
        setAddButtonDisable(false);
        setIsLoading(false);
        toast.success("Order Updated Successfully");
      })
      .catch(({ message = "" }) => {
        toast.error(message || "Oops! Something went wrong");
        setAddButtonDisable(false);
        setIsLoading(false);
      });
  }
  //

  function handleChange(key, value) {
    setSupply({ ...supply, [key]: value });

    setDataChange(true);
  }

  return (
    <>
      {status == "approved" || status == "declined" ? (
        <div className={`btns-group-sec sec-${status}`}>
          <a className="daily-supply-listing" onClick={handleClickOpen}>
            {status == "approved" ? (
              <CheckCircleRoundedIcon />
            ) : status == "declined" ? (
              <CancelRoundedIcon />
            ) : (
              ""
            )}{" "}
            {actionLabel}
          </a>
          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth="sm"
            fullWidth
            disableBackdropClick={true}
            className="modal-contents-main"
          >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              {actionLabel}
            </DialogTitle>
            <DialogContent dividers>
              <Box py={1} className="modal-textarea">
                <TextField
                  id="outlined-multiline-static"
                  label="Comments"
                  multiline
                  fullWidth
                  rows={4}
                  variant="outlined"
                  inputProps={{ maxLength: 3000 }}
                  onChange={({ target: { value } }) =>
                    handleChange("comments", value)

                  }
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <div className="foot-modal-btns">
                <Button
                  onClick={handleClose}
                  color="secondary"
                  variant="contained"
                >
                  Close
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onSubmit}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <CircularProgress size="20px" color="white" />
                  ) : (
                    <>Update</>
                  )}
                </Button>
              </div>
            </DialogActions>
          </Dialog>
        </div>
      ) : (
        ""
      )}{" "}
    </>
  );
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
