import { apiPost, apiGet, apiDelete } from '../../utils/axios'

const initData = {
  keyword: '',
  skip: 0,
  limit: 8
}

export function getVoucherAPI(data = initData) {
    return apiGet('/api/company/voucher/getVoucher', data)
}

export function getEmployeesListAPI(data = initData) {
    return apiGet(`/api/company/getEmployeeList`, data)
  }

  export function getEmployeeNameListAPI(data){
  let payload={};
  if(data.keyword){
    payload.keyword=data.keyword;
  }
    return apiGet("/api/company/voucher/getEmployeesList?",payload)
}
export function assignVoucherAPI(data) {
  return apiPost('/api/company/voucher/asignVoucherToEmployee', data)
}