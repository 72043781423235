import React from "react";




function DailySupplyIcon(props) {
    
return (
<svg xmlns="http://www.w3.org/2000/svg" width="28.367" height="24.934" viewBox="0 0 28.367 24.934">
  <g id="calendar_1_" data-name="calendar (1)" transform="translate(0 -30.988)">
    <path id="Path_5531" data-name="Path 5531" d="M.83,164.286H22.55a.832.832,0,0,0,.532-.192c.209-.175,4.967-4.268,5.258-13.105H5.013C4.724,159.01.341,162.78.3,162.818a.832.832,0,0,0,.534,1.468Z" transform="translate(0 -113.351)" fill="#757575"/>
    <path id="Path_5532" data-name="Path 5532" d="M113.472,32.65h-4.156v-.831a.831.831,0,0,0-1.662,0v.831h-4.211v-.831a.831.831,0,0,0-1.662,0v.831H97.625v-.831a.831.831,0,0,0-1.662,0v.831H91.807a.823.823,0,0,0-.831.831v2.493H114.3V33.481A.823.823,0,0,0,113.472,32.65Z" transform="translate(-85.935)" fill="#757575"/>
    <path id="Path_5533" data-name="Path 5533" d="M110.086,304.086a2.5,2.5,0,0,1-1.6.58H90.977v2.493a.831.831,0,0,0,.831.831h21.664a.831.831,0,0,0,.831-.831v-9.317A16.473,16.473,0,0,1,110.086,304.086Z" transform="translate(-85.936 -252.069)" fill="#757575"/>
  </g>
</svg>
);
}
export default DailySupplyIcon;