import { apiPost, apiGet, apiDelete } from '../../utils/axios'


export function getCompanyWalletAPI(payload) {
  return apiGet(`/api/company/walletHistory`, payload)
}
export function addWalletDataAPI(data) {
  return apiPost(`/api/company/todayMealData/approve`, data)
}

