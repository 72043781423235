import {
  getFoodMenuAPI
} from './apis'
import {
  GET_MENU,
  GET_MENU_SUCCEEDED,
  GET_MENU_FAILED,
} from './reducer'

export function getFoodMenu() {
  return dispatch => {
    dispatch({
      type: GET_MENU
    })
    return new Promise((resolve, reject) => {
      getFoodMenuAPI()
        .then(({ data }) => {
          dispatch({
            type: GET_MENU_SUCCEEDED,
            payload: data
          })
          return resolve(data)
        })
        .catch(err => {
          dispatch({
            type: GET_MENU_FAILED
          })
          return reject(err)
        })
    })
  }
}