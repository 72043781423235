import React, { useState } from 'react'
import { Grid, Box, CircularProgress, Tab, Tabs } from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings'
import InputField from '../../components/common/InputField'
import { Button } from '@material-ui/core'
import { updatePassword } from './actions'
import { useDispatch, useSelector } from 'react-redux'
import { validatePasswordUpdate } from './validations'
import ChangePassword from './Forms/ChangePassword'
import CompanyDetails from './Forms/CompanyDetails'
import MealSettings from './Forms/MealSettings'

export default function Settings(props) {
  const { toast, loginUserType, loginUserPermission } = props

  let showsettings, showChangeCompanyDetails, showChangeMealPrice
  showsettings = showChangeCompanyDetails = showChangeMealPrice = true
  showsettings =
    loginUserType == 'user' && loginUserPermission.settings
      ? loginUserPermission.settings.settings
      : showsettings
  showChangeCompanyDetails =
    loginUserType == 'user' && loginUserPermission.settings
      ? loginUserPermission.settings.showChangeCompanyDetails
      : showChangeCompanyDetails
  showChangeMealPrice =
    loginUserType == 'user' && loginUserPermission.settings
      ? loginUserPermission.settings.showChangeMealPrice
      : showChangeMealPrice
  const dispatch = useDispatch()

  const isLoading = useSelector((state) => state?.settings?.isLoading || false)

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }
  const [tabValue, setTabValue] = React.useState(0)
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  let companyDetailTabValue, mealTabValue, changePasswordTabValue
  if (showChangeCompanyDetails == true && showChangeMealPrice == true) {
    companyDetailTabValue = 0
    mealTabValue = 1
    changePasswordTabValue = 2
  } else if (showChangeCompanyDetails == false && showChangeMealPrice == true) {
    mealTabValue = 0
    changePasswordTabValue = 1
  } else if (showChangeCompanyDetails == true && showChangeMealPrice == false) {
    companyDetailTabValue = 0
    changePasswordTabValue = 1
  } else if (
    showChangeCompanyDetails == false &&
    showChangeMealPrice == false
  ) {
    changePasswordTabValue = 0
  }

  if (showsettings == true) {
    return (
      <Grid container className="location-container">
        <Grid item xs={12}>
          <Grid container className="location-head">
            <Grid item xs={12} sm={12} md={4} lg={4} className="heading">
              <h2>
                <SettingsIcon />
                Settings
              </h2>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              {/* <Box display="flex" justifyContent="flex-end" alignItems="center">
              Search
            </Box> */}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div className="ver-tabs-design">
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="simple tabs example"
              orientation="vertical"
              variant="scrollable"
              className="ver-tabs-left"
            >
              {showChangeCompanyDetails == true ? (
                <Tab
                  className="testing123"
                  label="Company Details"
                  {...a11yProps(companyDetailTabValue)}
                ></Tab>
              ) : (
                ''
              )}
              {showChangeMealPrice == true ? (
                <Tab label="Meal Settings" {...a11yProps(mealTabValue)}></Tab>
              ) : (
                ''
              )}
              <Tab
                label="Change Password"
                {...a11yProps(changePasswordTabValue)}
              ></Tab>
            </Tabs>
            {showChangeCompanyDetails == true ? (
              <CompanyDetails
                index={companyDetailTabValue}
                dispatch={dispatch}
                toast={toast}
                value={tabValue}
                className="ver-tabs-right"
              />
            ) : (
              ''
            )}
            {showChangeMealPrice == true ? (
              <MealSettings
                index={mealTabValue}
                dispatch={dispatch}
                isLoading={isLoading}
                toast={toast}
                value={tabValue}
                className="ver-tabs-right"
              />
            ) : (
              ''
            )}
            <ChangePassword
              index={changePasswordTabValue}
              dispatch={dispatch}
              isLoading={isLoading}
              toast={toast}
              value={tabValue}
              className="ver-tabs-right"
            />
          </div>
        </Grid>
      </Grid>
    )
  } else {
    return (
      <Grid container className="location-container">
        <Grid item xs={12}>
          <Grid container className="location-head">
            <Grid item xs={12} sm={12} md={4} lg={4} className="heading">
              <h2>
                <SettingsIcon />
                Settings
              </h2>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Box display="flex" justifyContent="space-between" p={2}>
            <h6>You do not have permission to view this page</h6>
          </Box>
        </Grid>
      </Grid>
    )
  }
}
