import Joi from "joi";

export function validatePasswordUpdate(data) {
  let isValid = true
  let newErrors = {}

  const schema = Joi.object({
    password: Joi
      .string()
      .required()
      .messages({
        "string.empty": `Current password is required!`,
      }),
    newPassword: Joi
      .string()
      .required()
      .messages({
        "string.empty": `New password is required!`
      }),
    confirmPassword: Joi
      .any()
      .required()
      .valid(Joi.ref('newPassword'))
      .messages({
        "string.empty": `Confirm password is required!`,
        "any.only": `Password does not match!`
      })
  }).validate(data, { abortEarly: false })

  const { error } = schema

  if (error) {
    isValid = false
    error.details.forEach(item => newErrors[item.context.key] = item.message)
  }

  return { isValid, errors: newErrors }
}

export function validateCompanyDetails(data) {
  let isValid = true
  let newErrors = {}

  const schema = Joi.object({
    name: Joi
      .string()
      .required()
      .messages({
        "string.empty": `Name is required!`
      }),
      email: Joi
      .string()
      .trim()
      .required()
      .email({ tlds: { allow: false } })
      .messages({
        "string.empty": `Email is required!`,
        "string.email": `Invalid email!`
      }),
      phoneNumber: Joi
      .string()
      .trim()
      .required()
      .regex(/^[0-9]{0,10}$/)
      .min(10)
      .max(10)
      .messages({
        "string.empty": "Phone Number is required!",
        "object.regex": "Invalid Phone number!",
        "string.pattern.base": "Invalid Phone number!",
        "string.min": "Please enter 10 digit valid Phone number!",
        "string.max": "Please enter 10 digit valid Phone number!",
      }),
    address: Joi
      .string()
      .required()
      .messages({
        "string.empty": `Address is required!`
      }),

      gstNumber: Joi
      .string()
      .required()
      .messages({
        "string.empty": `GST Number is required!`
      }),
    
       contactPersonName: Joi
      .string()
      .required()
      .messages({
        "string.empty": `Contact Person Name is required!`
      })

  }).options({allowUnknown: true}).validate(data, { abortEarly: false })

  const { error } = schema

  if (error) {
    isValid = false
    error.details.forEach(item => newErrors[item.context.key] = item.message)
  }

  return { isValid, errors: newErrors }
}

export function validateMealSetting(data,veg,nonVeg,snacks,combo) {
  let isValid = true
  let newErrors = {}

  const schema = Joi.object({
    vegEmployShare: Joi
      .number().min(0)
      .max(veg)
      .messages({
        "number.base": `Employee share veg meal must be numeric!`,
        "number.min":`Employee Share veg meal cannot be less than 0`,
        "number.max":`Employee Share veg meal cannot be greater than bites bee price`,
      }),
       nonVegEmployShare: Joi
      .number().min(0)
      .max(nonVeg)
      .messages({
        "number.base":`Employee share non veg meal must se numeric!`,
        "number.min":`Employee Share non veg meal cannot be less than 0`,
        "number.max":`Employee Share non veg meal cannot be greater than bites bee price`,
      }),
        snacksEmployShare: Joi
      .number().min(0)
      .max(snacks)
      .messages({
        "number.base":`Employee share BitesBee snack must be numeric!`,
        "number.min":`Employee Share BitesBee snack cannot be less than 0`,
        "number.max":`Employee Share BitesBee snack cannot be greater than bites bee price`,
      }),
      comboEmployShare: Joi
      .number().min(0)
      .max(combo)
      .messages({
        "number.base":`Employee share BitesBee combo must be numeric!`,
        "number.min":`Employee Share BitesBee combo cannot be less than 0`,
        "number.max":`Employee Share BitesBee combo cannot be greater than bites bee price`,
      }),
  }).validate(data, { abortEarly: false })

  const { error } = schema

  if (error) {
    isValid = false
    error.details.forEach(item => newErrors[item.context.key] = item.message)
  }

  return { isValid, errors: newErrors }
}