export const SET_LOADING = 'SET_LOADING'
export const RESET_LOADING = 'RESET_LOADING'
export const GET_COMPANY_DETAILS_REQUEST = 'GET_COMPANY_DETAILS_REQUEST'
export const GET_COMPANY_DETAILS_RESPONSE = 'GET_COMPANY_DETAILS_RESPONSE'
export const GET_MEAL_SETTINGS_REQUEST = 'GET_MEAL_SETTINGS_REQUEST'
export const GET_MEAL_SETTINGS_RESPONSE = 'GET_MEAL_SETTINGS_RESPONSE'


const initState = {
  isLoading: false,
  companydetails: {
    _id: "",
    username: "",
    email: "",
    logo: "",
    address: "",
    name: "",
    contactPersonName: "",
    gstNumber: "",
  },
  mealsettings: {
    vegEmployShare: 0,
    nonVegEmployShare: 0,
    snacksEmployShare: 0,
    veg: 0,
    nonVeg: 0,
    snacks: 0,
    allaCartOffer: 0,
  }
}


export default function (state = { ...initState }, action) {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: true
      }

    case RESET_LOADING:
      return {
        ...state,
        isLoading: false
      }

    case GET_COMPANY_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: false,

      }

    case GET_COMPANY_DETAILS_RESPONSE:
      return {
        ...state,
        isLoading: false,
        companydetails: action.payload
      }

    case GET_MEAL_SETTINGS_REQUEST:
      return {
        ...state,
        isLoading: false,

      }

    case GET_MEAL_SETTINGS_RESPONSE:
      return {
        ...state,
        isLoading: false,
        mealsettings: action.payload
      }


    default:
      return state
  }
}