import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";

import Login from "../containers/Auth/Login";
import ForgetPassword from "../containers/Auth/ForgetPassword";
import NotFound from "../containers/NotFound";
import Dashboard from "../containers/Dashboard";
import Employees from "../containers/Employees";
import Settings from "../containers/Settings";
import Meals from "../containers/Meals";
import TuckShopOrders from "../containers/TuckShopOrders";
import FoodMenu from "../containers/FoodMenu";
import Invoices from "../containers/Invoices";
import DailySupply from "../containers/DailySupply";
import ManageUsers from "../containers/ManageUsers";
import { getObject } from "../utils";
import Vouchers from "../containers/Vouchers";
import UserPermission from "./UserPermission";
class Routes extends Component {
  state = {
    severity: "success",
  };
  idleTimer = null;

  notification = (message, severity) => {
    this.setState({ severity }, () => {
      this.not.open(message);
    });
  };

  render() {


    return (
      <React.Fragment>
        <BrowserRouter>
          <Switch>
            <PublicRoute
              exact
              path="/"
              component={Login}
              notification={this.notification}
            />
            <PublicRoute
              exact
              path="/login"
              component={Login}
              notification={this.notification}
            />
             <PublicRoute
              exact
              path="/forgot-password"
              component={ForgetPassword}
              notification={this.notification}
            />

            <UserPermission notification={this.notification} />

            <Route component={NotFound} />
          </Switch>
        </BrowserRouter>
      </React.Fragment>
    );
  }
}

export default Routes;
