import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
  ListItemText,
  ListItemIcon,
  ListItem,
  Drawer,
  AppBar,
  Toolbar,
  List,
  CssBaseline,
  IconButton,
  Hidden,
  CircularProgress,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { logOut, getObject } from '../../../utils'
import CustomDialogBox from '../../../components/common/CustomDialogBox'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'
import colors from '../../../assets/scss/_colors.scss'
import { useDispatch } from 'react-redux'
import DashboardIcon from '@material-ui/icons/Dashboard'
import GroupIcon from '@material-ui/icons/Group'
import SettingsIcon from '@material-ui/icons/Settings'
import FastfoodIcon from '@material-ui/icons/Fastfood'
import ReceiptIcon from '@material-ui/icons/Receipt'
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import DailySupplyIcon from '../../DailySupply/DailySupplyIcon'
import ManageUsersIcon from '../../ManageUsers/ManageUsersIcon'
import MoneyOffIcon from '@material-ui/icons/MoneyOff'
import RupeeIcon from "../../../assets/images/RupeeIcon.svg"
import EmojiFoodBeverageIcon from '@material-ui/icons/EmojiFoodBeverage';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { useSelector } from 'react-redux'

let menuItems = [
  {
    label: 'Dashboard',
    path: '/dashboard',
    icon: <DashboardIcon />,
    permission: 'dashboard',
  },
  {
    label: 'Employees',
    path: '/employees',
    icon: <GroupIcon />,
    permission: 'employees',
  },
  {
    label: "Today’s Meal Count",
    path: '/today-meal',
    icon: <EmojiFoodBeverageIcon />,
    permission: 'todaymeal',
  },
  {
    label: 'Meal Records',
    path: '/meal-record',
    icon: <FastfoodIcon />,
    permission: 'mealRecord',
  },
  {
    label: 'Tuck Shop Orders',
    path: '/tuck-shop-order',
    icon: <FastfoodIcon />,
    permission: 'tuckShopOrder',
  },
  {
    label: 'Daily Supply',
    path: '/daily-supply',
    icon: <DailySupplyIcon />,
    permission: 'dailySupply',
  },

  {
    label: 'Invoices',
    path: '/invoices',
    icon: <ReceiptIcon />,
    permission: 'invoices',
  },
  {
    label: 'Vouchers',
    path: '/vouchers',
    icon: <img src={RupeeIcon} alt="rupee_icon" />,
    permission: 'vouchers',
  },
  {
    label: 'Weekly Menu',
    path: '/menu',
    icon: <RestaurantMenuIcon />,
    permission: 'weeklyMenu',
  },
  {
    label: 'Wallet',
    path: '/wallets',
    icon: <AccountBalanceWalletIcon />,
    permission: 'wallets',
  },
  {
    label: 'Manage Users',
    path: '/manage-users',
    icon: <ManageUsersIcon />,
    permission: 'manageUsers',
  },
  {
    label: 'Settings',
    path: '/settings',
    icon: <SettingsIcon />,
    permission: 'settings',
  },
  {
    label: 'Logout',
    icon: <ExitToAppIcon />,
    path: '',
    type: 'LOGOUT_ACTION',
  },
]

const drawerWidth = 240
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: 100,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: '300ms',
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: '300ms',
    }),
  },
  menuButton: {
    marginRight: 30,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  customDrawer: {
    zIndex: 100,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: '300ms',
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: '300ms',
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
  },
  logoleft: {
    height: '70px',
  },
  toolbarCustom: {
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    flexGrow: 1,
    background: colors.bgLight,
    padding: theme.spacing(3),
    paddingTop: 0,
  },
}))

//   function displayCompanyLogo(){
//    const object = getObject("bb-user");
//  const userObject = object && JSON.parse(object) || '';
//  const {email = "",logo="",userType="",permission={}} =  userObject && userObject.userDetails || "";
//   return {
//    myEmail: email || "",
//    myLogo: logo || "",
//    loginUserType:userType || "",
//    loginUserPermission:permission ||""
//  };
//
//}

function Main(props) {
  const { loginUserType, loginUserPermission, myEmail, myLogo, myName, isTuckShopEnabled } = props
  const classes = useStyles()
  const [open, setOpen] = useState(true)
  const [opens, setOpens] = useState(false)
  const [toggleValue, toggleSet] = useState(true)
  const [logoutMenu, setLogoutMenu] = useState(false)
  const history = useHistory()
  const [loader, setLoader] = useState(false)

  const [email, setEmail] = useState('')
  const [logo, setLogo] = useState('')
  const [companyName, setcompanyName] = useState('')

  if ((myEmail && !email) || (email && myEmail && email != myEmail)) {
    // setEmail(...email,myEmail);
    setEmail(myEmail)
  }

  if ((myLogo && !logo) || (logo && myLogo && myLogo != logo)) {
    // setLogo(...logo,myLogo)
    setLogo(myLogo)
  }

  if (
    (myName && !companyName) ||
    (companyName && myName && companyName != myName)
  ) {
    // setcompanyName(...companyName,myName);
    setcompanyName(myName)
  }

  function toggle() {
    setOpen(!open)
    toggleSet(!toggleValue)
  }

  const handleClickLogoutModal = () => {
    setOpens(true)
  }

  const handleClose = () => {
    setOpens(false)
  }

  const logoutModal = () => {
    setOpens(true)
  }

  const handleLogout = () => {
    logOut('bb-user')
    logOut('bb-user-token')
    history.push('/login')
  }

  const openLogout = () => {
    setLogoutMenu(!logoutMenu)
  }

  const dynamicDrawer = () => {
    if (!toggleValue) setOpen(!open)
  }

  const onSetLoader = (action) => {
    if (action) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    setLoader(action)
  }

  const reRoute = (menuItem) => {
    if (menuItem?.type && menuItem.type === 'LOGOUT_ACTION') {
      logoutModal()
      return
    }
    history.push(menuItem.path)
  }

  if(!isTuckShopEnabled) {
    menuItems = menuItems.filter((item) => item.permission !== "tuckShopOrder");
  }

  return (
    <div className={classes.root}>
      {loader ? (
        <div className="main-advanced-loader">
          <CircularProgress size={45} />
        </div>
      ) : null}
      <Hidden xsDown smDown>
        <CssBaseline />
        <Drawer
          variant="permanent"
          className={clsx(
            classes.drawer,
            'custom-menu',
            classes.customDrawer,
            'custom-menu',
            {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            },
          )}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div
            className={clsx(
              classes.toolbar,
              classes.toolbarCustom,
              classes.logoleft,
              'cus-head-logo',
            )}
          >
            <IconButton className="mobile logo" onClick={toggle}>
              <img className="toggle-btn" src="/images/Path 403.svg" alt="" />
            </IconButton>
            {open ? (
              <div className='logo-name'>
                <img src={logo} className="logo-icon" alt="" />
                <span>{companyName}</span>
              </div>
            ) : null}
          </div>

          <List
            className="sidebar-menus"
            onMouseEnter={dynamicDrawer}
            onMouseLeave={dynamicDrawer}
          >
            {[...menuItems].map((item, index) => {
              const { pathname } = (history && history.location) || {}
              const pathArray = pathname.split('/')
              let isActive = false

              if (pathArray[1] === item.path.replace('/', '')) {
                isActive = true
              }
              let showThisMenu = false

              // if(loginUserType == "user" && loginUserPermission){
              //     Object.entries(loginUserPermission).forEach(([permissionIndex, permission]) => {
              //           if(item.permission && item.permission == permissionIndex){
              //               if(permission[permissionIndex] == true){
              //                   showThisMenu =true;
              //               }
              //           }

              //    })
              //       if(!item.permission){
              //               showThisMenu =true;
              //          }
              // }else{
              //     showThisMenu =true;
              // }
              showThisMenu = true
              if (showThisMenu == true) {
                return (
                  <ListItem
                    key={index}
                    button
                    className={clsx('sidepanel-item', { active: isActive })}
                    onClick={() => reRoute(item)}
                    disabled={item.disabled}
                  >
                    <ListItemIcon className="sidebar-menu-icon">
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText className="sidebar-menu-text">
                      <h6>{item.label}</h6>
                    </ListItemText>
                  </ListItem>
                )
              }
            })}
          </List>
        </Drawer>
      </Hidden>
      <Hidden mdUp>
        <MobileResponsiveHeader
          props={props}
          logOut={logOut}
          history={history}
          openLogout={openLogout}
          handleClickLogoutModal={handleClickLogoutModal}
          logoutMenu={logoutMenu}
          reRoute={reRoute}
          email={email}
          logo={logo}
          companyName={companyName}
          loginUserType={loginUserType}
          loginUserPermission={loginUserPermission}
        />
      </Hidden>
      <main className={classes.content}>
        {!props.internetOption ? (
          <React.Fragment>
            {React.cloneElement(props.children, { setLoader: onSetLoader })}
          </React.Fragment>
        ) : (
          <div className="no-internet-connection">
            <img className="mt-4" src="/images/1.jpg" alt="" />
            <p>OOPS! No Internet</p>
            <h4>Please check your internet connection.</h4>
          </div>
        )}
      </main>

      <CustomDialogBox
        handleClose={handleClose}
        confirmAction={handleLogout}
        open={opens}
        title="Warning"
        dialogtext="Are you sure you want to logout?"
      />
    </div>
  )
}

const mapStateToProp = (state) => {
  return {
    internetOption: state.dashboard && state.dashboard.internetOption,
  }
}

export default connect(mapStateToProp)(Main)

function MobileResponsiveHeader(props) {
  const {
    history,
    handleClickLogoutModal,
    reRoute,
    logo,
    companyName,
    email,
    loginUserType,
    loginUserPermission,
  } = props

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        easing: theme.transitions.easing,
        duration: '300ms',
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    toolbar: {
      ...theme.mixins.toolbar,
    },
    logoleft: {
      height: 'auto',
    },
    toolbarCustom: {
      display: 'flex',
      alignItems: 'center',
    },
  }))

  const { window } = props
  const classes = useStyles()
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <React.Fragment>
      <List className="sidebar-menus">
        {[...menuItems].map((item, index) => {
          const { pathname } = (history && history.location) || {}
          const pathArray = pathname.split('/')
          let isActive = false

          if (pathArray[1] === item.path.replace('/', '')) {
            isActive = true
          }

          let showThisMenu = false

          // if(loginUserType == "user" && loginUserPermission){
          //     Object.entries(loginUserPermission).forEach(([permissionIndex, permission]) => {
          //           if(item.permission && item.permission == permissionIndex){
          //               if(permission[permissionIndex] == true){
          //                   showThisMenu =true;
          //               }
          //           }

          //    })
          //       if(!item.permission){
          //               showThisMenu =true;
          //          }
          // }else{
          //     showThisMenu =true;
          // }
          showThisMenu = true
          if ((showThisMenu = true)) {
            return (
              <ListItem
                key={index}
                button
                key={item.label}
                className={clsx('sidepanel-item', { active: isActive })}
                disabled={item.disabled}
                onClick={() => reRoute(item)}
              >
                <ListItemIcon className="sidebar-menu-small-icon">
                  {item.icon}
                </ListItemIcon>
                <ListItemText>
                  <h6>{item.label}</h6>
                </ListItemText>
              </ListItem>
            )
          }
        })}
      </List>
    </React.Fragment>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined
  //  const object = getObject("bb-user");
  //  const { email = '' } = object && JSON.parse(object) || '';

  // const object = getObject("bb-user");
  //  const userObject = object && JSON.parse(object) || '';
  //  const {email = "",logo=""} =  userObject && userObject.userDetails || "";

  return (
    <React.Fragment >
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <div className="d-flex">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <img src="/images/Path 403.svg" />
            </IconButton>
            {
              <div className='logo-name'>
                <img src={logo} className="logo-icon" />
                <span>{companyName}</span>
              </div>
            }
          </div>
          <div className="user-name-email" onClick={handleClickLogoutModal}>
            <div className="setting-option">
              <div className="user-profile-text">
                <div className="option-dropdown">
                  <h5>{email}</h5>
                </div>
                <img
                  src="/images/logOut.svg"
                  onClick={handleClickLogoutModal}
                  className="logout-icon"
                  alt=""
                />
              </div>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden mdUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            className={clsx(classes.drawerPaper, 'custom-menu', {
              paper: classes.drawerPaper,
            })}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <div
              className={clsx(
                classes.toolbar,
                classes.toolbarCustom,
                classes.logoleft,
                'cus-head-logo',
              )}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <img src="/images/Path 403.svg" />
              </IconButton>
              <div className='logo-name'>
                <img src={logo} className="logo-icon" />
                <span>{companyName}</span>
              </div>
            </div>
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </React.Fragment >
  )
}
