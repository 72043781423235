import React, { useEffect, useMemo, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Dialog, RadioGroup, CircularProgress, FormControl, Box, FormControlLabel, InputLabel, Select, MenuItem, Grid } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import InputField from '../../../components/common/InputField';
import { validateWalletData } from '../validations';
import { addWalletData } from '../actions'
import { useDispatch, useSelector } from 'react-redux';
import Radio from '@material-ui/core/Radio';
import { green, red } from '@material-ui/core/colors';
import { getMealTypes } from '../../Meals/actions';

import WbSunnyIcon from '@material-ui/icons/WbSunny';
import Brightness3Icon from '@material-ui/icons/Brightness3';
import { getCompanyDetailsAction } from '../../Settings/actions';
import { numberFormat } from '../../../utils';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const initEmp = {
  employeeIds: [],
  isAllSelected: false,
  walletAmount: 0
}


export default function AddWallet(props) {
  const { actionLabel, selectAllUser = false, selectUser = [], setEditable } = props
  const [open, setOpen] = useState(false);
  const [employee, setEmployee] = useState({ ...initEmp })
  const [addButtonDisable, setAddButtonDisable] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const dispatch = useDispatch()

  let { userDetails: { walletAmount = 0 } = {} } = JSON.parse(window.localStorage.getItem('bb-user'));
  function handleClickOpen() {
    setOpen(true);
    setErrors({});
    setEmployee({ ...employee, isAllSelected: selectAllUser, employeeIds: selectUser })
  };


  // if( Object.keys(NonVegObj).length ===0 && mealTypes.length > 0){
  //   var NonVeg=mealTypes.filter((meal) => { return meal.mealType==="NON VEG"; })
  //   setNonVegObj(NonVeg[0] || {});
  // }
  // console.log(mealTypes," this is are meal type")

  function handleClose() {
    setOpen(false);
    setEmployee({ ...initEmp })
    setErrors({})
  };


  function isValid() {
    const ToValidate = {
      walletAmount: employee.walletAmount || 0
    }

    const { isValid, errors: newErrors } = validateWalletData(ToValidate)
    setErrors(newErrors)
    return isValid
  }


  const [wallet_Amount, setwallet_Amount] = useState(walletAmount);

  const walletCount = useMemo(() => {
    if (employee.walletAmount < wallet_Amount) {
      return wallet_Amount - employee.walletAmount || 0
    } else {
      return wallet_Amount
    }
  }, [employee?.walletAmount, wallet_Amount])


  function onSubmit(e) {
    e.preventDefault()
    if (!isValid()) return;
    setAddButtonDisable(true)
    setIsLoading(true)
    create()
  }

  function create() {
    const { toast, onClose } = props;
    dispatch(addWalletData(employee))
      .then(async () => {
        onClose();
        handleClose()
        setAddButtonDisable(false)
        dispatch(getCompanyDetailsAction(true)).then((res) => {
          setwallet_Amount(res.walletAmount)
        }).catch((err) => {
          console.log("err", err)
        })
        setIsLoading(false)
        toast.success("Wallet amount added successfully");
      })
      .catch(({ message = '' }) => {
        toast.error(message || 'Oops! Something went wrong')
        setAddButtonDisable(false)
        setIsLoading(false)
      })
  }



  function handleChange(key, value) {
    setEmployee({ ...employee, [key]: value })
    setErrors({ ...errors, [key]: '' })
    // console.log(employee, " this is employee")
  }



  const valid = employee?.walletAmount || false

  useEffect(() => {
    dispatch(getCompanyDetailsAction(true))
  }, [dispatch])


  return (
    <div className='tras-bin ml-2'>
      <Button
        className="btn-custom-black button-btn-csv tras-bin-btn wallet-amount-btn"
        onClick={handleClickOpen}
        data-tooltip="Add Wallet"

      >
        {actionLabel}
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
        fullWidth
        disableBackdropClick={true}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Wallet Balance
        </DialogTitle>
        <DialogContent dividers>
          <Box py={1}>
            <InputField
              fullWidth
              label="Amount"
              onChange={({ target: { value } }) => handleChange('walletAmount', value)}
              value={employee.walletAmount}
              error={errors.walletAmount}
            />
          </Box>
          <Typography>Available Company’s Wallet Balance: ₹{numberFormat(walletCount) || "0"}</Typography>
        </DialogContent>
        <DialogActions>
          <div className="foot-modal-btns">
            <Button onClick={handleClose} color="secondary" variant="contained">
              Cancel
            </Button>
            <Button color="primary" variant="contained" onClick={onSubmit} disabled={isLoading || ((employee.walletAmount <= 0) ? true : false)}>
              {isLoading ? <CircularProgress size="20px" color="white" /> : "Add"}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div >
  );
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const RedRadio = withStyles({
  root: {
    color: red[400],
    '&$checked': {
      color: red[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);