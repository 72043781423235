import React, { useState, useEffect } from 'react'
import { Grid, Box, Paper, Select, MenuItem } from '@material-ui/core'

function ChartWrapper(props) {
  const { title, total = '', shiftType = "", selectedShift = "", type = "", handleShiftChange = "", isLoading, children } = props
  const [show, setShow] = useState(true);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setShow(true)
  //   }, 500)
  // }, [])


  if (isLoading) {
    return (
      <Paper className="box-shadow">
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <Box p={2} borderBottom={1} display="flex" justifyContent="space-between">
              <h4>
                {title}
              </h4>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="366px">
              ...loading
            </Box>
          </Grid>
        </Grid>
      </Paper>
    )
  }

  return (
    <Paper className="box-shadow">
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Box p={2} borderBottom={1} display="flex" justifyContent="space-between">
            <h4>
              {title}
            </h4>
            {/*
            { (shiftType ) ?
            <h5>
              <label>Shift</label>
            <Select
                    labelId="meal-type-selection"
                    id="shift-type"
                    value={selectedShift.value}
                    onChange={({ target: { value } }) => handleShiftChange(value,type)}

                    label="Shift Type"
                    fullWidth
                  >
                    {shiftType?.map(shift => <MenuItem value={shift.value} >{shift?.label || '-'}</MenuItem>)}
                  </Select>
            </h5>
            : "" }
            */ }
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          {children}
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ChartWrapper;