import { apiGet } from "../../utils/axios"
import moment from 'moment'

export function getDashboardGraphsAPI(data) {
    const payload={};
  if(data.startDate){
      payload.startDate=data.startDate;
  }
  if(data.endDate){
      payload.endDate=data.endDate;
  }
//  const payload = {
//    startDate: data?.startDate || '',
//    endDate: data?.endDate || ''
//  }
  return apiGet(`/api/company/getDashboardGraphs`, payload)
}

export function getDashboardMealGraphsAPI(data) {
  const payload={};
if(data.startDate){
    payload.startDate=data.startDate;
}
if(data.endDate){
    payload.endDate=data.endDate;
}
if(data.shift){
  payload.shift=data.shift;
}
return apiGet(`/api/company/getDashboardGraphsMealSummery`, payload)
}

export function getDashboardExpenseGraphsAPI(data) {
  const payload={};
if(data.startDate){
    payload.startDate=data.startDate;
}
if(data.endDate){
    payload.endDate=data.endDate;
}
if(data.shift){
  payload.shift=data.shift;
}
return apiGet(`/api/company/getDashboardGraphsMealExpenses`, payload)
}

export function getDashboardPayoutGraphsAPI(data) {
  const payload={};
if(data.startDate){
    payload.startDate=data.startDate;
}
if(data.endDate){
    payload.endDate=data.endDate;
}
return apiGet(`/api/company/getDashboardGraphsCompanyPayout`, payload)
}

export function getDashboardDataAPI(data) {
  const payload = {
    startDate: data?.startDate || '',
    endDate: data?.endDate || ''
  }
  return apiGet(`/api/company/getDashboardData`, payload)
}