import React, { useEffect, useState } from 'react'
import {
  Grid,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box,
  IconButton,
  CircularProgress,
  TableHead,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
// import EnhancedTableHead from '../../components/common/EnhancedTableHead'
import CustomTablePagination from '../../components/common/CustomPagination'
import { withTranslation } from 'react-i18next'
import { tablestyle } from '../../plugins/theme'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove';
import SearchField from '../../components/common/SearchField'
import { useDispatch, useSelector } from 'react-redux'
import TableLoader from '../../components/common/TableLoader'
import GroupIcon from '@material-ui/icons/Group'
import { getEmployeesList, deleteEmployee, updateEmployee } from './actions'
import { getMealTypes } from '../Meals/actions'
import AddEmployee from './AddEmployee'
import AddWAllet from './AddEmployee/AddWallet'
import DeleteEmployee from './AddEmployee/DeleteEmployee'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import BeeMenu from '../Common/BeeMenu'
import MealTypeDot from '../Common/MealTypeDot'
import IOSSwitch from '../Common/IOSSwitch'
import AlertDialog from '../Common/AlertDialog'
import { useHistory } from 'react-router-dom'
import { getCompanyDetailsAction } from '../Settings/actions'
import { numberFormat } from '../../utils'
import CSVBtn from './AddEmployee/CSVBtn'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
let searchTypeInterval = null
let manageLocationsHead


function EnhancedTableHead(props) {
  const { headCells, selectAllCheckBox, selectAllUser } = props
  return (
    <TableHead className="">
      <TableRow>
        {headCells.map((cell, key) => {
          return (
            <TableCell key={key}>
              {cell.id == 'all' ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectAllUser}
                      color="primary"
                      onChange={(e) => selectAllCheckBox(e)}
                    />
                  }
                  label="All"
                />
              ) : (
                cell.label
              )}
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

function getEmployeesData(state) {
  const employees =
    (state && state.employees && state.employees.employees) || {}
  return {
    employees: employees.data || [],
    count: employees.count || 0,
  }
}

function Employees(props) {
  const { classes, toast, loginUserType, loginUserPermission, isTuckShopEnabled } = props;

  let showemployees,
    showaddEmployees,
    showeditEmployees,
    showchangeEmployeesStatus,
    showdeleteEmployees,
    showEmployeeMeal
  showemployees = showaddEmployees = showeditEmployees = showchangeEmployeesStatus = showdeleteEmployees = showEmployeeMeal = true
  showemployees =
    loginUserType == 'user' && loginUserPermission.employees
      ? loginUserPermission.employees.employees
      : showemployees
  showeditEmployees =
    loginUserType == 'user' && loginUserPermission.employees
      ? loginUserPermission.employees.editEmployees
      : showeditEmployees
  showaddEmployees =
    loginUserType == 'user' && loginUserPermission.employees
      ? loginUserPermission.employees.addEmployees
      : showaddEmployees
  showchangeEmployeesStatus =
    loginUserType == 'user' && loginUserPermission.employees
      ? loginUserPermission.employees.changeEmployeesStatus
      : showchangeEmployeesStatus
  showdeleteEmployees =
    loginUserType == 'user' && loginUserPermission.employees
      ? loginUserPermission.employees.deleteEmployees
      : showdeleteEmployees
  showEmployeeMeal =
    loginUserType == 'user' && loginUserPermission.employees
      ? loginUserPermission.employees.viewEmployees
      : showEmployeeMeal

  const dispatch = useDispatch()
  const isLoading = useSelector((state) => state.employees.isLoading || false)
  const { employees, count } = useSelector(getEmployeesData)
  const history = useHistory()
  const [limit, setLimit] = useState(8)
  const [skip, setSkip] = useState(0)
  const [keyword, setKeyword] = useState('')
  const [page, setPage] = useState(1)

  const [editable, setEditable] = useState(false)
  const [empToEdit, setEmpToEdit] = useState(null)
  const [empToDelete, setEmpToDelete] = useState(null)
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false)
  const [updateLoading, setUpdateLoading] = useState(false)

  const [selectUser, setSelectUser] = useState([])
  const [selectAllUser, setSelectAllUser] = useState(false)
  const [userAddedStatus, setuserAddedStatus] = useState(false)

  useEffect(() => {
    getEmployees()
  }, [limit, skip])

  useEffect(() => {
    getMealTypesList()

  }, [])

  function getEmployees(search = '', nextPage) {
    if (!nextPage) {
      nextPage = page
    }
    setPage(nextPage)
    let skipToSend = (nextPage - 1) * limit

    if (showemployees == true) {
      dispatch(getEmployeesList({ limit, skip: skipToSend, keyword: search }))
    }
  }

  function onCloseAddEmployee() {
    getEmployees()
    setEditable(null)
    setEmpToEdit(null)
  }

  function getMealTypesList() {
    dispatch(getMealTypes())
    dispatch(getCompanyDetailsAction(true));
  }

  function handleChangeSearch({ target: { value = '' } }) {
    setKeyword(value)

    if (searchTypeInterval) {
      clearInterval(searchTypeInterval)
    }

    searchTypeInterval = setTimeout(() => {
      getEmployees(value, 1)
    }, 500)
  }

  function handlePageChange(e, nextPage) {
    e.preventDefault()
    if (nextPage === page) {
      return
    }
    setPage(nextPage)
    setSkip((nextPage - 1) * limit)
  }

  function onDelete(emp) {
    return () => {
      setEmpToDelete(emp)
      setOpenDeleteConfirm(true)
    }
  }

  function onView(emp) {
    return () => {
      history.push(`/meal-record/?employee_id=${emp._id}&employee=${emp.name}`)
    }
  }

  function onTuckShopView(emp) {
    return () => {
      history.push(`/tuck-shop-order/?employee_id=${emp._id}&employee=${emp.name}`)
    }
  }

  function onConfirmDelete() {
    const { _id = '' } = empToDelete

    setOpenDeleteConfirm(false)
    dispatch(deleteEmployee(_id))
      .then(() => {
        getEmployees()
        toast.success('Employee has been deleted successfully')
      })
      .catch(({ message = '' }) => {
        toast.error(message || 'Oops! Something went wrong')
      })
  }

  function onCancelDelete() {
    setEmpToDelete(null)
    setOpenDeleteConfirm(false)
  }

  function onEdit(emp) {
    return () => {
      setEmpToEdit({ ...emp, mealId: emp?.mealId?._id || '' })
      setEditable(true)
    }
  }

  function onUpdateStatus(e, emp) {
    e.preventDefault()
    setUpdateLoading(true)
    update(emp)
  }

  function update(emp) {
    dispatch(
      updateEmployee({
        ...emp,
        isActive: !emp.isActive,
        mealId: emp?.mealId?._id,
      }),
    )
      .then(() => {
        setUpdateLoading(false)
        // getEmployees()
        toast.success('Employee has been updated successfully')
      })
      .catch(({ message = '' }) => {
        toast.error(message || 'Oops! Something went wrong')
        setUpdateLoading(false)
      })
  }

  function makeAction(employee) {
    let viewEmployee = {
      label: 'View Meals',
      action: onView(employee),
    }
    let viewTuckShopEmployee = {
      label: 'View Tuck Shop',
      action: onTuckShopView(employee),
    }
    let editEmployee = {
      label: 'Edit Profile',
      action: onEdit(employee),
    }
    let deleteEmployee = {
      label: 'Delete Profile',
      action: onDelete(employee),
    }
    let action = []
    if (showEmployeeMeal == true) {
      action.push(viewEmployee)
    }
    if(isTuckShopEnabled) {
      action.push(viewTuckShopEmployee);
    }
    if (showeditEmployees == true) {
      action.push(editEmployee)
    }
    if (showdeleteEmployees == true) {
      action.push(deleteEmployee)
    }
    return action
  }
  if (showchangeEmployeesStatus == true) {
    manageLocationsHead = [
      { id: 'all', numeric: false, disablePadding: false, label: 'All' },
      { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
      { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
      {
        id: 'emaployeeId',
        numeric: false,
        disablePadding: false,
        label: 'Employee ID',
      },
      { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
      {
        id: 'phone',
        numeric: false,
        disablePadding: false,
        label: 'Mobile No.',
      },
      {
        id: 'walletAmount',
        numeric: false,
        disablePadding: false,
        label: 'Wallet Amount',
      },
      {
        id: 'mealType',
        numeric: false,
        disablePadding: false,
        label: 'Meal Type',
      },
      {
        id: 'shiftType',
        numeric: false,
        disablePadding: false,
        label: 'Shift Type',
      },
      { id: 'actions', numeric: false, disablePadding: false, label: 'Actions' },
    ]
  } else {
    manageLocationsHead = [
      { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
      {
        id: 'emaployeeId',
        numeric: false,
        disablePadding: false,
        label: 'Employee ID',
      },
      { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
      {
        id: 'phone',
        numeric: false,
        disablePadding: false,
        label: 'Mobile No.',
      },
      {
        id: 'mealType',
        numeric: false,
        disablePadding: false,
        label: 'Meal Type',
      },
      {
        id: 'shiftType',
        numeric: false,
        disablePadding: false,
        label: 'Shift Type',
      },
      { id: 'actions', numeric: false, disablePadding: false, label: 'Actions' },
    ]
  }

  function SelectAllCheckoxDefault() {
    if (employees && employees.length) {
      let myArr = [];
      employees.map((Ids) => {
        if (Ids.employeeId) {
          myArr.push(Ids.employeeId);
        }
      })
      setuserAddedStatus(false);
      setTimeout(function () {
        setSelectUser([...myArr]);
      }, 100);
    }
  }

  function handleChangeCheckbox(e, _id) {
    let checked = e.target.checked
    setSelectAllUser(false);
    setuserAddedStatus(false)
    let selectUserTmp = selectUser

    if (checked == false) {
      let index = selectUserTmp.indexOf(_id)
      if (index > -1) {
        selectUserTmp.splice(index, 1)
      }
      setSelectUser([...selectUserTmp])
    } else {
      if (selectUserTmp.indexOf(_id) > -1) {
        return true
      } else {
        selectUserTmp.push(_id)
        setSelectUser([...selectUserTmp])
      }
    }
  }

  function selectAllCheckBox(e) {
    let checked = e.target.checked
    setSelectAllUser(checked)
    setuserAddedStatus(checked);
    if (checked == false) {
      let myArr = [];
      setSelectUser([...myArr])
    }
  }
  function checkIfChecked(_id) {
    if (selectAllUser == true) {
      return true
    } else if (selectUser.length) {
      if (selectUser.indexOf(_id) > -1) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  if (showemployees == true) {
    return (
      <Grid container className="location-container ">
        {updateLoading ? (
          <div className="main-advanced-loader">
            <CircularProgress size={45} />
          </div>
        ) : null}
        <Grid item xs={12}>
          <Grid container className="location-head">
            <Grid item xs={12} sm={12} md={3} lg={3} className="heading">
              <h2>
                <GroupIcon />
                Employees
                {
                  (selectAllUser == true && userAddedStatus == true) ?
                    SelectAllCheckoxDefault()
                    : null}
              </h2>
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9} className='employee_btn_section'>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <SearchField
                  placeholder="Search"
                  value={keyword}
                  onChange={handleChangeSearch}
                />

                {(selectUser.length) ?
                  <>
                    <AddWAllet
                      onClose={onCloseAddEmployee}
                      toast={toast}
                      selectAllUser={selectAllUser}
                      selectUser={selectUser}
                      actionLabel={
                        <Box display="flex">
                          <AccountBalanceWalletIcon />

                        </Box>
                      }
                    />

                  </>
                  : null}

                {showaddEmployees == true ? (
                  <>
                    <AddEmployee
                      onClose={onCloseAddEmployee}
                      toast={toast}
                      editable={editable}
                      setEditable={setEditable}
                      empToEdit={empToEdit}
                      actionLabel={
                        <Box display="flex">
                          <GroupAddIcon />
                        </Box>
                      }
                    />
                    <CSVBtn
                      toast={toast}
                      actionLabel={
                        <Box display="flex">
                          <AddIcon />
                        </Box>
                      }
                    />
                  </>
                ) : (
                  ''
                )}
                {(selectUser.length) ?
                  <>
                    <div className="tras-bin">
                      <DeleteEmployee
                        onClose={onCloseAddEmployee}
                        toast={toast}
                        selectAllUser={selectAllUser}
                        selectUser={selectUser}
                        actionLabel={
                          <RemoveIcon />

                        }
                      />
                    </div>


                  </>
                  : null}


              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} className="">
          <div id="no-more-tables" className="card-design table-design cf">
            {isLoading ? (
              <>
                <TableContainer className={classes.container}>
                  <Table
                    className="employee-table-program"
                    stickyHeader
                    aria-label="sticky table"
                  >

                    <EnhancedTableHead
                      headCells={manageLocationsHead}
                      selectAllCheckBox={selectAllCheckBox}
                      selectAllUser={selectAllUser}
                    />

                  </Table>
                </TableContainer>
                <TableLoader />
              </>
            ) : null}

            {!isLoading ? (
              <TableContainer className={classes.container}>
                <Table
                  className="employee-table-program"
                  stickyHeader
                  aria-label="sticky table"
                >
                  <EnhancedTableHead
                    headCells={manageLocationsHead}
                    selectAllCheckBox={selectAllCheckBox}
                    selectAllUser={selectAllUser}
                  />
                  <TableBody>
                    {employees && employees.length ? (
                      employees.map((employee) => {
                        const {
                          name,
                          phone,
                          email,
                          isActive,
                          employeeId,
                          _id,
                          mealId,
                          shiftType,
                          walletAmount,
                        } = employee
                        const { mealType } = mealId || {}

                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            className="cursor_default"
                            key={_id}
                          >
                            <TableCell className="table-custom-width">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={checkIfChecked(_id)}
                                    color="primary"
                                    onChange={(e) => handleChangeCheckbox(e, _id)}
                                  />
                                }
                              />
                            </TableCell>
                            {showchangeEmployeesStatus == true ? (
                              <TableCell className="table-custom-width">
                                <IOSSwitch
                                  checked={isActive}
                                  onChange={(e) => onUpdateStatus(e, employee)}
                                />
                              </TableCell>
                            ) : (
                              ''
                            )}
                            <TableCell className="table-custom-width">
                              {name}
                            </TableCell>
                            <TableCell className="table-custom-width">
                              {employeeId}
                            </TableCell>
                            <TableCell className="table-custom-width">
                              {email}
                            </TableCell>
                            <TableCell className="table-custom-width">
                              {phone}
                            </TableCell>
                            <TableCell className="table-custom-width">
                              &#8377;{numberFormat(walletAmount) || 0}
                            </TableCell>
                            <TableCell className="table-custom-width both_meals">
                              <Box display="flex" alignItems="center">
                                {mealType === 'VEG' ?
                                  <><MealTypeDot mealType={mealType} />Veg</> :
                                  mealType == "NON VEG" ?
                                    <><MealTypeDot mealType={mealType} />Non Veg</> :
                                    mealType == "BOTH" ? <><MealTypeDot mealType="VEG" />Veg <MealTypeDot mealType="NON VEG" /> Non Veg</> : ""}
                              </Box>
                            </TableCell>
                            <TableCell className="table-custom-width">
                              {shiftType}
                            </TableCell>
                            <TableCell
                              className="table-custom-width"
                              style={{ textAlign: 'left' }}
                            >
                              <Box mr={4}>
                                {showeditEmployees == true ||
                                  showdeleteEmployees == true ||
                                  showEmployeeMeal == true ? (
                                  <BeeMenu
                                    actions={makeAction(employee)}
                                    actionTitle={
                                      <IconButton aria-label="delete">
                                        <MoreVertIcon />
                                      </IconButton>
                                    }
                                  />
                                ) : (
                                  ''
                                )}
                              </Box>
                            </TableCell>
                          </TableRow>
                        )
                      })
                    ) : (
                      <TableRow
                        hover
                        role="checkbox"
                        className="cursor_default"
                      >
                        <TableCell colSpan={7} className="table-custom-width">
                          <Box textAlign="center" p={1} className="no-data-found">
                            <p>No Data Found</p>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : null}

            <CustomTablePagination
              count={employees.length}
              total={count}
              limit={limit}
              page={page}
              onPageChange={handlePageChange}
            />
          </div>
          <AlertDialog
            title="Confirm Delete"
            content="Are you sure you want to delete this employee?"
            open={openDeleteConfirm}
            actions={
              <div className="foot-modal-btns">
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={onCancelDelete}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  autoFocus
                  style={{ marginLeft: 8 }}
                  onClick={onConfirmDelete}
                >
                  Confirm
                </Button>
              </div>
            }
          />
        </Grid>
      </Grid>
    )
  } else {
    return (
      <Grid container className="location-container">
        <Grid item xs={12}>
          <Grid container className="location-head">
            <Grid item xs={12} sm={12} md={4} lg={4} className="heading">
              <h2>
                <GroupIcon />
                Employees
              </h2>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Box display="flex" justifyContent="space-between" p={2}>
            <h6>You do not have permission to view this page</h6>
          </Box>
        </Grid>
      </Grid>
    )
  }
}

export default withTranslation('translations')(
  withStyles(tablestyle)(Employees),
)
