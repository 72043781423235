import React, { useEffect, useState } from 'react'
import {
  Grid,
  Box,
  Paper,
  Card,
  CardContent,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { getTimeStamps, numberFormat } from '../../utils'
import { tablestyle } from '../../plugins/theme'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { getCompanyWallet } from './actions'
import moment from 'moment'
import MealTypeDot from '../Common/MealTypeDot'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

import TabPanel from './TabPanel'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import UpdateForm from "./PopupModal/UpdateForm";
import { getObject } from "../../utils";
import {
  getCompanyDetailsAction,
} from '../Settings/actions'



function getWalletResults(state) {
  const walletsReducer = state?.wallets || {}
  return {

    walletList: walletsReducer.wallets?.walletHistory || [],
    count: walletsReducer.wallets?.total || 0,
    walletAmount: walletsReducer.wallets?.company || 0,
    // count:timeData.todaymeal?.todayMealData.length || 0
  };
}
function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
function Wallets(props) {
  const { classes, toast, loginUserType, loginUserPermission } = props
  const dispatch = useDispatch();
  const isLoading = useSelector(
    (state) => state.wallets.isLoading || false
  );



  const {
    walletList = [],
    count = 0,
    walletAmount = {},
  } = useSelector(getWalletResults);

  const [limit, setLimit] = useState(8);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(1);


  function getWalletData() {
    const data = {
      limit,
      skip,
    };
    dispatch(getCompanyWallet(data));
  }

  useEffect(() => {
    dispatch(getCompanyDetailsAction("true"));
    getWalletData();
  }, [])


  useEffect(() => {
    getWalletData();
  }, [skip, limit,]);

  function CardField(props) {
    return (
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <Card className="box-shadow">
          <CardContent>
            <Grid container>
              <Grid item xs={9} sm={9} md={9} lg={9}>
                <div className="dashboard-card-connent">{props?.children}</div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    )
  }




  const object = getObject("bb-user");

  const userObject = (object && JSON.parse(object)) || "";
  const { name = "", userType = "" } = (userObject && userObject.userDetails) || "";
  const user_id = (userObject.userDetails._id) || "";


  return (
    <Grid container className="location-container">
      <Grid item xs={12}>
        <Grid container className="location-head">
          <Grid item xs={12} sm={12} md={4} lg={4} className="heading">
            <h2>
              <AccountBalanceWalletIcon />
              Wallet
            </h2>
          </Grid>

        </Grid>
      </Grid>

      <Grid item xs={12} className='position-relative'>

        <div className='wallet-amount-text' mealType="VEG">
          <h5 className="bal-custom" style={{ textAlign: "center" }}>Balance
            &#8377;{numberFormat(walletAmount?.walletAmount) || 0}
          </h5>
        </div>


        {/* <CardField>
                  <div className="dashboard-card-connent">
                  <UpdateForm
                              toast={toast}
                              actionLabel="Add Amount"
                              status="approved"
                              userType={userType}
                            />
                  </div>
                  </CardField> */}
      </Grid>
      <TabPanel
        toast={toast}
        isLoading={isLoading}
        classes={classes}
        limit={limit}
        page={page}
        count={count}
        setPage={setPage}
        setSkip={setSkip}
        numberFormat={numberFormat}
        walletList={walletList}
        MealTypeDot={MealTypeDot}
      ></TabPanel>
    </Grid>
  )

}

export default withTranslation('translations')(
  withStyles(tablestyle)(Wallets),
)
