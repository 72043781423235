import config from "./config";
import moment from "moment";
export const apiUrl = config.API_URL || "";

export const dateFilter = [
  { label: 'Today', value: 'today' },
  { label: 'Yesterday', value: 'yesterday' },
  { label: 'Last 7 Days', value: 'last7Days' },
  { label: 'Last Week', value: 'lastWeek' },
  { label: 'Last 30 Days', value: 'last30Days' },
  { label: 'This Month', value: 'thisMonth' },
  { label: 'Last Month', value: 'lastMonth' },
  // { label: 'Last 60 Days', value: 'last60Days' },
  // { label: 'Last 90 Days', value: 'last90Days' },
  { label: 'Custom Date', value: 'customDate' },
]

export const DaydateFilter = [
  { label: 'All', value: 'all' },
  { label: 'Today', value: 'today' },
  { label: 'Yesterday', value: 'yesterday' },
  { label: 'Last 7 Days', value: 'last7Days' },
  { label: 'Last Week', value: 'lastWeek' },
  { label: 'Last 30 Days', value: 'last30Days' },
  { label: 'This Month', value: 'thisMonth' },
  { label: 'Last Month', value: 'lastMonth' },
  // { label: 'Last 60 Days', value: 'last60Days' },
  // { label: 'Last 90 Days', value: 'last90Days' },
  { label: 'Custom Date', value: 'customDate' },
]

export const monthsForGraph = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec'
}

export const monthForInvoice = [
  { label: 'January', value: '1' },
  { label: 'Feburary', value: '2' },
  { label: 'March', value: '3' },
  { label: 'April', value: '4' },
  { label: 'May', value: '5' },
  { label: 'June', value: '6' },
  { label: 'July', value: '7' },
  { label: 'August', value: '8' },
  { label: 'September', value: '9' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
]

export const yearForInvoice = [
  { label: '2020', value: '2020' },
  { label: '2021', value: '2021' },
  { label: '2022', value: '2022' },
  { label: '2023', value: '2023' },
  { label: '2024', value: '2024' },
  { label: '2025', value: '2025' },
]

export const shiftType = [
  { label: "Day", value: "DAY" },
  { label: "Night", value: "NIGHT" },
  { label: "Day/Night", value: "BOTH" }
];



