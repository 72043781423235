import React, { useState, useEffect } from "react";
import { Grid, Box, CircularProgress, Tab, Tabs } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import InputField from "../../../components/common/InputField";
import { Button } from "@material-ui/core";
import {
  updatePassword,
  getMealSettingsAction,
  updateMealSettings,
} from "../actions";
import { useDispatch, useSelector } from "react-redux";
import { validateMealSetting } from "../validations";
import MealTypeDot from "../../Common/MealTypeDot";

const initObj = {
  vegEmployShare: 0,
  nonVegEmployShare: 0,
  snacksEmployShare: 0,
  comboEmployShare:0,
};



function getMealData(state) {
  const mealdata = state?.settings?.mealsettings || {};

  return {
    vegEmployShare: mealdata.vegEmployShare || 0,
    nonVegEmployShare: mealdata.nonVegEmployShare || 0,
    snacksEmployShare: mealdata.snacksEmployShare || 0,
    comboEmployShare: mealdata.comboEmployShare || 0,
    veg: mealdata.veg || 0,
    nonVeg: mealdata.nonVeg || 0,
    snacks: mealdata.snacks || 0,
    combo:mealdata.combo || 0,
    allaCartOffer:mealdata.allaCartOffer || 0
  };
}

export default function MealSettings(props) {
  const [data, setData] = useState({ ...initObj });

  useEffect(() => {
    getMealSettings();
  }, []);

  function getMealSettings() {
    dispatch(getMealSettingsAction());
  }

  const {
    vegEmployShare,
    nonVegEmployShare,
    snacksEmployShare,
    comboEmployShare,
    veg,
    nonVeg,
    snacks,
    combo,
    allaCartOffer,
  } = useSelector(getMealData);
  

  
  const [errors, setErrors] = useState({});
  const { toast, dispatch, isLoading, value, index, ...other } = props;
  
  const [dataValueChanged,setdataValueChanged]=useState("");
  
     useEffect(() => {
   setdataValueChanged("");
   setData({ ...initObj})
  }, [vegEmployShare,nonVegEmployShare,snacksEmployShare,comboEmployShare]);
  
    
  if (!data.vegEmployShare && vegEmployShare && !dataValueChanged) {
    setData({ ...data, vegEmployShare: vegEmployShare });
  }
  if (!data.nonVegEmployShare && nonVegEmployShare && !dataValueChanged) {
    setData({ ...data, nonVegEmployShare: nonVegEmployShare });
  }
  if (!data.snacksEmployShare && snacksEmployShare && !dataValueChanged) {
    setData({ ...data, snacksEmployShare: snacksEmployShare });
  }
  if(!data.comboEmployShare && comboEmployShare && !dataValueChanged){
    setData({...data,comboEmployShare:comboEmployShare});
  }
 
  

  function handleChange(key, value) {
     setdataValueChanged("changed");
    setData({
      ...data,
      [key]: value,
    });
  }

  function submit() {
    if (validate()) {
      dispatch(updateMealSettings(data))
        .then(() => {
          toast.success("Meal Settings updated successfully");
          //          setData({ ...initObj })
        })
        .catch((err) => {
          toast.error(err.message || "Oops! Something went wrong");
        });
    }
  }

  function validate() {
    const { errors: newErrors, isValid } = validateMealSetting(data,veg,nonVeg,snacks,combo);
    setErrors(newErrors);
    let checkValid=true
    let validErrors={};
  
    
    return isValid;
  }

  const valid =(data.vegEmployShare || data.nonVegEmployShare || data.snacksEmployShare || data.comboEmployShare) ||
    false;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      <div id="no-more-tables" className="card-design table-design cf">
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <Box p={2} className="tab-con-form">
              <h5>Meal Settings</h5>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <div className="meal-set-heading">
                    <h4>
                      <MealTypeDot mealType="VEG" />
                      Veg Meal  
                    </h4>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Box>
                   
                    <InputField
                      className="password-input"
                      name="vegMeal"
                      fullWidth
                      variant="outlined"
                      value={veg}
                      label="BitesBee Price"
                      placeholder="BitesBee Price"
                      disabled
                    />
                      <span className="meal-setting-currency">&#8377;</span>
                  </Box>
                   <p className="cor-msg">
                   Exclusive of all taxes  
                  </p>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Box>
                    <InputField
                      className="password-input"
                      name="vegEmployShare"
                      fullWidth
                      variant="outlined"
                      value={data.vegEmployShare}
                      label="Employee Share"
                      placeholder="Employee Share"
                      onChange={({ target: { value } }) =>
                        handleChange("vegEmployShare", value)
                      }
                      error={errors.vegEmployShare}
                    />
                       <span className="meal-setting-percentage">&#8377;</span>
                  </Box>
                  <p className="cor-msg">
                    Enter an amount to collect from employee  
                  </p>
                </Grid>
                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <div className="meal-set-heading">
                    <h4>
                      <MealTypeDot mealType="NONVEG" />
                      Non Veg Meal  
                    </h4>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Box>
                  
                    <InputField
                      className="password-input"
                      name="nonVegMeal"
                      fullWidth
                      variant="outlined"
                      value={nonVeg}
                      label="BitesBee Price"
                      placeholder="BitesBee Price"
                      disabled
                    />
                      <span className="meal-setting-currency">&#8377;</span>
                  </Box>
                     <p className="cor-msg">
                   Exclusive of all taxes  
                  </p>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Box>
                    <InputField
                      className="password-input"
                      name="nonVegEmployShare"
                      fullWidth
                      variant="outlined"
                      value={data.nonVegEmployShare}
                      label="Employee Share"
                      placeholder="Employee Share"
                      onChange={({ target: { value } }) =>
                        handleChange("nonVegEmployShare", value)
                      }
                      error={errors.nonVegEmployShare}
                    />
                     <span className="meal-setting-percentage">&#8377;</span>
                  </Box>
                  <p className="cor-msg">
                    Enter an amount to collect from employee 
                  </p>
                </Grid>
                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <div className="meal-set-heading">
                    <h4>Snacks </h4>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Box>
                   
                    <InputField
                      className="password-input"
                      name="bitesBeeSnack"
                      fullWidth
                      variant="outlined"
                      value={snacks}
                      disabled
                      label="BitesBee Price"
                      placeholder="BitesBee Price"
                    />
                      <span className="meal-setting-currency">&#8377;</span>
                  </Box>
                     <p className="cor-msg">
                   Exclusive of all taxes  
                  </p>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Box>
                    <InputField
                      className="password-input"
                      name="snacksEmployShare"
                      fullWidth
                      variant="outlined"
                      value={data.snacksEmployShare}
                      label="Employee Share"
                      placeholder="Employee Share"
                      onChange={({ target: { value } }) =>
                        handleChange("snacksEmployShare", value)
                      }
                      error={errors.snacksEmployShare}
                    />
                     <span className="meal-setting-percentage">&#8377;</span>
                  </Box>
                  <p className="cor-msg">
                    Enter an amount to collect from employee  
                  </p>
                </Grid>
                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <div className="meal-set-heading">
                    <h4>Combo </h4>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Box>
                   
                    <InputField
                      className="password-input"
                      name="bitesBeeSnack"
                      fullWidth
                      variant="outlined"
                      value={combo}
                      disabled
                      label="BitesBee Price"
                      placeholder="BitesBee Price"
                    />
                      <span className="meal-setting-currency">&#8377;</span>
                  </Box>
                     <p className="cor-msg">
                   Exclusive of all taxes  
                  </p>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Box>
                    <InputField
                      className="password-input"
                      name="comboEmployShare"
                      fullWidth
                      variant="outlined"
                      value={data.comboEmployShare}
                      label="Employee Share"
                      placeholder="Employee Share"
                      onChange={({ target: { value } }) =>
                        handleChange("comboEmployShare", value)
                      }
                      error={errors.comboEmployShare}
                    />
                     <span className="meal-setting-percentage">&#8377;</span>
                  </Box>
                  <p className="cor-msg">
                    Enter an amount to collect from employee  
                  </p>
                </Grid>

                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <div className="meal-set-heading">
                    <h4>Alacart Discount  </h4>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Box>
                
                    <InputField
                      className="password-input"
                      name="bitesBeeSnack"
                      fullWidth
                      variant="outlined"
                      prefx="&#8377;"
                      value={allaCartOffer}
                      disabled
                      label="Alacart discount"
                      placeholder="Alacar discount"
                    />
                      <span className="meal-setting-percentage">%</span>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Box className="set-tabs-btn">
                    <Button
                      variant="contained"
                      className={`btn-custom-primry button-btn ${
                        valid ? "" : "disabled"
                      }`}
                      disabled={isLoading}
                      onClick={submit}
                    >
                      {isLoading ? (<>Loading
                        <CircularProgress size="24px" color="white" /></>
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
