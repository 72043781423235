import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
  Grid,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Paper,
  Tab,
  Tabs,
  AppBar,
  Badge,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EnhancedTableHead from "../../components/common/EnhancedTableHead";
import TableLoader from "../../components/common/TableLoader";
import CustomTablePagination from "../../components/common/CustomPagination";

import UpdateForm from "./PopupModal/UpdateForm";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { getObject } from "../../utils";
import moment from 'moment'

const TableHeaders = [
  { id: "createdat", numeric: false, disablePadding: false, label: "Date" },
  { id: "nonveg", numeric: false, disablePadding: false, label: "Transaction" },
  { id: "nonveg", numeric: false, disablePadding: false, label: "Transasction by" },
  { id: "totalveg", numeric: false, disablePadding: false, label: "Amount" },
];

function TabPanel(props) {
  const [editable, setEditable] = useState(false);

  const object = getObject("bb-user");

  const userObject = (object && JSON.parse(object)) || "";
  const { name = "", userType = "" } = (userObject && userObject.userDetails) || "";

  const user_id = (userObject.userDetails._id) || "";

  function handlePageChange(e, nextPage) {
    e.preventDefault();
    if (nextPage === page) {
      return;
    }

    setPage(nextPage);
    setSkip((nextPage - 1) * limit);
  }

  function capitalize(s) {
    return s[0].toUpperCase() + s.slice(1);
  }

  const {
    classes,
    isLoading,
    walletList,
    value,
    index,
    limit,
    page,
    count,
    setPage,
    setSkip,
    toast,
    numberFormat,
    MealTypeDot,
    from,
    ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className="table-design w-100"
    >
      {isLoading ? (
        <>
          <TableContainer className={classes.container}>
            <Table
              className="table-program"
              stickyHeader
              aria-label="sticky table"
            >
              <EnhancedTableHead headCells={TableHeaders} />
            </Table>
          </TableContainer>
          <TableLoader />
        </>
      ) : null}

      {!isLoading ? (
        <TableContainer className={classes.container}>
          <Table
            className="table-program"
            stickyHeader
            aria-label="sticky table"
          >
            <EnhancedTableHead headCells={TableHeaders} />
            <TableBody>
              {walletList && walletList.length ? (
                walletList.map((order, i) => {
                  const {
                    _id,
                    createdAt,
                    amount,
                    code,
                    type,
                    transactionBy,
                    to,
                  } = order;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      className="cursor_default"
                      key={_id}
                    >
                      {/* <TableCell className="table-custom-width">{orderNumber || '-'}</TableCell> */}

                      {/* <TableCell className="table-custom-width">{orderItem || '-'}</TableCell> */}

                      <TableCell className="table-custom-width">
                        {createdAt
                          ? moment((createdAt)).format('MMM DD, YY hh:mm A')
                          : "-"}
                      </TableCell>
                      <TableCell className="table-custom-width">
                        {type === "CREDIT" ?
                          <span className="red_badge">
                            CREDIT
                          </span> :
                          <span className="green_badge">
                            DEBIT
                          </span>

                        }
                      </TableCell>
                      <TableCell className="table-custom-width">
                        {transactionBy || "-"}
                      </TableCell>
                      <TableCell className="table-custom-width">
                        &#8377;{numberFormat(amount) || "-"}
                      </TableCell>
                      {/* <TableCell className="table-custom-width">
                      {code || ""}
                      </TableCell> */}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow hover role="checkbox" className="cursor_default">
                  <TableCell colSpan={6} className="table-custom-width">
                    <Box textAlign="center" p={1} className="no-data-found">
                      <p>No Data Found</p>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}

      <CustomTablePagination
        count={walletList.length}
        total={count}
        limit={limit}
        page={page}
        onPageChange={handlePageChange}
      />
    </div>
  );
}
export default TabPanel;
