import Joi from "joi";

export function validateAdditionalMeal(data) {
  let isValid = true
  let newErrors = {}

  const schema = Joi.object({
    vegAddOn: Joi
      .number().min(0)
      .max(1000)
      .messages({
        "number.base": `Veg Additional must be numeric!`,
        "number.min": ` Veg Additional cannot be less than 0`,
        "number.max": `Veg Additional cannot be greater than 1000`,
      }),
    nonVegAddOn: Joi
      .number().min(0)
      .max(1000)
      .messages({
        "number.base": `Non Veg Additional must be numeric!`,
        "number.min": `Non Veg Additional cannot be less than 0`,
        "number.max": `Non Veg Additional cannot be greater 1000`,
      }),
    shiftType: Joi
      .string()
      .required()
      .messages({
        "string.empty": `Shift type is required!`,
      }),
    status: Joi
      .string()
      .required()
      .messages({
        "string.empty": `Status type is required!`,
      })
  }).options({ allowUnknown: true }).validate(data, { abortEarly: false })

  const { error } = schema

  if (error) {
    isValid = false
    error.details.forEach(item => newErrors[item.context.key] = item.message)
  }

  return { isValid, errors: newErrors }
}

Joi.string().trim().required();