import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  RadioGroup,
  CircularProgress,
  FormControl,
  Box,
  FormControlLabel,
  InputLabel,
  Select,
  Grid,
  IconButton,
  Typography,
  Radio,
  TableRow,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import InputField from "../../../components/common/InputField";
import { green, red } from "@material-ui/core/colors";
import MealTypeDot from "../../Common/MealTypeDot";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import DeleteIcon from "@material-ui/icons/Delete";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export default function DeleteUserModal(props) {
  const { isLoading, email,deleteThisUser } = props;
  const [open, setOpen] = useState(false);

  //   function deleteThisUser(e,email){
  //      e.preventDefault();
  //       setUpdateLoading(true)
  //        dispatch(DeleteUser(email))
  //      .then(() => {
  //        setUpdateLoading(false)
  //         getUsers()
  //        toast.success("User has been deleted successfully")
  //      })
  //      .catch(({ message = '' }) => {
  //        toast.error(message || 'Oops! Something went wrong')
  //        setUpdateLoading(false)
  //      })
  //  }
  //    const [errors, setErrors] = useState({})
  
  function onSubmit(e){
        e.preventDefault();
        deleteThisUser(email);
  }

  function handleClickOpen() {
    setOpen(true);
    //    setErrors({});
  }

  function handleClose() {
    setOpen(false);
    //    setErrors({})
  }

  return (
    <div className="view-note-sec">
      <a
        className="daily-supply-listing view-comments-button"
        onClick={handleClickOpen}
      >
        <DeleteIcon  />
      </a>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
        fullWidth
        disableBackdropClick={true}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Confirmation
        </DialogTitle>
        <DialogContent dividers>
          <Box py={1} className="comments-here">
          <p>Are you sure, You want to delete this user<b> {email}</b>?</p>
          </Box>
        </DialogContent>
        <DialogActions>
          <div className="foot-modal-btns">
            <Button onClick={handleClose} color="secondary" variant="contained">
              Cancel
            </Button>
            <Button color="primary" variant="contained" disabled={isLoading} onClick={onSubmit}>
              {isLoading ? (
                <CircularProgress size="20px" color="white" />
              ) : (
                <>Delete</>
              )}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
