import Joi from "joi";

export function validateUser(data) {
  let isValid = true
  let newErrors = {}

  const schema = Joi.object({
    email: Joi
      .string()
      .trim()
      .required()
      .email({ tlds: { allow: false } })
      .messages({
        "string.empty": `Email is required!`,
        "string.email": `Invalid email!`
      }),
    name: Joi
      .string()
      .trim()
      .required()
      .messages({
        "string.empty": `Name is required!`,
      }),
       password: Joi
      .string()
      .trim()
      .required()
      .messages({
        "string.empty": `Password is required!`,
      }),

  }).options({allowUnknown: true}).validate(data, { abortEarly: false })

  const { error } = schema

  if (error) {
    isValid = false
    error.details.forEach(item => newErrors[item.context.key] = item.message)
  }
  
  return { isValid, errors: newErrors }
}


export function validateEditUser(data) {
  let isValid = true
  let newErrors = {}

  const schema = Joi.object({
    email: Joi
      .string()
      .trim()
      .required()
      .email({ tlds: { allow: false } })
      .messages({
        "string.empty": `Email is required!`,
        "string.email": `Invalid email!`
      }),
    name: Joi
      .string()
      .trim()
      .required()
      .messages({
        "string.empty": `Name is required!`,
      }),

  }).options({allowUnknown: true}).validate(data, { abortEarly: false })

  const { error } = schema

  if (error) {
    isValid = false
    error.details.forEach(item => newErrors[item.context.key] = item.message)
  }
  
  return { isValid, errors: newErrors }
}

Joi.string().trim().required();