import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  RadioGroup,
  CircularProgress,
  FormControl,
  Box,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Table,
  TableCell,
  TableBody,
  IconButton,
  Typography,
  Radio,
  TableRow,
  TableHead,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import InputField from "../../../components/common/InputField";
import { green, red } from "@material-ui/core/colors";
import MealTypeDot from "../../Common/MealTypeDot";
// import EnhancedTableHead from "../../../components/common/EnhancedTableHead";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

function EnhancedTableHead(props) {
  const {
    headCells
  } = props;

  return (

    <TableHead className="">
      <TableRow>
        {
          headCells.map((cell, key) => {
            return <TableCell colSpan={2} key={key}>
              {cell.label}
            </TableCell>
          })
        }
      </TableRow>
    </TableHead>
  );
}

const TableHeadersFirst = [
  {
    id: "meal_name",
    numeric: false,
    disablePadding: false,
    label: "Meal Name",
  },
  { id: "quantity", numeric: false, disablePadding: false, label: "Quantity" },
  { id: "mealtype", numeric: false, disablePadding: false, label: "Meal Type" },
  { id: "price", numeric: false, disablePadding: false, label: "Price" },
  { id: "total", numeric: false, disablePadding: false, label: "Total" },
];

const TableHeadersSecond = [
  {
    id: "meal_name",
    numeric: false,
    disablePadding: false,
    label: "Meal Name",
  },
  { id: "quantity", numeric: false, disablePadding: false, label: "Quantity" },
  // { id: "mealtype", numeric: false, disablePadding: false, label: "Meal Type" },
  { id: "price", numeric: false, disablePadding: false, label: "Price" },
  { id: "total", numeric: false, disablePadding: false, label: "Total" },
];
function GetLogo(props) {
  const { type } = props;
  if (type == "MORNING") {
    return (
      <svg id="sunrise" xmlns="http://www.w3.org/2000/svg" width="21.75" height="12.235" viewBox="0 0 21.75 12.235">
        <path id="Path_1280" data-name="Path 1280" d="M148.414,178.577a5.07,5.07,0,1,0-9.377,0Z" transform="translate(-132.766 -166.785)" fill="#ffce54" />
        <path id="Path_1281" data-name="Path 1281" d="M21.3,171.919H16.151a5.447,5.447,0,1,0-10.551,0H.453a.453.453,0,0,0,0,.907H21.3a.453.453,0,0,0,0-.907ZM6.334,170.56a4.541,4.541,0,0,1,9.082,0,4.559,4.559,0,0,1-.207,1.359H6.541A4.548,4.548,0,0,1,6.334,170.56Z" transform="translate(0 -160.591)" />
        <path id="Path_1282" data-name="Path 1282" d="M21.789,283.575h2.719a.453.453,0,0,0,0-.907H21.789a.453.453,0,0,0,0,.907Z" transform="translate(-20.43 -273.152)" />
        <path id="Path_1283" data-name="Path 1283" d="M405.789,282.668a.453.453,0,0,0,0,.907h2.719a.453.453,0,1,0,0-.906h-2.719Z" transform="translate(-388.117 -273.152)" />
        <path id="Path_1284" data-name="Path 1284" d="M359.243,126.969h0l1.922-1.922h0a.453.453,0,0,0-.641-.641h0l-1.922,1.922h0a.453.453,0,1,0,.641.641Z" transform="translate(-343.241 -121.486)" />
        <path id="Path_1285" data-name="Path 1285" d="M245.336,61.84a.453.453,0,0,0,.906,0h0V59.121h0a.453.453,0,1,0-.906,0h0V61.84Z" transform="translate(-234.914 -58.668)" />
        <path id="Path_1286" data-name="Path 1286" d="M87.073,125.047l1.922,1.922h0a.453.453,0,1,0,.641-.641h0l-1.922-1.922h0a.453.453,0,1,0-.641.641Z" transform="translate(-83.246 -121.486)" />
      </svg>


    );
  }
  else if (type == "AFTERNOON") {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16.347" height="16.347" viewBox="0 0 16.347 16.347">
        <g id="sun" transform="translate(0.25 0.25)">
          <ellipse id="Ellipse_60" data-name="Ellipse 60" cx="4.483" cy="4.483" rx="4.483" ry="4.483" transform="translate(3.441 3.441)" fill="#ffd347" />
          <path id="Path_1267" data-name="Path 1267" d="M108.165,112.886a4.722,4.722,0,0,1-3.686-7.673.239.239,0,0,1,.337-.037c.1.083.809-.45.726-.347a4.455,4.455,0,0,0-1.619,3.335,4.243,4.243,0,1,0,4.243-4.243,4.2,4.2,0,0,0-2.624.908c-.1.082-1.065,1.064-1.147.96a.639.639,0,0,1,.084-.576,4.483,4.483,0,0,1,3.686-1.771,4.722,4.722,0,0,1,0,9.444Z" transform="translate(-100.241 -100.24)" stroke="#000" stroke-width="0.5" />
          <path id="Path_1268" data-name="Path 1268" d="M248.5,1.76a.239.239,0,0,1-.239-.239V.239a.239.239,0,0,1,.479,0V1.52A.239.239,0,0,1,248.5,1.76Z" transform="translate(-240.579)" stroke="#000" stroke-width="0.5" />
          <path id="Path_1269" data-name="Path 1269" d="M125.011,34.85a.239.239,0,0,1-.208-.12l-.64-1.109a.239.239,0,0,1,.415-.239l.64,1.109a.239.239,0,0,1-.207.359Z" transform="translate(-120.289 -32.232)" stroke="#000" stroke-width="0.5" />
          <path id="Path_1270" data-name="Path 1270" d="M34.609,125.25a.238.238,0,0,1-.12-.032l-1.109-.64a.239.239,0,0,1,.239-.415l1.109.64a.24.24,0,0,1-.12.447Z" transform="translate(-32.231 -120.289)" stroke="#000" stroke-width="0.5" />
          <path id="Path_1271" data-name="Path 1271" d="M1.52,248.742H.239a.239.239,0,0,1,0-.479H1.52a.239.239,0,1,1,0,.479Z" transform="translate(0 -240.579)" stroke="#000" stroke-width="0.5" />
          <path id="Path_1272" data-name="Path 1272" d="M33.5,352.826a.239.239,0,0,1-.12-.447l1.109-.64a.239.239,0,1,1,.239.415l-1.109.64A.239.239,0,0,1,33.5,352.826Z" transform="translate(-32.231 -340.821)" stroke="#000" stroke-width="0.5" />
          <path id="Path_1273" data-name="Path 1273" d="M124.371,429.02a.24.24,0,0,1-.207-.359l.64-1.109a.239.239,0,1,1,.415.239l-.64,1.109A.239.239,0,0,1,124.371,429.02Z" transform="translate(-120.289 -414.202)" stroke="#000" stroke-width="0.5" />
          <path id="Path_1274" data-name="Path 1274" d="M248.5,456.911a.239.239,0,0,1-.239-.24v-1.281a.239.239,0,1,1,.479,0v1.281A.239.239,0,0,1,248.5,456.911Z" transform="translate(-240.579 -441.063)" stroke="#000" stroke-width="0.5" />
          <path id="Path_1275" data-name="Path 1275" d="M352.586,429.021a.239.239,0,0,1-.208-.12l-.64-1.109a.239.239,0,0,1,.415-.239l.64,1.109a.239.239,0,0,1-.207.359Z" transform="translate(-340.82 -414.203)" stroke="#000" stroke-width="0.5" />
          <path id="Path_1276" data-name="Path 1276" d="M428.781,352.825a.238.238,0,0,1-.12-.032l-1.109-.64a.239.239,0,0,1,.239-.415l1.109.64a.239.239,0,0,1-.12.447Z" transform="translate(-414.203 -340.82)" stroke="#000" stroke-width="0.5" />
          <path id="Path_1277" data-name="Path 1277" d="M456.67,248.742h-1.281a.239.239,0,1,1,0-.479h1.281a.239.239,0,0,1,0,.479Z" transform="translate(-441.062 -240.579)" stroke="#000" stroke-width="0.5" />
          <path id="Path_1278" data-name="Path 1278" d="M427.672,125.251a.239.239,0,0,1-.12-.447l1.109-.64a.239.239,0,1,1,.239.415l-1.109.64A.239.239,0,0,1,427.672,125.251Z" transform="translate(-414.203 -120.29)" stroke="#000" stroke-width="0.5" />
          <path id="Path_1279" data-name="Path 1279" d="M351.946,34.849a.24.24,0,0,1-.207-.359l.64-1.109a.239.239,0,1,1,.415.239l-.64,1.109A.24.24,0,0,1,351.946,34.849Z" transform="translate(-340.821 -32.231)" stroke="#000" stroke-width="0.5" />
        </g>
      </svg>

    );
  }
  else if (type == "EVENING") {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="15.777" height="15.923" viewBox="0 0 15.777 15.923">
        <g id="day" transform="translate(0.25 0.246)">
          <g id="Group_1652" data-name="Group 1652" transform="translate(0 1.806)">
            <path id="Path_1302" data-name="Path 1302" d="M108.627,105.258a4.627,4.627,0,0,1,0-9.254" transform="translate(-100.658 -94.722)" fill="#fdd020" />
          </g>
          <path id="Path_1306" data-name="Path 1306" d="M244.966,102.607a3.316,3.316,0,0,1-3.277-3.351,3.459,3.459,0,0,1,2.185-3.191,4.713,4.713,0,0,0-1.4-.16,4.432,4.432,0,0,0-2.47.752v7.749a4.44,4.44,0,0,0,6.684-2.279,2.99,2.99,0,0,1-1.717.479Zm0,0" transform="translate(-232.288 -92.817)" fill="#fce666" />
          <path id="Path_1307" data-name="Path 1307" d="M377.542,138.561l.953.527-.182-1.116.771-.79-1.066-.163L377.542,136l-.477,1.015-1.066.163.771.79-.182,1.116Zm0,0" transform="translate(-363.918 -131.63)" fill="#ffde3d" />
          <path id="Path_1308" data-name="Path 1308" d="M232.257,15.427A.257.257,0,0,1,232,15.17V.261a.257.257,0,0,1,.514,0V15.17A.257.257,0,0,1,232.257,15.427Zm0,0" transform="translate(-224.545 0)" fill="#92aeba" stroke="#000" stroke-width="0.5" />
          <path id="Path_1309" data-name="Path 1309" d="M65.713,58.08a.257.257,0,0,0,.363-.363l-1.542-1.542a.257.257,0,0,0-.363.363Zm0,0" transform="translate(-62.039 -54.296)" stroke="#000" stroke-width="0.5" />
          <path id="Path_1310" data-name="Path 1310" d="M2.571,232.261A.257.257,0,0,0,2.314,232H.257a.257.257,0,0,0,0,.514H2.314A.257.257,0,0,0,2.571,232.261Zm0,0" transform="translate(0 -224.546)" stroke="#000" stroke-width="0.5" />
          <path id="Path_1311" data-name="Path 1311" d="M65.523,360.175l-1.542,1.542a.257.257,0,1,0,.363.363l1.542-1.542a.257.257,0,0,0-.363-.363Zm0,0" transform="translate(-61.849 -348.528)" stroke="#000" stroke-width="0.5" />
          <path id="Path_1312" data-name="Path 1312" d="M107.275,9.1a2.738,2.738,0,0,1-1.576.437,3.061,3.061,0,0,1-3.02-3.094A3.206,3.206,0,0,1,104.7,3.489.257.257,0,0,0,104.686,3a4.971,4.971,0,0,0-1.483-.173,4.678,4.678,0,0,0-2.212.559V.261a.257.257,0,0,0-.514,0v2.6a4.884,4.884,0,0,0,0,9.713v2.6a.257.257,0,1,0,.514,0V12.04a4.69,4.69,0,0,0,6.669-2.646.257.257,0,0,0-.384-.3ZM96.62,7.715a4.375,4.375,0,0,1,3.856-4.337v8.674A4.375,4.375,0,0,1,96.62,7.715Zm6.582,4.37a4.175,4.175,0,0,1-2.212-.635V3.981a4.173,4.173,0,0,1,2.212-.635c.2,0,.41,0,.62.021a3.753,3.753,0,0,0-1.658,3.073,3.576,3.576,0,0,0,3.535,3.608,3.519,3.519,0,0,0,1.2-.192,4.143,4.143,0,0,1-3.7,2.23Zm0,0" transform="translate(-93.021)" stroke="#000" stroke-width="0.5" />
          <path id="Path_1313" data-name="Path 1313" d="M371.565,129.362a.257.257,0,0,0-.206-.172l-.93-.142-.418-.891a.257.257,0,0,0-.465,0l-.418.891-.93.142a.257.257,0,0,0-.145.433l.682.7-.161.988a.257.257,0,0,0,.378.266l.829-.459.829.458a.254.254,0,0,0,.124.032.257.257,0,0,0,.254-.3l-.161-.987.681-.7A.257.257,0,0,0,371.565,129.362Zm-1.2.692a.257.257,0,0,0-.07.221l.1.594-.49-.271a.257.257,0,0,0-.248,0l-.49.271.1-.594a.257.257,0,0,0-.07-.221l-.422-.432.571-.087a.257.257,0,0,0,.194-.145l.244-.52.244.52a.257.257,0,0,0,.194.145l.571.087Zm0,0" transform="translate(-356.155 -123.892)" stroke="#000" stroke-width="0.2" />
        </g>
      </svg>
    );
  }
  else if (type == "NIGHT") {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="12.258" height="13.596" viewBox="0 0 12.258 13.596">
        <g id="night-mode" transform="translate(0.252 0.25)">
          <path id="Path_1259" data-name="Path 1259" d="M19.726,19.4a7.411,7.411,0,0,1-.631.563,5.322,5.322,0,0,1-.769.509,6.325,6.325,0,0,1-2.609.745A6.332,6.332,0,0,1,10.667,10.6a6.372,6.372,0,0,1,3.847-1.972,5.791,5.791,0,0,1,.8-.055.218.218,0,0,1,.1.408,5.453,5.453,0,0,0,1.031,10.09,5.435,5.435,0,0,0,3.064-.033.218.218,0,0,1,.214.365Zm0,0" transform="translate(-8.785 -8.355)" fill="#ffdc00" />
          <path id="Path_1260" data-name="Path 1260" d="M19.1,22.047a5.322,5.322,0,0,1-.769.509,6.325,6.325,0,0,1-2.609.745,6.332,6.332,0,0,1-5.051-10.621,6.372,6.372,0,0,1,3.847-1.972,6.256,6.256,0,0,0,1.812,11.2,6.2,6.2,0,0,0,2.769.138Zm0,0" transform="translate(-8.785 -10.434)" fill="#ffdc00" />
          <path id="Path_1263" data-name="Path 1263" d="M6.949,13.094a6.659,6.659,0,0,1-4.384-1.657A6.445,6.445,0,0,1,.4,6.884,6.507,6.507,0,0,1,6.922,0h0A.429.429,0,0,1,7.344.33a.428.428,0,0,1-.216.49A5.318,5.318,0,0,0,4.3,5.531a5.23,5.23,0,0,0,6.755,4.943.436.436,0,0,1,.431.728,6.305,6.305,0,0,1-1.451,1.109,6.572,6.572,0,0,1-2.7.771c-.13.008-.26.012-.389.012ZM6.924.434A6.071,6.071,0,0,0,.84,6.862a6.012,6.012,0,0,0,2.014,4.248,6.217,6.217,0,0,0,4.458,1.537,6.129,6.129,0,0,0,2.519-.72,5.86,5.86,0,0,0,1.349-1.034A5.667,5.667,0,0,1,3.865,5.537,5.759,5.759,0,0,1,6.924.434Zm0,0" transform="translate(-0.392 0.002)" stroke="#000" stroke-width="0.5" />
          <path id="Path_1264" data-name="Path 1264" d="M278.482,71.545a.218.218,0,0,1-.218-.218,1.217,1.217,0,0,0-1.092-1.31.218.218,0,1,1,0-.437,1.217,1.217,0,0,0,1.092-1.31.218.218,0,1,1,.437,0,1.217,1.217,0,0,0,1.092,1.31.218.218,0,1,1,0,.437,1.217,1.217,0,0,0-1.092,1.31A.218.218,0,0,1,278.482,71.545Zm-.571-1.747a1.658,1.658,0,0,1,.571.63,1.656,1.656,0,0,1,.571-.63,1.656,1.656,0,0,1-.571-.629A1.657,1.657,0,0,1,277.911,69.8Zm0,0" transform="translate(-269.877 -66.31)" stroke="#000" stroke-width="0.5" />
          <path id="Path_1265" data-name="Path 1265" d="M363.533,181.823a.218.218,0,0,1-.218-.218.91.91,0,0,0-.811-.983.218.218,0,1,1,0-.437.91.91,0,0,0,.811-.983.218.218,0,1,1,.437,0,.91.91,0,0,0,.811.983.218.218,0,1,1,0,.437.91.91,0,0,0-.811.983A.218.218,0,0,1,363.533,181.823Zm-.365-1.419a.367.367,0,1,0,.73,0,.367.367,0,1,0-.73,0Zm0,0" transform="translate(-353.025 -174.405)" stroke="#000" stroke-width="0.5" />
        </g>
      </svg>

    );
  }
}

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
function HandleMealData(props) {
  const { mealData, numberFormat } = props;

  return (
    (mealData.length) ?
      <main className="meals-details-modal">
        <div className="meal-modal-head">
          <h5>Corporate Meals</h5>
          {(mealData[0].mealTiming) ? <h5><GetLogo type={mealData[0].mealTiming} />{capitalize(mealData[0].mealTiming.toLowerCase())}</h5> : ""}
        </div>
        <div className="table-design w-100">
          <Table className="table-program" stickyHeader aria-label="sticky table">
            <EnhancedTableHead headCells={TableHeadersFirst} />
            <TableBody>
              {mealData && mealData.length
                ? mealData.map((myMeal) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      className="cursor_default"
                      key={myMeal._id}
                    >
                      <TableCell colSpan={2} className="table-custom-width">
                        <span>{myMeal.mealName}</span>
                      </TableCell>
                      <TableCell colSpan={2} className="table-custom-width">
                        <span>{myMeal.quantity}</span>
                      </TableCell>
                      <TableCell colSpan={2} className="table-custom-width">
                        {(myMeal.mealType && (myMeal.mealType == "VEG" || myMeal.mealType == "NON VEG")) ? (
                          <Box display="flex" alignItems="center">
                            <MealTypeDot mealType={myMeal.mealType} />
                            {myMeal.mealType === "VEG" ? "Veg" : "Non Veg"}
                          </Box>
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell colSpan={2} className="table-custom-width">
                        <span> &#8377;{numberFormat(myMeal.price)}</span>
                      </TableCell>
                      <TableCell colSpan={2} className="table-custom-width">
                        {(myMeal.total) ? <span> &#8377;{numberFormat(myMeal.total)}</span> :
                          <span> &#8377;{numberFormat(0)}</span>}
                      </TableCell>
                    </TableRow>
                  );
                })
                : ""}
            </TableBody>
          </Table>
        </div>
      </main>
      : ""
  );
}
function HandleAllaCart(props) {
  const { allaCart, numberFormat } = props;

  return (
    (allaCart.length) ?
      <main className="meals-details-modal">
        <div className="meal-modal-head">
          <h5>&#x00C0; la carte Items</h5>
          <h5></h5>
          {/* {(allaCart[0].mealTiming) ? <h5><GetLogo type={allaCart[0].mealTiming} />{capitalize(allaCart[0].mealTiming.toLowerCase())}</h5> : ""} */}
        </div>
        <div className="table-design w-100">
          <Table className="table-program" stickyHeader aria-label="sticky table">
            <EnhancedTableHead headCells={TableHeadersSecond} />
            <TableBody>
              {allaCart && allaCart.length
                ? allaCart.map((myMeal) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      className="cursor_default"
                      key={myMeal._id}
                    >
                      <TableCell colSpan={2} className="table-custom-width table-title">
                        <span>{myMeal.mealName}</span>
                      </TableCell>
                      <TableCell colSpan={2} className="table-custom-width">
                        <span>{myMeal.quantity}</span>
                      </TableCell>
                      {/* <TableCell colSpan={2} className="table-custom-width">
                        {myMeal.mealType ? (
                          <Box display="flex" alignItems="center">
                            <MealTypeDot mealType={myMeal.mealType} />
                            {myMeal.mealType === "VEG" ? "Veg" : "Non Veg"}
                          </Box>
                        ) : (
                          ""
                        )}
                      </TableCell> */}
                      <TableCell colSpan={2} className="table-custom-width">
                        <span> &#8377;{numberFormat(myMeal.price)}</span>
                      </TableCell>
                      <TableCell colSpan={2} className="table-custom-width">
                        {(myMeal.total) ? <span> &#8377;{numberFormat(myMeal.total)}</span> :
                          <span> &#8377;{numberFormat(0)}</span>}
                      </TableCell>
                    </TableRow>
                  );
                })
                : ""}
            </TableBody>
          </Table>
        </div>
      </main>
      : ""

  );
}

export default function ViewMeal(props) {
  const { actionLabel, mealData, allaCart, numberFormat } = props;
  const [open, setOpen] = useState(false);
  //    const [errors, setErrors] = useState({})

  function handleClickOpen() {
    setOpen(true);
    //    setErrors({});
  }

  function handleClose() {
    setOpen(false);
    //    setErrors({})
  }

  return (
    <div className="meals-field">
      <a
        className="daily-supply-listing view-meal-button"
        onClick={handleClickOpen}
      >
        {actionLabel}
      </a>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="md"
        fullWidth
        disableBackdropClick={true}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Meal Data
        </DialogTitle>
        <DialogContent dividers>
          <Box py={1}>
            <HandleMealData numberFormat={numberFormat} mealData={mealData} />
            <HandleAllaCart numberFormat={numberFormat} allaCart={allaCart} />
          </Box>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="contained">
            Close
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
