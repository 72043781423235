import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import {
  Grid,
  Box,
  Paper,
  Card,
  CardContent,
  CircularProgress,
} from '@material-ui/core'
import { tablestyle } from '../../plugins/theme'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { getTimeStamps, numberFormat } from '../../utils'
import { useDispatch, useSelector } from 'react-redux'
import Chart from 'react-apexcharts'
import { DateRangePicker } from 'react-dates'
import CustomSelect from '../../components/common/CustomSelect'
import {
  getDashboardGraphs,
  getDashboardData,
  getDashboardMealGraphs,
  getDashboardExpenseGraphs,
  getDashboardPayoutGraphs,
} from './actions'
import { getCompanyDetailsAction } from '../Settings/actions'
import ChartWrapper from '../Common/ChartWrapper'
import DashboardIcon from '@material-ui/icons/Dashboard'
import { monthsForGraph } from '../../constant'
import { dateFilter, shiftType } from '../../constant'
import moment from 'moment'
import EventIcon from '@material-ui/icons/Event'
import totalExpanseIcon from '../../assets/images/totalExpanse.svg'
import totalMealIcon from '../../assets/images/totalMeal.svg'
import totalEmployeeIcon from '../../assets/images/totalEmploye.svg'
import newEmployeeIcon from '../../assets/images/newEmployee.svg'

function extractData(state) {
  const mon = Object.keys(monthsForGraph)

  const graphData = state?.dashboard?.graphData || []
  const nonVegOrders = []
  const vegOrders = []
  const nonVegExpenses = []
  const vegExpenses = []
  const months = []
  for (let month of mon) {
    const item = graphData?.find((i) => i._id == month) || {}
    const {
      nonVegOrder = '',
      vegOrder = '',
      vegOrderAmount = 0,
      nonVegOrderAmount = 0,
    } = item

    nonVegOrders.push(nonVegOrder)
    vegOrders.push(vegOrder)
    nonVegExpenses.push(nonVegOrderAmount)
    vegExpenses.push(vegOrderAmount)
  }

  return {
    nonVegOrders,
    vegOrders,
    months: Object.values(monthsForGraph),
    nonVegExpenses,
    vegExpenses,
  }
}

function getMealGraphData(state) {
  let mealGraph = state?.dashboard?.mealGraph || []
  const mon = Object.keys(monthsForGraph)
  const nonVegOrder = []
  const vegOrder = []
  const months = []
  for (let month of mon) {
    const item = mealGraph?.find((i) => i._id == month) || {}
    const { nonVegOrders = '', vegOrders = '' } = item
    nonVegOrder.push(nonVegOrders)
    vegOrder.push(vegOrders)
  }
  return {
    mealGraph: mealGraph,
    mealMonths: Object.values(monthsForGraph),
    mealVegOrders: vegOrder,
    mealNonVegOrders: nonVegOrder,
  }
}

function getExpenseGraphData(state) {
  let expenseGraph = state?.dashboard?.expenseGraph || []
  const mon = Object.keys(monthsForGraph)
  const nonVegExpenses = []
  const vegExpenses = []
  const months = []
  for (let month of mon) {
    const item = expenseGraph?.find((i) => i._id == month) || {}
    const { nonVegExpense = '', vegExpense = '' } = item
    nonVegExpenses.push(nonVegExpense)
    vegExpenses.push(vegExpense)
  }
  return {
    expenseGraph: expenseGraph,
    expenseMonths: Object.values(monthsForGraph),
    vegExpenses: vegExpenses,
    nonVegExpenses: nonVegExpenses,
  }
}

function empOrder(state) {
  const empOrderData = state?.dashboard?.dashboardData
  return {
    empOrderData,
  }
}

function getPayoutGraphData(state) {
  let payoutGraph = state?.dashboard?.payoutGraph || []
  const mon = Object.keys(monthsForGraph)
  const companyPayouts = []
  const employeePayouts = []
  const months = []
  for (let month of mon) {
    const item = payoutGraph?.find((i) => i._id == month) || {}
    const { companyPayout = '', employeePayout = '' } = item
    companyPayouts.push(companyPayout)
    employeePayouts.push(employeePayout)
  }
  return {
    payoutGraph: payoutGraph,
    payoutMonths: Object.values(monthsForGraph),
    companyPayout: companyPayouts,
    employeePayout: employeePayouts,
  }
}

function Dashboard(props) {
  const dispatch = useDispatch()
  const { loginUserType, loginUserPermission } = props

  let showdashboard,
    showTotalNumber,
    showMealSummary,
    showSummary,
    showPayoutSummary
  showdashboard = showTotalNumber = showMealSummary = showSummary = showPayoutSummary = true
  showdashboard =
    loginUserType == 'user' && loginUserPermission.dashboard
      ? loginUserPermission.dashboard.dashboard
      : showdashboard
  showTotalNumber =
    loginUserType == 'user' && loginUserPermission.dashboard
      ? loginUserPermission.dashboard.showTotalNumber
      : showTotalNumber
  showMealSummary =
    loginUserType == 'user' && loginUserPermission.dashboard
      ? loginUserPermission.dashboard.showMealSummary
      : showMealSummary
  showSummary =
    loginUserType == 'user' && loginUserPermission.dashboard
      ? loginUserPermission.dashboard.showSummary
      : showSummary
  showPayoutSummary =
    loginUserType == 'user' && loginUserPermission.dashboard
      ? loginUserPermission.dashboard.showPayoutSummary
      : showPayoutSummary

  const [filter, setFilter] = useState({ value: 'today', label: 'Today' })
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [focusedInput, setFocusedInput] = useState(false)

  // const { nonVegOrders, vegOrders, months, nonVegExpenses, vegExpenses } = useSelector(extractData)
  const {
    mealGraph,
    mealMonths,
    mealVegOrders,
    mealNonVegOrders,
  } = useSelector(getMealGraphData)

  const {
    expenseGraph,
    expenseMonths,
    nonVegExpenses,
    vegExpenses,
  } = useSelector(getExpenseGraphData)
  const {
    payoutGraph,
    payoutMonths,
    companyPayout,
    employeePayout,
  } = useSelector(getPayoutGraphData)
  const isLoading = useSelector((state) => state?.dashboard?.isLoading || false)

  const { empOrderData = {} } = useSelector(empOrder)
  const { employees = {} } = empOrderData
  const { orders = {} } = empOrderData

  const [mealShift, setMealShift] = useState({ label: 'Day', value: 'DAY' })
  const [expenseShift, setExpenseShift] = useState({
    label: 'Day',
    value: 'DAY',
  })

  const [mealGraphIsLoading, setmealGraphIsLoading] = useState(true)
  const [expenseGraphIsLoading, setexpenseGraphIsLoading] = useState(true)
  const [payoutGraphIsLoading, setpayoutGraphIsLoading] = useState(true)

  function UpdateLOGO() {
    dispatch(getCompanyDetailsAction('true'))
  }

  useEffect(() => {
    UpdateLOGO()
  }, [])

  useEffect(() => {
    if (!startDate || !endDate) return
    getData()
    getGraphData()
    getExpenseData()
    getPayoutData()
  }, [endDate])

  useEffect(() => {
    if (filter?.value) {
      const { startDate: newStartDate, endDate: newEndDate } = getTimeStamps(
        filter.value,
        startDate,
        endDate,
      )
      if (filter.value !== 'customDate') {
        setEndDate(moment(newEndDate))
        setStartDate(moment(newStartDate))
      }
    }
  }, [filter])

  useEffect(() => {
    if (!startDate || !endDate) return
    getGraphData()
  }, [mealShift])

  useEffect(() => {
    if (!startDate || !endDate) return
    getExpenseData()
  }, [expenseShift])

  function getGraphData() {
    let data = {
      startDate: moment(startDate).valueOf(),
      endDate: moment(endDate).valueOf(),
    }
    if (
      showdashboard &&
      showdashboard == true &&
      showMealSummary &&
      showMealSummary == true
    ) {
      dispatch(getDashboardMealGraphs(data))
      setmealGraphIsLoading(false)
    }
  }
  function getExpenseData() {
    let data = {
      startDate: moment(startDate).valueOf(),
      endDate: moment(endDate).valueOf(),
      // shift: mealShift.value,
    }
    if (
      showdashboard &&
      showdashboard == true &&
      showSummary &&
      showSummary == true
    ) {
      dispatch(getDashboardExpenseGraphs(data))
      setexpenseGraphIsLoading(false)
    }
  }
  function getPayoutData() {
    let data = {
      startDate: moment(startDate).valueOf(),
      endDate: moment(endDate).valueOf(),
    }
    if (
      showdashboard &&
      showdashboard == true &&
      showPayoutSummary &&
      showPayoutSummary == true
    ) {
      dispatch(getDashboardPayoutGraphs(data))
      setpayoutGraphIsLoading(false)
    }
  }

  function getData() {
    const data = {
      startDate: moment(startDate).valueOf(),
      endDate: moment(endDate).valueOf(),
    }
    if (
      showdashboard &&
      showdashboard == true &&
      showTotalNumber &&
      showTotalNumber == true
    ) {
      dispatch(getDashboardData(data))
      dispatch(getDashboardGraphs(data))
    }
  }

  function handleDateChange({ startDate, endDate }) {
    if (startDate) {
      setStartDate(startDate)
    }
    if (endDate) {
      setEndDate(endDate)
    }
  }
  function handleShiftChange(value, type) {
    if (type == 'MealData') {
      setmealGraphIsLoading(true)
      shiftType.map((shift) => {
        if (shift.value == value) {
          setMealShift(shift)
        }
      })
    } else if (type == 'ExpenseData') {
      setexpenseGraphIsLoading(true)
      shiftType.map((shift) => {
        if (shift.value == value) {
          setExpenseShift(shift)
        }
      })
    }
  }

  if (showTotalNumber == true || showPayoutSummary == true || showSummary == true || showTotalNumber == true) {
    return (
      <React.Fragment>
        {isLoading ? (
          <div className="main-advanced-loader">
            <CircularProgress size={45} />
          </div>
        ) : null}
        <Grid container className="location-container">
          <Grid item xs={12}>
            <Grid container className="location-head">
              <Grid item xs={12} sm={12} md={4} lg={4} className="heading">
                <h2>
                  <DashboardIcon />
                  Dashboard
                </h2>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} className="dashboard_images">
                <a href="https://play.google.com/store/apps/details?id=com.bitesbee.employee" target="_blank" > <img src="/images/google-store.png" alt="google_store" /></a>
                <a href="https://apps.apple.com/in/app/bitesbee-employee/id6450348101" target="_blank">  <img src="/images/apple-store.png" alt="apple_store" /></a>


                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <CustomSelect
                    minWidth={180}
                    options={dateFilter}
                    placeholder="Filter"
                    isSearchable
                    onChange={(opt) => setFilter(opt)}
                    value={filter}
                  />
                  {filter?.value !== 'customDate' ? (
                    ''
                  ) : (
                    <div className="date-range-picker">
                      <Box pl={1}>
                        <DateRangePicker
                          // disabled={filter?.value !== "customDate"}
                          startDate={startDate}
                          startDateId="startDate"
                          startDatePlaceholderText="From..."
                          endDatePlaceholderText="To..."
                          minimumNights={0}
                          customArrowIcon={
                            <p className="start-end-date-divider">|</p>
                          }
                          endDate={endDate}
                          endDateId="endDate"
                          customInputIcon={
                            <EventIcon className="date-picker-icon" />
                          }
                          isOutsideRange={(date) =>
                            date.isBefore(moment().year()) ||
                            date.isAfter(moment())
                          }
                          onDatesChange={handleDateChange}
                          focusedInput={focusedInput}
                          onFocusChange={(focusedInput) =>
                            setFocusedInput(focusedInput)
                          }
                        ></DateRangePicker>
                      </Box>
                    </div>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2} className="mt-3">
            {showTotalNumber == true ? (
              <>
                {/* <CardField img={totalExpanseIcon}>
                  <h5>Total Expense</h5>
                  <h6>
                    &#8377;
                    {orders.totalOrdersAmount
                      ? numberFormat(orders.totalOrdersAmount)
                      : '0'}
                  </h6>
                  <p>
                    Veg: &#8377;
                    {orders.vegOrderAmount
                      ? numberFormat(orders.vegOrderAmount)
                      : '0'}{' '}
                    | Non Veg: &#8377;
                    {orders.nonVegOrderAmount
                      ? numberFormat(orders.nonVegOrderAmount)
                      : '0'}{' '}
                  </p>
                </CardField> */}

                <CardField img={totalMealIcon}>
                  <h5>Total Meals</h5>
                  <h6>{orders?.totalOrders || '0'} </h6>
                  {/* <p>
                    Veg: {orders?.vegOrder || '0'} | Non Veg:{' '}
                    {orders?.nonVegOrder || '0'}{' '}
                  </p> */}
                </CardField>

                <CardField img={newEmployeeIcon}>
                  <h5>New Employees Added</h5>
                  <h6>{employees?.newEmployees || '0'} </h6>

                  {/* <p>
                    Day Shift: {employees?.newEmployeesDay || '0'} | Night
                    Shift: {employees?.newEmployeesNight || '0'}{' '}
                  </p> */}

                </CardField>

                <CardField img={totalEmployeeIcon}>
                  <h5>Total Employees</h5>
                  <h6>{employees?.totalEmployees || '0'}</h6>
                  {/* <p>
                    Day Shift: {employees?.totalEmployeesDay || '0'} | Night
                    Shift: {employees?.totalEmployeesNight || '0'}
                  </p> */}
                </CardField>
              </>
            ) : (
              ''
            )}
          </Grid>
          <Grid item xs={12}>
            <Box mt={3}>
              <Grid container spacing={2}>
                {showMealSummary == true ? (
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    xl={6}
                    className="chart-height"
                  >
                    <ChartWrapper
                      title="Meals Summary"
                      shiftType={shiftType}
                      selectedShift={mealShift}
                      type="MealData"
                      handleShiftChange={handleShiftChange}
                      isLoading={mealGraphIsLoading}
                    >
                      <ApexBarChart
                        id="meals-chart"
                        series1={mealVegOrders}
                        series2={mealNonVegOrders}
                        months={mealMonths}
                        chartDefaults={barChartDefaults}
                      />
                    </ChartWrapper>
                  </Grid>
                ) : (
                  ''
                )}
                {showSummary == true ? (
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    xl={6}
                    className="chart-height"
                  >
                    <ChartWrapper
                      title="Expenses Summary"
                      shiftType={shiftType}
                      selectedShift={expenseShift}
                      type="ExpenseData"
                      handleShiftChange={handleShiftChange}
                      isLoading={expenseGraphIsLoading}
                    >
                      <LineChart
                        id="expenses-chart"
                        series1={nonVegExpenses}
                        series2={vegExpenses}
                        months={expenseMonths}
                        chartDefaults={lineChartOptions}
                      />
                    </ChartWrapper>
                  </Grid>
                ) : (
                  ''
                )}
                {showPayoutSummary == true ? (
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    xl={6}
                    className="chart-height"
                  >
                    <ChartWrapper
                      title="Payout Summary"
                      type="CompanyData"
                      isLoading={payoutGraphIsLoading}
                    >
                      <LineChartPayout
                        id="expenses-chart"
                        series1={companyPayout}
                        series2={employeePayout}
                        months={payoutMonths}
                        chartDefaults={lineChartOptionsPayout}
                      />
                    </ChartWrapper>
                  </Grid>
                ) : (
                  ''
                )}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  } else {
    return (
      <Grid container className="location-container">
        <Grid item xs={12}>
          <Grid container className="location-head">
            <Grid item xs={12} sm={12} md={4} lg={4} className="heading">
              <h2>
                <DashboardIcon />
                Dashboard
              </h2>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Box display="flex" justifyContent="space-between" p={2}>
            <h6>You do not have permission to view this page</h6>
          </Box>
        </Grid>
      </Grid>
    )
  }
}

export default withTranslation('translations')(
  withStyles(tablestyle)(Dashboard),
)

function CardField(props) {
  return (
    <Grid item xs={12} sm={6} md={6} lg={3}>
      <Card className="box-shadow">
        <CardContent>
          <Grid container>
            <Grid item xs={3} sm={3} md={3} lg={3}>
              <div className="dashboard-card-img">
                <img src={props.img} alt="" />
              </div>
            </Grid>
            <Grid item xs={9} sm={9} md={9} lg={9}>
              <div className="dashboard-card-connent">{props?.children}</div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}

function ApexBarChart(props) {
  const { series1, series2, months, id, chartDefaults = {} } = props
  const series = [

    {
      name: 'Veg',
      data: series1,
    },
    {
      name: 'Non Veg',
      data: series2,
    },
  ]
  const [options, setOptions] = useState({ ...chartDefaults })

  useEffect(() => {
    setOptions(chartDefaults)
  }, [])

  useEffect(() => {
    if (months?.length) {

      setOptions({
        ...chartDefaults.options,
        xaxis: {
          ...chartDefaults.options.xaxis,
          categories: months,
        },
        legend: {
          ...chartDefaults.options.legend,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val.toFixed(0);
            }
          }
        }
      })
    }
  }, [months])
  return (
    <div id={id}>
      <Chart options={options} series={series} type="bar" height={350} />
    </div>
  )
}

const barChartDefaults = {
  options: {
    chart: {
      type: 'bar',
      stacked: false,
      toolbar: {
        show: false,
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 100,
        animateGradually: {
          enabled: true,
          delay: 1500,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 550,
        },
      },
    },
    dataLabels: {
      style: {
        fontSize: '10px',
        colors: ['#fff'],
      },
    },
    colors: ['#2EB872', '#FA4659'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '80%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    legend: {
      markers: {
        radius: 8,
      },
      itemMargin: {
        horizontal: 16,
        vertical: 5,
      },
    },

    tooltip: {
      followCursor: true,
      shared: false,
      // intersect: false,
      x: {
        show: true,
      },
    },
    xaxis: {
      axisTicks: {
        show: false,
      },
      categories: [],
    },
    fill: {
      opacity: 1,
    },
  },
}

const lineChartOptions = {
  options: {
    chart: {
      height: 240,
      type: 'area',
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ['#2EB872', '#FA4659'],

    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.8,
        opacityTo: 0.1,
      },
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 1,
    },
    grid: {
      yaxis: {
        lines: {
          show: false,
        },
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    markers: {
      size: 3.5,
      colors: ['#FFFFFF', '#FFFFFF'],
      strokeColors: ['#FA4659', '#2EB872'],
      strokeWidth: 1,
      shape: 'circle',
      hover: {
        size: 5,
      },
    },
    xaxis: {
      axisTicks: false,
      tickPlacement: 'between',
      categories: [],
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        formatter: (value) => {
          return 'Rs. ' + Number(value).toFixed(2)
        },
      },
    },
    legend: {
      // position: 'top',
      // horizontalAlign: 'right',
      // floating: true,
      // offsetY: -25,
      // offsetX: -5
    },
    tooltip: {
      followCursor: false,
      shared: true,
      intersect: false,
      x: {
        show: false,
      },
      custom: ({ dataPointIndex, series }) => {
        const veg = series[1][dataPointIndex] || 0
        const nonVeg = series[0][dataPointIndex] || 0
        const total = Number(veg) + Number(nonVeg)
        return `<span>
            <p style="font-size: 12px">&nbsp;&nbsp;Veg: ${Number(
          veg || 0,
        ).toFixed(2)}&nbsp;&nbsp;</p>
            <p style="font-size: 12px">&nbsp;&nbsp;Non Veg: ${Number(
          nonVeg || 0,
        ).toFixed(2)}&nbsp;&nbsp;</p>
            <p style="font-size: 12px"><b>&nbsp;&nbsp;Total: ${Number(
          total || 0,
        ).toFixed(2)}&nbsp;&nbsp;</b></p>
          </span>`
      },
    },
    animations: {
      enabled: true,
      easing: 'easeinout',
      speed: 800,
      animateGradually: {
        enabled: true,
        delay: 150,
      },
      dynamicAnimation: {
        enabled: true,
        speed: 350,
      },
    },
  },
}

const lineChartOptionsPayout = {
  options: {
    chart: {
      height: 240,
      type: 'area',
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ['#1c2b79', '#f9c546'],

    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.8,
        opacityTo: 0.1,
      },
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 1,
    },
    grid: {
      yaxis: {
        lines: {
          show: false,
        },
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    markers: {
      size: 3.5,
      colors: ['#FFFFFF', '#FFFFFF'],
      strokeColors: ['#FA4659', '#2EB872'],
      strokeWidth: 1,
      shape: 'circle',
      hover: {
        size: 5,
      },
    },
    xaxis: {
      axisTicks: false,
      tickPlacement: 'between',
      categories: [],
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        formatter: (value) => {
          return 'Rs. ' + Number(value).toFixed(2)
        },
      },
    },
    legend: {
      // position: 'top',
      // horizontalAlign: 'right',
      // floating: true,
      // offsetY: -25,
      // offsetX: -5
    },
    tooltip: {
      followCursor: false,
      shared: true,
      intersect: false,
      x: {
        show: false,
      },
      custom: ({ dataPointIndex, series }) => {
        const employee = series[1][dataPointIndex] || 0
        const company = series[0][dataPointIndex] || 0
        const total = Number(employee) + Number(company)
        return `<span>
            <p style="font-size: 12px">&nbsp;&nbsp;Employee Share: ${Number(
          employee || 0,
        ).toFixed(2)}&nbsp;&nbsp;</p>
            <p style="font-size: 12px">&nbsp;&nbsp;Company Share: ${Number(
          company || 0,
        ).toFixed(2)}&nbsp;&nbsp;</p>
            <p style="font-size: 12px"><b>&nbsp;&nbsp;Total: ${Number(
          total || 0,
        ).toFixed(2)}&nbsp;&nbsp;</b></p>
          </span>`
      },
    },
    animations: {
      enabled: true,
      easing: 'easeinout',
      speed: 800,
      animateGradually: {
        enabled: true,
        delay: 150,
      },
      dynamicAnimation: {
        enabled: true,
        speed: 350,
      },
    },
  },
}

function LineChart(props) {
  const { chartDefaults, months = '', series1, series2 } = props

  const series = [

    {
      name: 'Veg',
      data: series2,
    },
    {
      name: 'Non Veg',
      data: series1,
    },
  ]
  const [options, setOptions] = useState({})

  useState(() => {
    if (months.length) {
      setOptions({
        ...chartDefaults.options,
        xaxis: {
          ...chartDefaults.options.xaxis,
          categories: months,
        },
        legend: {
          ...chartDefaults.options.legend,
        },
      })
    }
  }, [months])

  return (
    <div>
      <Chart options={options} series={series} type="area" height={350} />
    </div>
  )
}
function LineChartPayout(props) {
  const { chartDefaults, months = '', series1, series2 } = props

  const series = [
    {
      name: 'Company Share',
      data: series1,
    },
    {
      name: 'Employee Share',
      data: series2,
    },
  ]
  const [options, setOptions] = useState({})

  useState(() => {
    if (months.length) {
      setOptions({
        ...chartDefaults.options,
        xaxis: {
          ...chartDefaults.options.xaxis,
          categories: months,
        },
        legend: {
          ...chartDefaults.options.legend,
        },
      })
    }
  }, [months])

  return (
    <div>
      <Chart options={options} series={series} type="area" height={350} />
    </div>
  )
}
