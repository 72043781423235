import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  RadioGroup,
  CircularProgress,
  FormControl,
  Box,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Table,
  TableCell,
  TableBody,
  IconButton,
  Typography,
  Radio,
  TableRow,
  TextField
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import InputField from "../../../components/common/InputField";
import { updateDailySupply } from "../actions";
import { green, red } from "@material-ui/core/colors";
import MealTypeDot from "../../Common/MealTypeDot";
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { useDispatch, useSelector } from "react-redux";
import EnhancedTableHead from "../../../components/common/EnhancedTableHead";
import moment from "moment";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const initSupply = {
  orderId: "",
  comments: "",
  user_id: ""
};

const TableHeaders = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  { id: "message", numeric: false, disablePadding: false, label: "Message" },
  { id: "updatedat", numeric: false, disablePadding: false, label: "Updated At" },
];

export default function ViewNote(props) {
  const { actionLabel, comments, orderId, getDailySupply, toast, user_id, userType } = props;
  const [open, setOpen] = useState(false);
  const [dataChange, setDataChange] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [addButtonDisable, setAddButtonDisable] = useState(false);
  const dispatch = useDispatch();
  const [supply, setSupply] = useState({ ...initSupply });

  if (!supply.orderId && orderId) {
    setSupply({ ...supply, orderId: orderId });
  }

  if (!supply.user_id && user_id && userType != "superAdmin") {
    setSupply({ ...supply, user_id: user_id });
  }
  //    const [errors, setErrors] = useState({})

  function handleClickOpen() {
    setOpen(true);
    //    setErrors({});
  }

  function handleClose() {
    setOpen(false);
    //    setErrors({})
  }
  function onSubmit(e) {
    e.preventDefault();
    setAddButtonDisable(true);
    setIsLoading(true);
    updateOrder();
  }
  function updateOrder() {
    const { toast, getDailySupply } = props;
    dispatch(updateDailySupply(supply))
      .then(async () => {
        getDailySupply();
        handleClose();
        setAddButtonDisable(false);
        setIsLoading(false);
        toast.success("Order Updated Successfully");
      })
      .catch(({ message = "" }) => {
        toast.error(message || "Oops! Something went wrong");
        setAddButtonDisable(false);
        setIsLoading(false);
      });
  }
  function handleChange(key, value) {
    setSupply({ ...supply, [key]: value });
    setDataChange(true);
  }

  return (
    <div className="view-note-sec">
      <a
        className="daily-supply-listing view-comments-button"
        onClick={handleClickOpen}
      >
        <DescriptionOutlinedIcon />
        {actionLabel}
      </a>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
        fullWidth
        disableBackdropClick={true}
        className="modal-contents-main"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Feedback
        </DialogTitle>
        <DialogContent >
          <Box py={1} className="modal-textarea">
            <TextField
              id="outlined-multiline-static"
              label="Feedback"
              multiline
              fullWidth
              rows={4}
              inputProps={{ maxLength: 3000 }}
              variant="outlined"
              onChange={({ target: { value } }) =>
                handleChange("comments", value)
              }
            />
          </Box>
        </DialogContent>
        <DialogActions >
          <div className="foot-modal-btns">
            <Button
              color="primary"
              variant="contained"
              onClick={onSubmit}
              disabled={isLoading || supply.comments.trim() === ""}
            >
              {isLoading ? (
                <CircularProgress size="20px" color="white" />
              ) : (
                <>Submit</>
              )}
            </Button>
          </div>
        </DialogActions>
        {(comments && comments.length > 0) && <div className="modal-comments-sec">
          <h5>Comments</h5>
          <div className="table-design w-100">
            <Table className="table-program" stickyHeader aria-label="sticky table">
              <EnhancedTableHead headCells={TableHeaders} />
              <TableBody>
                {comments && comments.length
                  ? comments.map((mycomment) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        className="cursor_default"
                        key={mycomment._id}
                      >
                        <TableCell className="table-custom-width">
                          <span>{mycomment.name}</span>
                        </TableCell>
                        <TableCell className="table-custom-width">
                          <span>{mycomment.message || "-"}</span>
                        </TableCell>
                        <TableCell className="table-custom-width">
                          <span>{moment(mycomment.time).format('MMM DD, YYYY hh:mm A')}</span>
                        </TableCell>
                      </TableRow>
                    );
                  })
                  : ""}
              </TableBody>
            </Table>
          </div>
        </div>}
        {/* <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="contained">
            Close
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
