import { loginAPI, forgotPasswordAPI } from "./apis.js";
import { actionsTypes } from "./actionsTypes";

//Login action
export function login(data) {
  return (dispatch) => {
    dispatch({
      type: actionsTypes.LOGIN_REQUEST,
    });
    return new Promise((resolve, reject) => {
      loginAPI(data)
        .then(({ data }) => {
          dispatch({
            type: actionsTypes.LOGIN_REQUEST_SUCCEEDED,
            payload: data,
          });

          return resolve(data);
        })
        .catch((err) => {
          dispatch({
            type: actionsTypes.LOGIN_REQUEST_FAILED,
          });
          return reject(err);
        });
    });
  };
}

export function forgotPassword(data) {
  return (dispatch) => {
    dispatch({
      type: actionsTypes.FORGOT_PASSWORD_REQUEST,
    });
    return new Promise((resolve, reject) => {
      forgotPasswordAPI(data)
        .then(({ data }) => {

          dispatch({
            type: actionsTypes.FORGOT_PASSWORD_SUCCEEDED,
            payload: data,
          });

          return resolve(data);
        })
        .catch((err) => {
          dispatch({
            type: actionsTypes.FORGOT_PASSWORD_FAILED,
          });
          return reject(err);
        });
    });
  };
}
